import AvatarIcon from 'assets/img/Avatar.svg';

import { AvatarImg, AvatarStyled } from './style';

type AvatarProps = React.ImgHTMLAttributes<HTMLImageElement>;

interface AvatarCustomProps {
  size?: number;
}

export function Avatar(props: AvatarProps & AvatarCustomProps) {
  const { size, src, ...rest } = props;

  return (
    <AvatarStyled size={size as number}>
      <AvatarImg src={src || AvatarIcon} alt="Avatar" {...rest} />
    </AvatarStyled>
  );
}

Avatar.defaultProps = {
  size: 36,
};
