import styled from 'styled-components';

export const ExpectationContainer = styled.div`
  height: 20px;

  svg {
    margin-right: 5px;
    cursor: pointer;
  }
`;
