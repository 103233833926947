import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'components/atoms/Button';
import { DatePicker } from 'components/atoms/DatePicker';
import { ActionIcon } from 'components/atoms/Icons/ActionIcon';
import { LinkIcon } from 'components/atoms/Icons/LinkIcon';
import { SpinnerIcon } from 'components/atoms/Icons/SpinnerIcon';
import { SearchField } from 'components/molecules/SearchField';
import { useModal } from 'contexts/ModalContext';
import { format } from 'date-fns';
import { colors } from 'styles/colors';
import { IconButton } from 'styles/miscellany';
import { validator } from 'utils/validators/form-validations';

import { UPDATE_PAYMENT } from '../../query';
import { SEND_PO, VERIFY_PO } from './query';
import {
  IconHolder,
  POForm,
  POModalContainer,
  PreviewData,
  VariantContainer,
  VariantText,
} from './style';

const POModalVariants = ({ variant, poData, paymentId, refetch }) => {
  const { closeModal } = useModal();
  const methods = useForm();
  const [sendPO, sendPORes] = useMutation(SEND_PO);
  const [updatePayment] = useMutation(UPDATE_PAYMENT);

  const [isCustomDate, setIsCustomDate] = useState(false);

  const onSubmit = (data) => {
    const customDate = format(data.datePicker, 'yyyy-MM-dd');

    const payload = {
      cnpj: poData.code.replace(/\D/g, ''),
      date: isCustomDate ? customDate : poData.dateExpected,
    };
    sendPO({
      variables: {
        ...payload,
        metadata: JSON.stringify(payload),
      },
    })
      .then((poResp) => {
        const poCode = poResp?.data.sendPO;
        return updatePayment({
          variables: { id: paymentId, poCode, status: 'PO Emitida' },
        });
      })
      .then(() => {
        toast.success('PO Enviado com sucesso!');
        if (refetch) {
          refetch();
        }
        closeModal();
      })
      .catch(() => toast.error('Erro ao enviar PO'));
  };

  if (variant === 'IRREGULAR') {
    return (
      <VariantContainer>
        <VariantText>
          Este fornecedor não está em ordem
          <br /> com a Receita Federal.
        </VariantText>
        <Button variant="secondary" onClick={closeModal}>
          fechar
        </Button>
      </VariantContainer>
    );
  }

  if (variant === 'NOT_FOUND') {
    return (
      <VariantContainer>
        <VariantText>
          Fornecedor sem cadastro concluído
          <br /> com a Receita Federal.
        </VariantText>
        <a href={poData.registrationLink} target="_blank" rel="noreferrer">
          <IconButton
            size={0.875}
            color={colors.primary}
            style={{ gridTemplateColumns: '1fr 20px', textAlign: 'left' }}
          >
            {poData.registrationLink} <LinkIcon />
          </IconButton>
        </a>
      </VariantContainer>
    );
  }

  const dt = new Date(poData.dateExpected);
  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000,
  );

  return (
    <VariantContainer>
      <PreviewData>
        Data prevista: {format(dtDateOnly, 'dd/MM/yyyy')}
      </PreviewData>
      <p>ou</p>
      <p>Escolha uma data personalizada:</p>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div
            style={{
              filter: isCustomDate ? '' : 'grayscale(1) opacity(0.5)',
            }}
          >
            <DatePicker onClick={() => setIsCustomDate(true)} />
          </div>
          <Button variant="primary">
            {sendPORes.loading ? (
              <SpinnerIcon variant="light" width={20} height={20} />
            ) : (
              'enviar'
            )}
          </Button>
        </form>
      </FormProvider>
    </VariantContainer>
  );
};

export function POModal({ paymentId, refetch }) {
  const methods = useForm();
  const [variant, setVariant] = useState<any>(null);
  const [verifyPo, verifyPoRes] = useLazyQuery(VERIFY_PO, {
    notifyOnNetworkStatusChange: true,
  });
  const cnpjData = verifyPoRes.data?.CNPJ;

  const onSubmit = (data) => {
    verifyPo({ variables: { code: data.cnpj } });
  };

  useEffect(() => {
    if (cnpjData) {
      if (!cnpjData.isRegistered) {
        setVariant('NOT_FOUND');
      } else {
        setVariant(cnpjData.isRegular ? 'REGULAR' : 'IRREGULAR');
      }
    }
  }, [cnpjData]);

  return (
    <POModalContainer>
      <IconHolder>
        <ActionIcon color={colors.light} />
      </IconHolder>
      <h6>Enviar PO</h6>

      <FormProvider {...methods}>
        <POForm onSubmit={methods.handleSubmit(onSubmit)} id="po-form">
          <SearchField
            name="cnpj"
            label="CNPJ"
            readOnly={variant}
            validation={validator('cnpj')}
            pattern="cnpjMask"
          />
        </POForm>
      </FormProvider>

      {variant ? (
        // TODO: add id of PO
        <POModalVariants
          variant={variant}
          poData={cnpjData}
          paymentId={paymentId}
          refetch={refetch}
        />
      ) : (
        <Button
          variant="secondary"
          form="po-form"
          disabled={verifyPoRes.loading}
        >
          {verifyPoRes.loading ? (
            <SpinnerIcon variant="special" width={20} height={20} />
          ) : (
            'verificar status'
          )}
        </Button>
      )}
    </POModalContainer>
  );
}
