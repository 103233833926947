import styled from 'styled-components';

export const BillingControlFormContainer = styled.div``;

export const FormDocuments = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0 1.5rem;
`;

export const DocumentsRow = styled.div`
  > div {
    margin-bottom: 1rem;
  }
`;
