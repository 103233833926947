export function NavTopIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66667 7.99983L3.60667 8.93983L7.33334 5.21983L7.33334 13.3332H8.66667L8.66667 5.21983L12.3867 8.9465L13.3333 7.99983L8 2.6665L2.66667 7.99983Z"
        fill="#A3A3A3"
      />
    </svg>
  );
}

export function NavBottomIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 7.99984L12.3933 7.05984L8.66666 10.7798V2.6665H7.33332V10.7798L3.61332 7.05317L2.66666 7.99984L7.99999 13.3332L13.3333 7.99984Z"
        fill="#A3A3A3"
      />
    </svg>
  );
}
