import { colors } from 'styles/colors';

interface UploadIconProps {
  variant: string;
  width: number;
  height: number;
}

export function UploadIcon({ variant, width, height }: UploadIconProps) {
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
    special: colors.textOnBackground.special,
  };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10V16H9V10H5L12 3L19 10H15ZM19 20V18H5V20H19Z"
        fill={variants[variant]}
        fillOpacity="0.87"
      />
    </svg>
  );
}
