import styled from 'styled-components';
import { colors } from 'styles/colors';

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: ${colors.background};
`;

export const BgLayer = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &:before {
    content: '';
    height: 200vh;
    width: 700px;
    opacity: 0.5;
    border-radius: 300px;
    transform: rotate(30deg);
    position: absolute;
    left: 45%;
    top: -50vh;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 0) 0%,
      rgba(12, 9, 60, 0) 5%,
      rgba(90, 82, 255, 35%) 45%,
      rgba(90, 82, 255, 35%) 55%,
      rgba(14, 192, 255, 0) 95%,
      rgba(0, 212, 255, 0) 100%
    );
  }
`;

export const LoginCard = styled.div`
  border-radius: 12px;
  background: ${colors.background};
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14),
    0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
  max-width: 425px;
  width: 100%;
  padding: 90px 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;

  > h5 {
    margin: 1.5rem 0;
  }
`;
