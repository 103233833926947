import { colors } from 'styles/colors';

interface TableChartIconProps {
  variant: 'dark' | 'light' | 'special';
  width: number;
  height: number;
}

export function TableChartIcon({
  variant,
  width,
  height,
}: TableChartIconProps) {
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
    special: colors.textOnBackground.special,
  };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6667 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V4.66667H16.3333V2.16667C16.3333 1.25 15.5833 0.5 14.6667 0.5ZM14.6667 15.5H12.1667V6.33333H16.3333V13.8333C16.3333 14.75 15.5833 15.5 14.6667 15.5ZM10.5 6.35H6.33333V15.5H10.5V6.35ZM2.16667 15.5C1.25 15.5 0.5 14.75 0.5 13.8333V6.33333H4.66667V15.5H2.16667Z"
        fill={variants[variant]}
        fillOpacity="0.87"
      />
    </svg>
  );
}
