import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Modal } from 'components/organisms/Modal';
import { AnimatePresence } from 'framer-motion';

interface ModalProps {
  width?: number;
  content: ReactNode;
}

interface ModalContextProps {
  isModalOpen?: boolean;
  openModal: (props: ModalProps) => void;
  closeModal: () => void;
}

export const ModalContext = createContext({} as ModalContextProps);

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProps>();

  function closeModal() {
    setIsModalOpen(false);
  }

  function openModal(props: ModalProps) {
    setIsModalOpen(true);

    setModalProps(props);
  }

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        closeModal();
      }
    };

    window.addEventListener('keydown', close);

    return () => window.removeEventListener('keydown', close);
  }, []);

  const value = {
    isModalOpen,
    openModal,
    closeModal,
  };

  return (
    <ModalContext.Provider value={value}>
      <AnimatePresence>
        {isModalOpen && <Modal {...(modalProps as ModalProps)} />}
      </AnimatePresence>

      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const ctx = useContext(ModalContext);

  return useMemo(() => ctx, [ctx]);
};
