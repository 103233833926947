import { gql } from '@apollo/client';

export const GET_PROJECTS_LIST = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $unit: String
    $sorting: [SorterInput]
  ) {
    ProjectItems(
      limit: $limit
      page: $page
      search_term: $search_term
      unit: $unit
      sorting: $sorting
    ) {
      items {
        _id
        status
        area
        campaign
        APcode
        revenue
        client {
          legalName
        }
        budget {
          revenue
        }
        costSoFar {
          value
          percentage
        }
        budgetCost {
          value
          percentage
        }
      }
      pages
    }
  }
`;
