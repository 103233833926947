import { gql } from '@apollo/client';

export const GET_AGREEMENTS = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $sorting: [SorterInput]
  ) {
    AgreementItems(
      limit: $limit
      page: $page
      search_term: $search_term
      sorting: $sorting
    ) {
      pages
      items {
        _id
        category
        defaultPercentage
        underValue
        underPercentage
        aboveValue
        abovePercentage
      }
    }
  }
`;

export const GET_AGREEMENT_BY_ID = gql`
  query ($id: String) {
    AgreementItems(id: $id) {
      items {
        category
        defaultPercentage
        underValue
        underPercentage
        aboveValue
        abovePercentage
      }
    }
  }
`;

export const CREATE_AGREEMENT = gql`
  mutation (
    $category: String!
    $defaultPercentage: Float
    $underValue: Float
    $underPercentage: Float
    $aboveValue: Float
    $abovePercentage: Float
  ) {
    createAgreement(
      category: $category
      defaultPercentage: $defaultPercentage
      underValue: $underValue
      underPercentage: $underPercentage
      aboveValue: $aboveValue
      abovePercentage: $abovePercentage
    ) {
      _id
    }
  }
`;

export const UPDATE_AGREEMENT = gql`
  mutation (
    $id: String!
    $category: String
    $defaultPercentage: Float
    $underValue: Float
    $underPercentage: Float
    $aboveValue: Float
    $abovePercentage: Float
  ) {
    agreementSetup(
      id: $id
      category: $category
      defaultPercentage: $defaultPercentage
      underValue: $underValue
      underPercentage: $underPercentage
      aboveValue: $aboveValue
      abovePercentage: $abovePercentage
    )
  }
`;

export const DELETE_AGREEMENT = gql`
  mutation ($id: String!) {
    deleteAgreement(id: $id)
  }
`;
