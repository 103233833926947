import {
  Percent,
  TitleCost,
} from 'pages/ManageProject/components/ProjectCosts/style';
import { toCurrencyBrl } from 'utils/masks';

import { RevenueField, RevenueFieldContainer, ValueContainer } from './style';

export function RevenueFields({ projectData }) {
  const pendingBillingCost = projectData?.pendingBillingCost;
  const billedCost = projectData?.billedCost;
  const receivedBillingCost = projectData?.receivedBillingCost;
  const overdueBillingCost = projectData?.overdueBillingCost;

  const data = [
    {
      title: 'Solicitado:',
      value: toCurrencyBrl(pendingBillingCost?.value),
      percentage: `${pendingBillingCost?.percentage} %`,
      style: 'primary',
    },
    {
      title: 'Faturado:',
      value: toCurrencyBrl(billedCost?.value),
      percentage: `${billedCost?.percentage} %`,
      style: 'primary',
    },
    {
      title: 'Recebido:',
      value: toCurrencyBrl(receivedBillingCost?.value),
      percentage: `${receivedBillingCost?.percentage} %`,
      style: 'primary',
    },
    {
      title: 'Vencido:',
      value: toCurrencyBrl(overdueBillingCost?.value),
      percentage: `${overdueBillingCost?.percentage} %`,
      style: 'danger',
    },
  ];

  return (
    <RevenueFieldContainer>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {data.map((item) => {
          return (
            <RevenueField key={item.title}>
              <TitleCost>{item.title}</TitleCost>
              <ValueContainer variant={item.style}>{item.value}</ValueContainer>
              <Percent variant="primary">{item.percentage}</Percent>
            </RevenueField>
          );
        })}
      </div>
    </RevenueFieldContainer>
  );
}
