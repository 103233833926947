import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const StyledGridDiv = styled(motion.div)`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  padding-bottom: 0 !important;
`;
export const StyledPermissionSubtitle = styled.p`
  /* Subtitle 2 */

  margin: 0.5rem 0;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;
  padding-left: 0;
  padding-top: 0.25rem;
  /* Dark Theme/Text on Background/Active */

  color: #e1e1e1;
`;

export const StyledCheckbox = styled.div`
  cursor: pointer;
  padding: 2px 0;
`;

interface StyledRoleTextProps {
  readOnly?: boolean;
}

export const StyledRoleText = styled.p<StyledRoleTextProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word;
  max-width: 105px;
  /* identical to box height, or 143% */

  letter-spacing: 0.25px;

  /* Dark Theme/Text on Background/Active */

  color: #e1e1e1;

  ${({ readOnly }) =>
    readOnly &&
    css`
      color: #707070;
    `}

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;
export const StyledRowDiv = styled.div`
  display: flex;
`;
