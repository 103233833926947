import styled from 'styled-components';
import { colors } from 'styles/colors';

export const EmptyTableContainer = styled.tbody`
  text-align: center;

  table-layout: fixed;
  height: 400px;

  border-top: 1px solid ${colors.outline};

  > tr,
  td {
    width: 100%;
  }
`;

export const EmptyContainer = styled.div`
  height: 400px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
`;

export const EmptyText = styled.p`
  color: ${colors.primary};
`;
