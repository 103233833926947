import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

const variants = {
  primary: {
    color: colors.white,
    colorDisabled: colors.textOnBackground.disable,
    backgroundActive: colors.buttons.primaryActive,
    backgroundHover: colors.buttons.primaryHover,
    backgroundDisabled: colors.buttons.primaryDisable,
    border: 'transparent',
  },
  secondary: {
    color: colors.primary,
    colorDisabled: colors.textOnBackground.disable,
    backgroundActive: colors.buttons.secondaryActive,
    backgroundHover: colors.buttons.secondaryHover,
    backgroundDisabled: colors.buttons.secondaryDisable,
    border: colors.buttons.secondaryHover,
  },
  tertiary: {
    color: colors.white,
    colorDisabled: colors.textOnBackground.disable,
    backgroundActive: colors.buttons.secondaryActive,
    backgroundHover: colors.buttons.secondaryHover,
    backgroundDisabled: colors.buttons.secondaryDisable,
    border: colors.buttons.secondaryHover,
  },
};

interface ButtonStyledProps {
  variant: string;
  hideShadow?: boolean;
}

export const ButtonStyled = styled.button<ButtonStyledProps>`
  ${({ variant, hideShadow }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    padding: 0.75rem 1rem;

    width: 100%;
    height: 2.5rem;

    color: ${variants[variant].color};
    background: ${variants[variant].backgroundActive};
    letter-spacing: 1.25px;

    border: none;
    border-radius: 4px;

    ${!hideShadow && `box-shadow: inset 0 0 0 1px ${variants[variant].border};`}

    &:hover {
      background: ${variants[variant].backgroundHover};
    }

    &:active {
      background: ${variants[variant].backgroundActive};
    }

    &:disabled {
      color: ${variants[variant].colorDisabled};
      background: ${variants[variant].backgroundDisabled};
      pointer-events: none;
      cursor: initial;
    }

    > svg {
      margin-right: 6px;
    }
  `}
`;

ButtonStyled.defaultProps = {
  hideShadow: false,
};
