/* eslint-disable array-callback-return */
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useLazyQuery } from '@apollo/client';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { SelectField } from 'components/atoms/SelectField';
import { TextField } from 'components/molecules/TextField';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { FieldContainer } from 'pages/Budgets/components/NewBudget/style';
import { FlexContainer, IconButton } from 'styles/miscellany';
import {
  clearCurrencyStr,
  clearCurrencyStrBrl,
  toCurrencyBrl,
  clearCurrencyNumberBrl,
} from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { FormGroupWithAdding } from '../../../../../../../components/organisms/FormGroupWithAdding';
import { GET_INTERNAL_PRODUCTIONS_LIST } from '../query';

interface Props {
  groupName: string;
}
export function Repass(props: Props) {
  const { groupName } = props;
  const { currentCompanyId } = useWorkspace();

  const [getRepass] = useLazyQuery(GET_INTERNAL_PRODUCTIONS_LIST, {
    notifyOnNetworkStatusChange: true,
  });
  const { setValue, getValues } = useFormContext();

  const updateTotal = (index) => {
    const totalValue = +clearCurrencyStrBrl(
      getValues(`${groupName}.repass.${index}.value`),
    );

    setValue(`${groupName}.repass.${index}.total`, toCurrencyBrl(totalValue));
  };

  const updatePercentageValue = (index) => {
    const value = clearCurrencyNumberBrl(
      getValues(`${groupName}.repass.${index}.value`),
    );
    const percentage = clearCurrencyStr(
      getValues(`${groupName}.repass.${index}.admPercentage`),
    );

    setValue(
      `${groupName}.repass.${index}.admValue`,
      toCurrencyBrl(Number(value * (percentage / 100)).toFixed(2)),
    );

    updateTotal(index);
  };

  useEffect(() => {
    getRepass({
      variables: { type: 'repass', unit: currentCompanyId },
    });
  }, [getRepass, currentCompanyId]);

  const [getOutsource, getOutsourceRes] = useLazyQuery(
    GET_INTERNAL_PRODUCTIONS_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const outsource =
    getOutsourceRes.data?.ConfigurationItems?.items?.map((item) => {
      return { ...JSON.parse(item.metadata), _id: item._id };
    }) || [];
  const outsourceMap: [{ value: ''; label: '' }] = [{ value: '', label: '' }];
  outsource.map((item) => {
    if (item.accountPlan.includes('1.3.3')) {
      outsourceMap.push({ value: item.role, label: item.role });
    }
  });
  outsourceMap.splice(0, 1);

  useEffect(() => {
    getOutsource({
      variables: { type: 'outsource', unit: currentCompanyId },
    });
  }, [getOutsource, currentCompanyId]);

  return (
    <div>
      <FormGroupWithAdding
        title="Repasses"
        groupName={`${groupName}.repass`}
        renderRow={({ index, onDelete }) => (
          <FieldContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.repass.${index}.outsource`}
                label="Terceiro"
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <SelectField
                name={`${groupName}.repass.${index}.item`}
                label="Item"
                placeholder="Item"
                options={outsourceMap}
                validation={validator('required')}
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.repass.${index}.value`}
                label="Valor"
                pattern="moneyPrefix"
                defaultValue={0}
                onKeyUp={() => {
                  updatePercentageValue(index);
                }}
              />
            </FlexContainer>
            <FlexContainer>
              <TextField
                name={`${groupName}.repass.${index}.admPercentage`}
                label="%adm"
                suffix="%"
                defaultValue={1}
                onKeyUp={() => {
                  updatePercentageValue(index);
                }}
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.repass.${index}.admValue`}
                label="Valor adm"
                defaultValue={0}
                readOnly
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.repass.${index}.total`}
                label="Total sem Impostos"
                defaultValue={0}
                readOnly
              />
            </FlexContainer>
            <IconButton
              type="button"
              tabIndex={0}
              onClick={() => onDelete(index)}
            >
              <TrashIcon variant="light" width={20} height={20} />
            </IconButton>
          </FieldContainer>
        )}
      />
    </div>
  );
}
