import { createGlobalStyle, css } from 'styled-components';

import { colors } from './colors';

interface GlobalStyleProps {
  isDrawerOpen?: boolean;
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  *, body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  body {
    background: ${colors.backgroundLight};
    color: ${colors.textOnBackground.active};

    overflow-x: hidden;

    ${(props) =>
      props.isDrawerOpen &&
      css`
        overflow: hidden;
      `}
  }

  body,
  input,
  textarea {
    font: 400 16px/1.5rem 'SFProDisplay', 'Helvetica', 'Arial', sans-serif;
    letter-spacing: 0.4px;
  }
  
  button {
    font: 500 0.875rem 'SFProDisplay', 'Helvetica', 'Arial', sans-serif;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  h1 {
    font: 400 6rem 'Lora', serif;
  }
  
  h2 {
    font: 400 3.75rem 'Lora', serif;
  }
  
  h3 {
    font: 400 3rem 'Lora', serif;
  }

  h4 {
    font: 400 2.125rem 'Lora', serif;
  }

  h5 {
    font: 400 1.5rem 'Lora', serif;
  }
  
  h6 {
    font: 400 1.25rem 'SFProDisplay', 'Helvetica', 'Arial', sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
    width: 100%;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* SCROLLBAR */
::-webkit-scrollbar {
    width: 4px;
    height: 3px;
}
/* Track */
::-webkit-scrollbar-track {
    background: ${colors.outline};
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: ${colors.primary};
}

/* SCROLLBAR FIREFOX */
:root{
    scrollbar-face-color: ${colors.primary};
    scrollbar-track-color: ${colors.outline};
    scrollbar-color: ${colors.outline} ${colors.primary};
    scrollbar-width: thin;
}
`;
