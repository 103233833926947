import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { useModal } from 'contexts/ModalContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { IconButton } from 'styles/miscellany';

import { ThirdPartyForm } from './components/ThirdPartyForm';
import {
  DELETE_INTERNAL_PRODUCTION,
  GET_INTERNAL_PRODUCTIONS_LIST,
  UPDATE_INTERNAL_PRODUCTION,
} from './query';

export function ThirdParties() {
  const [searchTerm, setSearchTerm] = useState('');
  const { openModal } = useModal();
  const { currentCompanyId } = useWorkspace();
  const { openDrawer } = useDrawer();
  const [, updateThirdPartyRes] = useMutation(UPDATE_INTERNAL_PRODUCTION);
  const [deleteThirdParty] = useMutation(DELETE_INTERNAL_PRODUCTION);
  const [getThirdParties, getThirdPartiesRes] = useLazyQuery(
    GET_INTERNAL_PRODUCTIONS_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const items =
    getThirdPartiesRes.data?.ConfigurationItems?.items?.map((item) => {
      return { ...JSON.parse(item.metadata), _id: item._id };
    }) || [];

  useEffect(() => {
    getThirdParties({
      variables: { type: 'outsource', unit: currentCompanyId },
    });
  }, [getThirdParties, currentCompanyId]);

  const handleAction = (id?) => {
    openDrawer({
      title: `${id ? 'Editar' : 'Adicionar'} Terceiro`,
      content: <ThirdPartyForm id={id} refetch={getThirdPartiesRes.refetch} />,
      formId: 'thirdparty-form',
      width: 600,
    });
  };

  const handleDelete = (id) => {
    openModal({
      content: (
        <DeleteModal
          onAccept={() => {
            deleteThirdParty({ variables: { id } }).then(() => {
              if (getThirdPartiesRes?.refetch)
                getThirdPartiesRes.refetch({
                  variables: getThirdPartiesRes.variables,
                });
            });
          }}
        />
      ),
    });
  };

  useEffect(() => {
    if (updateThirdPartyRes.error) {
      toast.error('Erro ao atualizar terceiro!');
    }
  }, [updateThirdPartyRes.error]);

  return (
    <>
      <Toolbar
        title="Planos de Contas"
        onSearch={setSearchTerm}
        onAction={handleAction}
      />

      <Table
        pages={getThirdPartiesRes.data?.ConfigurationItems?.pages}
        totalItems={getThirdPartiesRes.data?.ConfigurationItems?.total}
        fetchData={getThirdParties}
        fetchVariables={{ type: 'outsource', unit: currentCompanyId }}
        searchTerm={searchTerm}
        manualSort
        columns={[
          {
            Header: 'Cargo',
            accessor: 'role',
          },
          { Header: 'Plano de Contas Associado', accessor: 'accountPlan' },
        ]}
        loading={getThirdPartiesRes.loading}
        data={items}
        emptyText="Não há registros para consulta"
        actions={(rowData) => [
          {
            component: (
              <IconButton
                role="button"
                tabIndex={0}
                onClick={() => handleDelete(rowData._id)}
              >
                <TrashIcon variant="light" width={24} height={24} />
              </IconButton>
            ),
          },
          {
            component: (
              <IconButton onClick={() => handleAction(rowData._id)}>
                <GearIcon variant="light" width={20} height={20} />
              </IconButton>
            ),
          },
        ]}
      />
    </>
  );
}
