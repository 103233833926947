import { useState } from 'react';

import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { Plus } from 'components/atoms/Icons/Plus';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { useDrawer } from 'contexts/DrawerContext';
import { useModal } from 'contexts/ModalContext';
import { useUser } from 'contexts/UserContext';
import { motion } from 'framer-motion';
import { AccountsChartsForm } from 'pages/AccountsCharts/components/AccountsChartsForm';
import { GridColumn, IconButton } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';

import {
  ButtonTree,
  TextContainer,
  TreeContainer,
  TreeGrid,
  TreeHolder,
  TreeLabel,
  TreeToggle,
} from './style';

interface TreeProps {
  data: any[];
  onChange: (data: any) => void;
}

export function AccountChartsTree(props: TreeProps) {
  const { data: ogData, onChange } = props;
  const { openDrawer, closeDrawer } = useDrawer();
  const [data, setData] = useState(ogData);
  const [toggleTree, setToggleTree] = useState({});
  const { openModal } = useModal();

  const user = useUser();
  const { permission } = user.userData;

  function createNextId(lastId = '') {
    const splittedId = lastId.split('.');
    if (splittedId.length) {
      const lastPart = splittedId.pop() || '';
      const newId = `${splittedId.join('.')}.${parseInt(lastPart, 10) + 1}`;
      return newId;
    }
    return '';
  }

  function addNewRow(formData, parentId) {
    const newData = [...data];

    const mapping = (arr, id?) =>
      arr.map((item, i) => {
        const itemId = id ? `${id}.${i + 1}` : String(i + 1);

        if (parentId === itemId) {
          let newChildId = `${itemId}.1`;

          if (item.children.length) {
            const lastChild = [...item.children].pop();
            newChildId = createNextId(lastChild.id);
          }

          item.children.push({
            id: newChildId,
            title: capitalize(formData.name),
            vbsCode: formData.vbsCode,
            children: [],
          });
        }

        return mapping(item.children, itemId);
      });

    mapping(newData);
    setData(newData);
    onChange(newData);

    closeDrawer();
  }

  function editRow(formData, selectedId) {
    const newData = [...data];

    const mapping = (arr, id?) =>
      arr.map((item, i) => {
        const itemId = id ? `${id}.${i + 1}` : String(i + 1);

        if (selectedId === itemId) {
          item.title = capitalize(formData.name);
        }

        return mapping(item.children, itemId);
      });

    mapping(newData);
    setData(newData);
    onChange(newData);

    closeDrawer();
  }

  function deleteRow(selectedId) {
    const newData = [...data];

    const mapping = (arr, id?) =>
      arr.map((item, i) => {
        const itemId = id ? `${id}.${i + 1}` : String(i + 1);

        if (selectedId === itemId) {
          item.deleted = true;
        }

        return mapping(item.children, itemId);
      });

    mapping(newData);
    setData(newData);
    onChange(newData);

    closeDrawer();
  }

  const handleDelete = (id) => {
    openModal({
      content: <DeleteModal onAccept={() => deleteRow(id)} />,
    });
  };

  function renderer(config, id, type?) {
    const parent = type === 'parent';
    return (
      <TreeHolder isParent={parent} key={id} withoutLines={!config.hidden}>
        <TreeLabel
          isHidden={config.hidden && !parent}
          withoutLines={!config.hidden}
        >
          {config.children.length && !parent && config.hidden ? (
            <TreeToggle
              onClick={() =>
                setToggleTree({ ...toggleTree, [id]: !toggleTree[id] })
              }
            >
              <span>{!toggleTree[id] ? '-' : '+'}</span>
            </TreeToggle>
          ) : null}
          <TextContainer>
            <p>{`${id}. ${config.title}`}</p>
          </TextContainer>
          <GridColumn length={2} gap={0.75}>
            <IconButton
              onClick={() =>
                openDrawer({
                  title: 'Editar Item',
                  content: (
                    <AccountsChartsForm
                      onSubmit={(formData) => editRow(formData, id)}
                      data={{ name: config.title, vbsCode: config.vbsCode }}
                      readOnly={permission === 'Manager'}
                    />
                  ),
                  formId: 'accounts-charts-form',
                  onDelete:
                    type === 'parent' || permission === 'Manager'
                      ? undefined
                      : () => handleDelete(id),
                  width: 600,
                })
              }
            >
              <GearIcon width={16} height={16} variant="light" />
            </IconButton>

            <ButtonTree
              variant="primary"
              onClick={() =>
                openDrawer({
                  title: 'Adicionar Item',
                  content: (
                    <AccountsChartsForm
                      onSubmit={(formData) => addNewRow(formData, id)}
                    />
                  ),
                  formId: 'accounts-charts-form',
                  width: 600,
                })
              }
              disabled={permission === 'Manager'}
            >
              <Plus />
            </ButtonTree>
          </GridColumn>
        </TreeLabel>
        <motion.div
          initial={false}
          animate={{
            height: !toggleTree[id] ? 'auto' : 0,
            overflow: 'hidden',
            opacity: !toggleTree[id] ? 1 : 0,
            x: !toggleTree[id] ? 0 : 50,
          }}
          transition={{ ease: 'easeInOut', duration: 0.3 }}
        >
          {config.children
            ?.filter((child) => !child.deleted)
            .map((child) => renderer(child, child.id))}
        </motion.div>
      </TreeHolder>
    );
  }

  return (
    <TreeContainer>
      <TreeGrid>
        {data && data.map((item, i) => renderer(item, String(i + 1), 'parent'))}
      </TreeGrid>
    </TreeContainer>
  );
}
