import { useFormContext } from 'react-hook-form';

import { VisibilityIcon } from 'components/atoms/Icons/VisibilityIcon';
import { VisibilityOffIcon } from 'components/atoms/Icons/VisibilityOffIcon';
import { TextField } from 'components/molecules/TextField';
import { FormGroupWithAdding } from 'components/organisms/FormGroupWithAdding';
import { UnstyledButton } from 'pages/Companies/components/CompanyBankAccounts/style';
import {
  NoPrint,
  ThirdyColumn,
} from 'pages/PrintedBudget/components/PrintedBudgetTable/style';
import {
  PrintedBudgetColumnTitle,
  PrintedBudgetTitleContainer,
} from 'pages/PrintedBudget/style';
import {
  clearCurrencyStrBrl,
  clearCurrencyNumberBrl,
  clearCurrencyStr,
  toCurrencyBrl,
} from 'utils/masks';

interface Props {
  groupName: string;
  isVisible?: boolean;
  onVisibilityClick?: () => void;
}
export function Repass(props: Props) {
  const { groupName, isVisible, onVisibilityClick } = props;

  const { setValue, getValues } = useFormContext();

  const updateTotal = (index) => {
    const totalValue = +clearCurrencyStrBrl(
      getValues(`${groupName}.repass.${index}.value`),
    );

    setValue(`${groupName}.repass.${index}.total`, toCurrencyBrl(totalValue));
  };

  const updatePercentageValue = (index) => {
    const value = clearCurrencyNumberBrl(
      getValues(`${groupName}.repass.${index}.value`),
    );
    const percentage = clearCurrencyStr(
      getValues(`${groupName}.repass.${index}.admPercentage`),
    );

    setValue(
      `${groupName}.repass.${index}.admValue`,
      toCurrencyBrl(Number(value * (percentage / 100)).toFixed(2)),
    );

    updateTotal(index);
  };

  return (
    <div>
      <PrintedBudgetTitleContainer>
        <PrintedBudgetColumnTitle>Repasses</PrintedBudgetColumnTitle>
        <UnstyledButton
          title="Visível"
          type="button"
          onClick={onVisibilityClick}
          style={{ marginBottom: 16 }}
        >
          <NoPrint data-html2canvas-ignore>
            {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </NoPrint>
        </UnstyledButton>
      </PrintedBudgetTitleContainer>
      {isVisible && (
        <FormGroupWithAdding
          title="Repasses"
          groupName={`${groupName}.repass`}
          withoutAdding
          renderRow={({ index }) => (
            <ThirdyColumn>
              <TextField
                name={`${groupName}.repass.${index}.outsource`}
                label="Terceiro"
                isLight
              />
              <TextField
                name={`${groupName}.repass.${index}.item`}
                label="Item"
                isLight
              />
              <TextField
                name={`${groupName}.repass.${index}.value`}
                label="Valor"
                pattern="moneyPrefix"
                onKeyUp={() => {
                  updatePercentageValue(index);
                }}
                isLight
              />
              <TextField
                name={`${groupName}.repass.${index}.admPercentage`}
                label="%adm"
                suffix="%"
                onKeyUp={() => {
                  updatePercentageValue(index);
                }}
                isLight
              />
              <TextField
                name={`${groupName}.repass.${index}.admValue`}
                label="Valor adm"
                readOnly
                isLight
              />
              <TextField
                name={`${groupName}.repass.${index}.total`}
                label="Total sem Impostos"
                readOnly
                isLight
              />
            </ThirdyColumn>
          )}
        />
      )}
    </div>
  );
}
