export function removeAttribute(array, attribute) {
  const newArray: any = [];

  array.forEach((item) => {
    const newObj = { ...item };

    delete newObj[attribute];

    newArray.push(newObj);
  });

  return newArray;
}
