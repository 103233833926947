import styled from 'styled-components';
import { colors } from 'styles/colors';

export const BillingControlContainer = styled.div`
  padding: 1.5rem 0;
`;

export const BillingControlHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const TotalInvoice = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InvoiceTitle = styled.span`
  color: ${colors.textOnBackground.medium};
  font-size: 0.75rem;
`;

export const InvoiceValue = styled.p``;

export const SearchAction = styled.div``;
