import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import jwtDecode from 'jwt-decode';
import { storageKeys } from 'services/storage-keys';

interface UserContextProps {
  userData: any;
}

export const UserContext = createContext({} as UserContextProps);

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const token = localStorage.getItem(storageKeys.token) || null;
    const decoded: any = token && jwtDecode(token);

    if (token) {
      setUserData(decoded);
    }
  }, []);

  const value = {
    userData,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const ctx = useContext(UserContext);

  return useMemo(() => ctx, [ctx]);
};
