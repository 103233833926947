import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { Button } from 'components/atoms/Button';
import { SpinnerIcon } from 'components/atoms/Icons/SpinnerIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { useDrawer } from 'contexts/DrawerContext';

import {
  DrawerButtons,
  DrawerContainer,
  DrawerContent,
  DrawerFooter,
  DrawerHolder,
  DrawerLayer,
  DrawerTitle,
} from './style';

interface DrawerProps {
  width: number;
  title: string;
  content: ReactNode;
  formId?: string;
  onDelete?: () => void;
  isLoading?: boolean;
}

const layerVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 0.2, transition: { ease: 'linear', duration: 0.6 } },
  exit: { opacity: 0, transition: { ease: 'linear', duration: 0.4 } },
};

const contentVariants = {
  initial: { x: 900 },
  animate: { x: 0, transition: { ease: 'easeOut', duration: 0.6 } },
  exit: { x: 900, transition: { ease: 'easeIn', duration: 0.4 } },
};

export function Drawer(props: DrawerProps) {
  const { title, content, formId, onDelete, width, isLoading } = props;
  const { closeDrawer } = useDrawer();

  return ReactDOM.createPortal(
    <DrawerContainer initial="initial" animate="animate" exit="exit">
      <DrawerLayer variants={layerVariants} />
      <DrawerHolder variants={contentVariants} drawerWidth={width}>
        <DrawerTitle>{title}</DrawerTitle>
        <DrawerContent>{content}</DrawerContent>
        <DrawerFooter>
          <DrawerButtons>
            <Button variant="secondary" onClick={closeDrawer}>
              Cancelar
            </Button>
            {!!onDelete && (
              <Button
                variant="tertiary"
                icon={<TrashIcon width={20} height={20} variant="light" />}
                onClick={onDelete}
              >
                Deletar
              </Button>
            )}
          </DrawerButtons>
          <Button disabled={isLoading} variant="primary" form={formId}>
            {isLoading ? (
              <SpinnerIcon variant="light" width={24} height={24} />
            ) : (
              'Salvar'
            )}
          </Button>
        </DrawerFooter>
      </DrawerHolder>
    </DrawerContainer>,
    document.getElementById('drawer-root') as HTMLElement,
  );
}
Drawer.defaultProps = {
  isLoading: false,
};
