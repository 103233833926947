import styled from 'styled-components';
import { colors } from 'styles/colors';

export const SelectContainer = styled.div`
  position: relative;
  margin: 0.625rem 0;
`;

export const LabelContainer = styled.div`
  cursor: pointer;
  position: relative;

  > div {
    margin: 0;
  }

  input {
    pointer-events: none;
    padding-right: 2rem;
  }
`;

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${colors.textOnBackground.medium};
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;

interface DropdownContainerProps {
  isLight?: boolean;
}

export const DropdownContainer = styled.div<DropdownContainerProps>`
  position: absolute;
  min-width: 15rem;
  min-height: 8rem;
  width: 100%;
  z-index: 2;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    ),
    ${({ isLight }) => (isLight ? 'white' : '#121212')};

  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  padding: 0.6rem 1.5rem 1.5rem;
`;

export const OptionList = styled.ul`
  margin-top: 0.75rem;
  max-height: 17rem;
  overflow-y: auto;
`;

export const OptionItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.5rem;
  gap: 0.6rem;
  cursor: pointer;
`;

interface OptionTextProps {
  isLight?: boolean;
}

export const OptionText = styled.span<OptionTextProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
  color: ${({ isLight }) =>
    isLight ? colors.backgroundDark : colors.textOnBackground.active};
  flex: 1;
`;
