import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useMutation, useLazyQuery } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { Switch } from 'components/atoms/Switch';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { IconButton } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';

import { CompanyForm } from '../Companies/components/CompanyForm/index';
import { COMPANY_LIST, UPDATE_COMPANY } from '../Companies/query';

export function Providers() {
  const [searchTerm, setSearchTerm] = useState('');
  const { openDrawer } = useDrawer();
  const { refetchCompanyList } = useWorkspace();
  const [updateCompany, updateCompanyRes] = useMutation(UPDATE_COMPANY);
  const [getCompany, getCompanyRes] = useLazyQuery(COMPANY_LIST);
  const companies = getCompanyRes.data?.CompanyItems?.items || [];

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  const handleSwitch = (e, id) => {
    updateCompany({
      variables: {
        id,
        isDisabled: !e,
      },
    }).then(() => {
      if (refetchCompanyList) {
        refetchCompanyList();
      }
    });
  };

  const isCnpjInUse = (cnpj: string, companyId?: string) => {
    return companies.some(
      (item) => item._id !== companyId && item.cnpj === cnpj,
    );
  };

  const handleAction = (companyId?: string) => {
    openDrawer({
      title: companyId ? 'Editar Empresa' : 'Adicionar Empresa',
      content: (
        <CompanyForm
          companyId={companyId}
          refetch={getCompanyRes.refetch}
          isCnpjInUse={isCnpjInUse}
        />
      ),
      formId: 'company-form',
      width: 900,
    });
  };

  useEffect(() => {
    if (updateCompanyRes.error) {
      toast.error('Erro ao atualizar empresa!');
    }
  }, [updateCompanyRes.error]);

  return (
    <>
      <Toolbar
        title="Fornecedores"
        onSearch={setSearchTerm}
        onAction={handleAction}
      />
      <Table
        pages={getCompanyRes.data?.CompanyItems?.pages}
        totalItems={getCompanyRes.data?.CompanyItems?.total}
        fetchData={getCompany}
        searchTerm={searchTerm}
        columns={[
          {
            Header: 'Nome',
            accessor: 'name',
            Cell: ({ value }) => capitalize(value),
          },
        ]}
        loading={getCompanyRes.loading}
        emptyText="Não há registros para consulta"
        data={companies}
        actions={(rowData) => [
          {
            component: (
              <Switch
                onChange={(e) => handleSwitch(e, rowData._id)}
                disabled={updateCompanyRes.loading}
                defaultChecked={!rowData?.is_disabled}
              />
            ),
          },
          {
            component: (
              <IconButton onClick={() => handleAction(rowData._id)}>
                <GearIcon variant="light" width={20} height={20} />
              </IconButton>
            ),
          },
        ]}
      />
    </>
  );
}
