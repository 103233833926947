import { useFormContext } from 'react-hook-form';

import { VisibilityIcon } from 'components/atoms/Icons/VisibilityIcon';
import { VisibilityOffIcon } from 'components/atoms/Icons/VisibilityOffIcon';
import { TextField } from 'components/molecules/TextField';
import { FormGroupWithAdding } from 'components/organisms/FormGroupWithAdding';
import { UnstyledButton } from 'pages/Companies/components/CompanyBankAccounts/style';
import {
  NoPrint,
  ThirdyColumn,
} from 'pages/PrintedBudget/components/PrintedBudgetTable/style';
import {
  PrintedBudgetColumnTitle,
  PrintedBudgetTitleContainer,
} from 'pages/PrintedBudget/style';
import {
  clearCurrencyStr,
  clearCurrencyStrBrl,
  clearCurrencyNumberBrl,
  toCurrencyBrl,
} from 'utils/masks';

interface Props {
  groupName: string;
  companyTax: number;
  isVisible?: boolean;
  onVisibilityClick?: () => void;
}
export function Outsource(props: Props) {
  const { groupName, companyTax, isVisible, onVisibilityClick } = props;

  const { setValue, getValues } = useFormContext();

  const updateTotal = (index) => {
    const totalValue =
      +clearCurrencyStrBrl(getValues(`${groupName}.outsource.${index}.value`)) +
      +clearCurrencyStrBrl(
        getValues(`${groupName}.outsource.${index}.admValue`),
      );
    const companyTaxPercentage = clearCurrencyNumberBrl(companyTax) / 100 + 1;

    setValue(
      `${groupName}.outsource.${index}.total`,
      toCurrencyBrl(
        Number((totalValue * companyTaxPercentage) / 100).toFixed(2),
      ),
    );
  };

  const updatePercentageValue = (index) => {
    const value = clearCurrencyNumberBrl(
      getValues(`${groupName}.outsource.${index}.value`),
    );
    const percentage = clearCurrencyStr(
      getValues(`${groupName}.outsource.${index}.admPercentage`),
    );

    setValue(
      `${groupName}.outsource.${index}.admValue`,
      toCurrencyBrl(Number(value * (percentage / 100)).toFixed(2)),
    );

    updateTotal(index);
  };

  return (
    <div>
      <PrintedBudgetTitleContainer>
        <PrintedBudgetColumnTitle>Terceiros</PrintedBudgetColumnTitle>
        <UnstyledButton
          title="Visível"
          type="button"
          onClick={onVisibilityClick}
          style={{ marginBottom: 16 }}
        >
          <NoPrint data-html2canvas-ignore>
            {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </NoPrint>
        </UnstyledButton>
      </PrintedBudgetTitleContainer>
      {isVisible && (
        <FormGroupWithAdding
          title="Terceiros"
          groupName={`${groupName}.outsource`}
          withoutAdding
          renderRow={({ index }) => (
            <ThirdyColumn>
              <TextField
                name={`${groupName}.outsource.${index}.name`}
                label="Terceiro"
                isLight
              />
              <TextField
                name={`${groupName}.outsource.${index}.item`}
                label="Item"
                isLight
              />
              <TextField
                name={`${groupName}.outsource.${index}.value`}
                label="Valor"
                pattern="moneyPrefix"
                defaultValue={0}
                onKeyUp={() => {
                  updatePercentageValue(index);
                }}
                isLight
              />
              <TextField
                name={`${groupName}.outsource.${index}.admPercentage`}
                label="%adm"
                suffix="%"
                defaultValue={1}
                onKeyUp={() => {
                  updatePercentageValue(index);
                }}
                isLight
              />
              <TextField
                name={`${groupName}.outsource.${index}.admValue`}
                label="Valor adm"
                defaultValue={0}
                readOnly
                isLight
              />
              <TextField
                name={`${groupName}.outsource.${index}.total`}
                label="Total com Impostos"
                defaultValue={0}
                readOnly
                isLight
              />
            </ThirdyColumn>
          )}
        />
      )}
    </div>
  );
}
