import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { useModal } from 'contexts/ModalContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { IconButton } from 'styles/miscellany';

import { CommissionForm } from './components/CommissionForm';
import {
  DELETE_INTERNAL_PRODUCTION,
  GET_INTERNAL_PRODUCTIONS_LIST,
  UPDATE_INTERNAL_PRODUCTION,
} from './query';

export function Commissions() {
  const [searchTerm, setSearchTerm] = useState('');
  const { currentCompanyId } = useWorkspace();
  const { openModal } = useModal();
  const { openDrawer } = useDrawer();
  const [, updateCommissionRes] = useMutation(UPDATE_INTERNAL_PRODUCTION);
  const [deleteCommission] = useMutation(DELETE_INTERNAL_PRODUCTION);
  const [getCommissions, getCommissionsRes] = useLazyQuery(
    GET_INTERNAL_PRODUCTIONS_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const items =
    getCommissionsRes.data?.ConfigurationItems?.items?.map((item) => {
      return { ...JSON.parse(item.metadata), _id: item._id };
    }) || [];

  useEffect(() => {
    getCommissions({
      variables: { type: 'commission', unit: currentCompanyId },
    });
  }, [getCommissions, currentCompanyId]);

  const handleAction = (id?) => {
    openDrawer({
      title: `${id ? 'Editar' : 'Adicionar'} Comissão Orçada`,
      content: <CommissionForm id={id} refetch={getCommissionsRes.refetch} />,
      formId: 'commission-form',
      width: 600,
    });
  };

  const handleDelete = (id) => {
    openModal({
      content: (
        <DeleteModal
          onAccept={() => {
            deleteCommission({ variables: { id } }).then(() => {
              if (getCommissionsRes?.refetch)
                getCommissionsRes.refetch({
                  variables: getCommissionsRes.variables,
                });
            });
          }}
        />
      ),
    });
  };

  useEffect(() => {
    if (updateCommissionRes.error) {
      toast.error('Erro ao atualizar comissão!');
    }
  }, [updateCommissionRes.error]);

  return (
    <>
      <Toolbar
        title="Comissões Orçadas"
        onSearch={setSearchTerm}
        onAction={handleAction}
      />

      <Table
        pages={getCommissionsRes.data?.ConfigurationItems?.pages}
        totalItems={getCommissionsRes.data?.ConfigurationItems?.total}
        fetchData={getCommissions}
        fetchVariables={{ type: 'commission', unit: currentCompanyId }}
        searchTerm={searchTerm}
        manualSort
        columns={[
          { Header: 'Comissão', accessor: 'name' },
          { Header: 'Base de Cálculo', accessor: 'calculationBasis' },
          {
            Header: 'Valor',
            accessor: 'percentage',
            Cell: ({ value }) => `${value} %`,
          },
        ]}
        loading={getCommissionsRes.loading}
        data={items}
        emptyText="Não há registros para consulta"
        actions={(rowData) => [
          {
            component: (
              <IconButton
                role="button"
                tabIndex={0}
                onClick={() => handleDelete(rowData._id)}
              >
                <TrashIcon variant="light" width={24} height={24} />
              </IconButton>
            ),
          },
          {
            component: (
              <IconButton onClick={() => handleAction(rowData._id)}>
                <GearIcon variant="light" width={20} height={20} />
              </IconButton>
            ),
          },
        ]}
      />
    </>
  );
}
