import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Spinner } from 'components/atoms/Spinner';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { DefaultForm } from 'styles/miscellany';
import { unmaskCurrency } from 'utils/formatters/unmask-currency';
import { toNumber } from 'utils/helpers/to-number';
import { toCurrency, toCurrencyBrl } from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import {
  CREATE_AGREEMENT,
  GET_AGREEMENT_BY_ID,
  UPDATE_AGREEMENT,
} from '../../query';
import { StyledName, StyledDiv, StyledSubtitle } from './style';

interface ProfitDealsFormProps {
  refetch: any;
  id?: string;
}

export function ProfitDealsForm(props: ProfitDealsFormProps) {
  const { id, refetch } = props;
  const isEditing = !!id;
  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { closeDrawer } = useDrawer();
  const [getAgreementsById, agreementsResponse] = useLazyQuery(
    GET_AGREEMENT_BY_ID,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const agreement = agreementsResponse?.data?.AgreementItems?.items[0] || null;
  const [createAgreement] = useMutation(CREATE_AGREEMENT);
  const [updateAgreement] = useMutation(UPDATE_AGREEMENT);

  useEffect(() => {
    getAgreementsById({ variables: { id } });
  }, []);

  useEffect(() => {
    if (agreement && isEditing) {
      Object.entries(agreement).forEach(([key, value]: any) => {
        if (key !== '__typename') {
          if (key === 'underValue' || key === 'aboveValue') {
            methods.setValue(key, toCurrencyBrl(value.toFixed(2)), {
              shouldValidate: true,
              shouldDirty: true,
            });
          } else if (
            key === 'defaultPercentage' ||
            key === 'underPercentage' ||
            key === 'abovePercentage'
          ) {
            methods.setValue(key, toCurrency(value.toFixed(2)), {
              shouldValidate: true,
              shouldDirty: true,
            });
          } else {
            methods.setValue(key, value, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        }
      });
    }
  }, [agreement]);

  const onSubmit = async (dataForm) => {
    setIsLoading(true);

    try {
      if (isEditing) {
        await updateAgreement({
          variables: {
            id,
            ...dataForm,
            defaultPercentage: dataForm.defaultPercentage
              ? toNumber(dataForm.defaultPercentage)
              : 0,
            underValue: dataForm.underValue
              ? unmaskCurrency(dataForm.underValue)
              : 0,
            underPercentage: dataForm.underPercentage
              ? toNumber(dataForm.underPercentage)
              : 0,
            aboveValue: dataForm.aboveValue
              ? unmaskCurrency(dataForm.aboveValue)
              : 0,
            abovePercentage: dataForm.abovePercentage
              ? toNumber(dataForm.abovePercentage)
              : 0,
          },
        });
      } else {
        await createAgreement({
          variables: {
            id,
            ...dataForm,
            defaultPercentage: dataForm.defaultPercentage
              ? toNumber(dataForm.defaultPercentage)
              : 0,
            underValue: dataForm.underValue
              ? unmaskCurrency(dataForm.underValue)
              : 0,
            underPercentage: dataForm.underPercentage
              ? toNumber(dataForm.underPercentage)
              : 0,
            aboveValue: dataForm.aboveValue
              ? unmaskCurrency(dataForm.aboveValue)
              : 0,
            abovePercentage: dataForm.abovePercentage
              ? toNumber(dataForm.abovePercentage)
              : 0,
          },
        });
      }

      toast.success(`Cadastro ${isEditing ? 'atualizado' : 'efetuado'}!`);

      refetch();

      closeDrawer();
    } catch {
      toast.error(
        `Ops, falha ao ${isEditing ? 'atualizar' : 'efetuar'} o cadastro.`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <FormProvider {...methods}>
      <DefaultForm
        onSubmit={methods.handleSubmit(onSubmit)}
        id="profit-deals-form"
      >
        <StyledName>
          <TextField
            name="category"
            label="Categoria"
            validation={validator('required')}
          />
        </StyledName>
        <StyledName>
          <TextField
            name="defaultPercentage"
            label="Percentual Padrão"
            suffix="%"
            pattern="money"
            validation={validator('percent')}
          />
        </StyledName>
        <StyledSubtitle>Abaixo de</StyledSubtitle>
        <StyledDiv>
          <TextField name="underValue" label="Valor" pattern="moneyPrefix" />
          <TextField
            name="underPercentage"
            label="Percentagem"
            suffix="%"
            pattern="money"
            validation={validator('percent')}
          />
        </StyledDiv>
        <StyledSubtitle>Acima de</StyledSubtitle>
        <StyledDiv>
          <TextField name="aboveValue" label="Valor" pattern="moneyPrefix" />
          <TextField
            name="abovePercentage"
            label="Percentagem"
            suffix="%"
            pattern="money"
            validation={validator('percent')}
          />
        </StyledDiv>
      </DefaultForm>
    </FormProvider>
  );
}
