export function PrintedBudgetHeaderIcon() {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.91667 0H0V16.625L7.91667 8.62816V0Z" fill="black" />
      <path d="M19.0002 0H11.0835V16.625L19.0002 8.62816V0Z" fill="black" />
    </svg>
  );
}
