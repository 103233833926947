import { gql } from '@apollo/client';

export const CLIENT_LEGALS = gql`
  query ($id: String!) {
    ClientItems(id: $id) {
      total
      pages
      items {
        _id
        legals {
          message
          metadata
        }
      }
    }
  }
`;
