import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { FieldContainer } from 'pages/Budgets/components/NewBudget/style';
import { calculateAllBudgetTotals } from 'pages/Budgets/service';
import { colors } from 'styles/colors';
import { FlexContainer } from 'styles/miscellany';
import { toCurrencyBrl, clearCurrencyNumberBrl } from 'utils/masks';

import {
  GroupsText,
  StructureRevenueContainer,
  TextInformation,
} from './style';

interface Props {
  companyTax: number;
  repass?: boolean;
}

export function StructureRevenue(props: Props) {
  const methods = useFormContext();
  const budgetState = methods.watch();

  const {
    totalBilling,
    internalProductionTotal,
    outsourceTotal,
    repassTotal,
    comissionsTotal,
  } = calculateAllBudgetTotals(budgetState);

  const { companyTax, repass } = props;

  const earnings = totalBilling - repassTotal;

  useEffect(() => {
    methods.setValue('revenue', totalBilling.toString());
  }, [totalBilling]);

  const updateEarnings = () => {
    let total = earnings;
    if (companyTax) {
      total = +Number(
        (earnings * (1 - clearCurrencyNumberBrl(companyTax) / 100)) / 100,
      ).toFixed(2);
    }
    return total;
  };

  const netEarnings = updateEarnings();

  return (
    <StructureRevenueContainer>
      <GroupsText>Subtotal</GroupsText>
      <FieldContainer style={{ alignItems: 'flex-start' }}>
        <FlexContainer>
          <div>
            <p>
              Faturamento <strong>Bruto</strong>
              <TextInformation
                title={
                  repass
                    ? 'Consiste do Valor Total do Orçamento subtraído do Repasse.'
                    : 'Consiste do Valor Total do Orçamento.'
                }
              >
                i
              </TextInformation>
            </p>
          </div>
          <h4>{toCurrencyBrl(earnings)}</h4>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>
              Receita <strong>Líquida</strong>
              <TextInformation
                title={
                  repass
                    ? 'Valor Total do Orçamento subtraído de Impostos, Repasse, Terceiros e Invoice.'
                    : 'Valor Total do Orçamento subtraído de Impostos, Terceiros e Invoice.'
                }
              >
                i
              </TextInformation>
            </p>
          </div>
          <h4>{toCurrencyBrl(netEarnings.toFixed(2))}</h4>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>Valor Total do Orçamento</p>
          </div>
          <h4 style={{ color: colors.primary }}>
            {toCurrencyBrl(totalBilling)}
          </h4>
        </FlexContainer>
      </FieldContainer>
      <GroupsText>Resumo</GroupsText>
      <FieldContainer>
        <FlexContainer>
          <div>
            <p>Produção Interna</p>
          </div>
          <div>{toCurrencyBrl(internalProductionTotal)}</div>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>Terceiros</p>
          </div>
          <div>{toCurrencyBrl(outsourceTotal)}</div>
        </FlexContainer>
        {repass && (
          <FlexContainer>
            <div>
              <p>Repasses</p>
            </div>
            <div>{toCurrencyBrl(repassTotal)}</div>
          </FlexContainer>
        )}
        <FlexContainer>
          <div>
            <p>Comissões</p>
          </div>
          <div>{toCurrencyBrl(comissionsTotal)}</div>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>Impostos</p>
          </div>
          <div>
            {toCurrencyBrl(Number(earnings / 100 - netEarnings).toFixed(2))}
          </div>
        </FlexContainer>
      </FieldContainer>
    </StructureRevenueContainer>
  );
}
