import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
  query ($client_id: String!) {
    ProjectItems(client_id: $client_id) {
      items {
        _id
        status
        client {
          legalName
        }
        area
        campaign
        revenue
        budget {
          revenue
        }
        costSoFar
      }
      pages
    }
  }
`;
