export function Search() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2583 11.6667H12.9167L17.075 15.8333L15.8333 17.075L11.6667 12.9167V12.2583L11.4417 12.025C10.4917 12.8417 9.25833 13.3333 7.91667 13.3333C4.925 13.3333 2.5 10.9083 2.5 7.91667C2.5 4.925 4.925 2.5 7.91667 2.5C10.9083 2.5 13.3333 4.925 13.3333 7.91667C13.3333 9.25833 12.8417 10.4917 12.025 11.4417L12.2583 11.6667ZM4.16667 7.91667C4.16667 9.99167 5.84167 11.6667 7.91667 11.6667C9.99167 11.6667 11.6667 9.99167 11.6667 7.91667C11.6667 5.84167 9.99167 4.16667 7.91667 4.16667C5.84167 4.16667 4.16667 5.84167 4.16667 7.91667Z"
        fill="#A3A3A3"
      />
    </svg>
  );
}
