import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

interface TextFieldStyledProps {
  isLight?: boolean;
  readOnly?: boolean;
}

export const TextFieldStyled = styled.div<TextFieldStyledProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  margin: 0.625rem 0;

  position: relative;

  input {
    height: 3.875rem;

    & ~ span {
      display: none;
    }
  }

  input:focus,
  input:not(:placeholder-shown) {
    & ~ span {
      display: block;
    }
    & ~ label {
      top: -0.5rem;
      left: 0.625rem;

      font-size: 0.75rem;
      padding: 0 0.25rem;
    }
  }

  input:focus {
    & ~ label {
      color: ${colors.primary};
    }
  }

  input.error {
    & ~ label,
    & ~ span {
      color: ${colors.textOnBackground.error};
    }
  }

  input:disabled,
  input:read-only {
    ${({ isLight }) => css`
      color: ${isLight
        ? colors.backgroundDark
        : colors.textOnBackground.disable};
    `}
    cursor: default;
    & ~ label,
    & ~ span {
      ${({ isLight }) => css`
        color: ${isLight
          ? colors.backgroundDark
          : colors.textOnBackground.disable};
      `}
      cursor: not-allowed;
    }
    &:focus {
      border: 1px solid ${colors.outline};
    }
    box-shadow: none;
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
      pointer-events: none;
    `}
`;

export const Suffix = styled.span`
  position: absolute;
  top: 1.5rem;
  right: 1.375rem;
  font-size: 0.875rem;
  color: ${colors.textOnBackground.medium};
  line-height: 1rem;
`;

interface LabelStyledProps {
  isLight?: boolean;
  readOnly?: boolean;
}

export const LabelStyled = styled.label<LabelStyledProps>`
  position: absolute;
  top: 1.5rem;
  left: 1rem;

  transition: 0.1s all;
  line-height: 1rem;
  font-size: 0.875rem;

  cursor: text;

  ${({ isLight }) => css`
    color: ${isLight ? colors.backgroundDark : colors.textOnBackground.medium};
    background: ${isLight ? colors.white : colors.backgroundLight};
  `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      color: ${colors.textOnBackground.disable};
    `}
`;
