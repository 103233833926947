const baseUrl = `https://api-neuronos.flag.cx`;

export const Services = {
  Graphql: `${baseUrl}/v1/graphql`,
  Authentication: `${baseUrl}/google/signin`,
  Plans: `${baseUrl}/v1/plans`,
  Upload: `${baseUrl}/v1/upload`,
  Filemeta: `${baseUrl}/v1/filemeta`,
  CnpjSearch: 'https://www.receitaws.com.br/v1/cnpj',
  Cities: `${baseUrl}/v1/cidades`,
  Banks: 'https://brasilapi.com.br/api/banks/v1',
  Currency: 'https://api.exchangeratesapi.io/v1/latest',
  BacenConverter: `${baseUrl}/v1/bacen-converter`,
};
