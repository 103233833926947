export function PrintedBudgetBackground() {
  return (
    <svg
      width="1202"
      height="387"
      viewBox="0 0 1202 387"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M303.635 74.9307L277.767 58.3267C269.709 60.0297 261.228 60.4554 254.867 60.4554C235.784 60.4554 66.5792 60.4554 42.4071 60.4554C28.4128 60.4554 11.4499 59.1782 0 57.4752L0 95.3663C10.6018 94.9406 25.0202 94.0891 41.9831 94.0891C66.5792 94.0891 234.511 94.0891 259.108 94.0891C253.171 134.96 233.663 194.139 203.554 232.881C167.932 278.436 120.436 314.624 38.1664 335.059L67.0033 366.99C144.608 342.723 195.073 303.129 233.663 253.317C267.589 209.465 287.944 140.921 297.274 96.2178C298.97 87.703 300.667 80.8911 303.635 74.9307Z"
        fill="black"
        fillOpacity="0.03"
      />
      <path
        d="M465.085 40.8713V76.2079C476.535 75.3564 490.105 74.9307 502.828 74.9307C526.151 74.9307 645.315 74.9307 669.063 74.9307C683.482 74.9307 698.324 75.3564 708.078 76.2079V40.8713C697.9 42.1485 683.058 43 669.912 43C644.891 43 525.727 43 502.828 43C489.257 43 476.111 42.1485 465.085 40.8713ZM739.459 152.842L715.287 137.941C710.622 140.069 701.293 141.347 691.539 141.347C669.912 141.347 489.257 141.347 468.478 141.347C456.604 141.347 442.185 140.069 426.919 138.792V174.554C442.185 173.277 458.3 172.851 468.478 172.851C493.922 172.851 672.456 172.851 693.236 172.851C685.602 203.931 668.639 240.119 643.195 266.941C606.725 305.257 553.716 332.931 493.922 345.277L520.214 375.505C574.071 360.178 627.505 335.485 671.608 286.525C703.413 251.614 722.496 207.337 733.522 165.614C734.37 162.208 737.339 157.099 739.459 152.842Z"
        fill="black"
        fillOpacity="0.03"
      />
      <path
        d="M1120.15 17.0297L1097.25 26.8218C1108.7 43 1123.12 68.5446 1131.6 86L1154.5 75.3564C1146.02 58.3267 1130.76 32.3564 1120.15 17.0297ZM1166.38 0L1144.33 9.79208C1156.2 25.5446 1169.77 49.8119 1179.1 68.1188L1202 57.901C1193.94 42.1485 1177.83 15.7525 1166.38 0ZM1148.14 101.752L1120.15 84.7228C1112.95 87.703 1103.62 88.9802 1092.17 88.9802C1079.02 88.9802 974.274 88.9802 956.887 88.9802L943.317 119.634L1100.65 119.634C1091.74 158.376 1065.87 213.297 1032.8 252.465C994.205 297.594 941.62 336.337 863.591 359.327L894.124 387C973.85 357.624 1024.74 318.455 1063.33 271.198C1100.65 225.218 1126.94 167.317 1138.39 124.743C1140.51 117.931 1144.75 108.139 1148.14 101.752ZM1006.08 37.4653L967.064 24.6931C964.52 35.3366 958.159 51.0891 953.918 58.3267C935.259 96.6436 893.7 158.376 820.336 202.228L850.021 224.366C917.872 178.812 962.4 117.931 987.42 71.099C991.661 63.0099 999.294 48.5347 1006.08 37.4653Z"
        fill="black"
        fillOpacity="0.03"
      />
    </svg>
  );
}
