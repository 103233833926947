import styled from 'styled-components';

export const ToolbarContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
  justify-content: space-between;
`;

export const ToolbarForm = styled.form`
  width: 20rem;
`;

interface ToobalFormNoTitleProps {
  withButton: boolean;
}
export const ToolbarFormNoTitle = styled.form<ToobalFormNoTitleProps>`
  width: ${(props) => {
    return props.withButton ? '10000%;  padding-right: 1rem' : '100%';
  }};
`;
