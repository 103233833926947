import { colors } from 'styles/colors';

interface ActionIconProps {
  color?: string;
}

export function ActionIcon({ color }: ActionIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16667 3.33398H15.8333C16.7583 3.33398 17.5 4.08398 17.5 5.00065V15.0007C17.5 15.9173 16.75 16.6673 15.8333 16.6673H12.5V15.0007H15.8333V6.66732H4.16667V15.0007H7.5V16.6673H4.16667C3.24167 16.6673 2.5 15.9173 2.5 15.0007V5.00065C2.5 4.08398 3.24167 3.33398 4.16667 3.33398ZM6.66667 11.6673L10 8.33398L13.3333 11.6673H10.8333V16.6673H9.16667V11.6673H6.66667Z"
        fill={color}
      />
    </svg>
  );
}

ActionIcon.defaultProps = {
  color: colors.primary,
};
