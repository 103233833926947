import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Spinner } from 'components/atoms/Spinner';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { DefaultForm } from 'styles/miscellany';
import { unmaskCurrency } from 'utils/formatters/unmask-currency';
import { toNumber } from 'utils/helpers/to-number';
import { toCurrency, toCurrencyBrl } from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import {
  CREATE_RATE_CARD,
  GET_RATE_CARD_BY_ID,
  UPDATE_RATE_CARD,
} from '../../query';

interface RateCardFormProps {
  refetch: any;
  id?: string;
}

export function RateCardForm(props: RateCardFormProps) {
  const { id, refetch } = props;
  const isEditing = !!id;
  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { closeDrawer } = useDrawer();
  const [getRateCardById, rateCardResponse] = useLazyQuery(
    GET_RATE_CARD_BY_ID,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const rateCard = rateCardResponse?.data?.RateCardItems?.items[0] || null;
  const [createRateCard] = useMutation(CREATE_RATE_CARD);
  const [updateRateCard] = useMutation(UPDATE_RATE_CARD);

  useEffect(() => {
    getRateCardById({ variables: { id } });
  }, []);

  useEffect(() => {
    if (rateCard && isEditing) {
      Object.entries(rateCard).forEach(([key, value]: any) => {
        if (key !== '__typename') {
          if (key === 'cost' || key === 'saleValue' || key === 'clientValue') {
            methods.setValue(key, toCurrencyBrl(value.toFixed(2)), {
              shouldValidate: true,
              shouldDirty: true,
            });
          } else if (key === 'profitability') {
            methods.setValue(key, toCurrency(value.toFixed(2)), {
              shouldValidate: true,
              shouldDirty: true,
            });
          } else {
            methods.setValue(key, value, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        }
      });
    }
  }, [rateCard]);

  const onSubmit = async (dataForm) => {
    setIsLoading(true);

    try {
      if (isEditing) {
        await updateRateCard({
          variables: {
            id,
            ...dataForm,
            cost: dataForm.cost ? unmaskCurrency(dataForm.cost) : 0,
            saleValue: dataForm.saleValue
              ? unmaskCurrency(dataForm.saleValue)
              : 0,
            clientValue: dataForm.clientValue
              ? unmaskCurrency(dataForm.clientValue)
              : 0,
            profitability: dataForm.profitability
              ? toNumber(dataForm.profitability)
              : 0,
          },
        });
      } else {
        await createRateCard({
          variables: {
            id,
            ...dataForm,
            cost: dataForm.cost ? unmaskCurrency(dataForm.cost) : 0,
            saleValue: dataForm.saleValue
              ? unmaskCurrency(dataForm.saleValue)
              : 0,
            clientValue: dataForm.clientValue
              ? unmaskCurrency(dataForm.clientValue)
              : 0,
            profitability: dataForm.profitability
              ? toNumber(dataForm.profitability)
              : 0,
          },
        });
      }

      toast.success(`Cadastro ${isEditing ? 'atualizado' : 'efetuado'}!`);

      refetch();

      closeDrawer();
    } catch {
      toast.error(
        `Ops, falha ao ${isEditing ? 'atualizar' : 'efetuar'} o cadastro.`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <FormProvider {...methods}>
      <DefaultForm onSubmit={methods.handleSubmit(onSubmit)} id="ratecard-form">
        <TextField
          name="category"
          label="Categoria"
          validation={validator('required')}
        />
        <TextField
          name="serviceName"
          label="Nome do serviço"
          validation={validator('required')}
        />
        <TextField name="cost" label="Custo" pattern="moneyPrefix" />
        <TextField
          name="saleValue"
          label="Valor de Venda"
          pattern="moneyPrefix"
        />

        <TextField
          name="clientValue"
          label="Valor do Cliente"
          pattern="moneyPrefix"
        />
        <TextField
          name="profitability"
          label="Lucratividade"
          suffix="%"
          pattern="money"
          validation={validator('percent')}
        />
      </DefaultForm>
    </FormProvider>
  );
}
