import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

import { Button } from 'components/atoms/Button';
import { GoogleIcon } from 'components/atoms/Icons/GoogleIcon';
import { Logo } from 'components/atoms/Icons/Logo';
import { Services } from 'services/service';
import { storageKeys } from 'services/storage-keys';

import { BgLayer, LoginCard, LoginContainer } from './styles';

const stateEnvironment = process.env.REACT_APP_STATE_ENVIRONMENT;

export function Login() {
  const location = useLocation();

  useEffect(() => {
    const token = location?.search.replace('?token=', '');
    const error = location?.search.replace('?error=', '');

    if (token) {
      localStorage.setItem(storageKeys.token, token);

      window.location.href = '/';
    }

    if (error === 'account_not_found') {
      toast.error('Não foi possível encontrar sua conta NeuronOS');
    }
  }, [location]);

  return (
    <LoginContainer>
      <BgLayer />
      <LoginCard>
        <Logo variant="light" width={105} height={60} />
        <h5>Welcome to Neuron OS</h5>
        <a href={`${Services.Authentication}?state=${stateEnvironment}`}>
          <Button variant="primary" icon={<GoogleIcon />}>
            Entrar com o google
          </Button>
        </a>
      </LoginCard>
    </LoginContainer>
  );
}
