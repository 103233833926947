export function Terms() {
  return (
    <h1>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ligula
      ipsum, feugiat ac augue at, pharetra porttitor libero. Morbi ipsum orci,
      sollicitudin a porttitor ut, finibus vel velit. Proin sagittis mattis
      dapibus. Ut condimentum elementum leo, eu venenatis enim dictum in. Sed
      fermentum lacus id tellus finibus, id tempor enim vehicula. Maecenas vel
      sem commodo, ultricies felis vitae, sagittis sem. Morbi tincidunt
      scelerisque nunc, vel sodales mi tempus sit amet. Nullam sed est vitae mi
      efficitur finibus non in dolor. Duis lectus lectus, dictum sed urna
      convallis, rutrum semper est. Quisque ornare pulvinar varius. Nam et
      mattis neque. Ut convallis, nunc sit amet placerat efficitur, velit erat
      finibus nisi, et interdum libero quam volutpat felis. Morbi egestas massa
      id est dictum, quis consequat quam euismod. Morbi tempor dapibus nunc eget
      porttitor. Mauris vitae turpis eget nulla volutpat placerat non et tellus.
      Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
      inceptos himenaeos. Maecenas convallis erat id iaculis ornare. Curabitur
      elementum sagittis diam, ornare bibendum eros bibendum eget. Nunc vitae
      pharetra diam, et bibendum dui. Duis laoreet semper leo euismod posuere.
      Fusce blandit sagittis neque id accumsan. Nam feugiat, neque mollis ornare
      malesuada, quam urna efficitur quam, at pretium dolor orci eu erat. Nulla
      eget ex convallis nulla consectetur fermentum nec eget tellus. Maecenas et
      ante rutrum, porttitor arcu vitae, placerat neque. Curabitur quis est sed
      leo tincidunt ornare. Vestibulum malesuada tempus odio, id tincidunt urna
      dapibus sed. Morbi placerat diam rhoncus ultrices ornare. Morbi maximus a
      sapien sed blandit. Fusce consectetur egestas porta. Fusce in aliquet
      odio, sit amet hendrerit magna. Duis posuere mollis turpis, eu auctor nunc
      lacinia non. Fusce ultrices mi consectetur, rutrum libero ut, vestibulum
      nunc. Nam maximus dui sit amet vehicula fringilla. Donec pellentesque sed
      neque eu ullamcorper. Praesent ornare dictum quam vel vulputate. Nulla
      quis sagittis lectus. Quisque non elit neque. Suspendisse a risus aliquam,
      posuere nisl ut, ultricies tellus. Pellentesque semper ullamcorper urna
      eget pharetra. Maecenas semper metus metus, vel elementum tellus commodo
      pharetra. In sodales imperdiet est, id euismod libero molestie ut. Sed sed
      euismod lorem. Curabitur eu fringilla metus, vitae imperdiet turpis. In et
      velit quis metus maximus aliquam. Ut fringilla eros id eros ornare
      feugiat. Vivamus maximus aliquet bibendum. Aenean eu neque efficitur nisl
      interdum luctus ac eget velit. Nulla malesuada tincidunt neque eu auctor.
      Phasellus tristique mattis dui, sit amet gravida felis ornare eget. Sed a
      dolor arcu. Donec consectetur laoreet porttitor. Nunc feugiat efficitur
      fringilla. Vestibulum eu eleifend est. Vivamus malesuada, enim et mattis
      iaculis, tellus eros feugiat dui, ac lacinia dolor ante non nunc. Nullam
      lacinia nisl nec est bibendum dapibus. Donec odio sapien, consequat sit
      amet pretium sed, rutrum ac mi. In vel risus ipsum. Vestibulum ante ipsum
      primis in faucibus orci luctus et ultrices posuere cubilia curae; Quisque
      non leo vitae libero laoreet viverra. Aenean eget euismod mauris, in
      ultrices mi. Cras semper justo sit amet metus lobortis elementum. Aliquam
      vel ultricies tellus.
    </h1>
  );
}
