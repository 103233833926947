import { colors } from 'styles/colors';

interface ActionIconProps {
  color?: string;
}

export function RegularIcon({ color }: ActionIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99175 1.66602C5.39175 1.66602 1.66675 5.39935 1.66675 9.99935C1.66675 14.5993 5.39175 18.3327 9.99175 18.3327C14.6001 18.3327 18.3334 14.5993 18.3334 9.99935C18.3334 5.39935 14.6001 1.66602 9.99175 1.66602ZM8.33341 7.91602C8.33341 8.60637 7.77377 9.16602 7.08341 9.16602C6.39306 9.16602 5.83341 8.60637 5.83341 7.91602C5.83341 7.22566 6.39306 6.66602 7.08341 6.66602C7.77377 6.66602 8.33341 7.22566 8.33341 7.91602ZM12.9167 9.16602C13.6071 9.16602 14.1667 8.60637 14.1667 7.91602C14.1667 7.22566 13.6071 6.66602 12.9167 6.66602C12.2264 6.66602 11.6667 7.22566 11.6667 7.91602C11.6667 8.60637 12.2264 9.16602 12.9167 9.16602ZM7.50008 12.916V11.666H12.5001V12.916H7.50008ZM3.33341 9.99935C3.33341 13.6827 6.31675 16.666 10.0001 16.666C13.6834 16.666 16.6667 13.6827 16.6667 9.99935C16.6667 6.31602 13.6834 3.33268 10.0001 3.33268C6.31675 3.33268 3.33341 6.31602 3.33341 9.99935Z"
        fill={color}
      />
    </svg>
  );
}

RegularIcon.defaultProps = {
  color: colors.textOnBackground.medium,
};
