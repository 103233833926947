/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { SelectField } from 'components/atoms/SelectField';
import { Spinner } from 'components/atoms/Spinner';
import { TextField } from 'components/molecules/TextField';
import { CompaniesForm } from 'components/organisms/CompaniesForm';
import { NotificationsForm } from 'components/organisms/NotificationsForm';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { COMPANY_LIST } from 'pages/Companies/query';
import {
  ADD_NOTIFICATION,
  GET_NOTIFICATION_BY_EMAIL,
  NOTIFICATION_LIST,
  UPDATE_NOTIFICATION,
} from 'pages/Notifications/query';
import { CREATE_USER, GET_USER_BY_ID, UPDATE_USER } from 'pages/Users/query';
import { DefaultForm } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';
import { validator } from 'utils/validators/form-validations';

interface UserFormProps {
  id?: string | boolean;
  refetch: any;
  readOnly?: boolean;
}

export function UserForm(props: UserFormProps) {
  const { id, refetch, readOnly } = props;
  const isEditing = !!id;
  const methods = useForm();
  const [getUserById, getUserByIdRes] = useLazyQuery(GET_USER_BY_ID);
  const userItem = getUserByIdRes?.data?.UserItems?.items[0];
  const [updateUser, updateUserRes] = useMutation(UPDATE_USER);
  const [createUser, createUserRes] = useMutation(CREATE_USER);
  const { closeDrawer } = useDrawer();
  const { neuronMode, currentCompanyId } = useWorkspace();
  const [createNotification] = useMutation(ADD_NOTIFICATION);
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);
  const [getNotificationByEmail, getNotificationByEmailRes] = useLazyQuery(
    GET_NOTIFICATION_BY_EMAIL,
  );
  const notificationId =
    getNotificationByEmailRes?.data?.NotificationItems?.items[0]?._id;
  const notificationTags =
    getNotificationByEmailRes?.data?.NotificationItems?.items[0]?.scope;
  const [getNotifications, getNotificationsRes] =
    useLazyQuery(NOTIFICATION_LIST);
  const notifications =
    getNotificationsRes.data?.NotificationItems?.items || [];
  const [getCompany, getCompanyRes] = useLazyQuery(COMPANY_LIST);
  const companies = getCompanyRes.data?.CompanyItems?.items || [];

  useEffect(() => {
    getNotifications(
      neuronMode ? undefined : { variables: { unit: currentCompanyId } },
    );
  }, [currentCompanyId, getNotifications, neuronMode]);

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  const tmpNotifications = notifications.map((i) => {
    return i.email;
  });

  function capitalizeCompanyNameWithId(item) {
    const companyName = item.name.split(' ');
    const firstName = companyName[0];
    let secondName = '';
    for (let i = 0; i < companyName.length; i++) {
      secondName +=
        companyName[i + 1] !== undefined
          ? ` ${companyName[i + 1]?.slice(0, 3)}.`
          : '';
    }
    return {
      name: capitalize(firstName + secondName),
      id: item._id,
    };
  }

  const companiesObj = companies.map((i) => capitalizeCompanyNameWithId(i));

  const authorizedCompaniesTags = userItem?.authorizedCompanies.map((i) =>
    capitalizeCompanyNameWithId(i),
  );

  useEffect(() => {
    if (!userItem && isEditing) getUserById({ variables: { id } });

    if (userItem && isEditing) {
      Object.entries(userItem).forEach(([key, value]) => {
        if (key !== '__typename') {
          methods.setValue(key, value, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      });
    }
  }, [userItem, methods, isEditing, getUserById, id]);

  useEffect(() => {
    methods.reset({ tags: '' }, { keepValues: true });
    methods.setValue('tags', notificationTags);
    methods.reset({ authorizedCompanies: '' }, { keepValues: true });
    methods.setValue(
      'authorizedCompanies',
      authorizedCompaniesTags?.map((i) => i.name),
    );
  }, [methods, notificationTags]);

  useEffect(() => {
    if (userItem?.email)
      getNotificationByEmail({
        variables: {
          search_term: userItem.email,
        },
      });
  }, [userItem]);

  const onSubmit = (dataForm) => {
    const ids: any = [];
    companiesObj.map((item) => {
      dataForm.authorizedCompanies.map((i) => {
        if (capitalize(item.name) === i) {
          ids.push(item.id);
        }
      });
    });

    if (!dataForm?.tags) {
      toast.error('Selecione no mínimo uma permissão');

      return;
    }

    if (isEditing) {
      updateUser({
        variables: {
          id,
          name: dataForm.name,
          nickname: dataForm.nickname,
          email: dataForm.email,
          area: dataForm.area,
          permission: dataForm.permission,
          scope: dataForm.tags,
          authorizedCompanies: ids,
        },
      });
      let registeredEmail = false;
      tmpNotifications.map((i) => {
        if (i === dataForm.email) {
          registeredEmail = true;
        }
      });
      if (registeredEmail === false) {
        createNotification({
          variables: {
            email: dataForm.email,
            scope: dataForm.tags,
            unit: currentCompanyId,
          },
        });
      }

      if (dataForm?.tags && notificationId) {
        updateNotification({
          variables: {
            id: notificationId,
            email: dataForm.email,
            scope: dataForm.tags,
          },
        });
      }
    } else {
      createUser({
        variables: {
          name: dataForm.name,
          nickname: dataForm.nickname,
          email: dataForm.email,
          area: dataForm.area,
          permission: dataForm.permission,
          scope: dataForm.tags,
          authorizedCompanies: ids,
        },
      });
      if (dataForm?.tags) {
        createNotification({
          variables: {
            email: dataForm.email,
            scope: dataForm.tags,
            unit: currentCompanyId,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (createUserRes.data) {
      toast.success('Cadastro efetuado!');

      refetch();

      closeDrawer();
    }

    if (createUserRes.error) {
      toast.error('Ops, falha ao realizar o cadastro.');
    }
  }, [createUserRes.data, createUserRes.error, refetch]);
  // FIXME: Resolve re-render caused by closeDrawer in dependencies

  useEffect(() => {
    if (updateUserRes.data) {
      toast.success('Cadastro atualizado!');

      refetch();

      closeDrawer();
    }

    if (updateUserRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
  }, [updateUserRes.data, updateUserRes.error, refetch]);
  // FIXME: Resolve re-render caused by closeDrawer in dependencies

  useEffect(() => {
    methods.setValue('authorizedCompanies', companies);
    if (
      methods.watch('permission') === 'Master' ||
      methods.watch('permission') === 'Manager'
    ) {
      methods.setValue(
        'authorizedCompanies',
        companiesObj.map((i) => i.name),
      );
    }
  }, [methods.watch('permission')]);

  if (getUserByIdRes.loading || createUserRes.loading || updateUserRes.loading)
    return <Spinner />;

  return (
    <FormProvider {...methods}>
      <DefaultForm onSubmit={methods.handleSubmit(onSubmit)} id="user-form">
        <TextField
          name="name"
          label="Nome"
          validation={validator('name')}
          readOnly={readOnly}
        />
        <TextField
          name="nickname"
          label="Apelido"
          validation={validator('required')}
          readOnly={readOnly}
        />
        <TextField
          name="email"
          label="Email"
          validation={validator('email')}
          readOnly={readOnly}
        />
        <TextField
          name="area"
          label="Área"
          validation={validator('required')}
          readOnly={readOnly}
        />
        <SelectField
          name="permission"
          label="Permissão"
          placeholder="Permissão"
          options={[
            { value: 'Master', label: 'Master' },
            { value: 'Manager', label: 'Manager' },
            { value: 'Lead', label: 'Lead' },
            { value: 'Team', label: 'Team' },
          ]}
          defaultValue="Team"
          validation={validator('required')}
          readOnly={readOnly}
        />
      </DefaultForm>
      <CompaniesForm
        options={companiesObj}
        title="Empresas"
        readOnly={readOnly}
      />
      <NotificationsForm readOnly={readOnly} />
    </FormProvider>
  );
}

UserForm.defaultProps = {
  id: false,
};
