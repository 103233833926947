import { createContext, useContext, useState } from 'react';

import { useFetch } from 'hooks/useFetch';
import { Services } from 'services/service';
import { normalizeText } from 'utils/formatters/normalize-text';

interface HelperDataContextProps {
  searchCities: (text: string) => Promise<any[]>;
  searchBanks: (text: string) => Promise<any[]>;
}
export const HelperDataContext = createContext({} as HelperDataContextProps);

export const HelperDataProvider = ({ children }) => {
  const [allCities, setAllCities] = useState<string[]>([]);
  const [allBanks, setAllBanks] = useState<string[]>([]);

  const { fetchAuthorized } = useFetch();

  const searchCities = async (text: string) => {
    let cities = [...allCities];
    if (!cities.length) {
      cities = await fetchAuthorized(Services.Cities)
        .then((resp) => resp.json())
        .then((resp) => {
          const list = resp.estados
            .map((state) => {
              return state.cidades.map((city) => `${city}/${state.sigla}`);
            })
            .reduce((prev, curr) => [...prev, ...curr], []);
          setAllCities(list);
          return list;
        });
    }

    return cities
      .filter((city) => normalizeText(city).includes(normalizeText(text)))
      .slice(0, 25);
  };

  const searchBanks = async (text: string) => {
    let banks = [...allBanks];
    if (!banks.length) {
      banks = await fetch(Services.Banks)
        .then((resp) => resp.json())
        .then((resp) => {
          const list = resp.map((bank) => bank.name);
          setAllBanks(list);
          return list;
        });
    }
    return banks
      .filter((bank) => normalizeText(bank).includes(normalizeText(text)))
      .slice(0, 25);
  };

  return (
    <HelperDataContext.Provider
      value={{
        searchCities,
        searchBanks,
      }}
    >
      {children}
    </HelperDataContext.Provider>
  );
};

export const useHelperData = () => useContext(HelperDataContext);
