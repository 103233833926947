import { createContext, useContext, useState } from 'react';

interface SidebarContextProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  setSidebar: (boolean) => void;
}
export const SidebarContext = createContext({} as SidebarContextProps);

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  function setSidebar(value: boolean) {
    setIsSidebarOpen(value);
  }

  const value = {
    isSidebarOpen,
    toggleSidebar,
    setSidebar,
  };

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);
