import { Services } from 'services/service';

export function useBacenConverter(currency, value) {
  const BASE_URL = Services.BacenConverter;

  const options = {
    value,
    from: currency,
    to: 'BRL',
  };

  return fetch(BASE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  })
    .then((serverData) => {
      return serverData.json();
    })
    .catch((error) => {
      console.log('Error:', error);
    });
}
