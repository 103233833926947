export function LinkIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8337 0.833984H3.83366C2.91699 0.833984 2.16699 1.58398 2.16699 2.50065V14.1673H3.83366V2.50065H13.8337V0.833984ZM16.3337 4.16732H7.16699C6.25033 4.16732 5.50033 4.91732 5.50033 5.83398V17.5007C5.50033 18.4173 6.25033 19.1673 7.16699 19.1673H16.3337C17.2503 19.1673 18.0003 18.4173 18.0003 17.5007V5.83398C18.0003 4.91732 17.2503 4.16732 16.3337 4.16732ZM7.16699 17.5007H16.3337V5.83398H7.16699V17.5007Z"
        fill="#5A52FF"
      />
    </svg>
  );
}
