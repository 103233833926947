import styled from 'styled-components';
import { colors } from 'styles/colors';

export const CostControlContainer = styled.div`
  padding: 1.5rem 0;
`;

export const CostControlHeader = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;

  form {
    width: 100%;
  }
`;

export const ConstControlContent = styled.div`
  background-color: ${colors.backgroundLight};
  padding: 1rem 0;
  border-radius: 4px;
`;

export const ActionHolder = styled.div`
  margin: 1.5rem;
  max-width: 10rem;
`;

export const GroupsText = styled.p`
  color: ${colors.white};
  font-weight: bold;
  margin: 3rem 0 0 0;
  padding: 0 1.875rem;
`;

export const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 2rem;
`;

export const RowPerPage = styled.div`
  margin-right: 1.5rem;
  display: flex;
  align-items: center;

  > select {
    background: transparent;
    border: none;
    color: ${colors.textOnBackground.active};
    font-size: 0.75rem;

    option {
      color: ${colors.textOnPrimary.special};
    }
  }

  > span {
    color: ${colors.textOnBackground.medium};
    font-size: 0.75rem;
  }
`;

export const PageIndex = styled.div`
  margin-right: 1.5rem;

  > span {
    color: ${colors.textOnBackground.medium};
    font-size: 0.75rem;
  }
`;

export const PaginationButtons = styled.div`
  display: flex;
  align-items: center;

  > button {
    background: transparent;
    border: none;

    &:first-child {
      margin-right: 2rem;
    }
  }
`;
