import { colors } from 'styles/colors';

interface DownloadIconProps {
  color?: string;
}

export function DownloadIcon({ color }: DownloadIconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0003 12H25.3337L16.0003 21.3333L6.66699 12H12.0003V4H20.0003V12ZM6.66699 26.6667V24H25.3337V26.6667H6.66699Z"
        fill={color}
      />
    </svg>
  );
}

DownloadIcon.defaultProps = {
  color: colors.primary,
};
