interface LogoProps {
  variant: 'dark' | 'light';
  width: number;
  height: number;
}

export function Logo({ variant, width, height }: LogoProps) {
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
  };

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 352 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M225.073 38.5612V38.2439C225.073 27.3473 221.74 18.5665 215.128 12.1661C207.247 4.12589 195.028 0 178.842 0H126.157V111.346H158.053V78.0746H165.723L195.557 111.505H172.706L314.996 200L203.174 72.4676C217.509 65.9614 225.073 54.2714 225.073 38.5612ZM158.053 27.8233H177.255C187.411 27.8233 193.018 32.2137 193.018 40.1481V40.4655C193.018 48.1883 187.199 52.7903 177.413 52.7903H158.053V27.8233Z"
        fill={variants[variant]}
      />
      <path
        d="M319.386 0V58.556L267.971 0H239.884V111.346H271.727V52.949L323.142 111.346H351.23V0H319.386Z"
        fill={variants[variant]}
      />
      <path
        d="M79.5028 58.556L28.0878 0H0V111.346H31.8434V52.949L83.2584 111.346H111.293V0H79.5028V58.556Z"
        fill={variants[variant]}
      />
    </svg>
  );
}
