import styled from 'styled-components';
import { colors } from 'styles/colors';

export const SwitchContainer = styled.div``;

export const SwitchController = styled.div`
  cursor: pointer;
  position: relative;
  margin-top: 10px;

  --rail: ${colors.textOnBackground.disable};
  --knob: ${colors.textOnBackground.medium};

  &[data-enable='true'] {
    --offset: 14px;
    --knob: ${colors.gradient};
    --rail: ${colors.railGradient};
  }
`;

export const Rail = styled.div`
  position: relative;
  display: inline-block;
  z-index: 1;
  vertical-align: top;
  height: 14px;
  width: 34px;
  border-radius: 7px;
  background: var(--rail);
  transition: background 0.1s linear;
`;

export const Knob = styled.div`
  background: var(--knob);
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 0;
  top: -3px;
  transform: translateX(var(--offset, 0));
  transition: transform 0.2s;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
`;
