import { gql } from '@apollo/client';

export const GET_REVENUES = gql`
  query ($id: String) {
    ProjectItems(id: $id) {
      items {
        _id
        start_at
        finish_at
        revenues {
          name
          metadata
        }
        pendingBillingCost {
          value
          percentage
        }
        billedCost {
          value
          percentage
        }
        receivedBillingCost {
          value
          percentage
        }
        overdueBillingCost {
          value
          percentage
        }
      }
    }
  }
`;

export const SAVE_REVENUES = gql`
  mutation ($id: String!, $revenues: [RevenueInput]) {
    projectSetup(id: $id, revenues: $revenues)
  }
`;
