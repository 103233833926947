import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledName = styled.div`
  padding-bottom: 0 !important;
`;
export const StyledDiv = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  > div {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  padding-bottom: 0 !important;
`;

export const StyledMoreDocsDiv = styled.div`
  display: flex;
`;

export const StyledContractDiv = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  grid-column-gap: 1.5rem;
  > div {
    padding-bottom: 0.75rem;
  }
  /* Dark Theme/Outline */
  padding-bottom: 0 !important;
  /* border-bottom: 1px solid #3b3b3b; */
`;
export const StyledSubtitle = styled.p`
  /* Subtitle 2 */

  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;
  padding-left: 0;
  padding-top: 0.25rem;
  /* Dark Theme/Text on Background/Active */

  color: #e1e1e1;
`;
export const StyledOtherDocsDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledOtherDocsText = styled.p`
  /* Subtitle 2 */
  padding-top: 1.75rem;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.15px;

  /* Dark Theme/Text on Background/Active */

  color: #e1e1e1;
`;
export const StyledUploadInput = styled.input`
  display: none;
`;
export const StyledUploadText = styled.p`
  height: fit-content;
  padding-top: 0.25rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  /* Dark Theme/Primary */

  color: #5a52ff;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
`;

export const StyledUploadDiv = styled.div`
  height: fit-content;
  cursor: pointer;
  display: flex;
  margin-top: 1.75rem;
  padding-top: 0;
  padding-bottom: 0 !important;
`;

export const StyledUploadIconDiv = styled.div``;

export const StyledRadioDiv = styled.div`
  display: flex;
`;

export const StyledRadioInput = styled.div`
  width: 6.5rem;
  padding-right: 0.5625rem;
`;
