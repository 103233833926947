import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { SpinnerIcon } from 'components/atoms/Icons/SpinnerIcon';
import { Spinner } from 'components/atoms/Spinner';
import { Switch } from 'components/atoms/Switch';
import { CnpjSearchField } from 'components/molecules/CnpjSearchField';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import {
  StyledPermissionSubtitle as StyledPoText,
  StyledGridDiv,
  StyledGridDivDouble,
  StyledPoDiv,
  ClientFormGrid,
} from 'pages/Clients/components/ClientForm/style';
import {
  CLIENT_LIST_ALL_DATA,
  CREATE_CLIENT,
  UPDATE_CLIENT,
} from 'pages/Clients/query';
import { DefaultForm } from 'styles/miscellany';
import { validator } from 'utils/validators/form-validations';

interface ClientFormProps {
  client?: string;
  refetch: any;
}

export function ClientForm(props: ClientFormProps) {
  const methods = useForm();
  const { client, refetch } = props;
  const isEditing = !!client;
  const { closeDrawer, setIsLoading } = useDrawer();
  const [values, setValues] = useState({
    isPoRequired: isEditing ? 'loading' : true,
  });
  const [addClient, { loading }] = useMutation(CREATE_CLIENT);
  const [getClient, getClientsRes] = useLazyQuery(CLIENT_LIST_ALL_DATA);
  const [updateClient] = useMutation(UPDATE_CLIENT);
  const { neuronMode, currentCompanyId } = useWorkspace();
  function handlePoSwitch(value: boolean) {
    setValues({ ...values, isPoRequired: value });
  }

  useEffect(() => {
    if (isEditing) getClient({ variables: { id: client } });
  }, [client, getClient, isEditing]);
  useEffect(() => {
    if (
      !getClientsRes.loading &&
      getClientsRes.data?.ClientItems?.items &&
      getClientsRes.data?.ClientItems?.items[0]
    ) {
      const formData = getClientsRes.data?.ClientItems?.items[0];
      Object.keys(formData).forEach((field) => {
        methods.setValue(field, formData[field]);
      });
      setValues((v) => {
        return { ...v, isPoRequired: formData.isPoRequired };
      });
    }
  }, [getClientsRes, methods]);
  const onSubmit = (formInfo) => {
    const variables = {
      ...formInfo,
      ...values,
      unit: !neuronMode && currentCompanyId ? currentCompanyId : undefined,
    };

    setIsLoading(true);
    const promise = isEditing
      ? updateClient({ variables: { ...variables, id: client } }).then(() => {
          return getClientsRes.refetch ? getClientsRes.refetch() : undefined;
        })
      : addClient({ variables });
    promise
      .then(() => {
        setIsLoading(false);
        toast.success(
          isEditing
            ? 'Cliente Salvo com Sucesso!'
            : 'Cliente Criado com Sucesso!',
        );
        closeDrawer();
      })
      .catch(() => {
        toast.error('Ocorreu um erro, por favor tente novamente');
        setIsLoading(false);
      })
      .finally(() => {
        refetch();
      });
  };

  const handleCnpjFound = (data) => {
    Object.entries(data).forEach(([key, value]) =>
      methods.setValue(key, value),
    );
  };

  if (isEditing && (getClientsRes.loading || !getClientsRes.data))
    return <Spinner />;
  return (
    <FormProvider {...methods}>
      <DefaultForm onSubmit={methods.handleSubmit(onSubmit)} id="client-form">
        <StyledGridDiv>
          <CnpjSearchField name="cnpj" onCnpjFound={handleCnpjFound} />
          <TextField
            name="name"
            label="Nome Fantasia"
            validation={validator('required')}
          />
          <ClientFormGrid>
            <TextField
              name="legalName"
              label="Razão Social"
              validation={validator('required')}
            />
            <TextField name="clientPrefix" label="Prefixo" />
          </ClientFormGrid>
          <TextField name="clientNickname" label="Apelido" />
          <TextField name="cityTaxId" label="Inscrição Municipal" />
          <TextField name="stateTaxId" label="Inscrição Estadual" />
          <StyledGridDivDouble>
            <TextField name="address" label="Endereço" />
            <TextField name="city" label="Cidade" />
            <TextField name="state" label="Estado" />
            <TextField name="country" label="País" />
            <StyledPoDiv>
              {values.isPoRequired === 'loading' ? (
                <SpinnerIcon width={20} height={20} variant="light" />
              ) : (
                <Switch
                  onChange={(e) => handlePoSwitch(e)}
                  disabled={loading}
                  defaultChecked={!!values.isPoRequired}
                />
              )}
              <StyledPoText>Empresa exige emissão de PO</StyledPoText>
            </StyledPoDiv>
          </StyledGridDivDouble>
        </StyledGridDiv>
      </DefaultForm>
    </FormProvider>
  );
}

ClientForm.defaultProps = {
  client: null,
};
