import { useFormContext } from 'react-hook-form';
import Select from 'react-select';

import { ErrorMessage } from '@hookform/error-message';
import { TextField } from 'components/molecules/TextField';

import { ErrorLabel, SelectContainer } from './style';

interface OptionProp {
  value: string;
  label: string;
}

interface SelectFieldProps {
  inputName: string;
  selectName: string;
  label: string;
  placeholder?: string;
  menuPlacement?: string;
  options: OptionProp[];
  validation?: any;
  isSearchable?: boolean;
  isDisabled?: boolean;
  defaultValue?: OptionProp;
  actionTrigger?: (data: any) => void;
  isLight?: boolean;
}

export function InputFieldWithSelect(props: SelectFieldProps) {
  const {
    inputName,
    selectName,
    label,
    menuPlacement,
    options,
    validation,
    isSearchable,
    isDisabled,
    defaultValue,
    actionTrigger,
    isLight,
  } = props;
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const registration = !isDisabled && {
    ...register(inputName, validation),
  };

  const selectRegistration = !isDisabled && {
    ...register(selectName, validation),
  };

  const errorMessage = errors[inputName]?.message;

  return (
    <SelectContainer isLight={isLight} isDisabled={isDisabled}>
      <TextField
        {...registration}
        name={inputName}
        label={label}
        pattern="money"
        onKeyUp={(data) => {
          if (actionTrigger) {
            actionTrigger(data);
          }
        }}
        style={{ padding: '0px 60px 0px 12px' }}
        isLight={isLight}
        readOnly={isDisabled}
      />
      <Select
        {...selectRegistration}
        name={selectName}
        options={options}
        classNamePrefix="select-field"
        menuPlacement={menuPlacement}
        className={errorMessage && 'error'}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        onChange={(data) => {
          if (actionTrigger) {
            actionTrigger(data);
            setValue(selectName as string, data.value);
          }
        }}
        defaultValue={defaultValue}
      />
      <ErrorLabel>
        <ErrorMessage errors={errors} name={inputName} />
      </ErrorLabel>
    </SelectContainer>
  );
}

InputFieldWithSelect.defaultProps = {
  placeholder: 'selecionar',
  menuPlacement: 'auto',
  validation: null,
  isSearchable: false,
  isDisabled: false,
  defaultValue: null,
  actionTrigger: undefined,
};
