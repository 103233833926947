import { colors } from 'styles/colors';

interface ActionIconProps {
  variant: 'dark' | 'light' | 'special';
  width: number;
  height: number;
}

export function StatisticsIcon({ variant, width, height }: ActionIconProps) {
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
    special: colors.textOnBackground.special,
  };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16667 2.5H15.8333C16.75 2.5 17.5 3.25 17.5 4.16667V15.8333C17.5 16.75 16.75 17.5 15.8333 17.5H4.16667C3.25 17.5 2.5 16.75 2.5 15.8333V4.16667C2.5 3.25 3.25 2.5 4.16667 2.5ZM15.8333 15.8333H4.16667V4.16667H15.8333V15.8333ZM10.8333 14.1667H9.16667V5.83333H10.8333V14.1667ZM7.5 14.1667H5.83333V8.33333H7.5V14.1667ZM12.5 14.1667H14.1667V10.8333H12.5V14.1667Z"
        fill={variants[variant]}
      />
    </svg>
  );
}
