/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { StatusLabel } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';

import { GET_PROJECTS } from './query';
import { ClientProjectsContainer, SeeMoreText, SeeMoreDiv } from './style';

export function ClientProjects() {
  const history = useHistory();
  const params = useParams<any>();
  const [searchTerm, setSearchTerm] = useState('');
  const [getProjects, getProjectsRes] = useLazyQuery(GET_PROJECTS, {
    notifyOnNetworkStatusChange: true,
  });
  const items = getProjectsRes.data?.ProjectItems?.items || [];
  const pages = getProjectsRes.data?.ProjectItems?.pages || 1;

  useEffect(() => {
    if (params.id) getProjects({ variables: { client_id: params.id } });
  }, [getProjects]);

  const handleAction = (id) => {
    history.replace(`/projects/${id}`);
  };

  return (
    <ClientProjectsContainer>
      <Toolbar title="" onSearch={setSearchTerm} />
      <Table
        pages={pages}
        fetchData={getProjects}
        searchTerm={searchTerm}
        columns={[
          {
            Header: 'Status',
            accessor: (row) => (
              <StatusLabel
                variant={row.status === 'finalizado' ? 'inactive' : 'active'}
              >
                {capitalize(row.status)}
              </StatusLabel>
            ),
          },
          { Header: 'ID', accessor: '_id' },
          {
            Header: 'Cliente',
            accessor: (row) => row?.client?.legalName,
          },
          { Header: 'Produto', accessor: 'area' },
          { Header: 'Campanha', accessor: 'campaign' },
          { Header: 'Valor Faturado', accessor: 'revenue' },
          {
            Header: 'Custos Projetados',
            accessor: (row) => row?.budget?.revenue || 0,
          },
          { Header: 'Custo Realizado', accessor: 'costSoFar' },
          {
            Header: ' ',
            accessor: (rowData) => {
              return (
                <SeeMoreDiv>
                  <SeeMoreText
                    role="button"
                    tabIndex={0}
                    onClick={() => handleAction(rowData._id)}
                  >
                    Ver mais
                  </SeeMoreText>
                </SeeMoreDiv>
              );
            },
          },
        ]}
        loading={false}
        emptyText="Não há registros para consulta"
        data={items}
      />
    </ClientProjectsContainer>
  );
}
