export const colors = {
  primary: '#5452FF',
  primaryLight: '#9680FF',
  primaryDark: '#9680FF',
  secondary: '#FFD700',
  secondaryLight: '#FFFF52',
  secondaryDark: '#C7A600',
  background: '#181818',
  backgroundLight: '#202020',
  backgroundDark: '#000000',
  outline: '#3B3B3B',
  light: '#f9f9f9',
  white: '#ffffff',
  selectedSidebar: '#0000001F',
  railGradient: 'rgba(90, 82, 255, 0.32)',
  gradient: 'linear-gradient(180.59deg, #5A52FF 0.51%, #373388 328.37%);',
  buttons: {
    primaryActive: 'linear-gradient(180deg, #5A52FF 0%, #373388 100%)',
    primaryHover: 'linear-gradient(180deg, #6E67FE 0%, #4A4873 100%)',
    primaryDisable: '#3B3B3B',
    secondaryActive: '#202020',
    secondaryHover: '#3B3B3B',
    secondaryDisable: '#202020',
  },
  textOnPrimary: {
    active: '#ffffff',
    medium: '#D4D2FF',
    disable: '#9994FF',
    special: '#181818',
    error: '#FFCCCC',
  },
  textOnBackground: {
    active: '#E1E1E1',
    medium: '#A3A3A3',
    disable: '#707070',
    special: '#5A52FF',
    error: '#FF5252',
    success: '#219653',
  },
};
