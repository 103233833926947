import { EmptyIcon } from '../Icons/EmptyIcon';
import { EmptyTableContainer, EmptyText, EmptyContainer } from './style';

export function EmptyTable({
  colspan,
  text,
}: {
  colspan: number;
  text: string;
}) {
  return (
    <EmptyTableContainer>
      <tr>
        <td colSpan={colspan + 1}>
          <EmptyIcon />
          <EmptyText>{text}</EmptyText>
        </td>
      </tr>
    </EmptyTableContainer>
  );
}

export function EmptyLabel({ text }) {
  return (
    <EmptyContainer>
      <EmptyIcon />
      <EmptyText>{text}</EmptyText>
    </EmptyContainer>
  );
}
