import { useFormContext } from 'react-hook-form';

import { VisibilityIcon } from 'components/atoms/Icons/VisibilityIcon';
import { VisibilityOffIcon } from 'components/atoms/Icons/VisibilityOffIcon';
import { TextField } from 'components/molecules/TextField';
import { FormGroupWithAdding } from 'components/organisms/FormGroupWithAdding';
import { UnstyledButton } from 'pages/Companies/components/CompanyBankAccounts/style';
import {
  InternalProductColumn,
  NoPrint,
} from 'pages/PrintedBudget/components/PrintedBudgetTable/style';
import {
  PrintedBudgetColumnTitle,
  PrintedBudgetTitleContainer,
} from 'pages/PrintedBudget/style';
import { clearCurrencyStrBrl, toCurrencyBrl } from 'utils/masks';

interface Props {
  groupName: string;
  isVisible?: boolean;
  onVisibilityClick?: () => void;
}
export function InternalProduction(props: Props) {
  const { groupName, isVisible, onVisibilityClick } = props;

  const { setValue, getValues } = useFormContext();

  const updateTotal = (hours, index) => {
    const totalValue = toCurrencyBrl(
      clearCurrencyStrBrl(
        getValues(`${groupName}.internalProduction.${index}.hourValue`),
      ) * hours,
    );
    setValue(
      `${groupName}.internalProduction.${index}.total`,
      toCurrencyBrl(totalValue),
    );
  };

  return (
    <div>
      <PrintedBudgetTitleContainer>
        <PrintedBudgetColumnTitle>Produção Interna</PrintedBudgetColumnTitle>
        <UnstyledButton
          title="Visível"
          type="button"
          onClick={onVisibilityClick}
          style={{ marginBottom: 16 }}
        >
          <NoPrint data-html2canvas-ignore>
            {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </NoPrint>
        </UnstyledButton>
      </PrintedBudgetTitleContainer>
      {isVisible && (
        <FormGroupWithAdding
          title="Produção interna"
          groupName={`${groupName}.internalProduction`}
          withoutAdding
          renderRow={({ index }) => (
            <InternalProductColumn>
              <TextField
                name={`${groupName}.internalProduction.${index}.item`}
                label="Item"
                isLight
              />
              <TextField
                name={`${groupName}.internalProduction.${index}.responsible`}
                label="Responsável"
                isLight
              />
              <TextField
                name={`${groupName}.internalProduction.${index}.hours`}
                label="Quantidade de Horas"
                defaultValue={0}
                onInput={(hours) => {
                  updateTotal(hours, index);
                }}
                isLight
              />
              <TextField
                name={`${groupName}.internalProduction.${index}.hourValue`}
                label="Valor Hora"
                defaultValue={0}
                readOnly
                isLight
              />
              <TextField
                name={`${groupName}.internalProduction.${index}.total`}
                label="Total com Impostos"
                pattern="money"
                defaultValue={0}
                readOnly
                isLight
              />
            </InternalProductColumn>
          )}
        />
      )}
    </div>
  );
}
