import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Spinner } from 'components/atoms/Spinner';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { DefaultForm } from 'styles/miscellany';
import { masks } from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { CREATE_CONTACT, GET_CONTACT_BY_ID, UPDATE_CONTACT } from '../../query';

interface ContactFormProps {
  refetch: any;
  id?: string;
}

export function ContactForm(props: ContactFormProps) {
  const { id, refetch } = props;
  const isEditing = !!id;
  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { closeDrawer } = useDrawer();
  const [getContactsById, contactsResponse] = useLazyQuery(GET_CONTACT_BY_ID, {
    notifyOnNetworkStatusChange: true,
  });
  const contact = contactsResponse?.data?.ContactItems?.items[0] || null;
  const [createContact] = useMutation(CREATE_CONTACT);
  const [updateContact] = useMutation(UPDATE_CONTACT);

  useEffect(() => {
    getContactsById({ variables: { id } });
  }, []);

  useEffect(() => {
    if (contact && isEditing) {
      Object.entries(contact).forEach(([key, value]) => {
        if (key !== '__typename') {
          methods.setValue(key, value, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      });
    }
  }, [contact]);

  const onSubmit = async (dataForm) => {
    setIsLoading(true);

    try {
      if (isEditing) {
        await updateContact({ variables: { id, ...dataForm } });
      } else {
        await createContact({ variables: { id, ...dataForm } });
      }

      toast.success(`Cadastro ${isEditing ? 'atualizado' : 'efetuado'}!`);

      refetch();

      closeDrawer();
    } catch {
      toast.error(
        `Ops, falha ao ${isEditing ? 'atualizar' : 'efetuar'} o cadastro.`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <FormProvider {...methods}>
      <DefaultForm onSubmit={methods.handleSubmit(onSubmit)} id="contact-form">
        <TextField name="area" label="Setor" />
        <TextField
          name="name"
          label="Nome"
          validation={validator('required')}
        />
        <TextField name="email" label="Email" validation={validator('email')} />
        <TextField name="product" label="Produto" />
        <TextField name="phone" label="Celular" pattern={masks('phone')} />
      </DefaultForm>
    </FormProvider>
  );
}
