import styled, { css } from 'styled-components';

interface CompanyGridContainerProps {
  readOnly?: boolean;
}

export const CompanyGridContainer = styled.div<CompanyGridContainerProps>`
  display: grid;
  grid-template-columns: 1fr 6fr 6fr 8fr 3fr 1fr;
  ${({ readOnly }) =>
    readOnly &&
    css`
      grid-template-columns: 1fr 6fr 6fr 8fr 3fr;
    `}
  gap: 1.5rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const UnstyledButton = styled.button`
  background-color: transparent;
  border: none;
`;

export const RadioButtonContainer = styled(FlexContainer)`
  cursor: pointer;
`;
