import { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { toCurrency, toCurrencyBrl } from 'utils/masks';

import { ProfitDealsForm } from './components/ProfitDeals';
import { DELETE_AGREEMENT, GET_AGREEMENTS } from './query';
import { ClientProfitDealsContainer, TrashStyled } from './style';

export function ClientProfitDeals() {
  const { openDrawer } = useDrawer();
  const [searchTerm, setSearchTerm] = useState('');
  const [getAgreements, agreementsResponse] = useLazyQuery(GET_AGREEMENTS, {
    notifyOnNetworkStatusChange: true,
  });
  const [deleteAgreements] = useMutation(DELETE_AGREEMENT);
  const agreementsData = agreementsResponse.data?.AgreementItems?.items || [];
  const pages = agreementsResponse.data?.AgreementItems?.pages || 1;

  const handleAction = (id?: string) => {
    openDrawer({
      title: id ? 'Editar Acordo' : 'Adicionar Acordo',
      content: <ProfitDealsForm id={id} refetch={agreementsResponse.refetch} />,
      formId: 'profit-deals-form',
      width: 600,
    });
  };

  const handleDelete = (id: string) => {
    deleteAgreements({ variables: { id } });

    if (agreementsResponse.refetch) agreementsResponse.refetch();
  };

  useEffect(() => {
    getAgreements();
  }, [getAgreements]);

  return (
    <ClientProfitDealsContainer>
      <Toolbar title="" onSearch={setSearchTerm} onAction={handleAction} />
      <Table
        pages={pages}
        fetchData={getAgreements}
        searchTerm={searchTerm}
        columns={[
          { Header: 'Categoria', accessor: 'category' },
          {
            Header: 'Percentual Padrão',
            accessor: (row) =>
              `${toCurrency(row.defaultPercentage.toFixed(2))}%`,
          },
          {
            Header: 'Abaixo de',
            accessor: (row) =>
              `${toCurrencyBrl(row.underValue.toFixed(2))} - ${toCurrency(
                row.underPercentage.toFixed(2),
              )}%`,
          },
          {
            Header: 'Acima de',
            accessor: (row) =>
              `${toCurrencyBrl(row.aboveValue.toFixed(2))} - ${toCurrency(
                row.abovePercentage.toFixed(2),
              )}%`,
          },
        ]}
        loading={agreementsResponse.loading}
        emptyText="Não há registros para consulta"
        data={agreementsData}
        actions={(rowData) => [
          {
            component: (
              <TrashStyled
                role="button"
                tabIndex={0}
                onClick={() => handleDelete(rowData._id)}
              >
                <TrashIcon variant="light" width={24} height={24} />
              </TrashStyled>
            ),
          },
          {
            component: (
              <TrashStyled
                role="button"
                tabIndex={0}
                onClick={() => handleAction(rowData._id)}
              >
                <GearIcon variant="light" width={20} height={20} />
              </TrashStyled>
            ),
          },
        ]}
      />
    </ClientProfitDealsContainer>
  );
}
