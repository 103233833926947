import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { Switch } from 'components/atoms/Switch';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { IconButton } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';

import { CollaboratorForm } from './components/CollaboratorForm';
import { GET_COLLABORATORS_LIST, UPDATE_COLLABORATOR } from './query';

export function Collaborators() {
  const [searchTerm, setSearchTerm] = useState('');
  const { openDrawer } = useDrawer();
  const { currentCompanyId } = useWorkspace();
  const [updateCollaborator, updateCollaboratorRes] =
    useMutation(UPDATE_COLLABORATOR);
  const [getCollaborators, getCollaboratorsRes] = useLazyQuery(
    GET_COLLABORATORS_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const items = getCollaboratorsRes.data?.ColaboratorItems?.items || [];

  useEffect(() => {
    getCollaborators({ variables: { unit: currentCompanyId } });
  }, [getCollaborators, currentCompanyId]);

  const handleAction = (id?) => {
    openDrawer({
      title: `${id ? 'Editar' : 'Adicionar'} Colaborador`,
      content: (
        <CollaboratorForm id={id} refetch={getCollaboratorsRes.refetch} />
      ),
      formId: 'collaborator-form',
      width: 900,
    });
  };

  const handleSwitch = (e, id) => {
    updateCollaborator({
      variables: {
        id,
        isDisabled: !e,
      },
    });
  };

  useEffect(() => {
    if (updateCollaboratorRes.error) {
      toast.error('Erro ao atualizar colaborador!');
    }
  }, [updateCollaboratorRes.error]);

  return (
    <>
      <Toolbar
        title="Colaboradores"
        onSearch={setSearchTerm}
        onAction={handleAction}
      />

      <Table
        pages={getCollaboratorsRes.data?.ColaboratorItems?.pages}
        totalItems={getCollaboratorsRes.data?.ColaboratorItems?.total}
        fetchData={getCollaborators}
        fetchVariables={{ unit: currentCompanyId }}
        searchTerm={searchTerm}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ value }) => capitalize(value),
          },
          { Header: 'Cargo', accessor: 'area' },
        ]}
        loading={getCollaboratorsRes.loading}
        data={items}
        emptyText="Não há registros para consulta"
        withAvatar
        actions={(rowData) => [
          {
            component: (
              <Switch
                onChange={(e) => handleSwitch(e, rowData._id)}
                disabled={updateCollaboratorRes.loading}
                defaultChecked={!rowData?.is_disabled}
              />
            ),
          },
          {
            component: (
              <IconButton onClick={() => handleAction(rowData._id)}>
                <GearIcon variant="light" width={20} height={20} />
              </IconButton>
            ),
          },
        ]}
      />
    </>
  );
}
