import { ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from 'components/atoms/Button';
import { Plus } from 'components/atoms/Icons/Plus';
import { SearchField } from 'components/molecules/SearchField';
import { GridColumn } from 'styles/miscellany';

import { ToolbarContainer, ToolbarForm, ToolbarFormNoTitle } from './style';

interface ToolbarProps {
  title: string | ReactNode;
  onSearch?: (data: any) => void;
  onAction?: () => void;
}

export function Toolbar(props: ToolbarProps) {
  const { title, onSearch, onAction } = props;
  const methods = useForm();

  const withAction = !!onAction;
  if (title)
    return (
      <ToolbarContainer>
        <h4>{title}</h4>
        <GridColumn length={withAction ? 2 : 1} gap={1}>
          <FormProvider {...methods}>
            <ToolbarForm
              onSubmit={methods.handleSubmit(
                (data) => onSearch && onSearch(data.search),
              )}
            >
              <SearchField name="search" />
            </ToolbarForm>
          </FormProvider>
          {withAction && (
            <Button
              icon={<Plus />}
              variant="primary"
              onClick={() => onAction()}
            >
              ADD
            </Button>
          )}
        </GridColumn>
      </ToolbarContainer>
    );
  return (
    <ToolbarContainer>
      <FormProvider {...methods}>
        <ToolbarFormNoTitle
          withButton={withAction}
          onSubmit={methods.handleSubmit(
            (data) => onSearch && onSearch(data.search),
          )}
        >
          <SearchField name="search" />
        </ToolbarFormNoTitle>
      </FormProvider>
      {withAction && (
        <Button icon={<Plus />} variant="primary" onClick={() => onAction()}>
          ADD
        </Button>
      )}
    </ToolbarContainer>
  );
}

Toolbar.defaultProps = {
  onSearch: null,
  onAction: null,
};
