import styled from 'styled-components';
import { colors } from 'styles/colors';

export const POModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 2rem 1rem;
  overflow: none;
`;

export const IconHolder = styled.div`
  border: 1px solid ${colors.light};
  border-radius: 50%;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const POForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 1.5rem 0;

  & ~ button {
    width: 13rem;
  }
`;

export const VariantContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const VariantText = styled.p`
  text-align: center;
  margin-bottom: 1.5rem;

  & ~ button {
    width: 11rem;
  }

  & ~ a {
    width: fit-content;
  }
`;

export const PreviewData = styled.p`
  color: ${colors.primary};
  margin-bottom: 0.5rem;

  ~ p {
    margin-bottom: 0.5rem;
  }
`;
