import { gql } from '@apollo/client';

export const GET_REVENUES = gql`
  query ($id: String) {
    ProjectItems(id: $id) {
      items {
        _id
        recognitions {
          item
          state
          date
          revenue
          cost
          percentage
          recognizedBy {
            _id
            avatar
          }
        }
      }
    }
  }
`;

export const GET_PERCENTAGE = gql`
  query ($id: String) {
    ProjectItems(id: $id) {
      items {
        _id
        percentageToBeRecognized
      }
    }
  }
`;

export const SAVE_REVENUE = gql`
  mutation ($id: String!, $recognitions: [RecognitionInput]) {
    projectSetup(id: $id, recognitions: $recognitions)
  }
`;
