/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'components/atoms/Button';
import { EmptyLabel } from 'components/atoms/EmptyTable';
import { Plus } from 'components/atoms/Icons/Plus';
import { SearchField } from 'components/molecules/SearchField';
import { useUser } from 'contexts/UserContext';
import { ButtonsContainer } from 'pages/Budgets/components/NewBudget/style';
import { DefaultForm } from 'styles/miscellany';
import { normalizeText } from 'utils/formatters/normalize-text';
import { clearCurrencyNumberBrl, toCurrencyBrl } from 'utils/masks';

import RecognitionFormRow from './components/RecognitionFormRow';
import { GET_PERCENTAGE, GET_REVENUES, SAVE_REVENUE } from './query';
import {
  PercentHolder,
  PercentTitle,
  PercentValue,
  RecognitionControlContainer,
  RecognitionControlContent,
  RecognitionControlHeader,
  RecognitionFields,
  SearchAction,
} from './style';

export function RecognitionControl() {
  const methods = useForm();
  const [revenues, revenuesRes] = useLazyQuery(GET_REVENUES, {
    notifyOnNetworkStatusChange: true,
  });
  const [percentage, percentageRes] = useLazyQuery(GET_PERCENTAGE);
  const [saveRevenue] = useMutation(SAVE_REVENUE);

  const { userData } = useUser();
  const params: any = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const recognitions = revenuesRes.data?.ProjectItems?.items[0]?.recognitions;
  const percentageToBeRecognized =
    percentageRes.data?.ProjectItems?.items[0]?.percentageToBeRecognized;

  const formValues = methods.watch();
  const recognitionForm = formValues.recognitions || [];

  useEffect(() => {
    if (params?.id) {
      revenues({ variables: { id: params.id } });
      percentage({ variables: { id: params.id } });

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [revenues, params]);

  useEffect(() => {
    if (recognitions?.length) {
      const tmpFields = [...recognitions];
      tmpFields.forEach((item) => {
        item.cost = toCurrencyBrl(item.cost ? item.cost.toFixed(2) : 0);
        item.revenue = toCurrencyBrl(
          item.revenue ? item.revenue.toFixed(2) : 0,
        );
        item.avatar = item.recognizedBy?.avatar;
      });
      methods.setValue('recognitions', tmpFields);
    }
  }, [recognitions]);

  const onSubmit = (data) => {
    if (methods.formState.isValid) {
      const payload = data.map((item) => {
        return {
          item: item.item,
          date: item.date,
          state: item.state,
          cost: item.cost ? clearCurrencyNumberBrl(`${item.cost}`) : 0,
          revenue: item.revenue ? clearCurrencyNumberBrl(`${item.revenue}`) : 0,
          percentage: item.percentage || 0,
          recognizedBy: item.recognizedBy
            ? item.recognizedBy._id
            : userData._id,
        };
      });

      saveRevenue({
        variables: {
          id: params?.id,
          recognitions: payload,
        },
      })
        .then(() => {
          toast.success('Dados atualizados com sucesso.');
          if (percentageRes.refetch) {
            percentageRes.refetch();
          }
        })
        .catch(() => toast.error('Erro ao salvar os dados.'));
    }
  };

  const replaceItems = (newItems) => {
    methods.setValue('recognitions', newItems, { shouldDirty: true });
  };

  const addRecognition = () => {
    const tmpFields = [...recognitionForm];
    tmpFields.push({ avatar: userData.avatar });
    replaceItems(tmpFields);
  };

  const handleDelete = (index) => {
    const tmpFields = [...recognitionForm];
    tmpFields.splice(index, 1);
    replaceItems(tmpFields);
  };

  const handleSearch = (search) => {
    const newData = recognitionForm.map((item) => {
      if (!normalizeText(item.item).includes(normalizeText(search))) {
        return { ...item, hidden: true };
      }
      if (search) {
        return { ...item, hidden: false };
      }
      delete item.hidden;

      return { ...item };
    });
    replaceItems(newData);
  };

  return (
    <RecognitionControlContainer>
      <RecognitionControlHeader>
        <PercentHolder>
          <PercentTitle>Porcentagem a reconhecer:</PercentTitle>
          <PercentValue>{percentageToBeRecognized}%</PercentValue>
        </PercentHolder>
        <SearchAction>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(({ search }) =>
                handleSearch(search),
              )}
            >
              <SearchField name="search" />
            </form>
          </FormProvider>
          <Button icon={<Plus />} variant="primary" onClick={addRecognition}>
            ADD
          </Button>
        </SearchAction>
      </RecognitionControlHeader>
      <FormProvider {...methods}>
        <DefaultForm
          onSubmit={methods.handleSubmit(onSubmit)}
          id="recognition-form"
        >
          <RecognitionControlContent>
            {revenuesRes.loading || isLoading ? (
              <RecognitionFields>
                <Skeleton circle width={60} height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
              </RecognitionFields>
            ) : recognitionForm?.length ? (
              <>
                {recognitionForm.map(
                  (item, index) =>
                    !item.hidden && (
                      <RecognitionFormRow
                        key={index}
                        index={index}
                        handleDelete={handleDelete}
                      />
                    ),
                )}
              </>
            ) : (
              <EmptyLabel text="Não há registros para consulta" />
            )}
          </RecognitionControlContent>
        </DefaultForm>
      </FormProvider>

      <ButtonsContainer style={{ justifyContent: 'flex-end' }}>
        <div>
          <Button
            variant="primary"
            form="recognition-form"
            onClick={() => onSubmit(recognitionForm)}
            disabled={recognitionForm?.length <= 0}
          >
            Salvar
          </Button>
        </div>
      </ButtonsContainer>
    </RecognitionControlContainer>
  );
}
