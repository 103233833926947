/* eslint-disable react/destructuring-assignment */
import { createElement } from 'react';

import { colors } from 'styles/colors';

import { NewBudgetTabContainer, NumberContainer } from './style';

export function NewBudgetTab(props) {
  const tabs = ['Informações', 'Estrutura', 'Proposta Comercial'];

  return (
    <NewBudgetTabContainer>
      {tabs.map((item, index) => {
        return (
          <div key={item}>
            {props.id === index
              ? createElement(
                  NumberContainer,
                  { style: { backgroundColor: colors.primary } },
                  index + 1,
                )
              : createElement(NumberContainer, {}, index + 1)}
            {item}
            {index < 2 ? <span /> : ''}
          </div>
        );
      })}
    </NewBudgetTabContainer>
  );
}
