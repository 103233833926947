/* eslint-disable react/default-props-match-prop-types */
import { FieldProps } from 'components/atoms/Field';
import { UploadIcon } from 'components/atoms/Icons/UploadIcon';
import { TextField } from 'components/molecules/TextField';

import { RevenueLinkContainer, IconContainer } from './style';

interface Props extends FieldProps {
  label: string;
  variant: 'dark' | 'light' | 'special';
}
export function RevenueLink(props: Props) {
  const { name, label, variant, ...rest } = props;
  return (
    <RevenueLinkContainer>
      <TextField name={name} label={label} {...rest} />
      <IconContainer>
        <UploadIcon variant={variant} width={20} height={20} />
      </IconContainer>
    </RevenueLinkContainer>
  );
}

RevenueLink.defaultProps = {
  variant: 'special',
};
