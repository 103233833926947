import { Toaster } from 'react-hot-toast';
import { SkeletonTheme } from 'react-loading-skeleton';

import { useDrawer } from 'contexts/DrawerContext';
import Routes from 'routes';
import { GlobalStyle } from 'styles';

import 'assets/fonts/stylesheet.css';

function App() {
  const { isDrawerOpen } = useDrawer();

  return (
    <SkeletonTheme color="#272727" highlightColor="#202020">
      <GlobalStyle isDrawerOpen={isDrawerOpen} />
      <Toaster
        toastOptions={{
          style: {
            borderRadius: '10px',
            background: '#222222',
            color: '#fff',
          },
        }}
      />
      <Routes />
    </SkeletonTheme>
  );
}

export default App;
