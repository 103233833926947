import { useRef } from 'react';

import { useMutation } from '@apollo/client';
import { UploadIcon } from 'components/atoms/Icons/UploadIcon';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { useModal } from 'contexts/ModalContext';
import { AnimatePresence, motion } from 'framer-motion';
import { CompanyFile } from 'pages/Companies/components/CompanyForm';
import { DELETE_FILE } from 'pages/Companies/components/CompanyForm/query';
import { StyledUploadInput } from 'pages/Companies/components/CompanyForm/style';
import { colors } from 'styles/colors';
import { IconButton } from 'styles/miscellany';

import { FileDisplayCustom } from '../components/FileDisplayCustom';
import { DocumentsRow, FormDocuments } from '../style';

interface Props {
  files: CompanyFile[];
  onChange: (files: CompanyFile[]) => void;
  maxFiles?: number;
}
const ReceiptUpload = ({ files, onChange, maxFiles }: Props) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { openModal } = useModal();
  const [deleteFile] = useMutation(DELETE_FILE);

  const handleDeleteFile = (clickedFile: CompanyFile) => {
    if (clickedFile.type === 'uploadedFile') {
      openModal({
        content: (
          <DeleteModal
            onAccept={() =>
              deleteFile({ variables: { id: clickedFile.id } }).then(() => {
                onChange(files.filter((f) => f.id !== clickedFile.id));
              })
            }
          />
        ),
      });
    } else {
      onChange(files.filter((f) => f.id !== clickedFile.id));
    }
  };

  const triggerUpload = () => {
    if (inputFileRef.current) inputFileRef.current.click();
  };

  const onTypeChange = (id, type) => {
    const tmpFiles = [...files];
    const item: any = tmpFiles.find((f) => f.id === id) || {};
    item.fileType = type;
    onChange(tmpFiles);
  };

  return (
    <>
      <FormDocuments>
        <h6>Documentos</h6>
        {(!maxFiles || files.length < maxFiles) && (
          <IconButton
            size={0.875}
            color={colors.textOnBackground.special}
            type="button"
            onClick={triggerUpload}
          >
            <UploadIcon variant="special" width={20} height={20} />
            ANEXAR
          </IconButton>
        )}
        <StyledUploadInput
          type="file"
          ref={inputFileRef}
          multiple
          onChange={async (e) => {
            const fileList = e.target.files as FileList;
            const newFiles: File[] = [];
            for (
              let currentFile = 0;
              currentFile < fileList.length;
              currentFile += 1
            ) {
              const file = fileList.item(currentFile);
              if (file) newFiles.push(file as File);
            }
            if (newFiles) {
              const formattedFileArray: CompanyFile[] = [];
              for (
                let currentFile = 0;
                currentFile < fileList.length;
                currentFile += 1
              ) {
                formattedFileArray.push({
                  type: 'fileToUpload',
                  field: 'other',
                  name: newFiles[currentFile].name,
                  size: newFiles[currentFile].size / 1024 / 1024,
                  file: newFiles[currentFile],
                  fileType: 'nf',
                  id:
                    newFiles[currentFile].name +
                    newFiles[currentFile].size +
                    newFiles[currentFile].lastModified +
                    newFiles[currentFile].type,
                });
              }
              onChange(files.concat(formattedFileArray));
            }
          }}
        />
      </FormDocuments>

      <DocumentsRow>
        <AnimatePresence>
          {files.length > 0 && (
            <AnimatePresence>
              {files.map((otherFile) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key={otherFile.id || otherFile.name}
                >
                  <FileDisplayCustom
                    withType
                    fileLink={otherFile.fileUrl}
                    fileName={otherFile.name}
                    fileSizeMB={otherFile.size?.toFixed(2) || 0}
                    onClickDelete={() => handleDeleteFile(otherFile)}
                    onTypeChange={onTypeChange}
                    fileId={otherFile.id}
                    fileType={otherFile.fileType}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          )}
        </AnimatePresence>
      </DocumentsRow>
    </>
  );
};

export default ReceiptUpload;
