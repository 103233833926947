import { gql } from '@apollo/client';

export const GET_PROPOSAL_DATE = gql`
  query ($id: String!) {
    CompanyItems(id: $id) {
      items {
        _id
        name
        defaultProposalPrompt
      }
    }
  }
`;
