import { useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { StatusLabel } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';
import { toCurrencyBrl } from 'utils/masks';

import { GET_PROJECTS_LIST } from './query';
import { ProjectsContainer } from './style';

export function Revenues() {
  const { currentCompanyId } = useWorkspace();
  const [searchTerm, setSearchTerm] = useState('');
  const [getProjects, getProjectsRes] = useLazyQuery(GET_PROJECTS_LIST, {
    notifyOnNetworkStatusChange: true,
  });
  const items = getProjectsRes.data?.ProjectItems?.items || [];

  return (
    <ProjectsContainer>
      <Toolbar title="Faturamentos" onSearch={setSearchTerm} />
      <Table
        pages={getProjectsRes.data?.ProjectItems?.pages}
        fetchData={getProjects}
        searchTerm={searchTerm}
        fetchVariables={{ unit: currentCompanyId }}
        filter
        manualSort
        columns={[
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
              <StatusLabel
                variant={value === 'finalizado' ? 'inactive' : 'active'}
              >
                {capitalize(value)}
              </StatusLabel>
            ),
          },
          { Header: 'ID', accessor: 'APcode' },
          {
            Header: 'Cliente',
            accessor: 'client.legalName',
          },
          { Header: 'Produto', accessor: 'area' },
          { Header: 'Campanha', accessor: 'campaign' },
          {
            Header: 'Valor Faturado',
            accessor: 'revenue',
            Cell: ({ value }) => toCurrencyBrl(Number(value).toFixed(2)),
          },
          {
            Header: 'Custos Projetados',
            accessor: 'budget.revenue',
            Cell: ({ value }) => toCurrencyBrl(value),
          },
          {
            Header: 'Custo Realizado',
            accessor: 'costSoFar.value',
            Cell: ({ value }) => toCurrencyBrl(Number(value).toFixed(2)),
          },
        ]}
        loading={getProjectsRes.loading}
        emptyText="Não há registros para consulta"
        data={items}
      />
    </ProjectsContainer>
  );
}
