import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Drawer } from 'components/organisms/Drawer';
import { AnimatePresence } from 'framer-motion';

interface DrawerProps {
  width: number;
  title: string;
  content: ReactNode;
  formId?: string;
  onDelete?: () => void;
  isLoading?: boolean;
}

interface DrawerContextProps {
  isDrawerOpen?: boolean;
  openDrawer: (props: DrawerProps) => void;
  closeDrawer: () => void;
  setIsLoading: (boolean) => void;
}

export const DrawerContext = createContext({} as DrawerContextProps);

export const DrawerProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerProps, setDrawerProps] = useState<DrawerProps>();

  function closeDrawer() {
    setIsDrawerOpen(false);
  }

  function setIsLoading(value: boolean) {
    setLoading(value);
  }

  function openDrawer(props: DrawerProps) {
    setIsDrawerOpen(true);

    setDrawerProps(props);
  }

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        closeDrawer();
      }
    };

    window.addEventListener('keydown', close);

    return () => window.removeEventListener('keydown', close);
  }, []);

  const value = {
    isDrawerOpen,
    openDrawer,
    closeDrawer,
    setIsLoading,
  };

  return (
    <DrawerContext.Provider value={value}>
      <AnimatePresence>
        {isDrawerOpen && (
          <Drawer {...(drawerProps as DrawerProps)} isLoading={loading} />
        )}
      </AnimatePresence>

      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const ctx = useContext(DrawerContext);

  return useMemo(() => ctx, [ctx]);
};
