export function EmptyIcon() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 7H49C51.5667 7 53.6667 9.1 53.6667 11.6667V44.3333C53.6667 46.9 51.5667 49 49 49H7C4.43334 49 2.33334 46.9 2.33334 44.3333V11.6667C2.33334 9.1 4.43334 7 7 7ZM7 44.3333H49V21H30.3333V11.6667H7V44.3333Z"
        fill="#5A52FF"
      />
    </svg>
  );
}
