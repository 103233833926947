import { gql } from '@apollo/client';

export const APPROVE_BUDGET = gql`
  mutation (
    $budId: String!
    $serviceType: ProjectServiceType
    $paymentDate: String
    $approvedBy: String!
    $notes: String!
    $start_at: String
    $finish_at: String
  ) {
    approveBudget(
      budId: $budId
      serviceType: $serviceType
      paymentDate: $paymentDate
      approvedBy: $approvedBy
      notes: $notes
      start_at: $start_at
      finish_at: $finish_at
    ) {
      _id
    }
  }
`;
