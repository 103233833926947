import ReactDOM from 'react-dom';

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { DrawerProvider } from 'contexts/DrawerContext';
import { HelperDataProvider } from 'contexts/HelperDataContext';
import { ModalProvider } from 'contexts/ModalContext';
import { UserProvider } from 'contexts/UserContext';
import { WorkspaceProvider } from 'contexts/WorkspaceContext';
import { Services } from 'services/service';
import { storageKeys } from 'services/storage-keys';

import App from './App';

const httpLink = createHttpLink({
  uri: Services.Graphql,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(storageKeys.token);

  return {
    headers: {
      ...headers,
      'X-Access-Token': token || '',
    },
  };
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <UserProvider>
      <WorkspaceProvider>
        <HelperDataProvider>
          <ModalProvider>
            <DrawerProvider>
              <App />
            </DrawerProvider>
          </ModalProvider>
        </HelperDataProvider>
      </WorkspaceProvider>
    </UserProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
