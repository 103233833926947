import { colors } from 'styles/colors';

interface HomeIconProps {
  variant: 'dark' | 'light' | 'special';
  width: number;
  height: number;
}

export function HomeIcon({ variant, width, height }: HomeIconProps) {
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
    special: colors.textOnBackground.special,
  };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z"
        fill={variants[variant]}
      />
    </svg>
  );
}
