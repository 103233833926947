/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import { PrintedBudgetBackground } from 'components/atoms/Icons/PrintedBudgetBackground';
import { PrintedBudgetHeaderIcon } from 'components/atoms/Icons/PrintedBudgetHeaderIcon';
import { TextField } from 'components/molecules/TextField';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { add, addDays, addMonths, format } from 'date-fns';
import { calculateAllBudgetTotals } from 'pages/Budgets/service';
import { FlexContainer, Line } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';
import { clearCurrencyNumberBrl, toCurrency, toCurrencyBrl } from 'utils/masks';

import {
  GET_BUDGET_BY_ID,
  UPDATE_BUDGET,
} from '../Budgets/components/NewBudget/query';
import { PrintedBudgetTable } from './components/PrintedBudgetTable';
import { TextAreaCustom } from './components/PrintedBudgetTable/components/TextAreaCustom';
import { NoPrint, RightAlign } from './components/PrintedBudgetTable/style';
import { PrintedBudgetToolbar } from './components/PrintedBudgetToolbar';
import { GET_PROPOSAL_DATE } from './query';
import {
  BackgroundImageContainer,
  BoldText,
  BottomBackgroundImageContainer,
  CommentsContainer,
  CompanyNameContainer,
  DefaultForm,
  FieldContainer,
  FooterContainer,
  GridColumnLines,
  IdContainer,
  InCashButton,
  MarkedTitle,
  PaymentButtonsContainer,
  PaymentContainer,
  PortionContainer,
  PrintedBudgetColumnTitle,
  PrintedBudgetContainer,
  PrintedBudgetFlexContainer,
  PrintedBudgetFooter,
  PrintedBudgetHeader,
  PrintedBudgetMarkedSubtitle,
  PrintedBudgetSubText,
  PrintedBudgetSubtitle,
  PrintedBudgetTableColumn,
  PrintedBudgetText,
  PrintedBudgetTitle,
  TableBody,
  TableFooter,
  TableHeader,
  TotalInvestmentContainer,
} from './style';

export function PrintedBudget() {
  const [isVisible, setIsVisible] = useState(true);
  const [showBackground, setShowBackground] = useState(true);
  const [isInCash, setIsInCash] = useState(true);

  const methods = useForm();
  const { currentCompanyId, currentCompanyName } = useWorkspace();
  const { id } = useParams<any>();

  const [updateBudget, updateBudgetRes] = useMutation(UPDATE_BUDGET);
  const [getBudget, getBudgetRes] = useLazyQuery(GET_BUDGET_BY_ID, {
    notifyOnNetworkStatusChange: true,
  });

  const [getProposalDate, getProposalDateRes] = useLazyQuery(GET_PROPOSAL_DATE);

  const budgetItem = getBudgetRes?.data?.BudgetItems?.items[0];

  const budGroups = budgetItem?.budGroups;
  const proposalDate =
    getProposalDateRes?.data?.CompanyItems?.items[0].defaultProposalPrompt;

  const budgetState = methods.watch();
  const dateResult = add(new Date(), { days: proposalDate });

  const firstPortion = format(
    addDays(new Date(), proposalDate + 30 || 30),
    'dd/MM/yyyy',
  );
  const lastPortion =
    Number(methods.getValues('metadata')?.portion) !== 1
      ? format(
          addMonths(
            addDays(new Date(), proposalDate + 30 || 30),
            Number(methods.getValues('metadata')?.portion - 1 || 2),
          ),
          'dd/MM/yyyy',
        )
      : format(
          addMonths(addDays(new Date(), proposalDate + 30 || 30), 1),
          'dd/MM/yyyy',
        );

  const ref = React.createRef<any>();

  useEffect(() => {
    if (currentCompanyId) {
      getProposalDate({ variables: { id: currentCompanyId } });
    }
  }, [id, getProposalDate]);

  useEffect(() => {
    if (id) getBudget({ variables: { id } });
  }, [id, getBudget]);

  useEffect(() => {
    if (budgetItem) {
      if (budgetItem.metadata) {
        methods.setValue('metadata', JSON.parse(budgetItem.metadata));
      }
      if (budgetItem.paymentMethod) {
        methods.setValue('paymentMethod', budgetItem.paymentMethod);
      }

      budgetItem?.budGroups?.forEach((item: any, index) => {
        const tmpMetadata = JSON.parse(item.metadata);

        const newMetadata = {
          internalProduction: [],
          presets: [],
          outsource: [],
          repass: [],
          invoice: [],
          description: tmpMetadata.description,
          premise: tmpMetadata.premisses,
        };

        if (tmpMetadata?.internalProduction?.length > 0) {
          newMetadata.internalProduction =
            tmpMetadata.internalProduction.map((i) => {
              return {
                ...i,
                hourValue: toCurrencyBrl(i.hourValue.toFixed(2)),
                total: toCurrencyBrl(i.total.toFixed(2)),
              };
            }) || [];
        }
        if (tmpMetadata?.presets?.length > 0) {
          newMetadata.presets =
            tmpMetadata.presets.map((i) => {
              return {
                ...i,
                total: toCurrencyBrl(i.total),
                value: toCurrencyBrl(i.value),
              };
            }) || [];
        }
        if (tmpMetadata?.outsource?.length > 0) {
          newMetadata.outsource =
            tmpMetadata.outsource.map((i) => {
              return {
                ...i,
                admValue: toCurrencyBrl(i.admValue.toFixed(2)),
                total: toCurrencyBrl(i.total),
                value: toCurrencyBrl(i.value.toFixed(2)),
              };
            }) || [];
        }
        if (tmpMetadata?.repass?.length > 0) {
          newMetadata.repass =
            tmpMetadata.repass.map((i) => {
              return {
                ...i,
                admValue: toCurrencyBrl(i.admValue.toFixed(2)),
                total: toCurrencyBrl(i.total.toFixed(2)),
                value: toCurrencyBrl(i.value.toFixed(2)),
              };
            }) || [];
        }
        if (tmpMetadata?.invoice?.length > 0) {
          newMetadata.invoice =
            tmpMetadata.invoice.map((i) => {
              return {
                ...i,
                currencyConversion: toCurrencyBrl(i.currencyConversion),
                total: toCurrencyBrl(i.total),
                value: toCurrency(i.value.toFixed(2)),
              };
            }) || [];
        }

        methods.setValue(`budGroups.${index}`, {
          name: item.name,
          metadata: newMetadata,
        });
      });
      budgetItem?.budComissions?.forEach((item: any, index) => {
        methods.setValue(`budComissions.${index}`, {
          item: item.item,
          percentage: item.percentage,
          responsible: item.responsible,
          type: item.type,
        });
      });
      budgetItem?.budDeductions?.forEach((item: any, index) => {
        methods.setValue(`budDeductions.${index}`, {
          description: item.description,
          value: toCurrencyBrl(item.value * 100),
        });
      });

      setIsInCash(methods.getValues('metadata').isInCash);
    }
  }, [budgetItem]);

  useEffect(() => {
    if (updateBudgetRes.data) {
      toast.success('Alterações salvas', { position: 'top-left' });
    }
    if (updateBudgetRes.error) {
      toast.error('Ops, falha ao salvar as alterações.');
    }
  }, [updateBudgetRes.data, updateBudgetRes.error]);

  const onSubmit = (data) => {
    const payload = {
      ...JSON.parse(JSON.stringify(data)),
      id,
      clientId: budgetItem.client?._id,
      unit: currentCompanyId,
    };

    payload.metadata.isInCash = isInCash;

    if (
      Number(payload.metadata.portion) === 0 ||
      Number(payload.metadata.portion) === 1
    ) {
      payload.metadata.portion = 2;
    }

    if (payload.metadata) {
      payload.metadata = JSON.stringify(payload.metadata);
    }

    payload.budGroups?.forEach((group) => {
      if (group.metadata) {
        const tmpData = group.metadata;
        const newData = {
          internalProduction: [],
          presets: [],
          outsource: [],
          repass: [],
          invoice: [],
          description: tmpData.description,
          premisses: tmpData.premise,
        };

        newData.internalProduction = tmpData.internalProduction.map((i) => {
          return {
            ...i,
            hourValue: clearCurrencyNumberBrl(i.hourValue),
            total: clearCurrencyNumberBrl(i.total),
          };
        });

        newData.presets = tmpData.presets.map((i) => {
          return {
            ...i,
            total: clearCurrencyNumberBrl(i.total),
            value: clearCurrencyNumberBrl(i.value),
          };
        });

        newData.outsource = tmpData.outsource.map((i) => {
          return {
            ...i,
            admValue: clearCurrencyNumberBrl(i.value),
            total: clearCurrencyNumberBrl(i.total),
            value: clearCurrencyNumberBrl(i.value),
          };
        });

        newData.repass = tmpData.repass.map((i) => {
          return {
            ...i,
            admValue: clearCurrencyNumberBrl(i.admValue),
            total: clearCurrencyNumberBrl(i.total),
            value: clearCurrencyNumberBrl(i.value),
          };
        });

        newData.invoice = tmpData.invoice.map((i) => {
          return {
            ...i,
            currencyConversion: clearCurrencyNumberBrl(i.currencyConversion),
            total: clearCurrencyNumberBrl(i.total),
            value: clearCurrencyNumberBrl(i.value),
          };
        });

        group.name = group.name || 'Novo Grupo';
        if (newData) {
          group.metadata = JSON.stringify(newData);
        }
      }
    });
    payload.budComissions = payload.budComissions?.map((group) => {
      return {
        item: group.item,
        percentage: group.percentage,
        responsible: group.responsible,
        type: group.type,
      };
    });
    payload.budDeductions = payload.budDeductions?.map((group) => {
      return {
        description: group.description,
        value: clearCurrencyNumberBrl(group.value),
      };
    });
    updateBudget({ variables: payload });
  };

  const { totalBilling } = calculateAllBudgetTotals(budgetState);

  useEffect(() => {
    methods.setValue('revenue', totalBilling.toString());
  }, [totalBilling]);

  return (
    <>
      <FormProvider {...methods}>
        <DefaultForm onSubmit={methods.handleSubmit(onSubmit)}>
          {budgetState?.budGroups && (
            <PrintedBudgetToolbar
              onSave={() => onSubmit(budgetState)}
              budgetState={budgetState}
              docName={`${budgetItem?.APcodeExtended}-${currentCompanyName}-${budgetItem?.client.name}.${budgetItem?.area}-${budgetItem?.campaign}`}
              onVisibilityClick={() => setIsVisible(!isVisible)}
              isVisible={isVisible}
              showBackground={showBackground}
              onShowBackgroundClick={() => setShowBackground(!showBackground)}
            />
          )}

          <PrintedBudgetContainer ref={ref} id="pdf-print">
            <PrintedBudgetHeader>
              {showBackground && (
                <BackgroundImageContainer>
                  <PrintedBudgetBackground />
                </BackgroundImageContainer>
              )}
              <BoldText>
                <CompanyNameContainer>
                  <PrintedBudgetHeaderIcon />
                  <p
                    style={{
                      fontSize: 20,
                      marginLeft: 12,
                    }}
                  >
                    {currentCompanyName}
                  </p>
                </CompanyNameContainer>
              </BoldText>
              <PrintedBudgetTitle>
                <BoldText>
                  {budgetItem?.client?.name}/{budgetItem?.area}
                  {budgetItem?.campaign}
                </BoldText>
              </PrintedBudgetTitle>
              <IdContainer>
                <p>Proposta Comercial {budgetItem?.APcodeExtended}</p>
              </IdContainer>
            </PrintedBudgetHeader>

            <table>
              <thead>
                <tr>
                  <td>
                    <TableHeader />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <TableBody>
                      <PrintedBudgetMarkedSubtitle>
                        Escopo de trabalho
                      </PrintedBudgetMarkedSubtitle>

                      <div>
                        <PrintedBudgetTableColumn>
                          <PrintedBudgetColumnTitle>
                            ENTREGA
                          </PrintedBudgetColumnTitle>
                          <PrintedBudgetColumnTitle>
                            DESCRITIVO
                          </PrintedBudgetColumnTitle>
                          <PrintedBudgetColumnTitle>
                            PREMISSAS
                          </PrintedBudgetColumnTitle>
                          <RightAlign>
                            <PrintedBudgetColumnTitle>
                              VALOR
                            </PrintedBudgetColumnTitle>
                          </RightAlign>
                        </PrintedBudgetTableColumn>

                        {budGroups &&
                          budGroups?.map((group, index) => (
                            <PrintedBudgetTable
                              key={`budGroups.${index}`}
                              groupName={`budGroups.${index}`}
                              group={
                                budgetState?.budGroups
                                  ? budgetState?.budGroups[index]
                                  : null
                              }
                              isVisible={isVisible}
                              index={index}
                            />
                          ))}
                      </div>

                      <Line
                        color="#c4c4c4"
                        style={
                          isVisible
                            ? { margin: '-6rem 0 1.5625rem' }
                            : { margin: '3.875rem 0 1.5625rem' }
                        }
                      />
                      {budgetItem?.budDeductions && (
                        <>
                          <FieldContainer>
                            <FlexContainer>
                              <PrintedBudgetColumnTitle style={{ margin: 0 }}>
                                Deduções
                              </PrintedBudgetColumnTitle>
                            </FlexContainer>
                            {budgetItem &&
                              budgetItem?.budDeductions?.map((group) => (
                                <>
                                  <FlexContainer value={1.93}>
                                    <PrintedBudgetText>
                                      {group.description}
                                    </PrintedBudgetText>
                                  </FlexContainer>
                                  <FlexContainer>
                                    <RightAlign>
                                      <PrintedBudgetColumnTitle
                                        style={{ margin: 0 }}
                                      >
                                        {group.value &&
                                          toCurrencyBrl(group.value * 100)}
                                      </PrintedBudgetColumnTitle>
                                    </RightAlign>
                                  </FlexContainer>
                                </>
                              ))}
                          </FieldContainer>
                          <Line
                            color="#c4c4c4"
                            style={{ margin: '1.5625rem 0 4rem' }}
                          />
                        </>
                      )}

                      <TotalInvestmentContainer>
                        <PrintedBudgetFlexContainer>
                          <PrintedBudgetSubtitle
                            style={{ marginRight: '2.8125rem' }}
                          >
                            Investimento Total:
                          </PrintedBudgetSubtitle>
                          <PrintedBudgetSubtitle style={{ marginRight: 26 }}>
                            {toCurrencyBrl(totalBilling)}
                          </PrintedBudgetSubtitle>
                        </PrintedBudgetFlexContainer>
                      </TotalInvestmentContainer>

                      <Line color="#c4c4c4" style={{ margin: '4rem 0 5rem' }} />

                      <PaymentContainer>
                        <div>
                          <MarkedTitle>Forma de pagamento</MarkedTitle>

                          <PaymentButtonsContainer>
                            <InCashButton
                              isSelected={isInCash}
                              style={{
                                marginLeft: '1rem',
                                marginRight: '1rem',
                              }}
                              onClick={() => !isInCash && setIsInCash(true)}
                            >
                              Á vista
                            </InCashButton>
                            <InCashButton
                              isSelected={!isInCash}
                              onClick={() => isInCash && setIsInCash(false)}
                            >
                              Parcelado
                            </InCashButton>
                          </PaymentButtonsContainer>
                        </div>

                        {isInCash ? (
                          <PortionContainer>
                            <BoldText>
                              Data de pagamento: {firstPortion}
                            </BoldText>
                          </PortionContainer>
                        ) : (
                          <PortionContainer>
                            <BoldText
                              style={{
                                display: 'flex',
                                fontSize: 18,
                                marginRight: '1rem',
                                alignItems: 'center',
                              }}
                            >
                              Parcelas:
                              <TextField
                                name="metadata.portion"
                                label=""
                                style={{
                                  color: '#000000',
                                  fontWeight: 'bold',
                                  height: '34px',
                                  width: '50px',
                                  borderRadius: '8px',
                                  marginLeft: '10px',
                                  marginRight: '10px',
                                }}
                              />
                              <div style={{ display: 'flex' }}>
                                <BoldText style={{ marginRight: '4px' }}>
                                  R$
                                </BoldText>
                                {(
                                  clearCurrencyNumberBrl(
                                    toCurrencyBrl(totalBilling),
                                  ) /
                                  Number(
                                    Number(
                                      methods.getValues('metadata')?.portion,
                                    ) !== 0 &&
                                      Number(
                                        methods.getValues('metadata').portion,
                                      ) !== 1
                                      ? Number(
                                          methods.getValues('metadata')
                                            ?.portion,
                                        )
                                      : 2,
                                  )
                                ).toFixed(2)}
                              </div>
                            </BoldText>
                            <BoldText
                              style={{ fontSize: 16, marginRight: '8px' }}
                            >
                              Primeira parcela:{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                }}
                              >
                                {firstPortion}
                              </span>
                            </BoldText>
                            <BoldText style={{ fontSize: 16 }}>
                              Última parcela:{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                }}
                              >
                                {lastPortion}
                              </span>
                            </BoldText>
                          </PortionContainer>
                        )}
                      </PaymentContainer>

                      <Line
                        color="#c4c4c4"
                        style={{ margin: '5rem 0 5rem 0' }}
                      />

                      <div>
                        <PrintedBudgetMarkedSubtitle
                          style={{ marginBottom: '2rem' }}
                        >
                          Observações
                        </PrintedBudgetMarkedSubtitle>
                        <NoPrint>
                          <TextAreaCustom
                            placeholder="Escreva a observação aqui..."
                            name="metadata.note"
                            style={{ fontSize: '14px' }}
                          />
                        </NoPrint>
                        <CommentsContainer>
                          <p>{methods.getValues('metadata.note')}</p>
                        </CommentsContainer>
                      </div>

                      <Line
                        color="#c4c4c4"
                        style={{ margin: '5.3125rem 0 10.625rem 0' }}
                      />

                      <GridColumnLines>
                        <div
                          style={{
                            width: '400px',
                            marginLeft: 25,
                            marginRight: 25,
                          }}
                        >
                          <Line
                            color="#000000"
                            style={{ margin: '0 0 1.1875rem 0' }}
                          />
                          <p style={{ textAlign: 'center' }}>
                            <BoldText>{currentCompanyName}</BoldText>
                          </p>
                        </div>
                        <div
                          style={{
                            width: '400px',
                            marginLeft: 25,
                            marginRight: 25,
                          }}
                        >
                          <Line
                            color="#000000"
                            style={{ margin: '0 0 1.1875rem 0' }}
                          />
                          <p style={{ textAlign: 'center' }}>
                            <BoldText>
                              {capitalize(budgetItem?.client?.name)}
                            </BoldText>
                          </p>
                        </div>
                      </GridColumnLines>

                      <PrintedBudgetColumnTitle>Legal</PrintedBudgetColumnTitle>
                      <Line
                        color="#c4c4c4"
                        style={{ margin: '0 0 2.5rem 0' }}
                      />

                      <p style={{ color: 'black', fontSize: 14 }}>
                        {(budgetItem?.client?.legals &&
                          budgetItem?.client?.legals[0]?.message) ||
                          'Por meio da aprovação deste documento, a Agência receberá o valor acordado, que deve ser considerado para todos os efeitos como fixo e não sujeitos a reconciliação, auferido conforme faturado. A execução   desta proposta pode envolver a contratação de terceiros.'}
                      </p>
                    </TableBody>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <TableFooter />
                  </td>
                </tr>
              </tfoot>
            </table>

            <PrintedBudgetFooter style={{ marginTop: '8.25rem' }}>
              {showBackground && (
                <BottomBackgroundImageContainer>
                  <PrintedBudgetBackground />
                </BottomBackgroundImageContainer>
              )}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <PrintedBudgetColumnTitle
                  style={{ margin: 0, marginRight: '2rem' }}
                >
                  Validade da proposta
                </PrintedBudgetColumnTitle>
                <PrintedBudgetText>
                  {format(dateResult, 'dd/MM/yyyy')}
                </PrintedBudgetText>
              </div>
              <FooterContainer>
                <PrintedBudgetSubText>
                  PROPRIETARY AND CONFIDENTIAL.{' '}
                  {currentCompanyName?.toUpperCase()}, フラグ
                </PrintedBudgetSubText>
                <PrintedBudgetSubText>
                  {format(new Date(), 'dd.MM.yyyy')}
                </PrintedBudgetSubText>
              </FooterContainer>
            </PrintedBudgetFooter>
          </PrintedBudgetContainer>
        </DefaultForm>
      </FormProvider>
    </>
  );
}
