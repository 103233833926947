/* eslint-disable react/no-array-index-key */
import { useFormContext, useWatch } from 'react-hook-form';

import { Plus } from 'components/atoms/Icons/Plus';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { TextField } from 'components/molecules/TextField';
import { StyledRowDiv } from 'pages/Clients/components/ClientForm/style';
import { colors } from 'styles/colors';
import { validator } from 'utils/validators/form-validations';

import { FlexContainer, UnstyledButton } from '../CompanyBankAccounts/style';
import { AddButton, StyledDiv, StyledSubtitle } from '../CompanyForm/style';
import { GridContainer } from './style';

interface CompanyContactsProps {
  readOnly?: boolean;
}

export function CompanyContacts({ readOnly }: CompanyContactsProps) {
  const { setValue } = useFormContext();

  const groupName = 'contacts';
  const fields = useWatch({ name: groupName }) || [{}];

  const replaceItems = (newItems) => {
    setValue(groupName, newItems, { shouldDirty: true });
  };

  const addItem = () => {
    const tmpFields = [...fields];
    tmpFields.push({});
    replaceItems(tmpFields);
  };

  const handleDelete = (index) => {
    const tmpFields = [...fields];
    tmpFields.splice(index, 1);
    replaceItems(tmpFields);
  };

  return (
    <>
      <StyledDiv>
        <StyledSubtitle>Responsável Operacional</StyledSubtitle>
        {!readOnly && (
          <StyledRowDiv style={{ justifyContent: 'flex-end' }}>
            <AddButton type="button" onClick={addItem}>
              <Plus color={colors.primary} />
              ADD
            </AddButton>
          </StyledRowDiv>
        )}
      </StyledDiv>

      {fields?.map((item, index) => (
        <GridContainer key={index} readOnly={readOnly}>
          <TextField
            name={`${groupName}.${index}.name`}
            label="Nome"
            validation={validator('required')}
            readOnly={readOnly}
          />
          <TextField
            name={`${groupName}.${index}.email`}
            label="Email"
            validation={validator('email')}
            readOnly={readOnly}
          />
          {!readOnly && (
            <FlexContainer>
              <UnstyledButton type="button" onClick={() => handleDelete(index)}>
                <TrashIcon variant="light" width={20} height={20} />
              </UnstyledButton>
            </FlexContainer>
          )}
        </GridContainer>
      ))}
    </>
  );
}
