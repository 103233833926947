import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const HeaderContainer = styled.header`
  width: 100%;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.backgroundLight};
`;

export const CompanyTextContainer = styled.h6`
  font-weight: 600;
  letter-spacing: 0.15px;
  margin-left: 1rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuStyleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const MenuLabel = styled.p`
  margin-right: 1rem;
`;

export const ProfileStyleContainer = styled(motion.div)`
  width: 15.5rem;
  position: absolute;
  top: 3.5rem;
  right: 1.5rem;
  z-index: 9999;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    ),
    linear-gradient(0deg, #121212, #121212);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14),
    0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

interface ArrowStyleContainerProps {
  direction: 'up' | 'down';
}

export const ArrowStyleContainer = styled.span<ArrowStyleContainerProps>`
  ${({ direction }) => css`
  margin-left: 9.83px;
  width: 0;
  height: 0;
  padding: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-${direction === 'up' ? 'top' : 'bottom'}: 5px solid 
  ${colors.textOnBackground.medium};
  `}
`;

export const ListStyleContainer = styled.ul`
  list-style: none;
  padding: 0.5rem;
`;

export const ListItemStyleContainer = styled.li`
  display: flex;
  border-bottom: 1px solid ${colors.outline};
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  cursor: pointer;

  &[data-last] {
    border: none;
  }
`;

export const ProfileIconsStyleContainer = styled.div`
  display: inline;
  width: 20px;
  height: 20px;
`;

export const ProfileTextStyleContainer = styled.p`
  padding-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  /* identical to box height, or 143% */

  letter-spacing: 0.25px;
`;

export const InvisibleDivContainer = styled.div`
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100%;
`;
