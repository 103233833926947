import { Link, useHistory } from 'react-router-dom';

import { Button } from 'components/atoms/Button';
import { TertiaryFileIcon } from 'components/atoms/Icons/TertiaryFileIcon';
import { useDrawer } from 'contexts/DrawerContext';
import { ApproveProject } from 'pages/Budgets/components/ApproveProject';
import { colors } from 'styles/colors';
import { GridColumn } from 'styles/miscellany';

import { NewBudgetToolbarContainer } from './style';

interface NewBudgetToolbarProps {
  title: string;
  id?: string;
}

export function NewBudgetToolbar({ title, id }: NewBudgetToolbarProps) {
  const { openDrawer } = useDrawer();
  const history = useHistory();

  const handleClick = () => {
    if (id) {
      openDrawer({
        title: `Aprovar projeto`,
        content: (
          <ApproveProject id={id} onSuccess={() => history.push('/budgets')} />
        ),
        formId: 'user-form',
        width: 600,
      });
    }
  };

  return (
    <NewBudgetToolbarContainer>
      <h4>{title}</h4>
      <GridColumn length={2} gap={1}>
        {id && (
          <>
            <Link to={`/budgets/${id}/printedBudget`} target="_blank">
              <Button variant="tertiary">
                <TertiaryFileIcon
                  color={colors.primary}
                  width={20}
                  height={20}
                />
              </Button>
            </Link>
            <Button variant="primary" onClick={handleClick}>
              Aprovar
            </Button>
          </>
        )}
      </GridColumn>
    </NewBudgetToolbarContainer>
  );
}
