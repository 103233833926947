import { useState } from 'react';

import { BillingControlTable } from 'components/organisms/BillingControlTable';
import { Toolbar } from 'components/organisms/Toolbar';
import { useWorkspace } from 'contexts/WorkspaceContext';

import { PaymentsContainer } from './styles';

export function Payments() {
  const [searchTerm, setSearchTerm] = useState('');
  const { neuronMode, currentCompanyId } = useWorkspace();

  return (
    <PaymentsContainer>
      <Toolbar title="Pagamentos" onSearch={setSearchTerm} />
      <BillingControlTable
        defaultFilters={!neuronMode ? { unit: currentCompanyId } : undefined}
        searchTerm={searchTerm}
      />
    </PaymentsContainer>
  );
}
