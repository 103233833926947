import { Button } from 'components/atoms/Button';
import { CopyIcon } from 'components/atoms/Icons/CopyIcon';
import { useModal } from 'contexts/ModalContext';

import {
  AttentionTitle,
  ButtonHolder,
  CopyModalContainer,
  IconHolder,
} from './style';

interface CopyModalProps {
  onAccept: () => void;
}

export function CopyModal(props: CopyModalProps) {
  const { onAccept } = props;
  const { closeModal } = useModal();

  const handleAccept = () => {
    onAccept();

    closeModal();
  };

  const handleDecline = () => {
    closeModal();
  };

  return (
    <CopyModalContainer>
      <IconHolder>
        <CopyIcon color="#F9F9F9" />
      </IconHolder>
      <AttentionTitle>Atenção</AttentionTitle>
      <p>Deseja realmente copiar esse item?</p>
      <ButtonHolder length={2} gap={1.5}>
        <Button variant="secondary" onClick={handleDecline}>
          Não
        </Button>
        <Button variant="primary" onClick={handleAccept}>
          Sim
        </Button>
      </ButtonHolder>
    </CopyModalContainer>
  );
}
