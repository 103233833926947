import { colors } from 'styles/colors';

interface GearIconProps {
  variant: 'dark' | 'light' | 'special';
  width: number;
  height: number;
}

export function GearIcon({ variant, width, height }: GearIconProps) {
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
    special: colors.textOnBackground.special,
  };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5001 12C19.5001 12.34 19.4701 12.66 19.4301 12.98L21.5401 14.63C21.7301 14.78 21.7801 15.05 21.6601 15.27L19.6601 18.73C19.5401 18.95 19.2801 19.04 19.0501 18.95L16.5601 17.95C16.0401 18.34 15.4801 18.68 14.8701 18.93L14.4901 21.58C14.4601 21.82 14.2501 22 14.0001 22H10.0001C9.75008 22 9.54008 21.82 9.51008 21.58L9.13008 18.93C8.52008 18.68 7.96008 18.35 7.44008 17.95L4.95008 18.95C4.73008 19.03 4.46008 18.95 4.34008 18.73L2.34008 15.27C2.22008 15.05 2.27008 14.78 2.46008 14.63L4.57008 12.98C4.53008 12.66 4.50008 12.33 4.50008 12C4.50008 11.67 4.53008 11.34 4.57008 11.02L2.46008 9.37C2.27008 9.22 2.21008 8.95 2.34008 8.73L4.34008 5.27C4.46008 5.05 4.72008 4.96 4.95008 5.05L7.44008 6.05C7.96008 5.66 8.52008 5.32 9.13008 5.07L9.51008 2.42C9.54008 2.18 9.75008 2 10.0001 2H14.0001C14.2501 2 14.4601 2.18 14.4901 2.42L14.8701 5.07C15.4801 5.32 16.0401 5.65 16.5601 6.05L19.0501 5.05C19.2701 4.97 19.5401 5.05 19.6601 5.27L21.6601 8.73C21.7801 8.95 21.7301 9.22 21.5401 9.37L19.4301 11.02C19.4701 11.34 19.5001 11.66 19.5001 12ZM8.50008 12C8.50008 13.93 10.0701 15.5 12.0001 15.5C13.9301 15.5 15.5001 13.93 15.5001 12C15.5001 10.07 13.9301 8.5 12.0001 8.5C10.0701 8.5 8.50008 10.07 8.50008 12Z"
        fill={variants[variant]}
        fillOpacity="0.87"
      />
    </svg>
  );
}
