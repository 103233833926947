import styled from 'styled-components';
import { colors } from 'styles/colors';

export const RevenueControlContainer = styled.div`
  margin-top: 2rem;
`;

export const SolicitationsContainer = styled.div`
  margin-top: 3rem;
`;

export const Container = styled.div`
  padding: 1rem 1.5rem;
`;

export const RevenueText = styled.p`
  color: ${colors.white};
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const ExportButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > button {
    width: 13rem;
    margin: 1rem 0 2rem 0;
  }
`;

export const ThreeLineGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 0 1rem;
`;

export const RevenueGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 0 1rem;

  > div {
    display: grid;
  }
`;
