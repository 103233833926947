import { colors } from 'styles/colors';

interface VisibilityOffIconProps {
  color?: string;
}

export const VisibilityOffIcon = ({ color }: VisibilityOffIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.01 3.86995L3.42 2.44995L21.15 20.2L19.74 21.61L16.31 18.18C14.97 18.7 13.52 19 12 19C7 19 2.73 15.89 1 11.5C1.77 9.52995 3.06 7.82995 4.69 6.54995L2.01 3.86995ZM17 11.5C17 8.73995 14.76 6.49995 12 6.49995C11.49 6.49995 11 6.59995 10.53 6.73995L8.36 4.56995C9.51 4.19995 10.73 3.99995 12 3.99995C17 3.99995 21.27 7.10995 23 11.49C22.31 13.25 21.21 14.79 19.82 16.02L16.76 12.96C16.9 12.5 17 12.01 17 11.5ZM12 16.5C9.24 16.5 7 14.26 7 11.5C7 10.73 7.18 9.99995 7.49 9.35995L9.06 10.93C9.03 11.11 9 11.3 9 11.5C9 13.16 10.34 14.5 12 14.5C12.2 14.5 12.38 14.47 12.57 14.43L14.14 16C13.49 16.32 12.77 16.5 12 16.5ZM14.97 11.17C14.82 9.76995 13.72 8.67995 12.33 8.52995L14.97 11.17Z"
        fill={color}
      />
    </svg>
  );
};

VisibilityOffIcon.defaultProps = {
  color: colors.primary,
};
