import { colors } from 'styles/colors';

interface ExitIconProps {
  variant: 'dark' | 'light' | 'special';
  width: number;
  height: number;
}

export function ExitIcon({ variant, width, height }: ExitIconProps) {
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
    special: colors.textOnBackground.special,
  };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3.01001H21C22.1 3.01001 23 3.90001 23 5.01001V19.01C23 20.11 22.1 20.99 21 20.99H3C1.9 20.99 1 20.11 1 19.01V15H3V19.02H21V4.99001H3V9.00001H1V5.01001C1 3.91001 1.9 3.01001 3 3.01001ZM15 12L11 16V13H1V11H11V8.00001L15 12Z"
        fill={variants[variant]}
        fillOpacity="0.87"
      />
    </svg>
  );
}
