import { useEffect, useState } from 'react';
import Select from 'react-select';

import { SelectContainer } from 'components/atoms/SelectField/style';

export const SelectFieldCustom = (props) => {
  const {
    placeholder,
    name,
    id,
    label,
    options,
    thatValue,
    onChangeValue,
    ...rest
  } = props;
  const [selectValue, setValue] = useState<any>();

  useEffect(() => {
    const thatLabel = options.filter((item) => item.value === thatValue || '');

    setValue(thatLabel);
  }, [thatValue]);

  const handleChange = (e) => {
    onChangeValue(e);

    setValue(e);
  };

  return (
    <SelectContainer>
      <Select
        {...rest}
        placeholder={placeholder}
        name={name}
        id={id}
        label={label}
        options={options}
        classNamePrefix="select-field"
        onChange={handleChange}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        value={selectValue}
      />
    </SelectContainer>
  );
};
