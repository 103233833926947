import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Avatar } from 'components/atoms/Avatar';
import { EmptyTable } from 'components/atoms/EmptyTable';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import {
  TableComponent,
  TableContainer,
} from 'components/organisms/Table/style';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { format } from 'date-fns';
import { removeAttribute } from 'utils/formatters/remove-typename';

import { LegalForm } from './components/LegalForm';
import { UPDATE_LEGALS } from './components/LegalForm/query';
import { CLIENT_LEGALS } from './query';
import {
  ClientAnnotationsContainer,
  AnnotationContainer,
  AnnotationHeaderContainer,
  AnnotationHeaderText,
  AnnotationTimestampText,
  AnotationUserContainer,
  AnnotationTextContainer,
  AnnotationsFooterContainer,
  ActionsContainer,
  ActionTextContainer,
} from './style';

export function ClientAnnotations() {
  const { openDrawer } = useDrawer();
  const params = useParams<any>();
  const [legalsData, setLegalsData] = useState<any>([]);
  const [getLegals, legalsResponse] = useLazyQuery<any>(CLIENT_LEGALS, {
    notifyOnNetworkStatusChange: true,
  });
  const [updateLegals] = useMutation(UPDATE_LEGALS);
  const [searchLegalsData, setSearchLegalsData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleAction = (id?: string) => {
    openDrawer({
      title: id ? 'Editar Anotação' : 'Adicionar Anotação',
      content: (
        <LegalForm
          legalsData={legalsData}
          id={id}
          clientId={params.id}
          refetch={legalsResponse.refetch}
        />
      ),
      formId: 'legal-form',
      width: 600,
    });
  };

  const handleDelete = (id: string) => {
    let newLegals = [...removeAttribute(legalsData, '__typename')];

    const rowIndex = newLegals.findIndex((item) => item._id === id);

    newLegals.splice(rowIndex, 1);

    newLegals = [...removeAttribute(newLegals, '_id')];

    setSearchLegalsData([]);

    (async () => {
      setIsLoading(true);

      await updateLegals({ variables: { id: params.id, legals: newLegals } });

      if (legalsResponse.refetch) await legalsResponse.refetch();

      setIsLoading(false);
    })();
  };

  const handleSearch = (search) => {
    setSearchLegalsData([]);

    if (search !== '') {
      legalsData.forEach((item) => {
        if (item.message.toUpperCase().includes(search.toUpperCase())) {
          setSearchLegalsData((prev) => [...prev, item]);
        }
      });
    }
  };

  useEffect(() => {
    if (params.id) getLegals({ variables: { id: params.id } });
  }, [getLegals, params.id]);

  useEffect(() => {
    const clientDataRes =
      legalsResponse.data?.ClientItems?.items[0]?.legals || [];

    const newClientData: any = [];

    if (clientDataRes) {
      clientDataRes.forEach((item) => {
        newClientData.push({ ...item, _id: uuid() });
      });
    }

    setLegalsData(newClientData);
  }, [legalsResponse.data]);

  if (isLoading || legalsResponse.loading)
    return <Skeleton height={200} style={{ marginTop: 20 }} />;

  return (
    <ClientAnnotationsContainer>
      <Toolbar
        title=""
        onSearch={handleSearch}
        onAction={() => {
          handleAction();
        }}
      />
      {legalsData?.length > 0 ? (
        searchLegalsData?.length > 0 ? (
          searchLegalsData.map((item: any) => {
            const { avatar, name, date } = JSON.parse(item.metadata);

            return (
              <AnnotationContainer>
                <AnnotationHeaderContainer>
                  <AnotationUserContainer>
                    <Avatar src={avatar} />
                    <AnnotationHeaderText>{name}</AnnotationHeaderText>
                  </AnotationUserContainer>
                  <AnnotationTimestampText>
                    {`${format(new Date(date), 'dd/MM/yyyy')}  ${format(
                      new Date(date),
                      'HH:mm',
                    )}`}
                  </AnnotationTimestampText>
                </AnnotationHeaderContainer>
                <AnnotationTextContainer>
                  {item.message}
                </AnnotationTextContainer>
                <AnnotationsFooterContainer>
                  <ActionsContainer onClick={() => handleDelete(item._id)}>
                    <TrashIcon width={20} height={20} variant="light" />
                    <ActionTextContainer color="light">
                      Deletar
                    </ActionTextContainer>
                  </ActionsContainer>
                  <ActionsContainer onClick={() => handleAction(item._id)}>
                    <GearIcon width={20} height={20} variant="special" />
                    <ActionTextContainer color="special">
                      Editar
                    </ActionTextContainer>
                  </ActionsContainer>
                </AnnotationsFooterContainer>
              </AnnotationContainer>
            );
          })
        ) : (
          legalsData.map((item) => {
            const { avatar, name, date } = JSON.parse(item.metadata);

            return (
              <AnnotationContainer>
                <AnnotationHeaderContainer>
                  <AnotationUserContainer>
                    <Avatar src={avatar} />
                    <AnnotationHeaderText>{name}</AnnotationHeaderText>
                  </AnotationUserContainer>
                  <AnnotationTimestampText>
                    {`${format(new Date(date), 'dd/MM/yyyy')}  ${format(
                      new Date(date),
                      'HH:mm',
                    )}`}
                  </AnnotationTimestampText>
                </AnnotationHeaderContainer>
                <AnnotationTextContainer>
                  {item.message}
                </AnnotationTextContainer>
                <AnnotationsFooterContainer>
                  <ActionsContainer onClick={() => handleDelete(item._id)}>
                    <TrashIcon width={20} height={20} variant="light" />
                    <ActionTextContainer color="light">
                      Deletar
                    </ActionTextContainer>
                  </ActionsContainer>
                  <ActionsContainer onClick={() => handleAction(item._id)}>
                    <GearIcon width={20} height={20} variant="special" />
                    <ActionTextContainer color="special">
                      Editar
                    </ActionTextContainer>
                  </ActionsContainer>
                </AnnotationsFooterContainer>
              </AnnotationContainer>
            );
          })
        )
      ) : (
        <TableContainer>
          <TableComponent>
            <AnnotationHeaderContainer />
            <EmptyTable colspan={0} text="Não há registros para consulta" />
          </TableComponent>
        </TableContainer>
      )}
    </ClientAnnotationsContainer>
  );
}
