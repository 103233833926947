import styled, { css } from 'styled-components';

interface ISearchFieldProps {
  iconPosition?: 'left' | 'right';
}
export const SearchFieldStyled = styled.div<ISearchFieldProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  position: relative;

  input {
    ${({ iconPosition }) =>
      css`
        padding-${iconPosition}: 40px;
      `}
  }
`;

export const ButtonSearch = styled.button<ISearchFieldProps>`
  position: absolute;
  top: 10px;
  ${({ iconPosition }) =>
    css`
      ${iconPosition}: 12px;
    `}
  background: transparent;
  border: 0;
`;
