import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const DashboardContainer = styled(motion.div)`
  display: grid;
  grid-template-areas:
    'nav head'
    'nav main';
  grid-template-rows: 4rem 1fr;
  grid-template-columns: 17.5rem 1fr;
  height: 100vh;

  > header {
    grid-area: head;
  }

  > nav {
    grid-area: nav;
  }

  > main {
    background-color: ${colors.background};
    border-top-left-radius: 4px;
    grid-area: main;
  }
`;

export const Main = styled.main`
  padding: 0 1.5rem;
`;
