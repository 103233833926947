import { useEffect, useRef, useState } from 'react';

import { AnimatePresence } from 'framer-motion';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { colors } from 'styles/colors';

import {
  ContentHolder,
  TabButton,
  TabContent,
  TabHeader,
  TabsContainer,
} from './style';

type TabsProps = {
  tabHeader: any;
  tabContent: any;
} & React.HTMLAttributes<HTMLDivElement>;

export function Tabs(props: TabsProps) {
  const { tabHeader, tabContent, ...rest } = props;
  const selectedTab = useRef<any>(null);
  const selectedWidth = selectedTab.current && selectedTab.current.offsetWidth;
  const [activeWidth, setActiveWidth] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const activePosition = (activeWidth || 0) * activeIndex;
  const { width } = useWindowDimensions();

  const changeTab = (i) => {
    setActiveIndex(i);
    setActiveWidth(selectedWidth);
  };

  useEffect(() => {
    setActiveWidth(selectedWidth);
  }, [selectedWidth, width]);

  return (
    <TabsContainer {...rest}>
      <div>
        <TabHeader
          activePosition={activePosition}
          activeWidth={activeWidth}
          length={tabHeader.length}
          gap={0}
          fragment
        >
          {tabHeader.map((title, i) => (
            <TabButton
              key={title}
              style={{
                color: i === activeIndex ? colors.primary : colors.white,
              }}
              ref={i === activeIndex ? selectedTab : null}
              onClick={() => changeTab(i)}
            >
              {title}
            </TabButton>
          ))}
        </TabHeader>
        <TabContent>
          <AnimatePresence initial={false} exitBeforeEnter>
            {tabContent.map(
              (content, i) =>
                i === activeIndex && (
                  <ContentHolder
                    initial={{ y: '50%', opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: '100%', opacity: 0 }}
                    transition={{ duration: 0.4, ease: 'linear' }}
                    key={content.key}
                  >
                    {content}
                  </ContentHolder>
                ),
            )}
          </AnimatePresence>
        </TabContent>
      </div>
    </TabsContainer>
  );
}
