import { colors } from 'styles/colors';

interface ClipboardIconProps {
  variant: 'dark' | 'light' | 'special';
  width: number;
  height: number;
}

export function ClipboardIcon({ variant, width, height }: ClipboardIconProps) {
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
    special: colors.textOnBackground.special,
  };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM7 17H14V15H7V17ZM17 13H7V11H17V13ZM7 9H17V7H7V9Z"
        fill={variants[variant]}
        fillOpacity="0.87"
      />
    </svg>
  );
}
