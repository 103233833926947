import { gql } from '@apollo/client';

export const GET_INTERNALPRODUCTION = gql`
  query ($type: String!, $unit: String!) {
    ConfigurationItems(type: $type, unit: $unit) {
      items {
        label
        metadata
        type
      }
    }
  }
`;
