/* eslint-disable prefer-const */
export function dateValidation(date: string) {
  if (!date) return false;

  const parts = date.split('/');
  let day = Number(parts[0]);
  let month = Number(parts[1]);
  let year = Number(parts[2]);

  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  return day > 0 && day <= monthLength[month - 1];
}
