interface FileIconProps {
  variant: 'active' | 'inactive';
  width: number;
  height: number;
}

export function FileIcon({ variant, width, height }: FileIconProps) {
  const variants = {
    active: '#7B61FF',
    inactive: '#DADADA',
  };

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2H14L20 8V20C20 21.1 19.1 22 18 22H5.99C4.89 22 4 21.1 4 20V4C4 2.9 4.9 2 6 2ZM8 18H16V16H8V18ZM16 14H8V12H16V14ZM13 3.5V9H18.5L13 3.5Z"
        fill={variants[variant]}
      />
    </svg>
  );
}
