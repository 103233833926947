import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { Card } from 'styles/miscellany';

export const ProjectCostsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${Card} {
    min-height: 100%;
  }
`;

export const CostHolder = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const variants = {
  danger: colors.textOnBackground.error,
  warning: colors.secondary,
  primary: colors.primary,
};

export const TitleCost = styled.span`
  color: ${colors.textOnBackground.medium};
  font-size: 0.75rem;
`;

interface PercentProps {
  variant: string;
}

export const Percent = styled.span<PercentProps>`
  ${({ variant }) => css`
    border: 1px solid ${variants[variant]};
    border-radius: 8px;
    color: ${variants[variant]};
    height: 28px;
    padding: 0 5px;
    line-height: 28px;
    text-align: center;
    font-size: 0.75rem;
    margin-left: 0.5rem;
  `}
`;
