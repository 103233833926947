import { colors } from 'styles/colors';

interface ActionIconProps {
  color?: string;
}

export function SmileIcon({ color }: ActionIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66675 9.99935C1.66675 5.39935 5.39175 1.66602 9.99175 1.66602C14.6001 1.66602 18.3334 5.39935 18.3334 9.99935C18.3334 14.5993 14.6001 18.3327 9.99175 18.3327C5.39175 18.3327 1.66675 14.5993 1.66675 9.99935ZM7.08341 9.16602C7.77377 9.16602 8.33341 8.60637 8.33341 7.91602C8.33341 7.22566 7.77377 6.66602 7.08341 6.66602C6.39306 6.66602 5.83341 7.22566 5.83341 7.91602C5.83341 8.60637 6.39306 9.16602 7.08341 9.16602ZM12.9167 9.16602C13.6071 9.16602 14.1667 8.60637 14.1667 7.91602C14.1667 7.22566 13.6071 6.66602 12.9167 6.66602C12.2264 6.66602 11.6667 7.22566 11.6667 7.91602C11.6667 8.60637 12.2264 9.16602 12.9167 9.16602ZM10.0001 16.666C6.31675 16.666 3.33341 13.6827 3.33341 9.99935C3.33341 6.31602 6.31675 3.33268 10.0001 3.33268C13.6834 3.33268 16.6667 6.31602 16.6667 9.99935C16.6667 13.6827 13.6834 16.666 10.0001 16.666ZM7.1251 11.666C7.70843 12.6577 8.76677 13.3327 10.0001 13.3327C11.2334 13.3327 12.2918 12.6577 12.8751 11.666H14.2668C13.6001 13.3743 11.9418 14.5827 10.0001 14.5827C8.05843 14.5827 6.4001 13.3743 5.73343 11.666H7.1251Z"
        fill={color}
      />
    </svg>
  );
}

SmileIcon.defaultProps = {
  color: colors.textOnBackground.medium,
};
