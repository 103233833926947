import { gql } from '@apollo/client';

export const NOTIFICATION_LIST = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $unit: String
    $sorting: [SorterInput]
  ) {
    NotificationItems(
      limit: $limit
      page: $page
      search_term: $search_term
      unit: $unit
      sorting: $sorting
    ) {
      items {
        _id
        email
        scope
      }
      pages
      total
    }
  }
`;
export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($id: String!) {
    deleteNotification(id: $id)
  }
`;

export const ADD_NOTIFICATION = gql`
  mutation addNotification($email: String!, $scope: [String], $unit: String) {
    createNotification(email: $email, scope: $scope, unit: $unit) {
      _id
      scope
      unit
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation ($id: String!, $email: String!, $scope: [String]) {
    notificationSetup(id: $id, email: $email, scope: $scope)
  }
`;

export const GET_NOTIFICATION_BY_EMAIL = gql`
  query ($search_term: String) {
    NotificationItems(search_term: $search_term) {
      items {
        _id
        email
        scope
      }
    }
  }
`;
