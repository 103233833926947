import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { clearCurrencyStrBrl, toCurrencyBrl } from 'utils/masks';

import { COMPANY_TAX } from '../../../Budgets/components/Structure/components/StructureGroup/query';
import { InternalProduction } from './components/InternalProduction';
import { Invoice } from './components/Invoice';
import { Outsource } from './components/Outsource';
import { Presets } from './components/Presets';
import { Repass } from './components/Repass';
import { TextAreaCustom } from './components/TextAreaCustom';
import {
  PrintedBudgetLine,
  PrintedBudgetTableColumn,
  PrintedBudgetTableContainer,
  PrintedBudgetTableHeader,
  RightAlign,
} from './style';

interface Props {
  groupName: string;
  group: any;
  isVisible?: boolean;
  index?: number;
}

export function PrintedBudgetTable(props: Props) {
  const [isInternalProductionVisible, setIsInternalProductionVisible] =
    useState(true);
  const [isPresetsVisible, setIsPresetsVisible] = useState(true);
  const [isOutsourceVisible, setIsOutsourceVisible] = useState(true);
  const [isRepassVisible, setIsRepassVisible] = useState(true);
  const [isInvoiceVisible, setIsInvoiceVisible] = useState(true);

  const { groupName, group, isVisible, index } = props;
  const { currentCompanyId } = useWorkspace();

  const [getCompanyTax, getCompanyTaxRes] = useLazyQuery(COMPANY_TAX);
  const companyTax = getCompanyTaxRes.data?.CompanyItems?.items[0].directTax;

  useEffect(() => {
    getCompanyTax({ variables: { companyId: currentCompanyId } });
  }, [getCompanyTax, currentCompanyId]);

  const updateGroupTotal = () => {
    const totalsArray: any[] = [];
    if (group?.metadata) {
      Object.entries(group?.metadata).forEach((section: any) => {
        totalsArray?.push(
          section
            .filter((item: any) => typeof item !== 'string')
            .map(
              (item) =>
                item &&
                item.reduce(
                  (sum, current) =>
                    sum +
                    +clearCurrencyStrBrl(current.total ? current.total : '0'),
                  0,
                ),
            ),
        );
      });
    }
    return totalsArray.reduce((sum, current) => sum + (+current || 0), 0);
  };

  const totalBudget = updateGroupTotal();
  const metadataGroupName = `${groupName}.metadata`;

  return (
    <PrintedBudgetTableContainer isVisible={isVisible}>
      <PrintedBudgetTableHeader index={index}>
        {isVisible && <PrintedBudgetLine />}
        <PrintedBudgetTableColumn>
          <TextAreaCustom name={`${groupName}.name`} isBold={!isVisible} />
          <TextAreaCustom
            placeholder="Escreva a descrição aqui..."
            name={`${metadataGroupName}.description`}
          />
          <TextAreaCustom
            placeholder="Escreva as premissas aqui..."
            name={`${metadataGroupName}.premise`}
          />
          <RightAlign>
            <strong>{toCurrencyBrl(totalBudget)}</strong>
          </RightAlign>
        </PrintedBudgetTableColumn>
      </PrintedBudgetTableHeader>
      {isVisible && (
        <>
          {group?.metadata?.internalProduction?.length > 0 && (
            <InternalProduction
              groupName={metadataGroupName}
              isVisible={isInternalProductionVisible}
              onVisibilityClick={() =>
                setIsInternalProductionVisible(!isInternalProductionVisible)
              }
            />
          )}
          {group?.metadata?.presets?.length > 0 && (
            <Presets
              groupName={metadataGroupName}
              isVisible={isPresetsVisible}
              onVisibilityClick={() => setIsPresetsVisible(!isPresetsVisible)}
            />
          )}
          {group?.metadata?.outsource?.length > 0 && (
            <Outsource
              groupName={metadataGroupName}
              companyTax={companyTax}
              isVisible={isOutsourceVisible}
              onVisibilityClick={() =>
                setIsOutsourceVisible(!isOutsourceVisible)
              }
            />
          )}
          {group?.metadata?.repass?.length > 0 && (
            <Repass
              groupName={metadataGroupName}
              isVisible={isRepassVisible}
              onVisibilityClick={() => setIsRepassVisible(!isRepassVisible)}
            />
          )}
          {group?.metadata?.invoice?.length > 0 && (
            <Invoice
              groupName={metadataGroupName}
              isVisible={isInvoiceVisible}
              onVisibilityClick={() => setIsInvoiceVisible(!isInvoiceVisible)}
            />
          )}
        </>
      )}
    </PrintedBudgetTableContainer>
  );
}
