import styled from 'styled-components';
import { colors } from 'styles/colors';

export const DatePickerContainer = styled.div`
  .nice-dates-navigation_current {
    color: ${colors.light};
  }
  .nice-dates-day_month {
    display: none;
  }

  .nice-dates-day {
    color: ${colors.light};
    font-family: 'SFProDisplay';

    &.-selected {
      font-weight: normal;
      color: ${colors.light};

      &:before {
        background-color: ${colors.primary};
      }
    }

    &.-outside {
      display: none;
    }
  }
`;

export const CalendarFieldHolder = styled.div`
  position: relative;

  > input {
    width: 230px;
    padding: 0 40px;
    margin-bottom: 1rem;
    color: ${colors.light};
    text-align: center;
  }
`;

export const CalendarIconStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
`;
