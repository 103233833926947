export function GoogleIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0361 5.95661C13.0036 4.97186 11.6257 4.43539 10.2 4.45744C7.59116 4.45744 5.37547 6.21748 4.58546 8.58748V8.58754C4.16657 9.8295 4.16657 11.1743 4.58546 12.4163H4.58913C5.38281 14.7826 7.59482 16.5427 10.2037 16.5427C11.5504 16.5427 12.7065 16.1982 13.6026 15.5898V15.5874C14.6571 14.8892 15.3773 13.7906 15.6015 12.5486H10.2V8.69778H19.6323C19.7499 9.36653 19.805 10.05 19.805 10.7298C19.805 13.7713 18.718 16.3428 16.8267 18.0844L16.8287 18.086C15.1715 19.6145 12.897 20.5001 10.2 20.5001C6.419 20.5001 2.96135 18.3689 1.26375 14.9921V14.9921C-0.154572 12.1664 -0.154568 8.83739 1.26376 6.01175H1.26378L1.26375 6.01173C2.96135 2.63124 6.419 0.500064 10.2 0.500064C12.6839 0.470668 15.0833 1.40398 16.8912 3.10157L14.0361 5.95661Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
