import { Field } from 'components/atoms/Field';
import { CalendarIcon } from 'components/atoms/Icons/CalendarIcon';
import {
  LabelStyled,
  Suffix,
  TextFieldStyled,
} from 'components/molecules/TextField/style';
import { colors } from 'styles/colors';

import { CalendarIconHolder } from '../../style';

export const DateFieldCustom = (props) => {
  const { name, label, suffix, readOnly, ...rest } = props;

  return (
    <TextFieldStyled
      readOnly={readOnly}
      onClick={() => document.getElementById(name)?.focus()}
    >
      <Field
        id={name}
        name={name}
        pattern="dateMask"
        placeholder=" "
        readOnly={readOnly}
        {...rest}
      />
      <Suffix>{suffix}</Suffix>
      <LabelStyled htmlFor={name} readOnly={readOnly}>
        {label}
      </LabelStyled>
      <CalendarIconHolder>
        <CalendarIcon
          color={
            readOnly
              ? colors.textOnBackground.disable
              : colors.textOnBackground.medium
          }
        />
      </CalendarIconHolder>
    </TextFieldStyled>
  );
};
