import { useFormContext } from 'react-hook-form';

import { TextAreaCustomContainer, TextAreaCustomStyled } from './style';

type TextAreaType = React.InputHTMLAttributes<HTMLTextAreaElement>;

type TextAreaProps = {
  name: string;
  isBold?: boolean;
  isRight?: boolean;
  validation?: any;
} & TextAreaType;

export function TextAreaCustom(props: TextAreaProps) {
  const { name, disabled, validation, ...rest } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message;
  const registration = !disabled && { ...register(name, validation) };

  return (
    <TextAreaCustomContainer>
      <TextAreaCustomStyled
        {...rest}
        {...registration}
        name={name}
        className={errorMessage && 'error'}
        disabled={disabled}
      />
    </TextAreaCustomContainer>
  );
}
