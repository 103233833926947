import { Button } from 'components/atoms/Button';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const TreeContainer = styled.div`
  padding-bottom: 1.5rem;
`;

export const TreeGrid = styled.div`
  padding: 1.5rem 1rem;
  background-color: ${colors.backgroundLight};
  border-radius: 4px;

  display: grid;
  grid-template-columns: 1fr 1fr;

  > div:last-child {
    border-left: 2px solid ${colors.outline};
  }
`;

interface TreeHolderProps {
  isParent: boolean;
  withoutLines: boolean;
}

export const TreeHolder = styled.div<TreeHolderProps>`
  ${({ isParent, withoutLines }) => css`
    position: relative;
    padding-left: 2rem;

    > div {
      ${isParent &&
      css`
        margin: 0;
        &:after {
          display: none;
        }
      `}
    }

    &:after {
      content: '';
      width: 2px;
      height: calc(100%);
      background-color: ${colors.outline};
      position: absolute;
      left: 0.8rem;
      top: 1.5rem;
      display: ${isParent ? 'none' : 'block'};
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    ${withoutLines &&
    css`
      &:after {
        display: none;
      }
    `}
  `}
`;

interface TreeLabelProps {
  isHidden?: boolean;
  withoutLines?: boolean;
}

export const TreeLabel = styled.div<TreeLabelProps>`
  ${({ isHidden, withoutLines }) => css`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: ${colors.background};
    margin-top: 1rem;
    width: 100%;
    max-width: 21rem;
    /* height: 2.25rem; */
    position: relative;
    padding-left: 1rem;
    border-radius: 4px;
    box-shadow: 2px 0 inset ${colors.primary};

    &:after {
      content: '';
      width: 2px;
      height: calc(100% + 1rem);
      background-color: ${colors.outline};
      position: absolute;
      left: -1.2rem;
      top: calc(-1rem + -18px);
    }

    &:before {
      content: '';
      width: 1.2rem;
      height: 2px;
      background-color: ${colors.outline};
      position: absolute;
      left: -1.2rem;
    }

    ${isHidden &&
    css`
      visibility: hidden;
      height: 0;
      margin: 0;
    `}

    ${withoutLines &&
    css`
      &:before,
      &:after {
        display: none;
      }
    `}
  `}
`;

export const TreeToggle = styled.button`
  position: absolute;
  left: -1.7rem;
  width: 1.1rem;
  height: 1.1rem;
  background-color: ${colors.background};
  border: 2px solid ${colors.outline};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.outline};
  line-height: 1rem;
  font-size: 1rem;
  font-family: 600;
  z-index: 1;
`;

export const ButtonTree = styled(Button)`
  /* height: 2.25rem; */
  height: 100%;
  padding: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  > svg {
    margin: 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;
