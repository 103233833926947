import styled from 'styled-components';
import { colors } from 'styles/colors';

export const ClientContactsContainer = styled.div``;
export const SeeMoreText = styled.p`
  /* BUTTON */
  white-space: nowrap;
  width: 4.375rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  /* Dark Theme/Primary */

  color: ${colors.textOnBackground.special};
`;
export const SeeMoreDiv = styled.div`
  display: flex;
  cursor: pointer;
`;

export const TrashStyled = styled.div`
  cursor: pointer;
`;
