import { useRef } from 'react';

import { UploadIcon } from 'components/atoms/Icons/UploadIcon';
import { CompanyFile } from 'pages/Companies/components/CompanyForm';
import { StyledUploadInput } from 'pages/Companies/components/CompanyForm/style';

import { AttachContainer } from './style';

interface Props {
  onChange: (files: CompanyFile[]) => void;
}
export function Attach({ onChange }: Props) {
  const inputRef = useRef<any>(null);

  return (
    <AttachContainer>
      <p>Comprovante</p>
      <StyledUploadInput
        type="file"
        ref={inputRef}
        multiple
        onChange={async (e) => {
          const fileList = e.target.files as FileList;
          const newFiles: File[] = [];
          for (
            let currentFile = 0;
            currentFile < fileList.length;
            currentFile += 1
          ) {
            const file = fileList.item(currentFile);
            if (file) newFiles.push(file as File);
          }
          if (newFiles) {
            const formattedFileArray: CompanyFile[] = [];
            for (
              let currentFile = 0;
              currentFile < fileList.length;
              currentFile += 1
            ) {
              formattedFileArray.push({
                type: 'fileToUpload',
                field: 'other',
                name: newFiles[currentFile].name,
                size: newFiles[currentFile].size / 1024 / 1024,
                file: newFiles[currentFile],
                id:
                  newFiles[currentFile].name +
                  newFiles[currentFile].size +
                  newFiles[currentFile].lastModified +
                  newFiles[currentFile].type,
              });
            }
            onChange(formattedFileArray);
          }
        }}
      />
      <button type="button" onClick={() => inputRef.current?.click()}>
        <UploadIcon variant="special" width={20} height={20} />
        <span>Anexar</span>
      </button>
    </AttachContainer>
  );
}
