import { MouseEventHandler } from 'react';

import { CloseIcon } from '../Icons/CloseIcon';
import { PaperIcon } from '../Icons/PaperIcon';
import {
  FileInfoContainer,
  FileNameContainer,
  FileSizeContainer,
  FileStyled,
  IconContainer,
  UnderLineContainer,
} from './style';

interface FileDisplayProps {
  fileName: string;
  fileSizeMB: number | string;
  onClickDelete: MouseEventHandler;
  fileLink?: string;
  withType?: boolean;
}

export function FileDisplay(props: FileDisplayProps) {
  const { fileName, fileSizeMB, onClickDelete, fileLink, withType } = props;
  const child = () => (
    <FileInfoContainer>
      <FileNameContainer title={fileName}>{fileName}</FileNameContainer>
      <FileSizeContainer>{fileSizeMB} MB</FileSizeContainer>
    </FileInfoContainer>
  );
  return fileLink ? (
    <FileStyled
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <UnderLineContainer>
        <IconContainer>
          <PaperIcon width={24} height={24} variant="special" />
        </IconContainer>
        <a href={fileLink} target="_blank" rel="noreferrer">
          {child()}
        </a>
        <IconContainer onClick={onClickDelete}>
          <CloseIcon width={24} height={24} variant="special" />
        </IconContainer>
      </UnderLineContainer>
    </FileStyled>
  ) : (
    <FileStyled
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <UnderLineContainer>
        {withType ? (
          <IconContainer>
            <PaperIcon width={24} height={24} variant="special" />
          </IconContainer>
        ) : (
          <IconContainer>
            <PaperIcon width={24} height={24} variant="special" />
          </IconContainer>
        )}
        {child()}
        <IconContainer onClick={onClickDelete}>
          <CloseIcon width={24} height={24} variant="special" />
        </IconContainer>
      </UnderLineContainer>
    </FileStyled>
  );
}

FileDisplay.defaultProps = {
  fileLink: '',
  withType: false,
};
