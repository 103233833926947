import { colors } from 'styles/colors';

interface ActionIconProps {
  color?: string;
}

export function HappyIcon({ color }: ActionIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99175 1.66602C5.39175 1.66602 1.66675 5.39102 1.66675 9.99935C1.66675 14.6077 5.39175 18.3327 9.99175 18.3327C14.5917 18.3327 18.3334 14.6077 18.3334 9.99935C18.3334 5.39102 14.6001 1.66602 9.99175 1.66602ZM7.40012 8.28267L8.28345 9.166L9.16679 8.28267L7.40012 6.516L5.63345 8.28267L6.51679 9.166L7.40012 8.28267ZM10.0001 14.5827C11.9417 14.5827 13.5917 13.366 14.2584 11.666H5.74172C6.40839 13.366 8.05839 14.5827 10.0001 14.5827ZM11.7167 9.166L10.8334 8.28267L12.6001 6.516L14.3667 8.28267L13.4834 9.166L12.6001 8.28267L11.7167 9.166ZM3.33341 9.99935C3.33341 13.6827 6.31675 16.666 10.0001 16.666C13.6834 16.666 16.6667 13.6827 16.6667 9.99935C16.6667 6.31602 13.6834 3.33268 10.0001 3.33268C6.31675 3.33268 3.33341 6.31602 3.33341 9.99935Z"
        fill={color}
      />
    </svg>
  );
}

HappyIcon.defaultProps = {
  color: colors.textOnBackground.medium,
};
