import { Field, FieldProps } from 'components/atoms/Field';

import { TextFieldStyled, LabelStyled, Suffix } from './style';

type TextFieldProps = {
  label: string;
  suffix?: string;
  isLight?: boolean;
  readOnly?: boolean;
} & FieldProps;

export function TextField(props: TextFieldProps) {
  const { name, label, suffix, isLight, readOnly, ...rest } = props;

  return (
    <TextFieldStyled isLight={isLight} readOnly={readOnly}>
      <Field
        id={name}
        name={name}
        placeholder=" "
        isLight={isLight}
        readOnly={readOnly}
        {...rest}
      />
      <Suffix>{suffix}</Suffix>
      <LabelStyled htmlFor={name} isLight={isLight}>
        {label}
      </LabelStyled>
    </TextFieldStyled>
  );
}

TextField.defaultProps = {
  suffix: '',
};
