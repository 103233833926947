import { colors } from 'styles/colors';

interface ActionIconProps {
  color?: string;
}

export function CopyIcon({ color }: ActionIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3334 0.833984H3.33335C2.41669 0.833984 1.66669 1.58398 1.66669 2.50065V14.1673H3.33335V2.50065H13.3334V0.833984ZM15.8334 4.16732H6.66669C5.75002 4.16732 5.00002 4.91732 5.00002 5.83398V17.5007C5.00002 18.4173 5.75002 19.1673 6.66669 19.1673H15.8334C16.75 19.1673 17.5 18.4173 17.5 17.5007V5.83398C17.5 4.91732 16.75 4.16732 15.8334 4.16732ZM6.66669 17.5007H15.8334V5.83398H6.66669V17.5007Z"
        fill={color}
        fillOpacity="0.87"
      />
    </svg>
  );
}

CopyIcon.defaultProps = {
  color: colors.textOnBackground.medium,
};
