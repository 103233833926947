import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const DrawerContainer = styled(motion.div)`
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
`;

export const DrawerLayer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.white};
  z-index: 1000;
`;

interface DrawerHolderProps {
  drawerWidth: number;
}

export const DrawerHolder = styled(motion.div)<DrawerHolderProps>`
  ${({ drawerWidth }) => css`
    display: grid;
    grid-template-rows: 4rem 1fr 5rem;
    width: ${drawerWidth}px;
    height: 100vh;

    position: absolute;
    top: 0;
    right: 0;

    background-color: ${colors.backgroundLight};
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
      0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    z-index: 1001;
  `}
`;

export const DrawerTitle = styled.h6`
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid ${colors.outline};
`;

export const DrawerContent = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid ${colors.outline};

  overflow-y: auto;
`;

export const DrawerFooter = styled.div`
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;

  > button {
    max-width: 7.5rem;
  }
`;

export const DrawerButtons = styled.div`
  display: grid;
  grid-template-columns: 7.5rem 7.5rem;
  grid-column-gap: 1rem;
`;
