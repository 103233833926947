import { colors } from 'styles/colors';

interface TrashIconProps {
  variant: 'dark' | 'light' | 'special';
  width: number;
  height: number;
}
export function TrashIcon(props: TrashIconProps) {
  const { variant, width, height } = props;
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
    special: colors.textOnBackground.special,
  };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9165 3.33333H15.8332V5H4.1665V3.33333H7.08317L7.9165 2.5H12.0832L12.9165 3.33333ZM6.6665 17.5C5.74984 17.5 4.99984 16.75 4.99984 15.8333V5.83333H14.9998V15.8333C14.9998 16.75 14.2498 17.5 13.3332 17.5H6.6665Z"
        fill={variants[variant]}
      />
    </svg>
  );
}
