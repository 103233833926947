import { ReactNode } from 'react';

import { ButtonStyled } from './style';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

interface ButtonCustomProps {
  variant: 'primary' | 'secondary' | 'tertiary';
  icon?: ReactNode;
  hideShadow?: boolean;
}

export function Button(props: ButtonProps & ButtonCustomProps) {
  const { children, variant, icon, hideShadow, ...rest } = props;

  return (
    <ButtonStyled hideShadow={hideShadow} variant={variant} {...rest}>
      {icon}
      {children}
    </ButtonStyled>
  );
}

Button.defaultProps = {
  icon: null,
  hideShadow: false,
};
