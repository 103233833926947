import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';

import { AccountChartsTree } from 'components/organisms/AccountChartsTree';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { useFetch } from 'hooks/useFetch';
import { Services } from 'services/service';
import { Card } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';
import { normalizeText } from 'utils/formatters/normalize-text';

import { AccountsChartsForm } from './components/AccountsChartsForm';
import { AccountsChartsContainer } from './style';

export function AccountsCharts() {
  const [dataResult, setDataResult] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { fetchAuthorized } = useFetch();
  const { openDrawer, closeDrawer } = useDrawer();

  useEffect(() => {
    fetchAuthorized(Services.Plans)
      .then((res) => res.json())
      .then((res: any) => {
        setDataResult(res?.plan?.data);
      })
      .catch(() => {
        toast.error('Erro ao carregar o plano de contas');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchAuthorized]);

  const handleChange = (data) => {
    setIsLoading(true);

    const newData = [...data];

    const mapping = (arr) =>
      arr.map((item) => {
        delete item.hidden;

        return mapping(item.children);
      });

    mapping(newData);

    fetchAuthorized(Services.Plans, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: newData }),
    })
      .then((res) => res.json())
      .then(() => {
        toast.success('Plano de contas salvo com sucesso!');
      })
      .catch(() => {
        toast.error('Erro ao salvar o plano de contas');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearch = (search) => {
    const newData = [...dataResult];

    const mapping = (arr) =>
      arr.map((item) => {
        if (!normalizeText(item.title).includes(normalizeText(search))) {
          item.hidden = true;
        } else if (search) {
          item.hidden = false;
        } else {
          delete item.hidden;
        }

        return mapping(item.children);
      });

    mapping(newData);
    setDataResult(newData);
  };

  function addNewRow(formData) {
    const tmpData = [...dataResult];
    tmpData.push({
      id: tmpData.length + 1,
      title: capitalize(formData.name),
      vbsCode: formData.vbsCode,
      children: [],
    });

    setDataResult(tmpData);
    handleChange(tmpData);
    closeDrawer();
  }

  const handleAddAction = () => {
    openDrawer({
      title: 'Adicionar Item',
      content: (
        <AccountsChartsForm onSubmit={(formData) => addNewRow(formData)} />
      ),
      formId: 'accounts-charts-form',
      width: 600,
    });
  };

  if (isLoading)
    return (
      <AccountsChartsContainer>
        <Toolbar title="Plano de contas" />
        <Card>
          <Skeleton />
        </Card>
      </AccountsChartsContainer>
    );

  return (
    <AccountsChartsContainer>
      <Toolbar
        title="Plano de contas"
        onSearch={(search) => handleSearch(search)}
        onAction={dataResult?.length < 2 ? handleAddAction : undefined}
      />
      {dataResult?.length > 0 && (
        <AccountChartsTree data={dataResult} onChange={handleChange} />
      )}
    </AccountsChartsContainer>
  );
}
