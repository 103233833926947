import { MouseEventHandler, useState } from 'react';

import { ArrowDown } from 'components/atoms/AutocompleteSelect/style';
import { CloseIcon } from 'components/atoms/Icons/CloseIcon';
import { FileIcon } from 'components/atoms/Icons/FileIcon';
import { PaperIcon } from 'components/atoms/Icons/PaperIcon';
import { RoundCheckBoxIcon } from 'components/atoms/Icons/RoundCheckboxIcon';
import { SecondaryFileIcon } from 'components/atoms/Icons/SecondaryFileIcon';
import { StyledCheckbox } from 'components/organisms/Table/style';

import {
  FileInfoContainer,
  FileNameContainer,
  FileSizeContainer,
  FileStyled,
  IconContainer,
  PaymentTypeContainer,
  UnderLineContainer,
} from './style';

interface FileDisplayProps {
  fileName: string;
  fileSizeMB: number | string;
  onClickDelete: MouseEventHandler;
  fileLink?: string;
  withType?: boolean;
  onTypeChange?: (id, type) => void;
  fileId?: string;
  fileType?: string;
}

export function FileDisplayCustom(props: FileDisplayProps) {
  const {
    fileName,
    fileSizeMB,
    onClickDelete,
    fileLink,
    withType,
    onTypeChange,
    fileId,
    fileType,
  } = props;
  const [typeSelectOpen, setTypeSelectOpen] = useState(false);

  const child = () => (
    <FileInfoContainer>
      <FileNameContainer title={fileName}>{fileName}</FileNameContainer>
      <FileSizeContainer>{fileSizeMB} MB</FileSizeContainer>
    </FileInfoContainer>
  );

  const options = [
    { value: 'nf', label: 'NF' },
    { value: 'boleto', label: 'Boleto' },
  ];

  const toggleTypeSelect = () => {
    setTypeSelectOpen(!typeSelectOpen);
  };

  const renderIcon = () => {
    return withType ? (
      <IconContainer>
        <div
          onClick={toggleTypeSelect}
          aria-hidden="true"
          style={{ cursor: 'pointer', display: 'flex', width: '40px' }}
        >
          <ArrowDown
            style={{ position: 'relative', top: '13px', right: '5px' }}
          />
          {fileType !== 'boleto' ? (
            <SecondaryFileIcon variant="active" width={24} height={24} />
          ) : (
            <FileIcon variant="active" width={24} height={24} />
          )}
        </div>
        {typeSelectOpen && (
          <PaymentTypeContainer>
            <p>Tipo:</p>
            {options.map((item) => (
              <StyledCheckbox
                role="button"
                key={item.value}
                onClick={() => {
                  if (onTypeChange) {
                    onTypeChange(fileId, item.value);
                  }
                  toggleTypeSelect();
                }}
              >
                <RoundCheckBoxIcon checked={fileType === item.value} />
                <p>{item.label}</p>
                <input type="checkbox" />
              </StyledCheckbox>
            ))}
          </PaymentTypeContainer>
        )}
      </IconContainer>
    ) : (
      <IconContainer>
        <PaperIcon width={24} height={24} variant="special" />
      </IconContainer>
    );
  };

  return fileLink ? (
    <FileStyled
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <UnderLineContainer>
        {renderIcon()}
        <a href={fileLink} target="_blank" rel="noreferrer">
          {child()}
        </a>
        <IconContainer onClick={onClickDelete}>
          <CloseIcon width={24} height={24} variant="special" />
        </IconContainer>
      </UnderLineContainer>
    </FileStyled>
  ) : (
    <FileStyled
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <UnderLineContainer>
        {renderIcon()}
        {child()}
        <IconContainer onClick={onClickDelete}>
          <CloseIcon width={24} height={24} variant="special" />
        </IconContainer>
      </UnderLineContainer>
    </FileStyled>
  );
}
