import { InternalProduction } from './InternalProduction';
import { Invoice } from './Invoice';
import { Outsource } from './Outsource';
import { Presets } from './Presets';
import { Repass } from './Repass';
import * as S from './style';

interface Props {
  groupName: string;
  companyTax?: number;
  repass?: boolean;
}
export function StructureGroup(props: Props) {
  const { groupName, companyTax, repass } = props;

  return (
    <S.GroupContainer>
      <InternalProduction groupName={groupName} />
      <Presets groupName={groupName} />
      <Outsource groupName={groupName} companyTax={companyTax || 0} />
      {repass && <Repass groupName={groupName} />}
      <Invoice groupName={groupName} />
    </S.GroupContainer>
  );
}
