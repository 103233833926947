import { gql } from '@apollo/client';

export const ADD_COMPANY = gql`
  mutation addCompany(
    $name: String!
    $legalName: String
    $cityTaxId: String
    $stateTaxId: String
    $cnpj: String
    $fiscalCategory: String
    $publicityAgency: String
    $directTax: String
    $receiptTax: String
    $flagKey: String
    $flagReceipt: String
    $defaultProposalPrompt: Int
    $monthlyValue: Int
    $defaultPoPrompt: Int
    $bankAccounts: [BankAccountInput]
    $contacts: [ContactInput]
    $cpom: [String]
    $transferAllowed: Boolean
    $legal: String
  ) {
    createCompany(
      name: $name
      legalName: $legalName
      cityTaxId: $cityTaxId
      stateTaxId: $stateTaxId
      cnpj: $cnpj
      fiscalCategory: $fiscalCategory
      publicityAgency: $publicityAgency
      directTax: $directTax
      receiptTax: $receiptTax
      flagKey: $flagKey
      flagReceipt: $flagReceipt
      defaultProposalPrompt: $defaultProposalPrompt
      monthlyValue: $monthlyValue
      defaultPoPrompt: $defaultPoPrompt
      bankAccounts: $bankAccounts
      contacts: $contacts
      cpom: $cpom
      transferAllowed: $transferAllowed
    ) {
      _id
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation companySetup(
    $id: String!
    $name: String!
    $legalName: String
    $cityTaxId: String
    $stateTaxId: String
    $cnpj: String
    $fiscalCategory: String
    $publicityAgency: String
    $directTax: String
    $receiptTax: String
    $flagKey: String
    $flagReceipt: String
    $defaultProposalPrompt: Int
    $monthlyValue: Int
    $defaultPoPrompt: Int
    $bankAccounts: [BankAccountInput]
    $contacts: [ContactInput]
    $cpom: [String]
    $transferAllowed: Boolean
    $legal: String
  ) {
    companySetup(
      id: $id
      name: $name
      legalName: $legalName
      cityTaxId: $cityTaxId
      stateTaxId: $stateTaxId
      cnpj: $cnpj
      fiscalCategory: $fiscalCategory
      publicityAgency: $publicityAgency
      directTax: $directTax
      receiptTax: $receiptTax
      flagKey: $flagKey
      flagReceipt: $flagReceipt
      defaultProposalPrompt: $defaultProposalPrompt
      monthlyValue: $monthlyValue
      defaultPoPrompt: $defaultPoPrompt
      bankAccounts: $bankAccounts
      contacts: $contacts
      cpom: $cpom
      transferAllowed: $transferAllowed
      legal: $legal
    )
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFile($id: String!) {
    deleteFile(id: $id)
  }
`;
