import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const FileStyled = styled(motion.div)`
  background: #181818;
  padding-bottom: 0 !important;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 4.5rem;
`;

export const UnderLineContainer = styled.div`
  display: flex;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${colors.outline};
`;
export const FileInfoContainer = styled.div`
  width: 100%;
  padding-top: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;
export const FileNameContainer = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 1.5rem;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  /* Dark Theme/Text on Background/Active */

  color: ${colors.textOnBackground.active};
`;
export const FileSizeContainer = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.25px;

  /* Dark Theme/Text on Background/Medium */

  color: ${colors.textOnBackground.medium};
`;

export const IconContainer = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
`;

export const PaymentTypeContainer = styled.div`
  position: relative;
  text-align: left;
  padding: 15px 20px;
  z-index: 1000;

  top: 10px;
  right: 10px;
  width: 118px;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    ),
    linear-gradient(0deg, #121212, #121212);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  > p {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
  }
`;
