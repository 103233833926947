/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useHistory, useParams } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'components/atoms/Button';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { TextField } from 'components/molecules/TextField';
import { BudgetGroup } from 'components/organisms/BudgetGroup';
import { useModal } from 'contexts/ModalContext';
import { useSidebar } from 'contexts/SidebarContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { calculateAllBudgetTotals } from 'pages/Budgets/service';
import {
  DefaultForm,
  FlexContainer,
  GridColumn,
  IconButton,
  Line,
} from 'styles/miscellany';
import {
  clearCurrencyNumberBrl,
  clearCurrencyStr,
  toCurrency,
  toCurrencyBrl,
} from 'utils/masks';

import { FormGroupWithAdding } from '../../../../components/organisms/FormGroupWithAdding';
import { NewBudgetTab } from '../NewBudget/components/NewBudgetTab';
import { NewBudgetToolbar } from '../NewBudget/components/NewBudgetToolbar';
import { GET_BUDGET_BY_ID, UPDATE_BUDGET } from '../NewBudget/query';
import {
  ButtonsContainer,
  FieldContainer,
  FieldsContainer,
  FixedContainer,
  FooterContainer,
} from '../NewBudget/style';
import { Comissions } from './components/Comissions';
import { StructureGroup } from './components/StructureGroup';
import { COMPANY_TAX } from './components/StructureGroup/query';
import { StructureRevenue } from './components/StructureRevenue';
import { QUERY_COMPANY_REPASS } from './query';
import { StructureContainer } from './style';

export function Structure() {
  const { currentCompanyId } = useWorkspace();

  const [getCompanyTax, getCompanyTaxRes] = useLazyQuery(COMPANY_TAX);
  const [getBudget, getBudgetRes] = useLazyQuery(GET_BUDGET_BY_ID, {
    notifyOnNetworkStatusChange: true,
  });
  const [updateBudget, updateBudgetRes] = useMutation(UPDATE_BUDGET);
  const [getCompany, getCompanyRes] = useLazyQuery(QUERY_COMPANY_REPASS);

  const [repass, setRepass] = useState(true);

  const methods = useForm();
  const history = useHistory();
  const { id } = useParams<any>();
  const { isSidebarOpen } = useSidebar();

  const companyTax = getCompanyTaxRes.data?.CompanyItems?.items[0].directTax;
  const budgetItem = getBudgetRes?.data?.BudgetItems?.items[0];

  const budgetState = methods.watch();
  const budGroups = budgetState?.budGroups || [{ name: 'Novo grupo' }];

  const { openModal } = useModal();

  useEffect(() => {
    getCompany({ variables: { companyId: currentCompanyId } });
  }, []);

  useEffect(() => {
    if (getCompanyRes?.data?.CompanyItems?.items[0]) {
      setRepass(getCompanyRes?.data?.CompanyItems?.items[0].transferAllowed);
    }
  }, [getCompanyRes]);

  useEffect(() => {
    if (id) getBudget({ variables: { id } });
  }, [id, getBudget]);

  useEffect(() => {
    getCompanyTax({ variables: { companyId: currentCompanyId } });
  }, [getCompanyTax, currentCompanyId]);

  useEffect(() => {
    if (budgetItem) {
      budgetItem?.budGroups?.forEach((item: any, index) => {
        const tmpMetadata = JSON.parse(item.metadata);

        const newMetadata = {
          internalProduction: [],
          presets: [],
          outsource: [],
          repass: [],
          invoice: [],
        };

        if (tmpMetadata.internalProduction.length > 0) {
          newMetadata.internalProduction =
            tmpMetadata.internalProduction.map((i) => {
              return {
                ...i,
                hourValue: toCurrencyBrl(i.hourValue.toFixed(2)),
                total: toCurrencyBrl(i.total.toFixed(2)),
              };
            }) || [];
        }
        if (tmpMetadata.presets.length > 0) {
          newMetadata.presets =
            tmpMetadata.presets.map((i) => {
              return {
                ...i,
                total: toCurrencyBrl(i.total.toFixed(2)),
                value: toCurrencyBrl(i.value.toFixed(2)),
              };
            }) || [];
        }
        if (tmpMetadata.outsource.length > 0) {
          newMetadata.outsource =
            tmpMetadata.outsource.map((i) => {
              return {
                ...i,
                admValue: toCurrencyBrl(i.admValue.toFixed(2)),
                total: toCurrencyBrl(i.total.toFixed(2)),
                value: toCurrencyBrl(i.value.toFixed(2)),
              };
            }) || [];
        }
        if (tmpMetadata.repass.length > 0) {
          newMetadata.repass =
            tmpMetadata.repass.map((i) => {
              return {
                ...i,
                admValue: toCurrencyBrl(i.admValue.toFixed(2)),
                total: toCurrencyBrl(i.total.toFixed(2)),
                value: toCurrencyBrl(i.value.toFixed(2)),
              };
            }) || [];
        }
        if (tmpMetadata.invoice.length > 0) {
          newMetadata.invoice =
            tmpMetadata.invoice.map((i) => {
              return {
                ...i,
                currencyConversion: toCurrencyBrl(
                  i.currencyConversion.toFixed(2),
                ),
                total: toCurrencyBrl(i.total.toFixed(2)),
                value: toCurrency(Number(i.value).toFixed(2)),
              };
            }) || [];
        }

        methods.setValue(`budGroups.${index}`, {
          name: item.name,
          metadata: newMetadata,
        });
      });
      budgetItem?.budComissions?.forEach((item: any, index) => {
        methods.setValue(`budComissions.${index}`, {
          item: item.item,
          percentage: item.percentage,
          responsible: item.responsible,
          type: item.type,
        });
      });
      budgetItem?.budDeductions?.forEach((item: any, index) => {
        methods.setValue(`budDeductions.${index}`, {
          description: item.description,
          value: toCurrencyBrl(item.value * 100),
        });
      });
    }
  }, [budgetItem]);

  useEffect(() => {
    if (updateBudgetRes.data) {
      toast.success('Cadastro atualizado!');
    }
    if (updateBudgetRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
  }, [updateBudgetRes.data, updateBudgetRes.error]);

  const saveData = (data) => {
    const payload = {
      ...JSON.parse(JSON.stringify(data)),
      id,
      clientId: budgetItem.client?._id,
      unit: currentCompanyId,
    };

    payload.budGroups?.forEach((group, index) => {
      if (group.metadata) {
        const tmpData = group.metadata;

        const newData = {
          internalProduction: [],
          presets: [],
          outsource: [],
          repass: [],
          invoice: [],
        };

        if (tmpData.internalProduction) {
          newData.internalProduction = tmpData.internalProduction.map((i) => {
            return {
              ...i,
              hourValue: clearCurrencyNumberBrl(i.hourValue),
              total: clearCurrencyNumberBrl(i.total),
            };
          });
        }

        if (tmpData.presets) {
          newData.presets = tmpData.presets.map((i) => {
            return {
              ...i,
              total: clearCurrencyNumberBrl(i.total),
              value: clearCurrencyNumberBrl(i.value),
            };
          });
        }

        if (tmpData.outsource) {
          newData.outsource = tmpData.outsource.map((i) => {
            return {
              ...i,
              admValue: clearCurrencyNumberBrl(i.admValue),
              total: clearCurrencyNumberBrl(i.total),
              value: clearCurrencyNumberBrl(i.value),
            };
          });
        }

        if (tmpData.repass) {
          newData.repass = tmpData.repass.map((i) => {
            return {
              ...i,
              admValue: clearCurrencyNumberBrl(i.admValue),
              total: clearCurrencyNumberBrl(i.total),
              value: clearCurrencyNumberBrl(i.value),
            };
          });
        }

        if (tmpData.invoice) {
          newData.invoice = tmpData.invoice.map((i) => {
            return {
              ...i,
              currencyConversion: clearCurrencyNumberBrl(i.currencyConversion),
              total: clearCurrencyNumberBrl(i.total),
              value: clearCurrencyNumberBrl(i.value),
            };
          });
        }

        group.metadata = JSON.stringify(newData);
        group.name = budGroups[index].name || 'Novo Grupo';
      }
    });
    payload.budComissions = payload.budComissions?.map((group) => {
      return {
        item: group.item,
        percentage: clearCurrencyStr(group.percentage),
        responsible: group.responsible,
        type: group.type,
      };
    });
    payload.budDeductions = payload.budDeductions?.map((group) => {
      return {
        description: group.description,
        value: clearCurrencyNumberBrl(group.value),
      };
    });

    updateBudget({ variables: payload });
  };

  const onSubmit = (data) => {
    saveData(data);

    window.open(`/budgets/${id}/printedBudget`, '_blank');
    history.push('/budgets');
  };

  const addGroup = () => {
    budGroups.push({ name: `Novo grupo ${budGroups.length}` });
    methods.reset({ budGroups: [] }, { keepValues: true });
  };

  const deleteGroup = (index) => {
    openModal({
      content: (
        <DeleteModal
          onAccept={() => {
            budGroups.splice(index, 1);
            methods.reset({ budGroups: [] }, { keepValues: true });
          }}
        />
      ),
    });
  };

  const changeGroupName = (index, name) => {
    methods.setValue(`budGroups.${index}.name`, name);
  };

  const { totalBilling, netBilling, outsourceTotal } =
    calculateAllBudgetTotals(budgetState);

  return (
    <StructureContainer>
      <NewBudgetToolbar title={budgetItem?.campaign} id={id} />
      <NewBudgetTab id={1} />
      <Line style={{ margin: '1.5rem 0 2rem 0' }} />
      <FormProvider {...methods}>
        <DefaultForm onSubmit={methods.handleSubmit(onSubmit)}>
          <FieldsContainer>
            {budGroups.map((group, index) => (
              <BudgetGroup
                key={index}
                groupState={
                  budgetState.budGroups ? budgetState.budGroups[index] : null
                }
                title={group.name}
                titleName={`budGroups.${index}.name`}
                onChange={(name) => changeGroupName(index, name)}
                defaultOpen={index === 0}
                deleteGroup={() => deleteGroup(index)}
              >
                <StructureGroup
                  groupName={`budGroups.${index}.metadata`}
                  companyTax={companyTax}
                  repass={repass}
                />
              </BudgetGroup>
            ))}

            <GridColumn length={1} gap={1}>
              <Button
                variant="primary"
                style={{ margin: '2rem 0 0 1.875rem' }}
                type="button"
                onClick={addGroup}
              >
                CRIAR NOVO GRUPO
              </Button>
            </GridColumn>

            <Line style={{ margin: '2rem 0 1rem' }} />

            <Comissions
              outsourceTotal={outsourceTotal}
              projectTotal={totalBilling}
              revenueTotal={netBilling}
              comissions={budgetState?.budComissions}
            />

            <FormGroupWithAdding
              title="Deduções"
              groupName="budDeductions"
              renderRow={({ index, onDelete }) => (
                <FieldContainer>
                  <FlexContainer value={6}>
                    <TextField
                      name={`budDeductions.${index}.description`}
                      label="Descrição"
                    />
                  </FlexContainer>
                  <FlexContainer>
                    <TextField
                      name={`budDeductions.${index}.value`}
                      label="Total"
                      pattern="moneyPrefix"
                    />
                  </FlexContainer>
                  <IconButton
                    type="button"
                    tabIndex={0}
                    onClick={() => onDelete(index)}
                  >
                    <TrashIcon variant="light" width={20} height={20} />
                  </IconButton>
                </FieldContainer>
              )}
            />

            <Line style={{ margin: '2rem 0 1rem' }} />

            <StructureRevenue companyTax={companyTax} repass={repass} />
          </FieldsContainer>

          <FixedContainer
            animate={{
              position: 'fixed',
              left: isSidebarOpen ? 304 : 128,
            }}
            transition={{
              type: 'linear',
              duration: 0.6,
            }}
            isSidebarOpen={isSidebarOpen}
            style={{ bottom: '-1rem' }}
          >
            <FooterContainer>
              <ButtonsContainer>
                <div>
                  <Link to="/budgets">
                    <Button variant="secondary">CANCELAR</Button>
                  </Link>
                </div>
                <div>
                  <Link to={`/budgets/${id}/information`}>
                    <Button variant="secondary">VOLTAR</Button>
                  </Link>
                  <Button
                    variant="secondary"
                    style={{ marginLeft: '1rem' }}
                    onClick={() => {
                      saveData(budgetState);
                    }}
                    type="button"
                  >
                    SALVAR
                  </Button>
                  <Button variant="tertiary" style={{ marginLeft: '1rem' }}>
                    AVANÇAR
                  </Button>
                </div>
              </ButtonsContainer>
            </FooterContainer>
          </FixedContainer>
        </DefaultForm>
      </FormProvider>
    </StructureContainer>
  );
}
