import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useMutation } from '@apollo/client';
import { InputFieldWithSelect } from 'components/atoms/InputFieldWithSelect';
import { SelectField } from 'components/atoms/SelectField';
import { TextArea } from 'components/atoms/TextArea';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { useBacenConverter } from 'hooks/useBacenConverter';
import { DefaultForm, GridColumn } from 'styles/miscellany';
import {
  clearCurrencyBrl,
  clearCurrencyNumber,
  clearCurrencyNumberBrl,
  toCurrency,
  toCurrencyBrl,
} from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { CREATE_PAYMENT, UPDATE_PAYMENT } from '../../query';
import { CostControlFormContainer, SelectSpacer } from './style';

interface CostControlFormProps {
  presetData?: any;
  projectIds?: any;
  isEditing?: boolean;
  groupName?: string;
  refetch?: any;
}

export function CostControlForm(props: CostControlFormProps) {
  const { presetData, projectIds, isEditing, groupName, refetch } = props;
  const methods = useForm();

  const { closeDrawer } = useDrawer();

  const [updatePayment, updatePaymentRes] = useMutation(UPDATE_PAYMENT);
  const [createPayment, createPaymentRes] = useMutation(CREATE_PAYMENT);

  useEffect(() => {
    if (presetData) {
      Object.entries(presetData).forEach(([key, value]) => {
        if (key !== '__typename') {
          methods.setValue(key, value);
        }
        if (key === 'budgetedValue') {
          methods.setValue(key, toCurrency((value as number)?.toFixed(2)));
        }
        if (key === 'realizedValue') {
          methods.setValue(key, toCurrency((value as number)?.toFixed(2)));
        }

        if (key === 'budgetedQuota') {
          methods.setValue(key, Number(((value as number) || 1)?.toFixed(4)));
        }
      });
      methods.setValue(
        'realizedTotal',
        toCurrencyBrl(
          Number(
            (
              clearCurrencyNumberBrl(methods.getValues('realizedValue')) *
              methods.getValues('realizedQuota')
            ).toFixed(2),
          ),
        ),
      );
      methods.setValue(
        'budgetedTotal',
        toCurrencyBrl(
          clearCurrencyNumber(methods.getValues('budgetedValue')) *
            methods.getValues('budgetedQuota'),
        ),
      );
    }
  }, [presetData]);

  useEffect(() => {
    if (updatePaymentRes.data || createPaymentRes.data) {
      toast.success('Cadastro atualizado!');
      if (refetch) {
        refetch();
      }
      closeDrawer();
    }
    if (updatePaymentRes.error || createPaymentRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
  }, [
    updatePaymentRes.data,
    updatePaymentRes.error,
    createPaymentRes.data,
    createPaymentRes.error,
  ]);

  const onSubmit = (data) => {
    // console.log('SUBMIT DATA', data);
    const payload = { ...data };

    Object.entries(data).forEach(([key, value]) => {
      if (key === '_id') {
        payload.id = value;
      }
      if (typeof value === 'string') {
        if (key === 'realizedValue') {
          payload[key] = clearCurrencyNumberBrl(value);
        }
        if (key === 'budgetedValue') {
          payload[key] = clearCurrencyNumberBrl(value);
        }
      }
    });
    // console.log('SUBMIT PAYLOAD', payload);
    if (isEditing) {
      updatePayment({ variables: payload });
    } else {
      createPayment({
        variables: {
          ...payload,
          groupName: groupName || 'Novo Grupo',
          projectId: projectIds._id,
          clientId: projectIds.client._id,
          budgetId: projectIds.budget._id,
        },
      });
    }
  };

  const UpdateCurrency = async (current, value, quotationFieldName) => {
    const convertedValue = await useBacenConverter(current, value);
    const totalFieldName =
      quotationFieldName === 'budgetedQuota'
        ? 'budgetedTotal'
        : 'realizedTotal';

    methods.setValue(
      quotationFieldName,
      Number((convertedValue?.value / value).toFixed(4)) || 1,
    );

    const quotation = methods.getValues(quotationFieldName);

    methods.setValue(
      totalFieldName,
      toCurrencyBrl((quotation * value).toFixed(2)),
    );
  };

  const updateConversionValue = (data, name) => {
    const quotationFieldName =
      name === 'budgetedCurrency' ? 'budgetedQuota' : 'realizedQuota';

    const value = clearCurrencyBrl(
      methods.getValues(data?.target?.id).realizedValue ||
        methods.getValues(data?.target?.id),
    );
    const actualCurrency = data.value || 'USD';
    if (value && actualCurrency)
      UpdateCurrency(actualCurrency, value, quotationFieldName);
  };

  return (
    <CostControlFormContainer>
      <FormProvider {...methods}>
        <DefaultForm
          onSubmit={methods.handleSubmit(onSubmit)}
          id="cost-control-form"
        >
          <GridColumn length={2} gap={1} fragment>
            <SelectField
              name="status"
              label="Status"
              placeholder="Status*"
              options={[
                { value: 'pendente', label: 'Pendente' },
                { value: 'NF Recebida', label: 'NF Recebida' },
                { value: 'PO Emitida', label: 'PO Emitida' },
                { value: 'vencido', label: 'Vencido' },
                { value: 'Pago', label: 'Pago' },
              ]}
              validation={validator('required')}
            />
            <TextField name="poCode" label="Numero da PO*" readOnly />
          </GridColumn>
          <TextField
            name="itemDescription"
            label="Item*"
            validation={validator('required')}
          />
          <TextField
            name="providerName"
            label="Fornecedor*"
            validation={validator('required')}
          />
          <TextField
            name="providerEmail"
            label="Email do fornecedor*"
            validation={validator('required')}
          />
          <TextArea name="detailedDescription" label="Detalhes*" />
          <SelectField
            name="category"
            label="Categoria"
            placeholder="Categoria"
            options={[
              { value: 'internalProduction', label: 'Produção Interna' },
              { value: 'presets', label: 'Presets' },
              { value: 'outsource', label: 'Terceiro' },
              { value: 'repass', label: 'Repasse' },
              { value: 'comission', label: 'Comissão' },
            ]}
          />
          <h6>Invoice</h6>
          <GridColumn length={2} gap={1} fragment>
            <SelectSpacer>
              <InputFieldWithSelect
                inputName="budgetedValue"
                selectName="budgetedCurrency"
                label="Valor Orçado"
                options={[
                  { value: 'USD', label: 'USD' },
                  { value: 'BRL', label: 'BRL' },
                  { value: 'EUR', label: 'EUR' },
                  { value: 'GBP', label: 'GBP' },
                  { value: 'ARS', label: 'ARS' },
                  { value: 'CLP', label: 'CLP' },
                  { value: 'COP', label: 'COP' },
                  { value: 'MXN', label: 'MXN' },
                  { value: 'UYU', label: 'UYU' },
                ]}
                defaultValue={{
                  value: presetData?.budgetedCurrency,
                  label: presetData?.budgetedCurrency,
                }}
                menuPlacement="top"
                validation={validator('required')}
                isDisabled
                actionTrigger={(data) => {
                  updateConversionValue(data, 'budgetedCurrency');
                }}
              />
            </SelectSpacer>
            <SelectSpacer>
              <InputFieldWithSelect
                inputName="realizedValue"
                selectName="realizedCurrency"
                label="Valor Realizado"
                options={[
                  { value: 'USD', label: 'USD' },
                  { value: 'BRL', label: 'BRL' },
                  { value: 'EUR', label: 'EUR' },
                  { value: 'GBP', label: 'GBP' },
                  { value: 'ARS', label: 'ARS' },
                  { value: 'CLP', label: 'CLP' },
                  { value: 'COP', label: 'COP' },
                  { value: 'MXN', label: 'MXN' },
                  { value: 'UYU', label: 'UYU' },
                ]}
                defaultValue={{
                  value: presetData?.realizedCurrency,
                  label: presetData?.realizedCurrency,
                }}
                menuPlacement="top"
                validation={validator('required')}
                actionTrigger={(data) => {
                  updateConversionValue(data, 'realizedCurrency');
                }}
              />
            </SelectSpacer>
            <TextField
              name="budgetedQuota"
              label="Cotação Orçada"
              pattern="quota"
              readOnly
            />
            <TextField
              name="realizedQuota"
              label="Cotação Realizada"
              pattern="quota"
              readOnly
            />
            <TextField
              name="budgetedTotal"
              label="Total Orçado*"
              pattern="money"
              readOnly
            />
            <TextField
              name="realizedTotal"
              label="Total Realizado*"
              pattern="money"
              validation={validator('required')}
              readOnly
            />
          </GridColumn>
        </DefaultForm>
      </FormProvider>
    </CostControlFormContainer>
  );
}
