import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { CheckBoxIcon } from 'components/atoms/Icons/CheckboxIcon';
import {
  StyledPermissionSubtitle,
  StyledCheckbox,
  StyledRoleText,
  StyledRowDiv,
  StyledGridDiv,
} from 'pages/Notifications/components/NotificationForm/style';

interface NotificationsFormProps {
  options?: any;
  title?: string;
  readOnly?: boolean;
}

export function NotificationsForm({
  options,
  title,
  readOnly,
}: NotificationsFormProps) {
  const { setValue, getValues, reset, register } = useFormContext();

  const possiblePermissions = !options
    ? [
        'Admin',
        'Legal',
        'Contábil',
        'Relatórios',
        'Clientes',
        'Projetos',
        'Contas a pagar',
        'Contas a receber',
      ]
    : options;

  useEffect(() => {
    register('tags', { value: [] });
  }, [register]);

  const checkedValues = getValues('tags') || [];

  return (
    <>
      <StyledPermissionSubtitle>
        {title || 'Notificações'}
      </StyledPermissionSubtitle>
      <StyledGridDiv>
        {possiblePermissions.map((item) => {
          return (
            <StyledRowDiv>
              <StyledCheckbox
                role="button"
                tabIndex={0}
                onClick={
                  readOnly
                    ? () => null
                    : () => {
                        const oldValues = [...checkedValues];
                        const itemIndex = oldValues.indexOf(item);
                        if (itemIndex >= 0) {
                          oldValues.splice(itemIndex, 1);
                        } else {
                          oldValues.push(item);
                        }
                        reset({ tags: '' }, { keepValues: true });
                        setValue('tags', oldValues);
                      }
                }
              >
                <CheckBoxIcon
                  width={24}
                  height={24}
                  checked={checkedValues.indexOf(item) >= 0}
                />
              </StyledCheckbox>
              <StyledRoleText readOnly={readOnly}>{item}</StyledRoleText>
            </StyledRowDiv>
          );
        })}
      </StyledGridDiv>
    </>
  );
}
