import { colors } from 'styles/colors';

interface NeuronOsIconProps {
  variant: 'dark' | 'light' | 'special';
  width: number;
  height: number;
}

export function NeuronOsIcon({ variant, width, height }: NeuronOsIconProps) {
  const variants = {
    dark: '#000000',
    light: colors.textOnBackground.active,
    special: colors.textOnBackground.special,
  };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8333 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.7583 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.7583 0.5 13.8333 0.5ZM8 6.33333C7.08333 6.33333 6.33333 7.08333 6.33333 8C6.33333 8.91667 7.08333 9.66667 8 9.66667C8.91667 9.66667 9.66667 8.91667 9.66667 8C9.66667 7.08333 8.91667 6.33333 8 6.33333ZM12.3333 8.56667C12.3583 8.38333 12.375 8.19167 12.375 8C12.375 7.80833 12.3583 7.61667 12.3333 7.425L13.5667 6.45833C13.675 6.36667 13.7083 6.20833 13.6333 6.08333L12.4667 4.06667C12.3917 3.94167 12.2417 3.89167 12.1083 3.94167L10.6583 4.525C10.3583 4.29167 10.0333 4.1 9.67501 3.95L9.45834 2.40833C9.43334 2.275 9.30834 2.16667 9.16668 2.16667H6.83334C6.69168 2.16667 6.56668 2.275 6.54168 2.41667L6.32501 3.95833C5.97501 4.10833 5.64168 4.3 5.34168 4.53333L3.89168 3.95C3.75834 3.9 3.60834 3.95 3.53334 4.075L2.36668 6.09167C2.29168 6.21667 2.32501 6.375 2.43334 6.46667L3.66668 7.43333C3.64168 7.61667 3.62501 7.80833 3.62501 8C3.62501 8.19167 3.64168 8.38333 3.66668 8.575L2.43334 9.54167C2.32501 9.63333 2.29168 9.79167 2.36668 9.91667L3.53334 11.9333C3.60834 12.0583 3.75834 12.1083 3.89168 12.0583L5.34168 11.475C5.64168 11.7083 5.96668 11.9 6.32501 12.05L6.54168 13.5917C6.56668 13.725 6.69168 13.8333 6.83334 13.8333H9.16668C9.30834 13.8333 9.43334 13.725 9.45834 13.5833L9.67501 12.0417C10.025 11.8917 10.3583 11.7 10.6583 11.4667L12.1083 12.05C12.2417 12.1 12.3917 12.05 12.4667 11.925L13.6333 9.90833C13.7083 9.78333 13.675 9.625 13.5667 9.53333L12.3333 8.56667Z"
        fill={variants[variant]}
      />
    </svg>
  );
}
