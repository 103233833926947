import styled from 'styled-components';
import { colors } from 'styles/colors';

export const NewBudgetTabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: ${colors.textOnBackground.medium};

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > div > span {
    width: 100px;
    height: 1px;
    background-color: ${colors.textOnBackground.active};
    margin-left: 0.5rem;
  }
`;

export const NumberContainer = styled.div`
  color: ${colors.white};
  background-color: ${colors.textOnBackground.medium};
  border-radius: 50%;
  width: 1.5rem;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
`;
