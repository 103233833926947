interface SecondaryFileIconProps {
  variant: 'active' | 'inactive';
  width: number;
  height: number;
}

export function SecondaryFileIcon({
  variant,
  width,
  height,
}: SecondaryFileIconProps) {
  const variants = {
    active: '#E3E3E3',
    inactive: '#DADADA',
  };

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM13 9V3.5L18.5 9H13ZM13.0982 14.25V12L16 15L13.0982 18V15.75L8 15.75V14.25H13.0982Z"
        fill={variants[variant]}
      />
    </svg>
  );
}
