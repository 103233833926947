import styled from 'styled-components';
import { colors } from 'styles/colors';
import { GridColumn } from 'styles/miscellany';

export const DeleteModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

  height: 16rem;
`;

export const IconHolder = styled.span`
  border: 1px solid ${colors.light};
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AttentionTitle = styled.h6`
  font-weight: 500;
`;

export const ButtonHolder = styled(GridColumn)`
  > button {
    width: 100px;
  }
`;
