import styled, { css } from 'styled-components';

export const TextAreaCustomContainer = styled.div`
  align-self: center;
`;

interface TextAreaCustomStyledProps {
  isRight?: boolean;
  isBold?: boolean;
}

export const TextAreaCustomStyled = styled.textarea<TextAreaCustomStyledProps>`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  @media print {
    font-size: 1rem;
  }
  line-height: 100%;
  letter-spacing: 0.15px;
  width: 100%;
  height: 5rem;
  border: none;
  resize: none;
  background: transparent;

  ${({ isRight }) =>
    isRight &&
    css`
      text-align: right;
    `}

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: bold;
    `}

  &:disabled {
    color: black;
    background-color: transparent;
  }

  @media print {
    &::placeholder {
      font-size: 0;
    }
  }
`;
