import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Select from 'react-select';

import { ErrorMessage } from '@hookform/error-message';

import { ErrorLabel, LabelStyled, SelectContainer } from './style';

interface OptionProp {
  value: string;
  label: string;
}

interface SelectFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  menuPlacement?: string;
  options: OptionProp[];
  validation?: any;
  isSearchable?: boolean;
  readOnly?: boolean;
  isDisabled?: boolean;
  defaultValue?: string;
  actionTrigger?: (e: any) => void;
  onChange?: (value: string) => void;
}

export function SelectField(props: SelectFieldProps) {
  const {
    name,
    label,
    placeholder,
    menuPlacement,
    options,
    validation,
    isSearchable,
    readOnly,
    isDisabled,
    defaultValue,
    actionTrigger,
    onChange,
  } = props;
  const {
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useFormContext();

  const registration = !isDisabled && {
    ...register(name, validation || undefined),
  };
  const errorMessage = errors[name]?.message;
  const selectValue = getValues(name) || placeholder;
  const [optionValue, setOptionValue] = useState<any>();

  useEffect(() => {
    setOptionValue({
      value: selectValue,
      label:
        options?.find((opt) => opt.value === selectValue)?.label || selectValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValue]);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, []);

  return (
    <SelectContainer isDisabled={isDisabled || readOnly}>
      {selectValue !== placeholder && (
        <LabelStyled isDisabled={isDisabled || readOnly}>{label}</LabelStyled>
      )}
      <Select
        {...registration}
        name={name}
        options={options}
        classNamePrefix="select-field"
        placeholder={placeholder}
        menuPlacement={menuPlacement}
        className={errorMessage && 'error'}
        isSearchable={isSearchable}
        isDisabled={isDisabled || readOnly}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        value={optionValue}
        onChange={(data, element) => {
          setValue(name, data.value, {
            shouldValidate: true,
            shouldDirty: true,
          });
          if (actionTrigger) {
            actionTrigger(element);
          }
          if (onChange) {
            onChange(data.value);
          }
        }}
        defaultValue={defaultValue}
      />
      {Object.keys(errors).length > 0 && (
        <ErrorLabel>
          <ErrorMessage errors={errors} name={name} />
        </ErrorLabel>
      )}
    </SelectContainer>
  );
}

SelectField.defaultProps = {
  label: null,
  placeholder: 'selecionar',
  menuPlacement: 'auto',
  validation: null,
  isSearchable: false,
  isDisabled: false,
  defaultValue: null,
  actionTrigger: undefined,
};
