import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const PrintedBudgetContainer = styled.div`
  position: relative;
  color: ${colors.backgroundDark};
  background-color: ${colors.white};
  @media print {
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
  }
  padding: 0 1.5rem 0 1.5rem;
  max-width: 1300px;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const PrintedBudgetHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: calc(10rem - 65px) 0 0 0;
  margin-bottom: 10.625rem;

  @media print {
    position: fixed;
    margin-top: 0;
    height: 100px;
    top: 0;
    padding-top: 0rem;
    z-index: 99999999;
  }

  > h3 {
    @media print {
      font-size: 1.2rem;
    }
    font-size: 3.125rem;
    font-weight: 100;
  }

  > div > p {
    @media print {
      font-size: 1rem;
    }
    font-size: 1.25rem;
    font-weight: 500;
  }
`;

export const PrintedBudgetFooter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0 0;
  padding-bottom: 2.125rem;

  @media print {
    height: 100px;
    position: fixed;
    bottom: 0;
    background-color: white;
    width: 95%;
    max-width: calc(1300px - 3rem);
    z-index: 9999999;
  }
`;

export const TableHeader = styled.div`
  @media print {
    height: 110px;
  }
`;

export const TableBody = styled.div`
  @media print {
    page-break-after: always;
    box-sizing: border-box;
  }
`;

export const TableFooter = styled.div`
  @media print {
    height: 100px;
    margin: 2.5rem 0;
  }
`;

export const PrintedBudgetSubtitle = styled.h2`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  @media print {
    font-size: 1rem;
  }
  font-size: 1.5625rem;
  line-height: 2.25rem;
  letter-spacing: 0.15px;
`;

export const BackgroundImageContainer = styled.div`
  position: absolute;
  top: 80px;
  transform: scale(0.9);

  @media print {
    left: -100px;
    transform: scale(0.7);
  }
`;

export const BottomBackgroundImageContainer = styled.div`
  position: absolute;
  bottom: 250px;
  transform: scale(0.9);

  @media print {
    left: -100px;
    transform: scale(0.7);
  }
`;

export const PrintedBudgetMarkedSubtitle = styled.h2`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 2.25rem;
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #000000;
  padding-left: 28px;
  width: 280px;
  margin-left: -26px;
  height: 36px;

  @media print {
    color: #ffffff;
    font-size: 1rem;
    margin: 0;
  }
`;

export const MarkedTitle = styled.h2`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 2.25rem;
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #000000;
  padding-left: 28px;
  width: 280px;
  margin-left: -26px;
  height: 36px;

  position: absolute;

  @media print {
    width: 200px;
    color: #ffffff;
    font-size: 1rem;
    margin: 0;
  }
`;

export const PrintedBudgetTitleContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

interface PrintedBudgetColumnTitleProps {
  isBold?: boolean;
}

export const PrintedBudgetColumnTitle = styled.h3<PrintedBudgetColumnTitleProps>`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  @media print {
    font-size: 1rem;
  }
  font-size: 1.25rem;
  line-height: 2.25rem;
  letter-spacing: 0.15px;
  margin: 0 0 1rem 0;
  display: flex;
  align-items: flex-start;

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: bold;
    `}
`;

export const PrintedBudgetText = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  @media print {
    font-size: 1rem;
  }
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.15px;
`;

export const PrintedBudgetSubText = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.15px;
  max-width: 1111px;
  margin-top: 8.25rem;

  @media print {
    margin-top: 0;
  }
`;

export const PrintedBudgetGrid = styled.div`
  position: relative;
`;

export const PrintedBudgetFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media print {
    height: 47px;
  }
`;

export const PrintedBudgetTableColumn = styled.div`
  margin-top: 3.75rem;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  gap: 3rem;
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonsContainer = styled.div`
  padding: 1.25rem 1.875rem;
  display: flex;
  justify-content: end;
  background-color: ${colors.backgroundLight};

  > button {
    width: 200px;
  }
`;

export const DefaultForm = styled.form`
  display: grid;
`;

export const PrintedBudgetTitle = styled.h3`
  text-align: left;
`;

export const IdContainer = styled.div`
  min-width: 200px;
  word-wrap: break-word;
  padding: 10px 0;
  margin-top: 32px;

  @media print {
    margin-top: 0px;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const GridColumnLines = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 2rem 10.9375rem 2rem;

  @media print {
    text-align: center;
    align-items: flex-start;
  }
`;

export const ShowFlagBackgroundContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioCheckBoxContainer = styled.div`
  border: 1px solid #7b61ff;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  margin-left: 4px;
  margin-right: 4px;
`;

export const ShowFlagText = styled.p`
  color: #7b61ff;
  display: flex;
  align-items: center;
  text-transform: capitalize;
`;

export const CompanyNameContainer = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 106px;

  @media print {
    margin-bottom: 10px;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TotalInvestmentContainer = styled.div`
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -26px;
  color: white;
  background: #000000;

  @media print {
    margin: 0;
  }
`;

interface InCashButtonProps {
  isSelected?: boolean;
}

export const InCashButton = styled.button<InCashButtonProps>`
  border: none;
  background: transparent;
  color: #c4c4c4;
  border-radius: 8px;
  padding: 8px 16px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: white;
      border: none;
      background: black;
    `}
`;

export const PortionButton = styled.div`
  border: none;
  background: transparent;
  color: black;
`;

export const PaymentButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 36px;
  margin-left: 255px;

  @media print {
    margin-left: 200px;
  }
`;

export const PortionContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;

  height: 60px;
`;

export const CommentsContainer = styled.div`
  display: none;

  @media print {
    display: block;
  }
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media print {
    align-items: initial;
    flex-direction: column;
    height: 100px;
  }
`;
