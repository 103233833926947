/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { ArrowRightIcon } from 'components/atoms/Icons/ArrowIcon';
import { PenIcon } from 'components/atoms/Icons/PenIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { motion } from 'framer-motion';
import { colors } from 'styles/colors';
import { GridColumn, IconButton } from 'styles/miscellany';
import { clearCurrencyStrBrl, toCurrencyBrl } from 'utils/masks';

import { PenIconButton } from '../BudgetGroup/style';
import {
  BudgetCosts,
  BudgetGroupContainer,
  BudgetGroupHead,
  BudgetTitle,
  PaymentTitle,
  TitleHolder,
  TotalBudget,
  TotalRealizedBudget,
} from './style';

interface BudgetGroupProps {
  title: string;
  titleName?: string;
  titleEdit?: boolean;
  children: any;
  groupState?: any;
  onChange?: (oldName: string, newName: string) => void;
  defaultOpen?: boolean;
  hideCosts?: boolean;
  groupOriented?: boolean;
  budgetedTotal?: string;
  realizedTotal?: string;
  onDelete?: () => void;
  setOpen?: boolean;
}

export function PaymentGroup(props: BudgetGroupProps) {
  const {
    children,
    title,
    titleName,
    titleEdit,
    groupState,
    onChange,
    defaultOpen,
    hideCosts,
    groupOriented,
    budgetedTotal,
    realizedTotal,
    onDelete,
    setOpen,
  } = props;
  const [titleValue, setTitleValue] = useState(title);
  const [titleError, setTitleError] = useState(false);
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const totalsArray = [] as any;

  const handleTitleChange = (e) => {
    const { value } = e.currentTarget;

    setTitleValue(value);

    if (titleError) setTitleError(false);
  };

  const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context: any = canvas.getContext('2d');
    context.font = font;
    const metrics = context?.measureText(text);

    return metrics?.width;
  };

  const handleBlur = () => {
    if (!titleValue) {
      toast.error('O nome do grupo não pode ser vazio');
      setTitleError(true);
    } else if (onChange) {
      onChange(title, titleValue);
    }
  };

  const updateGroupTotal = () => {
    if (groupState && groupState.metadata) {
      Object.entries(groupState.metadata).forEach((section: any) => {
        totalsArray?.push(
          section
            .filter((item: any) => typeof item !== 'string')
            .map((item) =>
              item.reduce(
                (sum, current) =>
                  sum +
                  +clearCurrencyStrBrl(current.total ? current.total : '0'),
                0,
              ),
            ),
        );
      });
    }
    return totalsArray.reduce((sum, current) => sum + (+current || 0), 0);
  };

  useEffect(() => {
    if (title) {
      setTitleValue(title);
    } else {
      setTitleValue('Novo Grupo');
    }
  }, [title]);

  const totalBudget = !hideCosts && updateGroupTotal();

  useEffect(() => {
    if (setOpen) setIsOpen(setOpen);
  }, [setOpen]);

  return (
    <BudgetGroupContainer>
      <BudgetGroupHead>
        <TitleHolder>
          <IconButton
            style={{
              marginRight: '1rem',
              height: 20,
              width: 20,
              transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
            }}
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <ArrowRightIcon color={colors.white} />
          </IconButton>
          {titleEdit ? (
            <>
              <BudgetTitle
                type="text"
                name={titleName}
                id={titleName}
                value={titleValue}
                onInput={handleTitleChange}
                onBlur={handleBlur}
                titleError={titleError}
                style={{
                  width: titleError
                    ? 100
                    : getTextWidth(titleValue, '400 20px SFProDisplay'),
                }}
              />
              <PenIconButton
                type="button"
                id={titleName}
                onClick={() =>
                  document.getElementById(titleName as string)?.focus()
                }
              >
                <PenIcon />
              </PenIconButton>
            </>
          ) : (
            <PaymentTitle>{title}</PaymentTitle>
          )}
        </TitleHolder>
        <BudgetCosts onClick={() => setIsOpen(!isOpen)}>
          <GridColumn length={2} gap={1}>
            {!hideCosts ? (
              groupOriented ? (
                <>
                  <TotalBudget>
                    Total orçado: {toCurrencyBrl(budgetedTotal || 0)}
                  </TotalBudget>
                  <TotalRealizedBudget>
                    Total realizado: {toCurrencyBrl(realizedTotal || 0)}
                  </TotalRealizedBudget>
                </>
              ) : (
                <TotalBudget>{toCurrencyBrl(totalBudget)}</TotalBudget>
              )
            ) : (
              <>
                {onDelete && (
                  <IconButton
                    role="button"
                    type="button"
                    tabIndex={0}
                    onClick={() => onDelete()}
                  >
                    <TrashIcon variant="light" width={24} height={24} />
                  </IconButton>
                )}
              </>
            )}
          </GridColumn>
        </BudgetCosts>
      </BudgetGroupHead>
      <motion.div
        initial={false}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={{ duration: 0.6, ease: 'linear' }}
        style={{ overflow: 'hidden' }}
      >
        {children}
      </motion.div>
    </BudgetGroupContainer>
  );
}

PaymentGroup.defaultProps = {
  titleName: 'title',
  titleEdit: true,
  groupState: null,
  onChange: () => null,
  defaultOpen: false,
  hideCosts: false,
  onDelete: null,
};
