import { colors } from 'styles/colors';

interface NextIconProps {
  color?: string;
}

export function NextIcon({ color }: NextIconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0003 6.66732V1.33398L22.667 8.00065L16.0003 14.6673V9.33398C11.587 9.33398 8.00033 12.9207 8.00033 17.334C8.00033 21.7473 11.587 25.334 16.0003 25.334C20.4137 25.334 24.0003 21.7473 24.0003 17.334H26.667C26.667 23.2273 21.8937 28.0006 16.0003 28.0006C10.107 28.0006 5.33366 23.2273 5.33366 17.334C5.33366 11.4406 10.107 6.66732 16.0003 6.66732Z"
        fill={color}
      />
    </svg>
  );
}

NextIcon.defaultProps = {
  color: colors.primary,
};
