import { clearCurrencyStr } from 'utils/masks';

import { cnpjValidation } from './is-cnpj-valid';
import { testCpf } from './is-cpf-valid';
import { dateValidation } from './is-date-valid';
import { isEmailValid } from './is-email-valid';
import { isNameValid } from './is-name-valid';

const required = 'Campo obrigatório';

const validations = {
  required: {
    required,
  },
  percent: {
    validate: (percent: string) =>
      Number(clearCurrencyStr(percent) <= 10000) || 'Percentagem máxima 100%',
  },
  email: {
    required,
    validate: (email: string) =>
      isEmailValid(email) || 'Email inválido, verifique e tente novamente',
  },
  emailNotRequired: {
    validate: (email: string) =>
      !email ||
      isEmailValid(email) ||
      'Email inválido, verifique e tente novamente',
  },
  cnpjNotRequired: {
    validate: (cnpj: string) => {
      return (
        !cnpj ||
        cnpjValidation(cnpj) ||
        'CNPJ invalido, verifique e tente novamente'
      );
    },
  },
  cpfNotRequired: {
    validate: (cpf: string) => {
      return (
        !cpf || testCpf(cpf) || 'CPF invalido, verifique e tente novamente'
      );
    },
  },
  name: {
    required,
    validate: (name: string) =>
      isNameValid(name) || 'Nome inválido, verifique e tente novamente',
  },
  cnpj: {
    required,
    validate: (cnpj: string) => {
      return (
        !cnpj ||
        cnpjValidation(cnpj) ||
        'CNPJ invalido, verifique e tente novamente'
      );
    },
  },
  date: {
    required,
    validate: (date: string) => {
      return (
        !date ||
        dateValidation(date) ||
        'Data inválida, verifique e tente novamente'
      );
    },
  },
  dateNotRequired: {
    validate: (date: string) => {
      return (
        !date ||
        dateValidation(date) ||
        'Data inválida, verifique e tente novamente'
      );
    },
  },
};

type Validations = keyof typeof validations;
type ValidationsReturnType<T extends Validations> = typeof validations[T];

export const validator = <T extends Validations>(
  validationType: T,
): ValidationsReturnType<T> => {
  return validations[validationType];
};
