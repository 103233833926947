import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { CheckBoxIcon } from 'components/atoms/Icons/CheckboxIcon';
import {
  StyledPermissionSubtitle,
  StyledCheckbox,
  StyledRoleText,
  StyledRowDiv,
  StyledGridDiv,
} from 'pages/Notifications/components/NotificationForm/style';

interface CompaniesFormProps {
  options: any;
  title: string;
  readOnly?: boolean;
}

export function CompaniesForm({
  options,
  title,
  readOnly,
}: CompaniesFormProps) {
  const { setValue, getValues, reset, register } = useFormContext();

  useEffect(() => {
    register('authorizedCompanies', { value: [] });
  }, [register]);

  const checkedValues = getValues('authorizedCompanies') || [];

  return (
    <>
      <StyledPermissionSubtitle>{title}</StyledPermissionSubtitle>
      <StyledGridDiv>
        {options.map((item) => {
          return (
            <StyledRowDiv>
              <StyledCheckbox
                role="button"
                tabIndex={0}
                onClick={
                  readOnly
                    ? () => null
                    : () => {
                        const oldValues = [...checkedValues];
                        const itemIndex = oldValues.indexOf(item.name);
                        if (itemIndex >= 0) {
                          oldValues.splice(itemIndex, 1);
                        } else {
                          oldValues.push(item.name);
                        }
                        reset(
                          { authorizedCompanies: '' },
                          { keepValues: true },
                        );
                        setValue('authorizedCompanies', oldValues);
                      }
                }
              >
                <CheckBoxIcon
                  width={24}
                  height={24}
                  checked={checkedValues.indexOf(item.name) >= 0}
                />
              </StyledCheckbox>
              <StyledRoleText readOnly={readOnly}>{item.name}</StyledRoleText>
            </StyledRowDiv>
          );
        })}
      </StyledGridDiv>
    </>
  );
}
