import { colors } from 'styles/colors';

interface BellIconProps {
  variant: 'dark' | 'light' | 'special';
  width: number;
  height: number;
}

export function BellIcon({ variant, width, height }: BellIconProps) {
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
    special: colors.textOnBackground.special,
  };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 17V11C19 7.65 16.64 4.85 13.5 4.17V3C13.5 2.17 12.83 1.5 12 1.5C11.17 1.5 10.5 2.17 10.5 3V4.17C7.36 4.85 5 7.65 5 11V17L3 19V20H21V19L19 17ZM12 23C13.1 23 13.99 22.11 13.99 21.01H10.01C10.01 22.11 10.9 23 12 23ZM13 13.01H16V11H13V8H11V11H8V13.01H11V16.01H13V13.01Z"
        fill={variants[variant]}
        fillOpacity="0.87"
      />
    </svg>
  );
}
