import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

interface AvatarStyledProps {
  size: number;
}
export const AvatarStyled = styled.div<AvatarStyledProps>`
  ${({ size }) => css`
    border-radius: 50%;
    width: ${size}px;
    height: ${size}px;
    border: 2px solid ${colors.textOnBackground.active};
    overflow: hidden;
  `}
`;

export const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
`;
