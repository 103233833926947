import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useMutation } from '@apollo/client';
import { FileDisplay } from 'components/atoms/FileDisplay';
import { SelectField } from 'components/atoms/SelectField';
import { Spinner } from 'components/atoms/Spinner';
import { TextArea } from 'components/atoms/TextArea';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { useUser } from 'contexts/UserContext';
import { AnimatePresence, motion } from 'framer-motion';
import { useFetch } from 'hooks/useFetch';
import { CompanyFile } from 'pages/Companies/components/CompanyForm';
import { StyledDiv } from 'pages/Companies/components/CompanyForm/style';
import { Services } from 'services/service';
import { DefaultForm, FlexContainer } from 'styles/miscellany';
import { clearCurrencyNumberBrl } from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { Attach } from './components/Attach';
import { APPROVE_BUDGET } from './query';
import { ApproveFieldContainer, ApproveProjectContainer } from './style';

interface Props {
  id: string;
  onSuccess: () => void;
}
export function ApproveProject({ id, onSuccess }: Props) {
  const { userData } = useUser();
  const { closeDrawer } = useDrawer();
  const { fetchAuthorized } = useFetch();
  const methods = useForm();

  const [approveBudget, approveBudgetRes] = useMutation(APPROVE_BUDGET);

  const [files, setFiles] = useState<CompanyFile[]>([]);

  const formValues = methods.watch();

  const addFiles = (filesToUpload: CompanyFile[]) => {
    setFiles(files.concat(filesToUpload));
  };

  const deleteFiles = (clickedFile: CompanyFile) => {
    setFiles(files.filter((f) => f.id !== clickedFile.id));
  };

  const redirectAfterApprove = async () => {
    closeDrawer();
    onSuccess();
  };

  const onSubmit = (dataForm) => {
    const payload = {
      ...dataForm,
      budId: id,
      approvedBy: userData._id,
      totalRevenue: clearCurrencyNumberBrl(dataForm.totalRevenue),
    };

    if (dataForm.serviceType !== 'fee') {
      delete payload.start_at;
      delete payload.finish_at;
    }

    approveBudget({
      variables: payload,
    })
      .then(async (result) => {
        const projectId = result?.data?.approveBudget?._id;
        const formdata = new FormData();
        const filesToUpload = files.filter((file) => {
          return file.type === 'fileToUpload';
        });

        if (filesToUpload.length > 0) {
          toast.success('Enviando arquivos, por favor aguarde');

          filesToUpload.forEach((fileToUpload) => {
            if (fileToUpload.file)
              formdata.append('documents', fileToUpload.file);
          });

          const requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow' as RequestRedirect,
          };

          await fetchAuthorized(
            `${Services.Upload}?for=project&attachTo=${projectId}`,
            requestOptions,
          )
            .then((response) => response.text())
            .then(() => {
              toast.success('Arquivos enviados com sucesso!');
              redirectAfterApprove();
            })
            .catch(() => {
              toast.error('Ocorreu um erro ao enviar um ou mais arquivos.');
              redirectAfterApprove();
            });
        } else {
          toast.success('Orçamento aprovado com sucesso!');
          redirectAfterApprove();
        }
      })
      .catch(() => {
        toast.error('Ocorreu um erro, por favor tente novamente.');
      });
  };

  if (approveBudgetRes?.loading) return <Spinner />;

  return (
    <ApproveProjectContainer>
      <FormProvider {...methods}>
        <DefaultForm onSubmit={methods.handleSubmit(onSubmit)} id="user-form">
          <ApproveFieldContainer>
            <FlexContainer>
              <SelectField
                name="serviceType"
                label="Tipo"
                placeholder="Tipo"
                options={[
                  { value: 'project', label: 'Projeto' },
                  { value: 'fee', label: 'Fee' },
                ]}
                validation={validator('required')}
              />
            </FlexContainer>
            <FlexContainer style={{ margin: '0 1rem' }}>
              {formValues?.serviceType === 'fee' && (
                <TextField name="start_at" label="Início" pattern="dateMask" />
              )}
            </FlexContainer>
            <FlexContainer>
              {formValues?.serviceType === 'fee' && (
                <TextField name="finish_at" label="Fim" pattern="dateMask" />
              )}
            </FlexContainer>
          </ApproveFieldContainer>
          <TextArea
            name="notes"
            label="Observações"
            height="10"
            validation={validator('required')}
          />
        </DefaultForm>

        <Attach onChange={addFiles} />
        <AnimatePresence>
          {files.length > 0 && (
            <StyledDiv
              key="otherDiv"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <AnimatePresence>
                {files.map((otherFile) => (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key={otherFile.id || otherFile.name}
                  >
                    <FileDisplay
                      fileLink={otherFile.fileUrl}
                      fileName={otherFile.name}
                      fileSizeMB={otherFile.size?.toFixed(2) || 0}
                      onClickDelete={() => deleteFiles(otherFile)}
                    />
                  </motion.div>
                ))}
              </AnimatePresence>
            </StyledDiv>
          )}
        </AnimatePresence>
      </FormProvider>
    </ApproveProjectContainer>
  );
}
