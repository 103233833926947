import { gql } from '@apollo/client';

export const GET_INTERNAL_PRODUCTIONS_LIST = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $sorting: [SorterInput]
    $type: String!
    $unit: String!
  ) {
    ConfigurationItems(
      limit: $limit
      page: $page
      search_term: $search_term
      sorting: $sorting
      type: $type
      unit: $unit
    ) {
      items {
        _id
        label
        type
        metadata
      }
      pages
    }
  }
`;

export const COMPANY_TAX = gql`
  query queryCompany($companyId: String!) {
    CompanyItems(limit: 1, page: 1, id: $companyId) {
      items {
        name
        directTax
      }
    }
  }
`;

export const GET_BUDGET_LIST = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $sorting: [SorterInput]
    $unit: String
    $status: String
  ) {
    BudgetItems(
      limit: $limit
      page: $page
      search_term: $search_term
      unit: $unit
      sorting: $sorting
      status: $status
      is_approved: false
    ) {
      items {
        _id
        budInvoices {
          value
          currencyName
          isParadise
          hedgePercentage
          currencyQuotation
        }
        budGroups {
          name
          metadata
        }
        budComissions {
          item
          type
          percentage
        }
        budDeductions {
          description
          value
        }
      }
    }
  }
`;
