import { colors } from 'styles/colors';

interface PreviousIconProps {
  color?: string;
}

export function PreviousIcon({ color }: PreviousIconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9997 6.66732V1.33398L9.33301 8.00065L15.9997 14.6673V9.33398C20.413 9.33398 23.9997 12.9207 23.9997 17.334C23.9997 21.7473 20.413 25.334 15.9997 25.334C11.5863 25.334 7.99967 21.7473 7.99967 17.334H5.33301C5.33301 23.2273 10.1063 28.0006 15.9997 28.0006C21.893 28.0006 26.6663 23.2273 26.6663 17.334C26.6663 11.4406 21.893 6.66732 15.9997 6.66732Z"
        fill={color}
      />
    </svg>
  );
}

PreviousIcon.defaultProps = {
  color: colors.primary,
};
