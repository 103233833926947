import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledSkillsDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ButtonContainer = styled.div`
  width: 5.4375rem;
  height: 2.5rem;
`;

export const SkillsTextDiv = styled.p`
  margin-top: 0.25rem;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.15px;

  color: #ffffff;
`;
export const SkillsGridDiv = styled(motion.div)`
  display: grid;
  grid-template-columns: 15rem 15rem 1.5rem;
  grid-column-gap: 1.5rem;
  > div {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  > button {
    margin-left: -0.5rem;
  }
  padding-bottom: 0 !important;
`;
