import { gql } from '@apollo/client';

export const GET_USERS_LIST = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $sorting: [SorterInput]
  ) {
    UserItems(
      limit: $limit
      page: $page
      search_term: $search_term
      sorting: $sorting
    ) {
      items {
        _id
        name
        nickname
        avatar
        area
        permission
        is_disabled
      }
      pages
      total
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($id: String!) {
    UserItems(limit: 1, page: 1, id: $id) {
      items {
        name
        nickname
        email
        area
        permission
        authorizedCompanies {
          _id
          name
        }
      }
      pages
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: String!
    $name: String
    $nickname: String
    $email: String
    $area: String
    $permission: String
    $isDisabled: Boolean
    $authorizedCompanies: [String]
  ) {
    userSetup(
      id: $id
      name: $name
      nickname: $nickname
      email: $email
      area: $area
      permission: $permission
      role: "Lorem Ipsum"
      unit: "Lorem Ipsum"
      isDisabled: $isDisabled
      authorizedCompanies: $authorizedCompanies
    )
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $name: String!
    $nickname: String!
    $email: String!
    $area: String!
    $permission: String!
    $authorizedCompanies: [String]
  ) {
    createUser(
      name: $name
      nickname: $nickname
      email: $email
      area: $area
      permission: $permission
      role: "Lorem Ipsum"
      unit: "Lorem Ipsum"
      authorizedCompanies: $authorizedCompanies
    ) {
      _id
    }
  }
`;
