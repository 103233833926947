import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { Breadcrumb } from 'components/atoms/Breadcrumb';
import { Button } from 'components/atoms/Button';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { SpinnerIcon } from 'components/atoms/Icons/SpinnerIcon';
import { Tabs } from 'components/organisms/Tabs';
import { useDrawer } from 'contexts/DrawerContext';
import { ClientForm } from 'pages/Clients/components/ClientForm';

import { ClientContacts } from './components/Contacts';
import { ClientAnnotations } from './components/Legal';
import { ClientProfitDeals } from './components/ProfitDeals';
import { ClientProjects } from './components/Projects';
import { ClientRateCards } from './components/RateCard';
import { CLIENT_NAME } from './query';
import { TabsHolder } from './style';

export function ManageClient() {
  const [getClient, getClientRes] = useLazyQuery(CLIENT_NAME);
  const { openDrawer } = useDrawer();
  const history = useHistory();
  const [clientId, setClientId] = useState('loading');
  const [clientData, setClientData] = useState('loading');
  useEffect(() => {
    setClientId(
      history.location.pathname.split('/')[
        history.location.pathname.split('/').length - 1
      ],
    );
  }, [history.location.pathname]);
  useEffect(() => {
    getClient({ variables: { id: clientId } });
  }, [clientId, getClient]);

  useEffect(() => {
    const items = getClientRes.data?.ClientItems?.items;
    if (items) setClientData(items[0].name);
  }, [getClientRes.data]);

  const tabHeader = [
    'Projetos',
    'Contatos',
    'Acordo de Lucratividade',
    'Rate Card',
    'Legal',
  ];
  const handleAction = () => {
    openDrawer({
      title: 'Editar Cliente',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      content: <ClientForm client={clientId} refetch={() => {}} />,
      formId: 'client-form',
      width: 600,
    });
  };

  const tabContent = [
    <ClientProjects key="projects" />,
    <ClientContacts key="contacts" />,
    <ClientProfitDeals key="profitDeals" />,
    <ClientRateCards key="rateCards" />,
    <ClientAnnotations key="annotation" />,
  ];

  const breadcrumbs = [
    {
      title: 'Clientes',
      link: '/clients',
    },
    {
      title:
        clientData !== 'loading' ? (
          clientData
        ) : (
          <SpinnerIcon variant="light" width={24} height={24} />
        ),
      link: `/clients/${clientId}`,
    },
  ];

  const rightContent = (
    <Button
      variant="secondary"
      style={{ maxWidth: 170 }}
      onClick={handleAction}
      icon={<GearIcon width={20} height={20} variant="special" />}
    >
      Informações
    </Button>
  );

  return (
    <div>
      <Breadcrumb breadcrumbs={breadcrumbs} rightContent={rightContent} />
      <TabsHolder>
        <Tabs tabHeader={tabHeader} tabContent={tabContent} />
      </TabsHolder>
    </div>
  );
}
