import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'components/atoms/Button';
import { SelectField } from 'components/atoms/SelectField';
import { TextArea } from 'components/atoms/TextArea';
import { TextField } from 'components/molecules/TextField';
import { BudgetGroup } from 'components/organisms/BudgetGroup';
import { FormGroupWithAdding } from 'components/organisms/FormGroupWithAdding';
import { differenceInCalendarMonths, parse } from 'date-fns';
import { FieldsContainer } from 'pages/Budgets/components/NewBudget/style';
import { DefaultForm, GridColumn, Line } from 'styles/miscellany';
import { clearCurrencyNumberBrl, toCurrencyBrl } from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { DateFieldCustom } from '../RecognitionControl/components/DateFieldCustom';
import { RevenueFields } from './components/RevenueFields';
import { RevenueLink } from './components/RevenueLink';
import { GET_REVENUES, SAVE_REVENUES } from './query';
import {
  Container,
  ExportButtonContainer,
  RevenueControlContainer,
  RevenueGrid,
  RevenueText,
  SolicitationsContainer,
  ThreeLineGrid,
} from './style';

export function RevenueControl() {
  const methods = useForm();
  const params: any = useParams();
  const [project, projectRes] = useLazyQuery(GET_REVENUES);
  const [saveRevenues] = useMutation(SAVE_REVENUES);

  const projectData = projectRes.data?.ProjectItems?.items[0];
  const revenues = projectData?.revenues;
  const formValues = methods.watch();

  useEffect(() => {
    if (params?.id) {
      project({ variables: { id: params.id } });
    }
  }, [project, params?.id]);

  useEffect(() => {
    if (revenues?.length) {
      const editValues = revenues.map((item) => {
        const metadata = JSON.parse(item.metadata);
        metadata.value = toCurrencyBrl(
          metadata.value ? metadata.value.toFixed(2) : 0,
        );
        return {
          name: item.name,
          metadata,
        };
      });
      methods.setValue('revenues', editValues);
    }
  }, [revenues]);

  const changeGroupName = (index, name) => {
    methods.setValue(`revenues.${index}.name`, name);
  };

  const onSubmit = (formInfo) => {
    const payload = formInfo?.revenues.map((item) => {
      const metadata = { ...item.metadata };
      metadata.value = metadata.value
        ? clearCurrencyNumberBrl(`${metadata.value}`)
        : 0;
      return {
        name: item.name || 'Solicitações',
        metadata: JSON.stringify(metadata),
      };
    });

    saveRevenues({ variables: { id: params?.id, revenues: payload } })
      .then(() => toast.success('Dados salvos com sucesso!'))
      .catch(() => toast.error('Ocorreu um erro ao salvar os dados'));
  };

  const getGroupTitle = () => {
    if (
      projectData &&
      projectData.start_at !== 'N/A' &&
      projectData.finish_at !== 'N/A'
    ) {
      const startAt = parse(projectData.start_at, 'dd/MM/yyyy', new Date());
      const finishAt = parse(projectData.finish_at, 'dd/MM/yyyy', new Date());
      const totalParcels = differenceInCalendarMonths(finishAt, startAt);
      const currentParcel = differenceInCalendarMonths(startAt, new Date());

      return `Parcelas do Fee - <span>${
        currentParcel <= totalParcels ? totalParcels : totalParcels
      }/${totalParcels}</span>`;
    }
    return 'Solicitação do projeto';
  };

  return (
    <RevenueControlContainer>
      <FieldsContainer>
        <RevenueFields projectData={projectData} />
        <SolicitationsContainer>
          <FormProvider {...methods}>
            <DefaultForm
              onSubmit={methods.handleSubmit(onSubmit)}
              id="revenue-control-form"
            >
              <FormGroupWithAdding
                groupName="revenues"
                title={getGroupTitle()}
                defaultItem={{ name: 'Solicitações' }}
                renderRow={({ index, onDelete }) => (
                  <BudgetGroup
                    title={
                      formValues?.revenues?.length
                        ? formValues.revenues[index].name || 'Solicitações'
                        : 'Solicitações'
                    }
                    titleName={`revenues.${index}.name`}
                    onChange={(name) => changeGroupName(index, name)}
                    onDelete={() => onDelete(index)}
                    defaultOpen={index === 0}
                    hideCosts
                  >
                    <Container>
                      <ThreeLineGrid>
                        <TextField
                          name={`revenues.${index}.metadata.value`}
                          label="Valor"
                          pattern="moneyPrefix"
                        />
                        <TextField
                          name={`revenues.${index}.metadata.poCode`}
                          label="Nº da PO"
                        />
                        <RevenueLink
                          name={`revenues.${index}.metadata.fileLink`}
                          label="Link do documento"
                        />
                      </ThreeLineGrid>

                      <RevenueGrid>
                        <div>
                          <DateFieldCustom
                            name={`revenues.${index}.metadata.dueDate`}
                            label="Vencimento Padrão"
                            validation={validator('dateNotRequired')}
                          />
                          <TextField
                            name={`revenues.${index}.metadata.cnpj`}
                            label="CNPJ"
                            pattern="cnpjMask"
                            validation={validator('cnpjNotRequired')}
                          />
                        </div>

                        <div>
                          <TextField
                            name={`revenues.${index}.metadata.legalName`}
                            label="Razão Social"
                          />
                          <TextField
                            name={`revenues.${index}.metadata.email`}
                            label="Email"
                            validation={validator('emailNotRequired')}
                          />
                        </div>

                        <div>
                          <TextArea
                            name={`revenues.${index}.metadata.instructions`}
                            label="Instruções de Faturamento"
                            height="9"
                          />
                        </div>
                      </RevenueGrid>

                      <ExportButtonContainer>
                        <Button variant="primary">ENVIAR SOLICITAÇÃO</Button>
                      </ExportButtonContainer>

                      <Line style={{ margin: '0 0 1.5rem 0' }} />

                      <RevenueText>Retorno Neuron</RevenueText>

                      <GridColumn length={5} gap={1} fragment>
                        <SelectField
                          name={`revenues.${index}.metadata.neuronReturn.status`}
                          label="Status"
                          placeholder="Status"
                          options={[{ value: 'received', label: 'Recebido' }]}
                          readOnly
                        />

                        <TextField
                          name={`revenues.${index}.metadata.neuronReturn.nfNumber`}
                          label="Nº da NF"
                          readOnly
                        />

                        <RevenueLink
                          name={`revenues.${index}.metadata.neuronReturn.fileLink`}
                          label="Link do documento"
                          variant="light"
                          readOnly
                        />

                        <DateFieldCustom
                          name={`revenues.${index}.metadata.neuronReturn.dueDate`}
                          label="Data de Vencimento"
                          validation={validator('dateNotRequired')}
                          readOnly
                        />

                        <DateFieldCustom
                          name={`revenues.${index}.metadata.neuronReturn.issueDate`}
                          label="Data de Emissão"
                          validation={validator('dateNotRequired')}
                          readOnly
                        />
                      </GridColumn>
                      <ExportButtonContainer>
                        <Button variant="primary">SALVAR RETORNO</Button>
                      </ExportButtonContainer>

                      <Line style={{ margin: '1.5rem 0 0 0' }} />
                    </Container>
                  </BudgetGroup>
                )}
              />
            </DefaultForm>
          </FormProvider>
        </SolicitationsContainer>
      </FieldsContainer>
    </RevenueControlContainer>
  );
}
