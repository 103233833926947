import styled from 'styled-components';

export const NewBudgetToolbarContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
  justify-content: space-between;

  button > svg {
    margin: 0;
  }
`;
