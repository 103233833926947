import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StructureRevenueContainer = styled.div``;

export const GroupsText = styled.p`
  color: ${colors.white};
  font-weight: bold;
  margin: 3rem 0 2rem 0;
  padding: 0 1.875rem;
`;

export const TextInformation = styled.span`
  margin: 0 0.5rem;
  padding: 0 0.5rem;
  border-radius: 50%;
  color: ${colors.primary};
  background-color: ${colors.background};
`;
