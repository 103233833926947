import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { BreadcrumbContainer, BreadcrumbTitle, Title } from './style';

interface BreadcrumbObjectProps {
  title: string | ReactNode;
  link: string;
}

interface BreadcrumbProps {
  breadcrumbs: Array<BreadcrumbObjectProps>;
  rightContent?: any;
}

export function Breadcrumb(props: BreadcrumbProps) {
  const { breadcrumbs, rightContent } = props;

  return (
    <BreadcrumbContainer>
      <BreadcrumbTitle>
        {breadcrumbs.map(({ title, link }) => (
          <Title>
            <Link to={link}>{title}</Link>
          </Title>
        ))}
      </BreadcrumbTitle>
      {rightContent}
    </BreadcrumbContainer>
  );
}

Breadcrumb.defaultProps = {
  rightContent: null,
};
