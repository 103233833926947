import { useCallback, useMemo } from 'react';

import { storageKeys } from 'services/storage-keys';

export function useFetch() {
  const fetchAuthorized = useCallback((url, options?) => {
    const token = localStorage.getItem(storageKeys.token);

    return fetch(url, {
      ...options,
      headers: { ...options?.headers, 'X-Access-Token': token || '' },
    });
  }, []);

  return useMemo(() => {
    return {
      fetchAuthorized,
    };
  }, [fetchAuthorized]);
}
