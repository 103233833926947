import { Button } from 'components/atoms/Button';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { useModal } from 'contexts/ModalContext';

import {
  AttentionTitle,
  ButtonHolder,
  DeleteModalContainer,
  IconHolder,
} from './style';

interface DeleteModalProps {
  onAccept: () => void;
}

export function DeleteModal(props: DeleteModalProps) {
  const { onAccept } = props;
  const { closeModal } = useModal();

  const handleAccept = () => {
    onAccept();

    closeModal();
  };

  const handleDecline = () => {
    closeModal();
  };

  return (
    <DeleteModalContainer>
      <IconHolder>
        <TrashIcon variant="light" width={24} height={24} />
      </IconHolder>
      <AttentionTitle>Atenção</AttentionTitle>
      <p>Deseja realmente excluir esse item?</p>
      <ButtonHolder length={2} gap={1.5}>
        <Button variant="secondary" onClick={handleDecline}>
          Não
        </Button>
        <Button variant="primary" onClick={handleAccept}>
          Sim
        </Button>
      </ButtonHolder>
    </DeleteModalContainer>
  );
}
