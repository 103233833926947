import { colors } from 'styles/colors';

interface DraftIconProps {
  variant: 'dark' | 'light' | 'special';
  width: number;
  height: number;
}

export function DraftIcon({ variant, width, height }: DraftIconProps) {
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
    special: colors.textOnBackground.special,
  };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5417 5.25065C18.0167 5.54232 18.325 6.06732 18.325 6.66732L18.3334 15.0007C18.3334 15.9173 17.5834 16.6673 16.6667 16.6673H3.33335C2.41669 16.6673 1.66669 15.9173 1.66669 15.0007V6.66732C1.66669 6.06732 1.98335 5.54232 2.45835 5.25065L10 0.833984L17.5417 5.25065ZM3.11669 6.53398L10 10.834L16.8834 6.53398L10 2.50065L3.11669 6.53398Z"
        fill={variants[variant]}
        fillOpacity="0.87"
      />
    </svg>
  );
}
