/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-bind */
// eslint-disable-next-line no-use-before-define
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'components/atoms/Button';
import { DownloadIcon } from 'components/atoms/Icons/DownloadIcon';
import { NextIcon } from 'components/atoms/Icons/NextIcon';
import { PreviousIcon } from 'components/atoms/Icons/PreviousIcon';
import { PrinterIcon } from 'components/atoms/Icons/PrinterIcon';
import { RadioBoxIcon } from 'components/atoms/Icons/RadioCheckboxIcon';
import { VisibilityIcon } from 'components/atoms/Icons/VisibilityIcon';
import { VisibilityOffIcon } from 'components/atoms/Icons/VisibilityOffIcon';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { UnstyledButton } from 'pages/Companies/components/CompanyBankAccounts/style';
import {
  RadioCheckBoxContainer,
  ShowFlagBackgroundContainer,
  ShowFlagText,
} from 'pages/PrintedBudget/style';
import { toCurrencyBrl } from 'utils/masks';

import {
  ToolbarHolder,
  PrintedBudgetToolbarContainer,
  ButtonsContainer,
} from './style';

interface Props {
  onSave: () => void;
  budgetState: any;
  docName?: string;
  onVisibilityClick?: () => void;
  isVisible?: boolean;
  showBackground?: boolean;
  onShowBackgroundClick?: () => void;
}

export function PrintedBudgetToolbar(props: Props) {
  const {
    onSave,
    budgetState,
    docName,
    onVisibilityClick,
    isVisible,
    showBackground,
    onShowBackgroundClick,
  } = props;
  const methods = useFormContext();
  const [formStateStep, setFormStateStep] = useState(0);

  const historyArray = useRef<any[]>([JSON.parse(JSON.stringify(budgetState))]);

  const updateState = (stateIndex: number) => {
    const newIndex = stateIndex > 0 ? stateIndex : 0;

    if (newIndex < historyArray.current.length) {
      const rootMetadata = historyArray.current[newIndex].metadata;
      methods.setValue(
        'metadata',
        rootMetadata ? JSON.parse(JSON.stringify(rootMetadata)) : {},
      );

      historyArray.current[newIndex]?.budGroups?.forEach((item: any, index) => {
        methods.setValue(`budGroups.${index}`, {
          name: item.name,
          metadata: JSON.parse(JSON.stringify(item.metadata)),
        });
      });
      historyArray.current[newIndex]?.budDeductions?.forEach(
        (item: any, index) => {
          methods.setValue(`budDeductions.${index}`, {
            description: item.description,
            value: toCurrencyBrl(item.value * 100),
          });
        },
      );
      setFormStateStep(newIndex);
      onSave();
    }
  };

  const addState = () => {
    historyArray.current = historyArray.current.slice(0, formStateStep + 1);
    historyArray.current.push(JSON.parse(JSON.stringify(budgetState)));
    setFormStateStep(historyArray.current.length - 1);
  };

  const printPDF = () => {
    const htmlToPrint = document.getElementById('pdf-print');

    if (htmlToPrint) {
      let w = htmlToPrint.offsetWidth;
      let h = htmlToPrint.offsetHeight;

      html2canvas(htmlToPrint, {
        onclone: (document) => {
          w =
            document.body.offsetWidth > 1300 ? 1300 : document.body.offsetWidth;
          h = document.body.offsetHeight;
        },
      }).then((canvas) => {
        const img = canvas.toDataURL('image/jpeg', 1);
        // eslint-disable-next-line new-cap
        const doc = new jsPDF('p', 'px', [w, h]);
        doc.addImage(img, 'JPEG', 0, 0, w, h);
        doc.save(`${docName}.pdf`);
      });
    }
  };

  return (
    <ToolbarHolder data-html2canvas-ignore>
      <PrintedBudgetToolbarContainer>
        <UnstyledButton
          title="Visível"
          type="button"
          onClick={onVisibilityClick}
        >
          {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </UnstyledButton>

        <UnstyledButton title="Imprimir" type="button" onClick={window.print}>
          <PrinterIcon />
        </UnstyledButton>
        <UnstyledButton title="Download" type="button" onClick={printPDF}>
          <DownloadIcon />
        </UnstyledButton>
        <UnstyledButton
          title="Desfazer"
          type="button"
          onClick={() => updateState(formStateStep - 1)}
        >
          <PreviousIcon />
        </UnstyledButton>
        <UnstyledButton
          title="Refazer"
          type="button"
          onClick={() => updateState(formStateStep + 1)}
        >
          <NextIcon />
        </UnstyledButton>
      </PrintedBudgetToolbarContainer>
      <ButtonsContainer>
        <UnstyledButton
          onClick={onShowBackgroundClick}
          style={{
            color: `${showBackground ? '#7B61FF' : '#FFFFFF'}`,
          }}
        >
          <ShowFlagBackgroundContainer>
            {showBackground ? <RadioBoxIcon /> : <RadioCheckBoxContainer />}
            <ShowFlagText>Flag Background</ShowFlagText>
          </ShowFlagBackgroundContainer>
        </UnstyledButton>
        <Button
          variant="secondary"
          type="button"
          onClick={() => {
            addState();
            onSave();
          }}
        >
          SALVAR
        </Button>
      </ButtonsContainer>
    </ToolbarHolder>
  );
}
