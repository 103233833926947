import { Fragment, useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTable, usePagination, useSortBy } from 'react-table';

import { Avatar } from 'components/atoms/Avatar';
import { EmptyTable } from 'components/atoms/EmptyTable';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from 'components/atoms/Icons/ArrowIcon';
import { FilterIcon } from 'components/atoms/Icons/FilterIcon';
import { NavBottomIcon, NavTopIcon } from 'components/atoms/Icons/NavIcon';
import { Plus } from 'components/atoms/Icons/Plus';
import { RoundCheckBoxIcon } from 'components/atoms/Icons/RoundCheckboxIcon';
import { useSidebar } from 'contexts/SidebarContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { AnimatePresence } from 'framer-motion';
import { colors } from 'styles/colors';
import { GridColumn, IconButton } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';

import {
  TableContainer,
  TableComponent,
  HeadCell,
  BodyRow,
  Pagination,
  RowPerPage,
  PageIndex,
  PaginationButtons,
  WithAddingHolder,
  ActionsHolder,
  TableContentContainer,
  InvisibleDivContainer,
  FilterContainer,
  StyledCheckbox,
  TdContainer,
  FilterHeadCell,
  FooterContainer,
  TotalContainer,
} from './style';

type TableProps = {
  columns: any;
  data: any;
  pages: number;
  loading: boolean;
  emptyText: string;
  withAvatar?: boolean;
  actions?: any;
  searchTerm?: string;
  filter?: boolean;
  fetchVariables?: any;
  manualSort?: boolean;
  withAdding?: (any) => void;
  fetchData: (any) => void;
  totalItems?: number;
} & React.HTMLAttributes<HTMLDivElement>;

const MIN_LOADING_TIME = 1000;

export function Table(props: TableProps) {
  const {
    columns,
    data,
    actions,
    pages,
    loading,
    emptyText,
    withAvatar,
    fetchData,
    withAdding,
    fetchVariables,
    searchTerm,
    filter,
    manualSort,
    totalItems,
    ...rest
  } = props;

  const [isMinLoading, setIsMinLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { neuronMode, currentCompanyId } = useWorkspace();
  const { isSidebarOpen } = useSidebar();

  useEffect(() => {
    if (loading) {
      setIsMinLoading(true);
      setTimeout(() => {
        setIsMinLoading(false);
      }, MIN_LOADING_TIME);
    }
  }, [loading]);

  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      manualSortBy: !manualSort,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: pages,
    },
    useSortBy,
    usePagination,
  );

  const cols = headerGroups.map((headerGroup) => headerGroup.headers.length);

  const fetchWithSort = useCallback(
    (pageNumber: number) => {
      return fetchData({
        variables: {
          search_term: searchTerm,
          limit: pageSize,
          page: pageNumber,
          sorting: sortBy[0]
            ? { by: sortBy[0]?.id, as: sortBy[0]?.desc ? 'desc' : 'asc' }
            : { as: 'asc' },
          ...fetchVariables,
        },
      });
    },
    [sortBy, fetchData, searchTerm, pageSize, neuronMode, currentCompanyId],
  );

  useEffect(() => {
    gotoPage(0);
    fetchWithSort(1);
  }, [fetchWithSort, gotoPage]);

  const handlePrevious = () => {
    fetchWithSort(pageIndex);
    previousPage();
  };

  const handleNext = () => {
    fetchWithSort(pageIndex + 2);
    nextPage();
  };

  const handleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <TableContainer
      {...(rest as any)}
      isSideBarOpen={isSidebarOpen}
      transition={{ type: 'linear', duration: 0.6 }}
      animate={{
        width: isSidebarOpen ? 'calc(100vw - 328px)' : `calc(100vw - 152px)`,
      }}
    >
      <TableContentContainer>
        <TableComponent {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <HeadCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <GridColumn length={2} gap={0.5}>
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <NavBottomIcon />
                        ) : (
                          <NavTopIcon />
                        )
                      ) : (
                        ''
                      )}
                    </GridColumn>
                  </HeadCell>
                ))}
                {actions && (
                  <FilterHeadCell style={{ width: 150, textAlign: 'right' }}>
                    {filter && (
                      <div
                        onClick={handleFilter}
                        aria-hidden="true"
                        style={{ cursor: 'pointer' }}
                      >
                        <FilterIcon color="white" />
                      </div>
                    )}

                    <AnimatePresence>
                      {isFilterOpen && (
                        <>
                          <InvisibleDivContainer onClick={handleFilter} />
                          <FilterContainer
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                          >
                            <p>Projetar:</p>
                            {allColumns.map((column, i) => (
                              <>
                                {i > 0 && (
                                  <StyledCheckbox key={column.id}>
                                    <RoundCheckBoxIcon
                                      checked={
                                        column.getToggleHiddenProps().checked
                                      }
                                    />
                                    <p>{column.Header}</p>
                                    <input
                                      type="checkbox"
                                      id={column.id}
                                      {...column.getToggleHiddenProps()}
                                    />
                                  </StyledCheckbox>
                                )}
                              </>
                            ))}
                          </FilterContainer>
                        </>
                      )}
                    </AnimatePresence>
                  </FilterHeadCell>
                )}
              </tr>
            ))}
          </thead>
          {loading || isMinLoading ? (
            <tbody {...getTableBodyProps()}>
              {[...Array(pageSize)].map((c, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <BodyRow key={`loading-row-${i}`}>
                    {headerGroups.map((headerGroup) =>
                      headerGroup.headers.map((component, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <td key={`loading-row-${index}`}>
                          <Skeleton width="80%" />
                        </td>
                      )),
                    )}
                    {actions && (
                      <td>
                        <Skeleton width="80%" />
                      </td>
                    )}
                  </BodyRow>
                );
              })}
            </tbody>
          ) : page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);

                const actionElement =
                  actions &&
                  actions(row.original, row.index).map(({ component }, i) => (
                    <Fragment key={`${row.cells[0].value + i}`}>
                      {component}
                    </Fragment>
                  ));

                return (
                  <BodyRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if (cell.column.id === 'name' && withAvatar) {
                        return (
                          <td {...cell.getCellProps()}>
                            <GridColumn length={2} gap={0.75}>
                              <Avatar src={row.original.avatar} />{' '}
                              {cell.render('Cell')}
                            </GridColumn>
                          </td>
                        );
                      }

                      return (
                        <TdContainer
                          {...cell.getCellProps()}
                          title={
                            (cell.column.Header === 'ID' && cell.value) ||
                            (cell.column.Header === 'Cliente' && cell.value)
                          }
                        >
                          {cell.column.Header === 'Cliente'
                            ? capitalize(cell.value)
                            : cell.render('Cell')}
                        </TdContainer>
                      );
                    })}
                    {actionElement && (
                      <ActionsHolder key="actions">
                        {actionElement}
                      </ActionsHolder>
                    )}
                  </BodyRow>
                );
              })}
            </tbody>
          ) : emptyText ? (
            <EmptyTable colspan={cols} text={emptyText} />
          ) : (
            <></>
          )}
        </TableComponent>
      </TableContentContainer>
      {withAdding && (
        <WithAddingHolder>
          <IconButton
            size={0.85}
            color={colors.primary}
            style={{ maxWidth: 100 }}
            onClick={withAdding}
          >
            <Plus color={colors.primary} /> ADD
          </IconButton>
        </WithAddingHolder>
      )}
      <FooterContainer>
        {totalItems !== 0 && totalItems !== undefined && (
          <TotalContainer>
            <PageIndex>
              <span>Total: {totalItems}</span>
            </PageIndex>
          </TotalContainer>
        )}

        {pages > 0 && (
          <Pagination>
            <RowPerPage>
              <span>Linhas por página:</span>
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 50, 100].map((pageSizeSelect) => (
                  <option key={pageSizeSelect} value={pageSizeSelect}>
                    {pageSizeSelect}
                  </option>
                ))}
              </select>
            </RowPerPage>
            <PageIndex>
              <span>
                {pageIndex + 1} de {pageOptions.length}
              </span>
            </PageIndex>
            <PaginationButtons>
              <button
                type="button"
                onClick={() => handlePrevious()}
                disabled={!canPreviousPage}
              >
                <ArrowLeftIcon
                  color={
                    canPreviousPage
                      ? colors.textOnBackground.active
                      : colors.textOnBackground.disable
                  }
                />
              </button>
              <button
                type="button"
                onClick={() => handleNext()}
                disabled={!canNextPage}
              >
                <ArrowRightIcon
                  color={
                    canNextPage
                      ? colors.textOnBackground.active
                      : colors.textOnBackground.disable
                  }
                />
              </button>
            </PaginationButtons>
          </Pagination>
        )}
      </FooterContainer>
    </TableContainer>
  );
}

Table.defaultProps = {
  actions: null,
  withAvatar: false,
  withAdding: false,
  searchTerm: '',
  filter: false,
  manualSort: false,
};
