import { gql } from '@apollo/client';

export const VERIFY_PO = gql`
  query ($code: String) {
    CNPJ(code: $code) {
      code
      isRegular
      isRegistered
      dateExpected
      registrationLink
    }
  }
`;

export const SEND_PO = gql`
  mutation ($cnpj: String!, $date: String!, $metadata: String!) {
    sendPO(cnpj: $cnpj, date: $date, metadata: $metadata)
  }
`;
