import { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { toCurrency, toCurrencyBrl } from 'utils/masks';

import { RateCardForm } from './components/RateCardForm';
import { DELETE_RATE_CARD, GET_RATE_CARDS } from './query';
import { ClientRateCardsContainer, TrashStyled } from './style';

export function ClientRateCards() {
  const { openDrawer } = useDrawer();
  const [searchTerm, setSearchTerm] = useState('');
  const [getRateCards, rateCardsResponse] = useLazyQuery(GET_RATE_CARDS, {
    notifyOnNetworkStatusChange: true,
  });
  const [deleteRateCard] = useMutation(DELETE_RATE_CARD);
  const rateCardsData = rateCardsResponse.data?.RateCardItems?.items || [];
  const pages = rateCardsResponse.data?.RateCardItems?.pages || 1;

  const handleAction = (id?: string) => {
    openDrawer({
      title: id ? 'Editar Rate Card' : 'Adicionar Rate Card',
      content: <RateCardForm id={id} refetch={rateCardsResponse.refetch} />,
      formId: 'ratecard-form',
      width: 600,
    });
  };

  const handleDelete = (id: string) => {
    deleteRateCard({ variables: { id } });

    if (rateCardsResponse.refetch) rateCardsResponse.refetch();
  };

  useEffect(() => {
    getRateCards();
  }, [getRateCards]);

  return (
    <ClientRateCardsContainer>
      <Toolbar title="" onSearch={setSearchTerm} onAction={handleAction} />
      <Table
        pages={pages}
        fetchData={getRateCards}
        searchTerm={searchTerm}
        columns={[
          { Header: 'Categoria', accessor: 'category' },
          { Header: 'Serviço', accessor: 'serviceName' },
          {
            Header: 'Custo',
            accessor: (row) => toCurrencyBrl(row.cost.toFixed(2)),
          },
          {
            Header: 'Valor de Venda',
            accessor: (row) => toCurrencyBrl(row.saleValue.toFixed(2)),
          },
          {
            Header: 'Valor do cliente',
            accessor: (row) => toCurrencyBrl(row.clientValue.toFixed(2)),
          },
          {
            Header: 'Lucratividade',
            accessor: (row) => `${toCurrency(row.profitability.toFixed(2))}%`,
          },
        ]}
        loading={rateCardsResponse.loading}
        emptyText="Não há registros para consulta"
        data={rateCardsData}
        actions={(rowData) => [
          {
            component: (
              <TrashStyled
                role="button"
                tabIndex={0}
                onClick={() => handleDelete(rowData._id)}
              >
                <TrashIcon variant="light" width={24} height={24} />
              </TrashStyled>
            ),
          },
          {
            component: (
              <TrashStyled
                role="button"
                tabIndex={0}
                onClick={() => handleAction(rowData._id)}
              >
                <GearIcon variant="light" width={20} height={20} />
              </TrashStyled>
            ),
          },
        ]}
      />
    </ClientRateCardsContainer>
  );
}
