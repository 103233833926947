import { colors } from 'styles/colors';

interface OptionsIconProps {
  variant: 'dark' | 'light' | 'special';
  width: number;
  height: number;
}

export function OptionsIcon({ variant, width, height }: OptionsIconProps) {
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
    special: colors.textOnBackground.special,
  };
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z"
        fill={variants[variant]}
      />
    </svg>
  );
}
