import styled, { css } from 'styled-components';

interface GridContainerProps {
  readOnly?: boolean;
}

export const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: 12fr 12fr 1fr;
  ${({ readOnly }) =>
    readOnly &&
    css`
      grid-template-columns: 12fr 12fr;
    `}
  gap: 1.5rem;
`;
