import { colors } from 'styles/colors';

interface ActionIconProps {
  color?: string;
}

export function DeadIcon({ color }: ActionIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66675 9.99935C1.66675 5.39102 5.39175 1.66602 9.99175 1.66602C14.6001 1.66602 18.3334 5.39102 18.3334 9.99935C18.3334 14.6077 14.5917 18.3327 9.99175 18.3327C5.39175 18.3327 1.66675 14.6077 1.66675 9.99935ZM6.51679 9.99935L7.40012 9.11601L8.28345 9.99935L9.16679 9.11601L8.28345 8.23268L9.16679 7.34935L8.28345 6.46601L7.40012 7.34935L6.51679 6.46601L5.63345 7.34935L6.51679 8.23268L5.63345 9.11601L6.51679 9.99935ZM10.0001 11.2493C8.05839 11.2493 6.40839 12.466 5.74172 14.166H14.2584C13.5917 12.466 11.9417 11.2493 10.0001 11.2493ZM10.0001 16.666C6.31675 16.666 3.33341 13.6827 3.33341 9.99935C3.33341 6.31602 6.31675 3.33268 10.0001 3.33268C13.6834 3.33268 16.6667 6.31602 16.6667 9.99935C16.6667 13.6827 13.6834 16.666 10.0001 16.666ZM12.6001 7.34935L13.4834 6.46601L14.3667 7.34935L13.4834 8.23268L14.3667 9.11601L13.4834 9.99935L12.6001 9.11601L11.7167 9.99935L10.8334 9.11601L11.7167 8.23268L10.8334 7.34935L11.7167 6.46601L12.6001 7.34935Z"
        fill={color}
      />
    </svg>
  );
}

DeadIcon.defaultProps = {
  color: colors.textOnBackground.medium,
};
