import { colors } from 'styles/colors';

interface FilterIconProps {
  color?: string;
}

export function FilterIcon({ color }: FilterIconProps) {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V2H18V0H0ZM7 12H11V10H7V12ZM15 7H3V5H15V7Z"
        fill={color}
        fillOpacity="0.87"
      />
    </svg>
  );
}

FilterIcon.defaultProps = {
  color: colors.primary,
};
