import { gql } from '@apollo/client';

export const COMPANY_LIST = gql`
  query ($limit: Int, $page: Int, $search_term: String) {
    CompanyItems(limit: $limit, page: $page, search_term: $search_term) {
      items {
        _id
        name
        is_disabled
      }
      pages
    }
  }
`;
