import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

interface SelectedTabProps {
  isSelected: boolean;
  pointerCursor?: boolean | undefined;
}

export const SidebarContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-color: ${colors.backgroundLight};
`;

export const CompanyText = styled(motion.p)`
  padding-left: 1.5rem;
  font-size: 0.625rem;
  line-height: 1rem;
  letter-spacing: 0.09375rem;
  text-transform: uppercase;
  color: ${colors.textOnBackground.active};
  margin: 1rem 0;
`;

export const PageText = styled(motion.p)<SelectedTabProps>`
  padding-left: 1rem;
  font-size: 0.875rem;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: ${colors.textOnBackground.active};
  white-space: nowrap;
  ${(props) => props.isSelected && `color: ${colors.textOnBackground.special};`}
`;

export const SelectedTab = styled(motion.div)<SelectedTabProps>`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1.5rem;
  width: 100%;
  height: 3.25rem;
  background: ${colors.backgroundLight};
  overflow: hidden;

  &:hover {
    background: ${colors.selectedSidebar};
  }
  ${(props) =>
    props.isSelected &&
    `background: rgba(0, 0, 0, 0.12);
    box-shadow: inset -4px 0px 0px #5A52FF;

    /* Inside Auto Layout */

    flex: none;
    order: 3;
    flex-grow: 0;
    margin: 0px 0px;`}
  ${(props) => props.pointerCursor && `cursor: pointer`}
`;

export const SelectedTabChildren = styled(motion.div)<SelectedTabProps>`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1.5rem;
  padding-left: 3rem;
  width: 100%;
  height: 3.25rem;
  background: ${colors.backgroundLight};
  overflow: hidden;

  &:hover {
    background: ${colors.selectedSidebar};
  }
  ${(props) =>
    props.isSelected &&
    `background: rgba(0, 0, 0, 0.12);
    box-shadow: inset -4px 0px 0px #5A52FF;

    /* Inside Auto Layout */

    flex: none;
    order: 3;
    flex-grow: 0;
    margin: 0px 0px;`}
  ${(props) => props.pointerCursor && `cursor: pointer`}
`;

export const TabIconDivStyle = styled.div`
  margin-top: 0.5rem;
`;

interface ArrowStyleContainerProps {
  direction: 'up' | 'down';
  variant: 'light' | 'special';
}

export const ArrowStyleContainer = styled.span<ArrowStyleContainerProps>`
  ${({ direction, variant }) => css`
  margin-left: auto;
  padding-right:29px
  width: 0;
  height: 0;
  padding: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-${direction === 'up' ? 'top' : 'bottom'}: 5px solid 
  ${
    variant === 'light'
      ? colors.textOnBackground.active
      : colors.textOnBackground.special
  };
  `}
`;
