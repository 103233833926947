import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const ModalContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  bottom: 0;

  height: 100vh;
  width: 100%;

  z-index: 90;
`;

export const ModalLayer = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.2);

  z-index: 1;
`;

interface ModalContentProps {
  width?: number;
}

export const ModalContent = styled(motion.div)<ModalContentProps>`
  ${({ width }) => css`
    display: flex;
    flex-direction: column;

    width: ${width}px;
    border-radius: 10px;
    padding: 16px 16px 32px;

    height: auto;
    max-height: 90vh;
    min-height: 250px;

    background-color: ${colors.backgroundLight};
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
      0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);

    z-index: 2;
    position: relative;
  `}
`;
