import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const ClientAnnotationsContainer = styled.div``;

export const SeeMoreText = styled.p`
  /* BUTTON */
  white-space: nowrap;
  width: 4.375rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  /* Dark Theme/Primary */

  color: ${colors.textOnBackground.special};
`;
export const SeeMoreDiv = styled.div`
  display: flex;
  cursor: pointer;
`;

export const TrashStyled = styled.div`
  cursor: pointer;
`;

export const AnnotationContainer = styled(motion.div)`
  /* Auto Layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;

  /* Dark Theme/Background-Light */

  background: #202020;
  border-radius: 8px;
  margin-bottom: 1rem;
`;
export const AnnotationHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const AnnotationHeaderText = styled.p`
  /* Subtitle 1 */
  margin-left: 0.5rem;
  margin-top: 0.35rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.15px;

  /* Dark Theme/Text on Background/Active */

  color: ${colors.textOnBackground.active};
`;

export const AnnotationTimestampText = styled.p`
  padding-top: 0.5rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 1rem;
  /* identical to box height, or 160% */

  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  /* Dark Theme/Text on Background/Active */

  color: #e1e1e1;
`;

export const AnotationUserContainer = styled.div`
  display: flex;
`;

export const AnnotationTextContainer = styled.p`
  margin-left: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: 0.25px;
  width: 100%;

  /* Dark Theme/Text on Background/Active */

  color: #e1e1e1;

  /* Inside Auto Layout */

  margin: 24px 0px;
`;

export const ActionsContainer = styled.div`
  cursor: pointer;
  margin: 0.5rem;
  display: flex;
`;

export const AnnotationsFooterContainer = styled.div`
  display: flex;
  margin-top: 1.875rem;
  width: 100%;
  justify-content: right;
`;
interface ActionTextContainerProps {
  color: 'light' | 'special';
}
export const ActionTextContainer = styled.p<ActionTextContainerProps>`
  /* BUTTON */
  padding-top: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  /* Dark Theme/Text on Background/Active */

  color: ${({ color }) => {
    return color === 'light'
      ? colors.textOnBackground.active
      : colors.textOnBackground.special;
  }};

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
`;
