import styled from 'styled-components';

export const RevenueLinkContainer = styled.div`
  position: relative;
`;

export const IconContainer = styled.div`
  position: absolute;
  background: transparent;
  border: none;
  top: 30px;
  right: 15px;
`;
