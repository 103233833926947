/* eslint-disable react/no-array-index-key */
import { useFormContext, useWatch } from 'react-hook-form';

import { AutocompleteSelect } from 'components/atoms/AutocompleteSelect';
import { Plus } from 'components/atoms/Icons/Plus';
import { RoundCheckBoxIcon } from 'components/atoms/Icons/RoundCheckboxIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { SelectField } from 'components/atoms/SelectField';
import { TextField } from 'components/molecules/TextField';
import { useHelperData } from 'contexts/HelperDataContext';
import { StyledRowDiv } from 'pages/Clients/components/ClientForm/style';
import { colors } from 'styles/colors';
import { masks } from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { AddButton, StyledDiv, StyledSubtitle } from '../CompanyForm/style';
import {
  CompanyGridContainer,
  FlexContainer,
  RadioButtonContainer,
  UnstyledButton,
} from './style';

interface CompanyBankAccountsProps {
  readOnly?: boolean;
}

export function CompanyBankAccounts({ readOnly }: CompanyBankAccountsProps) {
  const { setValue } = useFormContext();
  const { searchBanks } = useHelperData();

  const groupName = 'bankAccounts';
  const fields = useWatch({ name: groupName }) || [{}];

  const replaceItems = (newItems) => {
    setValue(groupName, newItems);
  };

  const addItem = () => {
    const tmpFields = [...fields];
    tmpFields.push({});
    replaceItems(tmpFields);
  };

  const handleDelete = (index) => {
    const tmpFields = [...fields];
    tmpFields.splice(index, 1);
    replaceItems(tmpFields);
  };

  const isPrincipalAccount = (index: number) => {
    return fields.findIndex((field) => field.isPrincipal) === index;
  };

  const handlePrincipalClicked = (index: number) => {
    const tmpFields = [...fields];
    tmpFields.forEach((field, i) => {
      field.isPrincipal = index === i;
    });
    replaceItems(tmpFields);
  };

  return (
    <>
      <StyledDiv>
        <StyledSubtitle>Contas Bancárias</StyledSubtitle>
        {!readOnly && (
          <StyledRowDiv style={{ justifyContent: 'flex-end' }}>
            <AddButton type="button" onClick={addItem}>
              <Plus color={colors.primary} />
              ADD
            </AddButton>
          </StyledRowDiv>
        )}
      </StyledDiv>
      {fields?.map((item, index) => (
        <CompanyGridContainer key={index} readOnly={readOnly}>
          <RadioButtonContainer
            onClick={
              readOnly ? () => null : () => handlePrincipalClicked(index)
            }
          >
            <RoundCheckBoxIcon checked={isPrincipalAccount(index)} />
          </RadioButtonContainer>
          <AutocompleteSelect
            name={`${groupName}.${index}.name`}
            label="Banco"
            placeholder="Pesquisar Banco"
            loadOptions={searchBanks}
            validation={validator('required')}
            readOnly={readOnly}
          />
          <TextField
            name={`${groupName}.${index}.agencyNumber`}
            label="Agência"
            pattern={masks('agency')}
            validation={validator('required')}
            readOnly={readOnly}
          />
          <TextField
            name={`${groupName}.${index}.accountNumber`}
            label="Conta Corrente"
            pattern={masks('account')}
            validation={validator('required')}
            readOnly={readOnly}
          />
          <SelectField
            name={`${groupName}.${index}.accountType`}
            label="Tipo"
            placeholder="Tipo"
            options={[
              { value: 'PJ', label: 'PJ' },
              { value: 'PF', label: 'PF' },
            ]}
            validation={validator('required')}
            readOnly={readOnly}
          />
          {!readOnly && (
            <FlexContainer>
              <UnstyledButton type="button" onClick={() => handleDelete(index)}>
                <TrashIcon variant="light" width={20} height={20} />
              </UnstyledButton>
            </FlexContainer>
          )}
        </CompanyGridContainer>
      ))}
    </>
  );
}
