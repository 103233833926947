import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { SelectField } from 'components/atoms/SelectField';
import { Spinner } from 'components/atoms/Spinner';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { COMPANY_TAX } from 'pages/Budgets/components/Structure/components/StructureGroup/query';
import {
  CREATE_INTERNAL_PRODUCTION,
  GET_INTERNALPRODUCTION_BY_ID,
  UPDATE_INTERNAL_PRODUCTION,
} from 'pages/InternalProduction/query';
import { DefaultForm, GridColumn } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';
import { clearCurrencyNumberBrl, toCurrency, toCurrencyBrl } from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

interface InternalProductionFormProps {
  id?: string | boolean;
  refetch: any;
}

export function InternalProductionForm(props: InternalProductionFormProps) {
  const { currentCompanyId } = useWorkspace();
  const { id, refetch } = props;
  const isEditing = !!id;
  const methods = useForm();

  const [getInternalProductionById, getInternalProductionByIdRes] =
    useLazyQuery(GET_INTERNALPRODUCTION_BY_ID);
  const internalproductionItem =
    getInternalProductionByIdRes?.data?.ConfigurationItems?.items[0];
  const [updateInternalProduction, updateInternalProductionRes] = useMutation(
    UPDATE_INTERNAL_PRODUCTION,
  );
  const [createInternalProduction, createInternalProductionRes] = useMutation(
    CREATE_INTERNAL_PRODUCTION,
  );
  const { closeDrawer } = useDrawer();

  const formValues = methods.watch();

  const [totalValue, setTotalValue] = useState<number>();

  useEffect(() => {
    if (!internalproductionItem && isEditing)
      getInternalProductionById({ variables: { id } });

    if (internalproductionItem && isEditing) {
      const decodedValues = JSON.parse(internalproductionItem.metadata);
      Object.entries(decodedValues).forEach(([key, value]: any) => {
        if (key !== '__typename') {
          methods.setValue(key, value);
        }
        if (key === 'margin') {
          methods.setValue(key, toCurrency(Number(value).toFixed(2)));
        }
        if (key === 'overhead') {
          methods.setValue(key, toCurrency(Number(value).toFixed(2)));
        }
        if (key === 'revenue') {
          methods.setValue(key, toCurrencyBrl(Number(value).toFixed(2)));
        }
        if (key === 'monthHours') {
          methods.setValue(key, toCurrency(Number(value).toFixed(2)));
        }
        if (key === 'taxes') {
          methods.setValue(key, toCurrency(Number(value).toFixed(2)));
        }
        if (key === 'saleValue') {
          methods.setValue(key, toCurrencyBrl(Number(value).toFixed(2)));
        }
      });
    }
  }, [
    internalproductionItem,
    methods,
    isEditing,
    getInternalProductionById,
    id,
  ]);

  useEffect(() => {
    if (formValues?.revenue && formValues?.monthHours) {
      const revenue = clearCurrencyNumberBrl(formValues.revenue);
      const contract = Number(methods.getValues('wayOfContract'));
      const hours = 160 / clearCurrencyNumberBrl(formValues.monthHours);

      if (contract === 3) {
        setTotalValue(revenue * hours * 1.58 * 12);
      } else if (contract === 2) {
        setTotalValue(revenue * hours * 1.15 * 12);
      }
      if (contract === 1) {
        if (revenue < 1900) {
          setTotalValue(revenue * hours * 2.08 * 12);
        }
        if (revenue > 1900) {
          setTotalValue(revenue * hours * 1.87 * 12);
        }
        if (revenue > 2700) {
          setTotalValue(revenue * hours * 1.75 * 12);
        }
        if (revenue > 3800) {
          setTotalValue(revenue * hours * 1.65 * 12);
        }
        if (revenue > 8400) {
          setTotalValue(revenue * hours * 1.58 * 12);
        }
      }
    }
  }, [
    formValues?.revenue,
    formValues?.monthHours,
    methods.getValues('wayOfContract'),
  ]);

  const [getCompanyById, getCompanyByIdRes] = useLazyQuery(COMPANY_TAX);
  const companyTax = getCompanyByIdRes?.data?.CompanyItems?.items[0]?.directTax;

  useEffect(() => {
    getCompanyById({ variables: { companyId: currentCompanyId } });
  }, []);

  useEffect(() => {
    methods.setValue('taxes', clearCurrencyNumberBrl(companyTax));
  }, [companyTax]);

  useEffect(() => {
    const overhead = clearCurrencyNumberBrl(formValues.overhead);
    const margin = clearCurrencyNumberBrl(formValues.margin);
    const taxes = clearCurrencyNumberBrl(companyTax);

    const totalWithOverhead =
      (totalValue as number) * (overhead / 100) + (totalValue as number);
    const yearTotal = totalWithOverhead / (1 - margin / 100 - taxes / 100);

    const hourTotal = Number(yearTotal / 12 / 160).toFixed(0);

    methods.setValue('saleValue', toCurrencyBrl(Number(hourTotal).toFixed(2)));
  }, [
    totalValue,
    companyTax,
    formValues?.margin,
    formValues?.overhead,
    formValues?.revenue,
  ]);

  const onSubmit = (dataForm) => {
    dataForm.name = capitalize(dataForm.name);
    dataForm.category = capitalize(dataForm.category);
    dataForm.margin = clearCurrencyNumberBrl(dataForm.margin);
    dataForm.overhead = clearCurrencyNumberBrl(dataForm.overhead);
    dataForm.revenue = clearCurrencyNumberBrl(dataForm.revenue);
    dataForm.monthHours = clearCurrencyNumberBrl(dataForm.monthHours);
    dataForm.taxes = clearCurrencyNumberBrl(dataForm.taxes);
    dataForm.saleValue = clearCurrencyNumberBrl(dataForm.saleValue);

    if (isEditing) {
      updateInternalProduction({
        variables: {
          id,
          label: dataForm.name,
          type: 'production',
          metadata: JSON.stringify(dataForm),
          unit: currentCompanyId,
        },
      });
    } else {
      createInternalProduction({
        variables: {
          label: dataForm.name,
          type: 'production',
          metadata: JSON.stringify(dataForm),
          unit: currentCompanyId,
        },
      });
    }
  };

  useEffect(() => {
    if (createInternalProductionRes.data) {
      toast.success('Cadastro efetuado!');

      refetch();

      closeDrawer();
    }

    if (createInternalProductionRes.error) {
      toast.error('Ops, falha ao realizar o cadastro.');
    }
  }, [createInternalProductionRes.data, createInternalProductionRes.error]);

  useEffect(() => {
    if (updateInternalProductionRes.data) {
      toast.success('Cadastro atualizado!');

      refetch();

      closeDrawer();
    }

    if (updateInternalProductionRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
  }, [updateInternalProductionRes.data, updateInternalProductionRes.error]);

  if (
    getInternalProductionByIdRes.loading ||
    createInternalProductionRes.loading ||
    updateInternalProductionRes.loading
  )
    return <Spinner />;

  return (
    <FormProvider {...methods}>
      <DefaultForm
        onSubmit={methods.handleSubmit(onSubmit)}
        id="internalproduction-form"
      >
        <TextField
          name="name"
          label="Nome"
          validation={validator('required')}
          pattern="capitalize"
        />
        <GridColumn length={2} gap={1} fragment>
          <TextField
            name="category"
            label="Categoria"
            validation={validator('required')}
            pattern="capitalize"
          />
          <SelectField
            name="wayOfContract"
            label="Forma de contratação"
            placeholder="Forma de contratação"
            validation={validator('required')}
            options={[
              { value: '1', label: 'CLT' },
              { value: '2', label: 'PJ' },
              { value: '3', label: 'Estágio' },
            ]}
          />
          <TextField
            name="revenue"
            label="Salário"
            validation={validator('required')}
            pattern="moneyPrefix"
          />
          <TextField
            name="taxes"
            label="Impostos"
            validation={validator('required')}
            pattern="money"
            suffix="%"
            readOnly
          />
          <TextField
            name="margin"
            label="Margem"
            validation={validator('required')}
            pattern="money"
            suffix="%"
          />
          <TextField
            name="overhead"
            label="Overhead"
            validation={validator('required')}
            pattern="money"
            suffix="%"
          />
          <TextField
            name="monthHours"
            label="Horas Mensais"
            validation={validator('required')}
            pattern="money"
          />
          <TextField
            name="saleValue"
            label="Valor Hora"
            validation={validator('required')}
            pattern="moneyPrefix"
            readOnly
          />
        </GridColumn>
      </DefaultForm>
    </FormProvider>
  );
}

InternalProductionForm.defaultProps = {
  id: false,
};
