import { gql } from '@apollo/client';

export const CREATE_BUDGET = gql`
  mutation createBudget(
    $clientId: String!
    $area: String
    $campaign: String
    $task: String
    $unit: String!
    $budInvoices: [InvoiceInput]
    $budGroups: [CostGroupInput]
    $budComissions: [ComissionInput]
    $budDeductions: [DeductionInput]
    $revenue: String
  ) {
    createBudget(
      clientId: $clientId
      area: $area
      campaign: $campaign
      task: $task
      unit: $unit
      budInvoices: $budInvoices
      budGroups: $budGroups
      budComissions: $budComissions
      budDeductions: $budDeductions
      revenue: $revenue
    ) {
      _id
    }
  }
`;

export const UPDATE_BUDGET = gql`
  mutation budgetSetup(
    $id: String!
    $clientId: String!
    $area: String
    $campaign: String
    $task: String
    $revenue: String
    $paymentMethod: String
    $unit: String!
    $budInvoices: [InvoiceInput]
    $budGroups: [CostGroupInput]
    $budComissions: [ComissionInput]
    $budDeductions: [DeductionInput]
    $metadata: String
    $expectation: Int
  ) {
    budgetSetup(
      id: $id
      clientId: $clientId
      area: $area
      campaign: $campaign
      task: $task
      revenue: $revenue
      paymentMethod: $paymentMethod
      unit: $unit
      budInvoices: $budInvoices
      budGroups: $budGroups
      budComissions: $budComissions
      budDeductions: $budDeductions
      metadata: $metadata
      expectation: $expectation
    )
  }
`;

export const GET_BUDGET_BY_ID = gql`
  query ($id: String!) {
    BudgetItems(limit: 1, page: 1, id: $id, is_approved: false) {
      items {
        _id
        area
        campaign
        APcode
        APcodeExtended
        task
        revenue
        paymentMethod
        unit
        expectation
        metadata
        client {
          _id
          name
          clientPrefix
          legals {
            message
            metadata
          }
        }
        budInvoices {
          value
          currencyName
          isParadise
          hedgePercentage
          currencyQuotation
        }
        budGroups {
          name
          metadata
        }
        budComissions {
          item
          percentage
          responsible
          type
        }
        budDeductions {
          description
          value
        }
        APcode
      }
    }
  }
`;
