import { colors } from 'styles/colors';

interface CalendarIconProps {
  color?: string;
}

export function CalendarIcon({ color }: CalendarIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H20C21.1 3 22 3.9 22 5V21C22 22.1 21.1 23 20 23H4C2.9 23 2 22.1 2 21V5C2 3.9 2.9 3 4 3H5V1H7V3H17V1H19V3ZM4 21H20V8H4V21Z"
        fill={color}
      />
    </svg>
  );
}

CalendarIcon.defaultProps = {
  color: colors.primary,
};
