import { colors } from 'styles/colors';

interface PrinterIconProps {
  color?: string;
}

export function PrinterIcon({ color }: PrinterIconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00033 4H24.0003V9.33333H8.00033V4ZM6.66699 10.6667H25.3337C27.547 10.6667 29.3337 12.4533 29.3337 14.6667V22.6667H24.0003V28H8.00033V22.6667H2.66699V14.6667C2.66699 12.4533 4.45366 10.6667 6.66699 10.6667ZM10.667 25.3333H21.3337V18.6667H10.667V25.3333ZM25.3337 16C24.6003 16 24.0003 15.4 24.0003 14.6667C24.0003 13.9333 24.6003 13.3333 25.3337 13.3333C26.067 13.3333 26.667 13.9333 26.667 14.6667C26.667 15.4 26.067 16 25.3337 16Z"
        fill={color}
      />
    </svg>
  );
}

PrinterIcon.defaultProps = {
  color: colors.primary,
};
