/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useLazyQuery } from '@apollo/client';
import { AutocompleteSelect } from 'components/atoms/AutocompleteSelect';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { InputFieldWithSelect } from 'components/atoms/InputFieldWithSelect';
import { SelectField } from 'components/atoms/SelectField';
import { TextField } from 'components/molecules/TextField';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { useBacenConverter } from 'hooks/useBacenConverter';
import { FieldContainer } from 'pages/Budgets/components/NewBudget/style';
import { FlexContainer, IconButton } from 'styles/miscellany';
import {
  clearCurrencyStr,
  toCurrencyBrl,
  clearCurrencyNumberBrl,
} from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { FormGroupWithAdding } from '../../../../../../../components/organisms/FormGroupWithAdding';
import { GET_INTERNAL_PRODUCTIONS_LIST } from '../query';
import { FISCAL_PARADISE_ITEMS } from './query';

interface Props {
  groupName: string;
}

export function Invoice(props: Props) {
  const { groupName } = props;
  const { currentCompanyId } = useWorkspace();

  const [getInvoice] = useLazyQuery(GET_INTERNAL_PRODUCTIONS_LIST, {
    notifyOnNetworkStatusChange: true,
  });
  const [getFiscalParadiseItems, getFiscalParadiseItemsRes] = useLazyQuery(
    FISCAL_PARADISE_ITEMS,
  );

  const fiscalParadiseItems =
    getFiscalParadiseItemsRes?.data?.FiscalParadiseItems?.items || [];

  const [fiscalParadiseOption, setFiscalParadiseOption] = useState<any>([{}]);

  useEffect(() => {
    if (fiscalParadiseItems) {
      const tmpFiscalParadiseItems = fiscalParadiseItems.map((i) => {
        return { value: i.country, label: i.country };
      });

      setFiscalParadiseOption(tmpFiscalParadiseItems);
    }
  }, [getFiscalParadiseItemsRes]);

  const loadOptions = async (text: string) => {
    const tmpText = await fiscalParadiseOption;

    const newText = tmpText.map((i) => i.label);

    return newText.filter((i) => i.includes(text));
  };

  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    getInvoice({
      variables: { type: 'invoice', unit: currentCompanyId },
    });
  }, [getInvoice, currentCompanyId]);

  useEffect(() => {
    getFiscalParadiseItems();
  }, []);

  const [getOutsource, getOutsourceRes] = useLazyQuery(
    GET_INTERNAL_PRODUCTIONS_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const outsource =
    getOutsourceRes.data?.ConfigurationItems?.items?.map((item) => {
      return { ...JSON.parse(item.metadata), _id: item._id };
    }) || [];
  const outsourceMap: [{ value: ''; label: '' }] = [{ value: '', label: '' }];
  outsource.map((item) => {
    if (item.accountPlan.includes('1.3.3')) {
      outsourceMap.push({ value: item.role, label: item.role });
    }
  });
  outsourceMap.splice(0, 1);

  useEffect(() => {
    getOutsource({
      variables: { type: 'outsource', unit: currentCompanyId },
    });
  }, [getOutsource, currentCompanyId]);

  const updateTotal = (index) => {
    const value = clearCurrencyNumberBrl(
      getValues(`${groupName}.invoice.${index}.value`),
    );
    const hedge = clearCurrencyStr(
      getValues(`${groupName}.invoice.${index}.hedgePercentage`),
    );
    const quotation = clearCurrencyNumberBrl(
      getValues(`${groupName}.invoice.${index}.currencyQuotation`),
    );
    const totalValue = Number(
      (value + (hedge / 100) * value) * quotation,
    ).toFixed(2);

    setValue(
      `${groupName}.invoice.${index}.currencyConversion`,
      toCurrencyBrl(Number(value * quotation).toFixed(2)),
    );
    setValue(`${groupName}.invoice.${index}.total`, toCurrencyBrl(totalValue));
  };

  const UpdateCurrency = async (current, value, index) => {
    const convertedValue = await useBacenConverter(current, value);
    if (convertedValue?.value) {
      setValue(
        `${groupName}.invoice.${index}.currencyQuotation`,
        toCurrencyBrl(Number(convertedValue.value / value).toFixed(2)),
      );
    }
  };

  const updateConversionValue = (currency, index) => {
    const value = clearCurrencyNumberBrl(
      getValues(`${groupName}.invoice.${index}.value`),
    );
    const actualCurrency = getValues(`${groupName}.invoice.${index}.currency`);
    if (value && actualCurrency) {
      UpdateCurrency(currency.value || actualCurrency, value, index);
      updateTotal(index);
    }
  };

  return (
    <div>
      <FormGroupWithAdding
        title="Invoice"
        groupName={`${groupName}.invoice`}
        renderRow={({ index, onDelete }) => (
          <FieldContainer>
            <FlexContainer value={2}>
              <SelectField
                name={`${groupName}.invoice.${index}.item`}
                label="Item"
                placeholder="Item"
                options={outsourceMap}
                validation={validator('required')}
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <InputFieldWithSelect
                inputName={`${groupName}.invoice.${index}.value`}
                selectName={`${groupName}.invoice.${index}.currency`}
                label="Valor"
                options={[
                  { value: 'USD', label: 'USD' },
                  { value: 'EUR', label: 'EUR' },
                  { value: 'GBP', label: 'GBP' },
                  { value: 'ARS', label: 'ARS' },
                  { value: 'CLP', label: 'CLP' },
                  { value: 'COP', label: 'COP' },
                  { value: 'MXN', label: 'MXN' },
                  { value: 'UYU', label: 'UYU' },
                ]}
                defaultValue={
                  getValues(`${groupName}.invoice.${index}.currency`)
                    ? {
                        value: `${getValues(
                          `${groupName}.invoice.${index}.currency`,
                        )}`,
                        label: `${getValues(
                          `${groupName}.invoice.${index}.currency`,
                        )}`,
                      }
                    : {
                        value: 'USD',
                        label: 'USD',
                      }
                }
                menuPlacement="top"
                validation={validator('required')}
                actionTrigger={(data) => {
                  updateConversionValue(data, index);
                }}
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <AutocompleteSelect
                name={`${groupName}.invoice.${index}.isParadise`}
                label="País de Origem"
                placeholder="País de Origem"
                loadOptions={loadOptions}
                validation={validator('required')}
              />
            </FlexContainer>
            <FlexContainer value={1}>
              <TextField
                name={`${groupName}.invoice.${index}.hedgePercentage`}
                label="Hedge"
                suffix="%"
                defaultValue={1}
                onKeyUp={() => {
                  updateTotal(index);
                }}
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.invoice.${index}.currencyQuotation`}
                label="Cotação"
                pattern="quotaMask"
                defaultValue={1}
                readOnly
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.invoice.${index}.total`}
                label="R$ Custo total"
                defaultValue={0}
                readOnly
              />
            </FlexContainer>
            <IconButton
              type="button"
              tabIndex={0}
              onClick={() => onDelete(index)}
            >
              <TrashIcon variant="light" width={20} height={20} />
            </IconButton>
          </FieldContainer>
        )}
      />
    </div>
  );
}
