import styled from 'styled-components';
import { colors } from 'styles/colors';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1.875rem 0;
`;

export const Title = styled.p`
  color: ${colors.white};
  font-weight: bold;

  span {
    color: ${colors.primary};
  }
`;

export const AddButton = styled.button`
  color: ${colors.primary};
  background: none;
  border: none;
  display: flex;
  align-items: center;
`;
