import { Field, FieldProps } from 'components/atoms/Field';
import { Search } from 'components/atoms/Icons/Search';

import { ButtonSearch, SearchFieldStyled } from './style';

type SearchFieldProps = {
  label?: string;
  iconPosition?: 'left' | 'right';
  isSubmit?: boolean;
} & FieldProps;

export function SearchField(props: SearchFieldProps) {
  const { name, label, isSubmit, iconPosition, pattern, ...rest } = props;

  return (
    <SearchFieldStyled iconPosition={iconPosition}>
      <ButtonSearch
        type={isSubmit ? 'submit' : 'button'}
        iconPosition={iconPosition}
      >
        <Search />
      </ButtonSearch>
      <Field
        name={name}
        placeholder={label || 'Search'}
        pattern={pattern}
        {...rest}
      />
    </SearchFieldStyled>
  );
}

SearchField.defaultProps = {
  label: null,
  iconPosition: 'left',
  isSubmit: true,
};
