import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useHistory, useParams } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'components/atoms/Button';
import { SelectField } from 'components/atoms/SelectField';
import { TextField } from 'components/molecules/TextField';
import { useSidebar } from 'contexts/SidebarContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { CLIENT_LIST } from 'pages/Clients/query';
import { DefaultForm, FlexContainer, Line } from 'styles/miscellany';
import { validator } from 'utils/validators/form-validations';

import { NewBudgetTab } from './components/NewBudgetTab';
import { NewBudgetToolbar } from './components/NewBudgetToolbar';
import { CREATE_BUDGET, GET_BUDGET_BY_ID, UPDATE_BUDGET } from './query';
import {
  ButtonsContainer,
  FieldsContainer,
  FieldContainer,
  NewBudgetContainer,
  FooterContainer,
  FixedContainer,
} from './style';

export function NewBudget() {
  const { currentCompanyId } = useWorkspace();

  const [getClients, getClientsRes] = useLazyQuery(CLIENT_LIST, {
    notifyOnNetworkStatusChange: true,
  });
  const [getBudget, getBudgetRes] = useLazyQuery(GET_BUDGET_BY_ID, {
    notifyOnNetworkStatusChange: true,
  });
  const [createBudget, createBudgetRes] = useMutation(CREATE_BUDGET);
  const [updateBudget, updateBudgetRes] = useMutation(UPDATE_BUDGET);

  const methods = useForm();
  const history = useHistory();
  const { id } = useParams<any>();
  const { isSidebarOpen } = useSidebar();

  const isEditing = !!id;
  const budgetItem = getBudgetRes?.data?.BudgetItems?.items[0];

  useEffect(() => {
    if (isEditing) getBudget({ variables: { id } });
  }, [isEditing, id, getBudget]);

  useEffect(() => {
    if (budgetItem) {
      const initData = {
        clientId: budgetItem.client?._id,
        area: budgetItem.area,
        campaign: budgetItem.campaign,
        task: budgetItem.task,
      };

      Object.entries(initData).forEach(([key, value]) => {
        methods.setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      });
    }
  }, [budgetItem, methods]);

  useEffect(() => {
    getClients({ variables: { unit: currentCompanyId } });
  }, [currentCompanyId, getClients]);

  const clients = getClientsRes.data?.ClientItems?.items;
  const clientsMap = clients?.map((client) => ({
    value: client._id,
    label: client.name,
  }));

  const onSubmit = (data) => {
    if (isEditing) {
      updateBudget({ variables: { ...data, id, unit: currentCompanyId } });
    } else {
      createBudget({ variables: { ...data, unit: currentCompanyId } });
    }
  };

  useEffect(() => {
    if (createBudgetRes.data) {
      history.push(
        `/budgets/${createBudgetRes.data?.createBudget?._id}/structure`,
      );
    }
    if (createBudgetRes.error) {
      toast.error('Ops, falha ao realizar o cadastro.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBudgetRes.data, createBudgetRes.error]);

  useEffect(() => {
    if (updateBudgetRes.data) {
      history.push(`/budgets/${id}/structure`);
    }
    if (updateBudgetRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBudgetRes.data, updateBudgetRes.error]);

  return (
    <NewBudgetContainer>
      <NewBudgetToolbar
        title={budgetItem?.campaign || 'Novo Orçamento'}
        id={id}
      />
      <NewBudgetTab id={0} />
      <Line style={{ margin: '1.5rem 0 2rem 0' }} />
      <FieldsContainer>
        <FormProvider {...methods}>
          <DefaultForm
            id="budgetForm"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <FieldContainer>
              <FlexContainer>
                <SelectField
                  name="clientId"
                  label="Cliente"
                  placeholder="Cliente"
                  options={clientsMap}
                  validation={validator('required')}
                />
              </FlexContainer>
              <FlexContainer>
                <TextField name="area" label="Área/produto" />
              </FlexContainer>
            </FieldContainer>
            <FieldContainer>
              <FlexContainer>
                <TextField name="campaign" label="Campanha/Projeto" />
              </FlexContainer>
              <FlexContainer>
                <TextField name="task" label="Task" />
              </FlexContainer>
            </FieldContainer>
          </DefaultForm>
        </FormProvider>
      </FieldsContainer>

      <FixedContainer
        animate={{
          position: 'fixed',
          left: isSidebarOpen ? 304 : 128,
        }}
        transition={{
          type: 'linear',
          duration: 0.6,
        }}
        isSidebarOpen={isSidebarOpen}
      >
        <FooterContainer>
          <ButtonsContainer>
            <div>
              <Link to="/budgets">
                <Button variant="secondary">CANCELAR</Button>
              </Link>
            </div>
            <div>
              <Button variant="tertiary" form="budgetForm">
                AVANÇAR
              </Button>
            </div>
          </ButtonsContainer>
        </FooterContainer>
      </FixedContainer>
    </NewBudgetContainer>
  );
}
