import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useMutation } from '@apollo/client';
import { CheckBoxIcon } from 'components/atoms/Icons/CheckboxIcon';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import {
  StyledPermissionSubtitle,
  StyledCheckbox,
  StyledRoleText,
  StyledRowDiv,
  StyledGridDiv,
} from 'pages/Notifications/components/NotificationForm/style';
import {
  ADD_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from 'pages/Notifications/query';
import { DefaultForm } from 'styles/miscellany';
import { validator } from 'utils/validators/form-validations';

export interface NotificationI {
  _id: string;
  email: string;
  scope: string[];
}
interface NotificationFormProps {
  notification?: NotificationI;
  refetch: any;
}

export function NotificationForm(props: NotificationFormProps) {
  const methods = useForm();
  const { notification, refetch } = props;
  const isEditing = !!notification?._id;
  const possiblePermissions = [
    'Admin',
    'Legal',
    'Contábil',
    'Relatórios',
    'Clientes',
    'Projetos',
    'Contas a pagar',
    'Contas a receber',
  ];
  const { closeDrawer, setIsLoading } = useDrawer();
  const [values, setValues] = useState(
    possiblePermissions.map((permission) => {
      return {
        permission,
        enabled: !!notification?.scope?.find((scope) => scope === permission),
      };
    }),
  );
  const [addNotification] = useMutation(ADD_NOTIFICATION);
  const { neuronMode, currentCompanyId } = useWorkspace();
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);

  const onSubmit = (formInfo) => {
    const valuesToInclude = values.filter((value) => value.enabled);

    if (valuesToInclude.length === 0) {
      return toast.error('Selecione no mínimo uma permissão');
    }

    setIsLoading(true);

    if (isEditing) {
      return updateNotification({
        variables: {
          id: notification?._id,
          email: formInfo.email,
          scope: valuesToInclude.map((value) => value.permission),
        },
      })
        .then(() => {
          toast.success('Notificação atualizada com sucesso!');
        })
        .catch(() => {
          toast.error('Ocorreu um erro, por favor tente novamente');
        })
        .finally(() => {
          closeDrawer();
          setIsLoading(false);
          refetch();
        });
    }

    return addNotification({
      variables: {
        email: formInfo.email,
        scope: valuesToInclude.map((value) => value.permission),
        unit: !neuronMode && currentCompanyId ? currentCompanyId : undefined,
      },
    })
      .then(() => {
        toast.success('Notificação criada com sucesso!');
      })
      .catch(() => {
        toast.error('Ocorreu um erro, por favor tente novamente');
      })
      .finally(() => {
        closeDrawer();
        setIsLoading(false);
        refetch();
      });
  };

  useEffect(() => {
    if (isEditing) methods.setValue('email', notification.email);
  }, []);

  return (
    <FormProvider {...methods}>
      <DefaultForm onSubmit={methods.handleSubmit(onSubmit)} id="company-form">
        <TextField name="email" label="Email" validation={validator('email')} />
        <StyledPermissionSubtitle>Permissões</StyledPermissionSubtitle>
        <StyledGridDiv>
          {possiblePermissions.map((permission, index) => {
            return (
              <StyledRowDiv>
                <StyledCheckbox
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    const oldValues = [...values];
                    oldValues[index].enabled = !oldValues[index].enabled;
                    setValues(oldValues);
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.type === 'keydown' &&
                      (e.code === 'Space' || e.code === 'Enter')
                    ) {
                      const oldValues = [...values];
                      oldValues[index].enabled = !oldValues[index].enabled;
                      setValues(oldValues);
                    }
                  }}
                >
                  <CheckBoxIcon
                    width={24}
                    height={24}
                    checked={values[index].enabled}
                  />
                </StyledCheckbox>
                <StyledRoleText>{permission}</StyledRoleText>
              </StyledRowDiv>
            );
          })}
        </StyledGridDiv>
      </DefaultForm>
    </FormProvider>
  );
}
