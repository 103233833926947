import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

interface ArrowStyleContainerProps {
  direction: 'up' | 'down';
}

export const ArrowStyleContainer = styled.span<ArrowStyleContainerProps>`
  ${({ direction }) => css`
  margin-left: 0.625rem;
  width: 0;
  height: 0;
  padding: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-${direction === 'up' ? 'top' : 'bottom'}: 5px solid 
  ${colors.textOnBackground.active};
  `}
`;

export const WorkspaceSelectorStyled = styled.div`
  cursor: pointer;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin: 1rem auto 0;
  width: 85%;
  position: relative;
`;

export const LabelHolder = styled.div`
  display: flex;
`;

export const SelectedTextContainer = styled(motion.p)`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.5rem;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.15px;
  white-space: nowrap;
`;

export const WorkspaceListDiv = styled(motion.div)`
  width: 15.5rem;
  position: absolute;
  top: 11.75rem;
  left: 1rem;
  z-index: 9999;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    ),
    linear-gradient(0deg, #121212, #121212);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14),
    0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

export const ListStyleContainer = styled.ul`
  list-style: none;
`;

export const ListItemStyleContainer = styled.li`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 0.625rem;
  padding-left: 0.5rem;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${colors.selectedSidebar};
  }
`;

export const ListTextContainer = styled.p`
  font-size: 0.875rem;
  line-height: 1.25rem;
  /* identical to box height, or 143% */

  letter-spacing: 0.25px;
`;

export const InvisibleDivContainer = styled.div`
  position: absolute;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100%;
`;

export const NeuronIconStyledDiv = styled.div`
  padding-left: 0.65625rem;
  padding-top: 0.15rem;
`;

export const NeuronSelectorDiv = styled.div`
  /* Dark Theme/Outline */
  display: flex;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-top: 1.375rem;
  padding-bottom: 0.90625rem;
  cursor: pointer;
  &:hover {
    background: ${colors.selectedSidebar};
  }
`;

export const NeuronSelectorBorder = styled.div`
  border: 1px solid #3b3b3b;
  width: 14.5rem;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 0.5rem;
`;

export const NeuronTextContainer = styled.p`
  top: calc(50% - 1.5rem / 2);
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  /* Dark Theme/Text on Background/Active */

  color: ${colors.textOnBackground.active};

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0.625rem;
`;
