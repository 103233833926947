import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const NewBudgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  height: 97%;
`;

export const FieldsContainer = styled.div`
  background-color: ${colors.backgroundLight};
  padding: 1.25rem 0;
  border-radius: 4px 4px 0 0;
  margin-bottom: 3.5rem;
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0 1.75rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  height: 80px;
  padding: 1.25rem 1.875rem;
  background-color: ${colors.backgroundLight};
  border-top: 1px solid #3b3b3b;

  > div {
    display: flex;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
`;

interface FixedContainerProps {
  isSidebarOpen?: boolean;
}

export const FixedContainer = styled(motion.div)<FixedContainerProps>`
  z-index: 100;
  position: fixed;
  bottom: 0;
  right: 24px;
  left: ${({ isSidebarOpen }) => (isSidebarOpen ? 304 : 128)}px;
`;
