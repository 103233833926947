import { colors } from 'styles/colors';

import { StyledSpinner } from './SpinnerIconStyle';

interface SpinnerIconProps {
  variant: 'dark' | 'light' | 'special';
  width: number;
  height: number;
}

export function SpinnerIcon({ variant, width, height }: SpinnerIconProps) {
  const variants = {
    dark: '#000000',
    light: '#F9F9F9',
    special: colors.textOnBackground.special,
  };
  return (
    <StyledSpinner
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0V4C31.9411 4 40 12.0589 40 22C40 31.9411 31.9411 40 22 40C12.0589 40 4 31.9411 4 22H0C0 34.1503 9.84974 44 22 44Z"
        fill={variants[variant]}
      />
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="44"
        height="44"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0V4C31.9411 4 40 12.0589 40 22C40 31.9411 31.9411 40 22 40C12.0589 40 4 31.9411 4 22H0C0 34.1503 9.84974 44 22 44Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)" />
    </StyledSpinner>
  );
}
