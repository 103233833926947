import { gql } from '@apollo/client';

export const GET_PAYMENT_ITEMS_BY_ID = gql`
  query ($id: String!, $unit: String!) {
    PaymentItems(id: $id, unit: $unit) {
      items {
        _id
        status
        category
        type
        poCode
        paymentDate
        detailedDescription
        realizedValue
        files {
          _id
          path
          filename
          originalname
          fileExt
          url
          filesize
          metadata
        }
        unit
        nfCode
        nfDueDate
        nfEmissionDate
        paymentMethod
        providerName
        providerEmail
        client {
          cnpj
          clientPrefix
        }
      }
    }
  }
`;
