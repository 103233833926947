export function RadioBoxIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.5"
        y="4.5"
        width="15"
        height="15"
        rx="7.5"
        fill="#7B61FF"
        stroke="#7B61FF"
      />
      <path
        d="M11.7499 13.7375L10.3041 12.2917C10.1416 12.1292 9.8791 12.1292 9.7166 12.2917C9.5541 12.4542 9.5541 12.7167 9.7166 12.8792L11.4583 14.6209C11.6208 14.7834 11.8833 14.7834 12.0458 14.6209L16.4541 10.2125C16.6166 10.05 16.6166 9.78755 16.4541 9.62505C16.2916 9.46255 16.0291 9.46255 15.8666 9.62505L11.7499 13.7375Z"
        fill="white"
      />
    </svg>
  );
}
