import { ReactNode } from 'react';

import { useModal } from 'contexts/ModalContext';

import { ModalContainer, ModalContent, ModalLayer } from './style';

const variants = {
  animate: { opacity: 1, scale: 1 },
  initial: { opacity: 0, scale: 0.3 },
  exit: { opacity: 0, y: '20vh' },
};
interface ModalProps {
  width?: number;
  content: ReactNode;
}

export function Modal(props: ModalProps) {
  const { width, content } = props;
  const { closeModal } = useModal();

  return (
    <ModalContainer>
      <ModalLayer
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.4 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        onClick={closeModal}
      />
      <ModalContent
        animate="animate"
        initial="initial"
        exit="exit"
        transition={{ duration: 0.3 }}
        variants={variants}
        width={width}
      >
        {content}
      </ModalContent>
    </ModalContainer>
  );
}

Modal.defaultProps = {
  width: 320,
};
