import { gql } from '@apollo/client';

export const FISCAL_PARADISE_ITEMS = gql`
  query {
    FiscalParadiseItems(limit: 300) {
      items {
        country
        fiscalParadise
      }
    }
  }
`;
