import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useLazyQuery } from '@apollo/client';
import { AutocompleteSelect } from 'components/atoms/AutocompleteSelect';
import { VisibilityIcon } from 'components/atoms/Icons/VisibilityIcon';
import { VisibilityOffIcon } from 'components/atoms/Icons/VisibilityOffIcon';
import { InputFieldWithSelect } from 'components/atoms/InputFieldWithSelect';
import { TextField } from 'components/molecules/TextField';
import { FormGroupWithAdding } from 'components/organisms/FormGroupWithAdding';
import { useBacenConverter } from 'hooks/useBacenConverter';
import { FieldContainer } from 'pages/Budgets/components/NewBudget/style';
import { FISCAL_PARADISE_ITEMS } from 'pages/Budgets/components/Structure/components/StructureGroup/Invoice/query';
import { UnstyledButton } from 'pages/Companies/components/CompanyBankAccounts/style';
import {
  PrintedBudgetColumnTitle,
  PrintedBudgetTitleContainer,
} from 'pages/PrintedBudget/style';
import { FlexContainer } from 'styles/miscellany';
import {
  clearCurrencyNumberBrl,
  clearCurrencyStr,
  toCurrencyBrl,
} from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { NoPrint } from '../../style';

interface Props {
  groupName: string;
  isVisible?: boolean;
  onVisibilityClick?: () => void;
}
export function Invoice(props: Props) {
  const { groupName, isVisible, onVisibilityClick } = props;

  const { setValue, getValues } = useFormContext();

  const [getFiscalParadiseItems, getFiscalParadiseItemsRes] = useLazyQuery(
    FISCAL_PARADISE_ITEMS,
  );

  const fiscalParadiseItems =
    getFiscalParadiseItemsRes?.data?.FiscalParadiseItems?.items || [];

  const [fiscalParadiseOption, setFiscalParadiseOption] = useState<any>([{}]);

  useEffect(() => {
    getFiscalParadiseItems();
  }, []);

  useEffect(() => {
    if (fiscalParadiseItems) {
      const tmpFiscalParadiseItems = fiscalParadiseItems.map((i) => {
        return { value: i.country, label: i.country };
      });

      setFiscalParadiseOption(tmpFiscalParadiseItems);
    }
  }, [getFiscalParadiseItemsRes]);

  const loadOptions = async (text: string) => {
    const tmpText = await fiscalParadiseOption;

    const newText = tmpText.map((i) => i.label);

    return newText.filter((i) => i.includes(text));
  };

  const updateTotal = (index) => {
    const value = clearCurrencyNumberBrl(
      getValues(`${groupName}.invoice.${index}.value`),
    );
    const hedge = clearCurrencyStr(
      getValues(`${groupName}.invoice.${index}.hedgePercentage`),
    );
    const quotation = clearCurrencyNumberBrl(
      getValues(`${groupName}.invoice.${index}.currencyQuotation`),
    );
    const totalValue = Number(
      (value + (hedge / 100) * value) * quotation,
    ).toFixed(2);

    setValue(
      `${groupName}.invoice.${index}.currencyConversion`,
      toCurrencyBrl(Number(value * quotation).toFixed(2)),
    );
    setValue(`${groupName}.invoice.${index}.total`, toCurrencyBrl(totalValue));
  };

  const UpdateCurrency = async (current, value, index) => {
    const convertedValue = await useBacenConverter(current, value);
    if (convertedValue?.value) {
      setValue(
        `${groupName}.invoice.${index}.currencyQuotation`,
        toCurrencyBrl(Number(convertedValue.value / value).toFixed(2)),
      );
    }
  };

  const updateConversionValue = (currency, index) => {
    const value = clearCurrencyNumberBrl(
      getValues(`${groupName}.invoice.${index}.value`),
    );
    const actualCurrency = getValues(`${groupName}.invoice.${index}.currency`);
    if (value && actualCurrency) {
      UpdateCurrency(currency.value || actualCurrency, value, index);
      updateTotal(index);
    }
  };

  return (
    <div>
      <PrintedBudgetTitleContainer>
        <PrintedBudgetColumnTitle>Invoice</PrintedBudgetColumnTitle>
        <UnstyledButton
          title="Visível"
          type="button"
          onClick={onVisibilityClick}
          style={{ marginBottom: 16 }}
        >
          <NoPrint data-html2canvas-ignore>
            {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </NoPrint>
        </UnstyledButton>
      </PrintedBudgetTitleContainer>
      {isVisible && (
        <FormGroupWithAdding
          title="Invoice"
          groupName={`${groupName}.invoice`}
          withoutAdding
          renderRow={({ index }) => (
            <FieldContainer style={{ padding: '0' }}>
              <FlexContainer value={2}>
                <TextField
                  name={`${groupName}.invoice.${index}.item`}
                  label="Item"
                  isLight
                />
              </FlexContainer>
              <FlexContainer value={2}>
                <InputFieldWithSelect
                  inputName={`${groupName}.invoice.${index}.value`}
                  selectName={`${groupName}.invoice.${index}.currency`}
                  label="Valor"
                  options={[
                    { value: 'USD', label: 'USD' },
                    { value: 'EUR', label: 'EUR' },
                    { value: 'GBP', label: 'GBP' },
                    { value: 'ARS', label: 'ARS' },
                    { value: 'CLP', label: 'CLP' },
                    { value: 'COP', label: 'COP' },
                    { value: 'MXN', label: 'MXN' },
                    { value: 'UYU', label: 'UYU' },
                  ]}
                  defaultValue={
                    getValues(`${groupName}.invoice.${index}.currency`)
                      ? {
                          value: `${getValues(
                            `${groupName}.invoice.${index}.currency`,
                          )}`,
                          label: `${getValues(
                            `${groupName}.invoice.${index}.currency`,
                          )}`,
                        }
                      : {
                          value: 'USD',
                          label: 'USD',
                        }
                  }
                  menuPlacement="top"
                  validation={validator('required')}
                  actionTrigger={(data) => {
                    updateConversionValue(data, index);
                  }}
                  isLight
                />
              </FlexContainer>
              <FlexContainer value={2}>
                <AutocompleteSelect
                  name={`${groupName}.invoice.${index}.isParadise`}
                  label="País de Origem"
                  placeholder="País de Origem"
                  loadOptions={loadOptions}
                  validation={validator('required')}
                  isLight
                />
              </FlexContainer>
              <FlexContainer value={1}>
                <TextField
                  name={`${groupName}.invoice.${index}.hedgePercentage`}
                  label="Hedge"
                  suffix="%"
                  defaultValue={1}
                  onKeyUp={() => {
                    updateTotal(index);
                  }}
                  isLight
                />
              </FlexContainer>
              <FlexContainer value={2}>
                <TextField
                  name={`${groupName}.invoice.${index}.currencyQuotation`}
                  label="Cotação"
                  pattern="quotaMask"
                  defaultValue={1}
                  readOnly
                  isLight
                />
              </FlexContainer>
              <FlexContainer value={2}>
                <TextField
                  name={`${groupName}.invoice.${index}.total`}
                  label="R$ Custo total"
                  defaultValue={0}
                  readOnly
                  isLight
                />
              </FlexContainer>
            </FieldContainer>
          )}
        />
      )}
    </div>
  );
}
