import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { GridColumn } from 'styles/miscellany';

export const TabsContainer = styled.div``;

interface TabButtonProps {
  activePosition?: number;
  activeWidth?: number;
}

export const TabHeader = styled(GridColumn)<TabButtonProps>`
  ${({ activePosition, activeWidth }) => css`
    text-align: center;
    border-bottom: 1px solid ${colors.outline};
    position: relative;

    &:after {
      content: '';
      width: ${activeWidth}px;
      height: 2px;
      background-color: ${colors.primary};
      position: absolute;
      bottom: 0;
      left: ${activePosition}px;
      transition: left 0.4s cubic-bezier(0.42, -0.01, 0.26, 1.17);
    }
  `}
`;

export const TabButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 1rem 0;
  color: ${colors.textOnBackground.active};
  text-transform: initial;
  font-weight: 400;
`;

export const TabContent = styled.div`
  width: 100%;
  display: flex;
  width: 100%;
`;

export const ContentHolder = styled(motion.div)`
  width: 100%;
`;
