import { clearCurrencyStrBrl } from 'utils/masks';

type BudgetSection =
  | 'internalProduction'
  | 'presets'
  | 'outsource'
  | 'repass'
  | 'invoice';

/**
 * Sum all totals from same section name of all groups
 * @param list list object containing all groups
 * @param metadataSection the section to calculate total.
 * If this parameter is not provided, doesn't filter by section and uses the whole list in the sum
 * @returns the sum of all items
 */
export const calculateSectionTotal = (
  list: any[],
  metadataSection?: BudgetSection,
) => {
  if (!list) {
    return 0;
  }

  const groupList = metadataSection
    ? list.map((item) =>
        item.metadata ? item.metadata[metadataSection] : null,
      )
    : [list];

  const sumResult = groupList
    .filter((item) => item && Object.keys(item).length)
    .map((item) => {
      return item?.reduce(
        (sum, current) =>
          sum + +clearCurrencyStrBrl(current.total || current.value),
        0,
      );
    })
    .reduce((sum, current) => sum + (current || 0), 0);
  return sumResult;
};

/**
 * Calculates totals of all sections
 * @param obj
 * @returns an object containing all totals
 */
export const calculateAllBudgetTotals = (obj) => {
  const internalProductionTotal = calculateSectionTotal(
    obj?.budGroups,
    'internalProduction',
  );
  const presetsTotal = calculateSectionTotal(obj?.budGroups, 'presets');
  const outsourceTotal = calculateSectionTotal(obj?.budGroups, 'outsource');
  const invoiceTotal = calculateSectionTotal(obj?.budGroups, 'invoice');
  const repassTotal = calculateSectionTotal(obj?.budGroups, 'repass');
  const comissionsTotal = calculateSectionTotal(obj?.budComissions);
  const deductionsTotal = calculateSectionTotal(obj?.budDeductions);

  const netBilling =
    internalProductionTotal +
    presetsTotal +
    outsourceTotal +
    invoiceTotal +
    comissionsTotal -
    deductionsTotal;
  const totalBilling = netBilling + repassTotal;

  return {
    internalProductionTotal,
    presetsTotal,
    outsourceTotal,
    invoiceTotal,
    repassTotal,
    comissionsTotal,
    deductionsTotal,
    netBilling,
    totalBilling,
  };
};
