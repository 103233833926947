/* eslint-disable prefer-const */
import { FormEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'components/atoms/Button';
import { Plus } from 'components/atoms/Icons/Plus';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { Spinner } from 'components/atoms/Spinner';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { QUERY_COMPANY } from 'pages/Companies/query';
import { SelectFieldCustom } from 'pages/ManageProject/components/RecognitionControl/components/SelectFieldCustom';
import {
  CREATE_PRESET,
  GET_PRESET_BY_ID,
  UPDATE_PRESET,
} from 'pages/Presets/query';
import { DefaultForm, IconButton } from 'styles/miscellany';
import {
  clearCurrencyFloat,
  clearCurrencyNumber,
  clearCurrencyNumberBrl,
  toCurrency,
  toCurrencyBrl,
} from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { GET_INTERNALPRODUCTION } from './query';
import {
  ButtonContainer,
  StyledSkillsDiv,
  SkillsTextDiv,
  SkillsGridDiv,
} from './style';

interface PresetFormProps {
  id?: string | boolean;
  refetch: any;
}
interface SkillsInterface {
  name: string;
  hours: any;
}
export function PresetForm(props: PresetFormProps) {
  const { id, refetch } = props;
  const { currentCompanyId } = useWorkspace();
  const companyId = currentCompanyId;
  const isEditing = !!id;
  const methods = useForm();
  const [getPresetById, getPresetByIdRes] = useLazyQuery(GET_PRESET_BY_ID);
  const presetItem = getPresetByIdRes?.data?.ConfigurationItems?.items[0];
  const [updatePreset, updatePresetRes] = useMutation(UPDATE_PRESET);
  const [createPreset, createPresetRes] = useMutation(CREATE_PRESET);
  const [getInternalProduction, getInternalProductionRes] = useLazyQuery(
    GET_INTERNALPRODUCTION,
  );
  const internalProductionItem =
    getInternalProductionRes?.data?.ConfigurationItems?.items;
  const { closeDrawer } = useDrawer();
  const [skills, setSkills] = useState([] as SkillsInterface[]);
  const [options, setOptions] = useState([{ value: '', label: '' }]);
  const [listaHabilidades, setListaHabilidades] = useState([]);
  const [getCompany, getCompanyRes] = useLazyQuery(QUERY_COMPANY);
  const companyData = getCompanyRes.data?.CompanyItems?.items[0];
  const formValues = methods.watch();

  useEffect(() => {
    getCompany({ variables: { companyId } });
  }, [companyId, getCompany]);

  useEffect(() => {
    if (!presetItem && isEditing) getPresetById({ variables: { id } });

    if (presetItem && isEditing) {
      const decodedValues = JSON.parse(presetItem.metadata);
      Object.entries(decodedValues).forEach(([key, value]) => {
        if (key !== '__typename') {
          methods.setValue(key, value);
        }
      });
    }
  }, [presetItem, methods, isEditing, getPresetById, id]);

  const onSubmit = (dataForm) => {
    const metadata = {
      name: dataForm.name,
      category: dataForm.category,
      costValue: dataForm.costValue,
      saleValue: clearCurrencyNumberBrl(dataForm.saleValue),
    };
    skills.forEach((skill, index) => {
      metadata[`hours${index}`] = clearCurrencyFloat(skill.hours);
      metadata[`skill${index}`] = skill.name;
    });
    if (isEditing) {
      updatePreset({
        variables: {
          id,
          label: dataForm.name,
          type: 'preset',
          metadata: JSON.stringify(metadata),
          unit: currentCompanyId,
        },
      });
    } else {
      createPreset({
        variables: {
          label: dataForm.name,
          type: 'preset',
          metadata: JSON.stringify(metadata),
          unit: currentCompanyId,
        },
      });
    }
  };

  useEffect(() => {
    if (createPresetRes.data) {
      toast.success('Configuração salva!');

      refetch();

      closeDrawer();
    }

    if (createPresetRes.error) {
      toast.error('Ops, falha ao salvar configuração.');
    }
  }, [createPresetRes.data, createPresetRes.error, refetch]);

  useEffect(() => {
    if (updatePresetRes.data) {
      toast.success('Cadastro atualizado!');

      refetch();

      closeDrawer();
    }

    if (updatePresetRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
  }, [updatePresetRes.data, updatePresetRes.error, refetch]);

  useEffect(() => {
    if (getPresetByIdRes.data) {
      let currentSkillName;
      let currentSkillHour;
      const metadata = JSON.parse(
        getPresetByIdRes.data?.ConfigurationItems?.items[0]?.metadata,
      );
      let currentSkillIndex = 0;
      const newSkills = [] as SkillsInterface[];
      do {
        currentSkillName = metadata[`skill${currentSkillIndex}`];
        currentSkillHour = metadata[`hours${currentSkillIndex}`];
        if (currentSkillName || currentSkillHour) {
          newSkills.push({ hours: currentSkillHour, name: currentSkillName });
        }
        currentSkillIndex += 1;
      } while (currentSkillHour || currentSkillName);
      setSkills(newSkills);
    }
  }, [getPresetByIdRes.data]);

  function handleDelete(index: number) {
    const newSkills = [...skills];
    newSkills.splice(index, 1);
    setSkills(newSkills);
  }

  useEffect(() => {
    getInternalProduction({ variables: { unit: currentCompanyId } });
  }, []);

  useEffect(() => {
    if (internalProductionItem) {
      const habilidades = internalProductionItem.map((item) => item.label);
      setListaHabilidades(habilidades);
    }
  }, [internalProductionItem]);

  useEffect(() => {
    if (listaHabilidades) {
      const newOptions = listaHabilidades?.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      setOptions(newOptions);
    }
  }, [listaHabilidades]);

  let saleValue = 0;
  let valorCustoTotal = 0;
  useEffect(() => {
    if (internalProductionItem && companyData) {
      const internalProductionMetadata = internalProductionItem.map((item) =>
        JSON.parse(item.metadata),
      );
      internalProductionMetadata.forEach((item) => {
        if (item.name || item.role) {
          skills.forEach((skill, index) => {
            if (
              item.name === skills[index].name ||
              item.role === skills[index].name
            ) {
              let valorCusto = item.saleValue;
              let horas = clearCurrencyNumber(skills[index].hours) / 100;
              const valorTotal = valorCusto * horas;
              saleValue += valorTotal;
              valorCustoTotal += valorCusto * horas;
            }
          });

          let total = toCurrencyBrl(
            (
              saleValue /
              (1 - clearCurrencyNumberBrl(companyData?.directTax) / 100)
            ).toFixed(2),
          );
          methods.setValue('costValue', valorCustoTotal);
          methods.setValue('saleValue', total);
        }
      });
    }
  }, [skills, companyData, formValues?.profitability]);

  useEffect(() => {
    getInternalProduction({
      variables: { type: 'production', unit: currentCompanyId },
    });
  }, [getInternalProduction, currentCompanyId]);

  if (
    getPresetByIdRes.loading ||
    createPresetRes.loading ||
    updatePresetRes.loading
  )
    return <Spinner />;

  return (
    <FormProvider {...methods}>
      <DefaultForm onSubmit={methods.handleSubmit(onSubmit)} id="preset-form">
        <TextField
          name="name"
          label="Nome"
          validation={validator('required')}
          pattern="capitalize"
        />
        <TextField
          name="category"
          label="Categoria"
          validation={validator('required')}
          pattern="capitalize"
        />

        <StyledSkillsDiv>
          <SkillsTextDiv>Skills</SkillsTextDiv>
          <ButtonContainer>
            <Button
              icon={<Plus />}
              variant="secondary"
              hideShadow
              onClick={(e) => {
                e.preventDefault();
                setSkills((old: SkillsInterface[]) => {
                  return old.concat([{ name: '', hours: '' }]);
                });
              }}
            >
              ADD
            </Button>
          </ButtonContainer>
        </StyledSkillsDiv>
        <SkillsGridDiv>
          {skills.map((skill, index) => {
            return (
              <>
                <SelectFieldCustom
                  name={`skill${index}`}
                  label="Habilidade"
                  placeholder={skill.name ? `${skill.name}` : 'Habilidade'}
                  options={options}
                  validation={validator('required')}
                  onChangeValue={(event) => {
                    const newSkills = [...skills];
                    newSkills[index].name = event.value.toString();
                    setSkills(newSkills);
                  }}
                />
                <TextField
                  name={`hours${index}`}
                  label="Horas trabalhadas"
                  validation={validator('required')}
                  value={toCurrency(skill.hours)}
                  pattern="money"
                  onInput={(event: FormEvent<HTMLInputElement>) => {
                    const newSkills = [...skills];
                    newSkills[index].hours = event.toString();
                    setSkills(newSkills);
                  }}
                />
                <IconButton
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(index);
                  }}
                >
                  <TrashIcon variant="light" width={24} height={24} />
                </IconButton>
              </>
            );
          })}
          <TextField
            name="saleValue"
            label="Valor Total com Impostos"
            validation={validator('required')}
            pattern="moneyPrefix"
            readOnly
          />
        </SkillsGridDiv>
      </DefaultForm>
    </FormProvider>
  );
}

PresetForm.defaultProps = {
  id: false,
};
