import { gql } from '@apollo/client';

export const GET_CONTACTS = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $sorting: [SorterInput]
  ) {
    ContactItems(
      limit: $limit
      page: $page
      search_term: $search_term
      sorting: $sorting
    ) {
      pages
      items {
        _id
        name
        area
        email
        product
      }
    }
  }
`;

export const GET_CONTACT_BY_ID = gql`
  query ($id: String) {
    ContactItems(id: $id) {
      items {
        name
        area
        email
        product
        phone
      }
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation (
    $name: String!
    $area: String
    $email: String!
    $product: String
    $phone: String
  ) {
    createContact(
      name: $name
      area: $area
      email: $email
      product: $product
      phone: $phone
    ) {
      _id
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation (
    $id: String!
    $name: String
    $area: String
    $email: String
    $product: String
    $phone: String
  ) {
    contactSetup(
      id: $id
      name: $name
      area: $area
      email: $email
      product: $product
      phone: $phone
    )
  }
`;

export const DELETE_CONTACT = gql`
  mutation ($id: String!) {
    deleteContact(id: $id)
  }
`;
