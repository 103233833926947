import { colors } from 'styles/colors';

interface ActionIconProps {
  color?: string;
  width: number;
  height: number;
}

export function TertiaryFileIcon({ color, width, height }: ActionIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.34159 3.33268C3.34159 2.41602 4.08325 1.66602 4.99992 1.66602H11.6666L16.6666 6.66602V16.666C16.6666 17.5827 15.9166 18.3327 14.9999 18.3327H4.99159C4.07492 18.3327 3.33325 17.5827 3.33325 16.666L3.34159 3.33268ZM10.8333 2.91602V7.49935H15.4166L10.8333 2.91602Z"
        fill={color}
      />
    </svg>
  );
}

TertiaryFileIcon.defaultProps = {
  color: colors.primary,
};
