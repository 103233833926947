import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { BellIcon } from 'components/atoms/Icons/BellIcon';
import { ClipboardIcon } from 'components/atoms/Icons/ClipboardIcon';
import { CompaniesIcon } from 'components/atoms/Icons/CompaniesIcon';
import { DraftIcon } from 'components/atoms/Icons/DraftIcon';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { Logo } from 'components/atoms/Icons/Logo';
import { StatisticsIcon } from 'components/atoms/Icons/StatisticsIcon';
import { TableChartIcon } from 'components/atoms/Icons/TableChartIcon';
import { UsersIcon } from 'components/atoms/Icons/UsersIcon';
import { WorkspaceSelector } from 'components/atoms/WorkspaceSelector';
import { useSidebar } from 'contexts/SidebarContext';
import { useUser } from 'contexts/UserContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { AnimatePresence, motion } from 'framer-motion';

import {
  CompanyText,
  SidebarContainer,
  PageText,
  SelectedTab,
  TabIconDivStyle,
  ArrowStyleContainer,
  SelectedTabChildren,
} from './style';

export function Sidebar() {
  const location = useLocation();

  const { isSidebarOpen, setSidebar } = useSidebar();
  const [openSections, setOpenSections] = useState({
    settings: !![
      '/settings/internalProductions',
      '/settings/presets',
      '/settings/thirdParties',
      '/settings/commissions',
    ].find((route) => route === location.pathname),
  });
  const { neuronMode } = useWorkspace();
  useEffect(() => {
    if (!isSidebarOpen && openSections.settings)
      setOpenSections({ ...openSections, settings: false });
  }, [isSidebarOpen, openSections, setOpenSections]);

  useEffect(() => {
    if (!location.pathname.includes('/settings'))
      setOpenSections({
        settings: false,
      });
  }, [location.pathname]);

  const user = useUser();
  const { permission } = user.userData;

  const neuronPages = [
    {
      text: 'USUÁRIOS',
      icon: UsersIcon,
      path: '/',
    },
    {
      text: 'PLANO DE CONTAS',
      icon: ClipboardIcon,
      path: '/accounts-charts',
    },
    {
      text: 'EMPRESAS',
      icon: CompaniesIcon,
      path: '/companies',
    },
    {
      text: 'FORNECEDORES',
      icon: CompaniesIcon,
      path: '/providers',
    },
    {
      text: 'NOTIFICAÇÕES',
      icon: BellIcon,
      path: '/notifications',
    },
    {
      text: 'PAGAMENTOS',
      icon: StatisticsIcon,
      path: '/payments',
    },
    {
      text: 'FATURAMENTOS',
      icon: StatisticsIcon,
      path: '/revenues',
    },
  ];

  const allCompanyPages = {
    companySection: [
      {
        text: 'Colaboradores',
        icon: UsersIcon,
        path: '/collaborators',
      },
      {
        text: 'Notificações',
        icon: BellIcon,
        path: '/notifications',
      },
      {
        text: 'Configurações',
        sectionName: 'settings',
        icon: GearIcon,
        path: undefined,
        isSelected: (pathName: string) =>
          openSections.settings &&
          ![
            '/settings/internalProductions',
            '/settings/presets',
            '/settings/thirdParties',
            '/settings/commissions',
          ].find((route) => route === pathName),
        children: [
          {
            text: 'Rate card',
            path: '/settings/internalProductions',
          },
          {
            text: 'Presets',
            path: '/settings/presets',
          },
          {
            text: 'Terceiros',
            path: '/settings/thirdParties',
          },
          {
            text: 'Comissões orçadas',
            path: '/settings/commissions',
          },
        ],
        onClick: () => {
          setSidebar(true);
          setOpenSections({
            ...openSections,
            settings: !openSections.settings,
          });
        },
      },
    ],
    managerSection: [
      {
        text: 'Clientes',
        icon: CompaniesIcon,
        path: '/clients',
        isSelected: (url: string) => {
          return url.includes('/clients');
        },
      },
      {
        text: 'Orçamentos',
        icon: DraftIcon,
        path: '/budgets',
        sectionName: undefined,
        onClick: undefined,
        children: [
          {
            text: undefined,
            icon: undefined,
            path: undefined,
          },
        ],
        isSelected: (url: string) => {
          return url.includes('/budgets');
        },
      },
      {
        text: 'Projetos',
        icon: TableChartIcon,
        path: '/projects',
        sectionName: undefined,
        onClick: undefined,
        children: [
          {
            text: undefined,
            icon: undefined,
            path: undefined,
          },
        ],
        isSelected: (url: string) => {
          return url.includes('/projects');
        },
      },
      {
        text: 'Pagamentos',
        icon: StatisticsIcon,
        path: '/payments',
      },
      {
        text: 'Faturamentos',
        icon: StatisticsIcon,
        path: '/billings',
      },
    ],
  };

  const teamCompanyPages = {
    companySection: [
      {
        text: 'Colaboradores',
        icon: UsersIcon,
        path: '/collaborators',
      },
      {
        text: 'Notificações',
        icon: BellIcon,
        path: '/notifications',
      },
      {
        text: 'Configurações',
        sectionName: 'settings',
        icon: GearIcon,
        path: undefined,
        isSelected: (pathName: string) =>
          openSections.settings &&
          ![
            '/settings/internalProductions',
            '/settings/presets',
            '/settings/thirdParties',
            '/settings/commissions',
          ].find((route) => route === pathName),
        children: [
          {
            text: 'Produção Interna',
            path: '/settings/internalProductions',
          },
          {
            text: 'Presets',
            path: '/settings/presets',
          },
          {
            text: 'Plano de Contas',
            path: '/settings/thirdParties',
          },
          {
            text: 'Comissões orçadas',
            path: '/settings/commissions',
          },
        ],
        onClick: () => {
          setSidebar(true);
          setOpenSections({
            ...openSections,
            settings: !openSections.settings,
          });
        },
      },
    ],
    managerSection: [
      {
        text: 'Clientes',
        icon: CompaniesIcon,
        path: '/clients',
        isSelected: (url: string) => {
          return url.includes('/clients');
        },
      },
      {
        text: 'Orçamentos',
        icon: DraftIcon,
        path: '/budgets',
        sectionName: undefined,
        onClick: undefined,
        children: [
          {
            text: undefined,
            icon: undefined,
            path: undefined,
          },
        ],
        isSelected: (url: string) => {
          return url.includes('/budgets');
        },
      },
      {
        text: 'Projetos',
        icon: TableChartIcon,
        path: '/projects',
        sectionName: undefined,
        onClick: undefined,
        children: [
          {
            text: undefined,
            icon: undefined,
            path: undefined,
          },
        ],
        isSelected: (url: string) => {
          return url.includes('/projects');
        },
      },
    ],
  };

  let companyPages;
  if (permission === 'Team') {
    companyPages = teamCompanyPages;
  } else {
    companyPages = allCompanyPages;
  }

  const containerVariants = {
    animate: {
      maxWidth: isSidebarOpen ? '17.5rem' : '6.5rem',
      transition: { type: 'linear', duration: 0.6 },
    },
  };

  const logoVariants = {
    animate: {
      padding: isSidebarOpen ? '3rem 6.5rem 0' : '3rem 0.75rem 0',
      transition: { type: 'linear', duration: 0.6 },
    },
  };

  const textVariants = {
    animate: {
      opacity: isSidebarOpen ? 1 : 0,
      transition: {
        type: 'linear',
        duration: 0.3,
        delay: isSidebarOpen ? 0.3 : 0,
      },
    },
  };

  const tabVariants = {
    animate: {
      padding: isSidebarOpen ? '1rem 1.5rem' : '1rem 2.5rem',
      transition: { type: 'linear', duration: 0.6 },
    },
  };
  return (
    <SidebarContainer
      initial={false}
      animate="animate"
      variants={containerVariants}
    >
      <motion.div variants={logoVariants}>
        <Logo variant="light" width={80} height={45} />
      </motion.div>

      <WorkspaceSelector isExpanded={isSidebarOpen} />

      {permission === 'Team' || permission === 'Lead' ? (
        <></>
      ) : (
        <>
          <CompanyText>COMPANY</CompanyText>
          <AnimatePresence>
            {neuronMode
              ? neuronPages.map((page) => {
                  const isSelected =
                    location.pathname === page.path && !openSections.settings;

                  return (
                    <Link to={page.path} key={page.path}>
                      <SelectedTab
                        variants={tabVariants}
                        isSelected={isSelected}
                      >
                        <TabIconDivStyle>
                          <page.icon
                            variant={isSelected ? 'special' : 'light'}
                            width={24}
                            height={24}
                          />
                        </TabIconDivStyle>

                        <PageText
                          isSelected={isSelected}
                          variants={textVariants}
                        >
                          {page.text}
                        </PageText>
                      </SelectedTab>
                    </Link>
                  );
                })
              : companyPages.companySection.map((page) => {
                  const isSelected = page.isSelected
                    ? page.isSelected(location.pathname)
                    : location.pathname === page.path && !openSections.settings;
                  const sidebarCompanySection = () => (
                    <>
                      <SelectedTab
                        variants={tabVariants}
                        isSelected={isSelected}
                        onClick={page.onClick}
                        pointerCursor={!!page.onClick}
                      >
                        <TabIconDivStyle>
                          <page.icon
                            variant={isSelected ? 'special' : 'light'}
                            width={24}
                            height={24}
                          />
                        </TabIconDivStyle>

                        <PageText
                          isSelected={isSelected}
                          variants={textVariants}
                        >
                          {page.text}
                        </PageText>
                        {page.onClick && page.sectionName && (
                          <ArrowStyleContainer
                            variant={isSelected ? 'special' : 'light'}
                            direction={
                              openSections[page.sectionName] ? 'down' : 'up'
                            }
                          />
                        )}
                      </SelectedTab>
                      <AnimatePresence>
                        {page.onClick &&
                          page.sectionName &&
                          openSections[page.sectionName] &&
                          page.children?.map((children) => {
                            const childrenIsSelected =
                              location.pathname === children.path;
                            return (
                              <Link to={children.path} key={children.path}>
                                <SelectedTabChildren
                                  isSelected={childrenIsSelected}
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  key={children.path}
                                >
                                  <PageText
                                    isSelected={childrenIsSelected}
                                    variants={textVariants}
                                  >
                                    {children.text}
                                  </PageText>
                                </SelectedTabChildren>
                              </Link>
                            );
                          })}
                      </AnimatePresence>
                    </>
                  );
                  return page.path ? (
                    <Link to={page.path} key={page.path}>
                      {sidebarCompanySection()}
                    </Link>
                  ) : (
                    sidebarCompanySection()
                  );
                })}
          </AnimatePresence>
        </>
      )}
      {!neuronMode && <CompanyText layout>MANAGER</CompanyText>}
      {!neuronMode &&
        companyPages.managerSection.map((page) => {
          const isSelected = page.isSelected
            ? page.isSelected(location.pathname) && !openSections.settings
            : location.pathname === page.path && !openSections.settings;
          const sidebarManagerSection = () => (
            <>
              <SelectedTab
                variants={tabVariants}
                isSelected={isSelected}
                onClick={page.onClick}
                pointerCursor={!!page.onClick}
              >
                <TabIconDivStyle>
                  <page.icon
                    variant={isSelected ? 'special' : 'light'}
                    width={24}
                    height={24}
                  />
                </TabIconDivStyle>

                <PageText isSelected={isSelected} variants={textVariants}>
                  {page.text}
                </PageText>
                {page.onClick && page.sectionName && (
                  <ArrowStyleContainer
                    variant={isSelected ? 'special' : 'light'}
                    direction={openSections[page.sectionName] ? 'down' : 'up'}
                  />
                )}
              </SelectedTab>
              <AnimatePresence>
                {page.onClick &&
                  page.sectionName &&
                  openSections[page.sectionName] &&
                  page.children?.map((children) => {
                    const childrenIsSelected =
                      location.pathname === children.path;
                    return (
                      <Link to={children?.path || ''} key={children.path}>
                        <SelectedTabChildren
                          isSelected={childrenIsSelected}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          key={children.path}
                        >
                          <PageText
                            isSelected={childrenIsSelected}
                            variants={textVariants}
                          >
                            {children.text}
                          </PageText>
                        </SelectedTabChildren>
                      </Link>
                    );
                  })}
              </AnimatePresence>
            </>
          );
          return page.path ? (
            <Link to={page.path} key={page.path}>
              {sidebarManagerSection()}
            </Link>
          ) : (
            sidebarManagerSection()
          );
        })}
    </SidebarContainer>
  );
}
