import { Header } from 'components/molecules/Header';
import { Sidebar } from 'components/molecules/Sidebar';
import { useSidebar } from 'contexts/SidebarContext';

import { Main, DashboardContainer } from './style';

export function Dashboard({ children }) {
  const { isSidebarOpen } = useSidebar();

  return (
    <DashboardContainer
      initial={false}
      animate={{
        gridTemplateColumns: isSidebarOpen ? '17.5rem 1fr' : '6.5rem 1fr',
      }}
      transition={{ type: 'linear', duration: 0.6 }}
    >
      <Sidebar />
      <Header />
      <Main>{children}</Main>
    </DashboardContainer>
  );
}
