import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Avatar } from 'components/atoms/Avatar';
import { ExitIcon } from 'components/atoms/Icons/ExitIcon';
// import { HelpIconFilled } from 'components/atoms/Icons/HelpIconFilled';
import { OptionsIcon } from 'components/atoms/Icons/OptionsIcon';
// import { UserIcon } from 'components/atoms/Icons/UserIcon';
import { useSidebar } from 'contexts/SidebarContext';
import { useUser } from 'contexts/UserContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { AnimatePresence } from 'framer-motion';
import { storageKeys } from 'services/storage-keys';
import { IconButton } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';

import {
  CompanyTextContainer,
  HeaderContainer,
  LabelContainer,
  MenuStyleContainer,
  ProfileStyleContainer,
  ArrowStyleContainer,
  ListStyleContainer,
  ListItemStyleContainer,
  ProfileIconsStyleContainer,
  ProfileTextStyleContainer,
  InvisibleDivContainer,
  MenuLabel,
} from './style';

export function Header() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { toggleSidebar } = useSidebar();
  const { userData } = useUser();
  const { name, avatar } = userData;
  const history = useHistory();
  const { currentCompanyName, neuronMode } = useWorkspace();

  // const profileOptions = [
  //   { text: 'Minha Conta', icon: UserIcon, link: '/my-account' },
  //   { text: 'Help', icon: HelpIconFilled, link: '/help' },
  // ];

  const handleClick = (e: any) => {
    if (
      e.type === 'click' ||
      (e.type === 'keydown' && (e.keyCode === 32 || e.keyCode === 13))
    )
      setToggleMenu((prev) => !prev);
  };

  const handleExit = () => {
    localStorage.removeItem(storageKeys.token);

    history.push('/login');
  };

  return (
    <HeaderContainer>
      <LabelContainer>
        <IconButton onClick={toggleSidebar}>
          <OptionsIcon variant="light" width={24} height={24} />
        </IconButton>
        <CompanyTextContainer>
          {neuronMode ? 'Neuron OS' : capitalize(currentCompanyName)}
        </CompanyTextContainer>
      </LabelContainer>

      <MenuStyleContainer
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <MenuLabel>{name}</MenuLabel>
        <Avatar src={avatar} />
        <ArrowStyleContainer direction={toggleMenu ? 'down' : 'up'} />
      </MenuStyleContainer>
      <AnimatePresence>
        {toggleMenu && (
          <>
            <InvisibleDivContainer onClick={() => setToggleMenu(false)} />
            <ProfileStyleContainer
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ListStyleContainer>
                {/* {profileOptions.map((option) => (
                  <Link to={option.link}>
                    <ListItemStyleContainer key={`profile${option.text}`}>
                      <ProfileIconsStyleContainer>
                        <option.icon width={20} height={20} variant="light" />
                      </ProfileIconsStyleContainer>

                      <ProfileTextStyleContainer>
                        {option.text}
                      </ProfileTextStyleContainer>
                    </ListItemStyleContainer>
                  </Link>
                ))} */}

                <ListItemStyleContainer
                  data-last
                  key="exit"
                  onClick={handleExit}
                >
                  <ProfileIconsStyleContainer>
                    <ExitIcon width={20} height={20} variant="light" />
                  </ProfileIconsStyleContainer>
                  <ProfileTextStyleContainer>Sair</ProfileTextStyleContainer>
                </ListItemStyleContainer>
              </ListStyleContainer>
            </ProfileStyleContainer>
          </>
        )}
      </AnimatePresence>
    </HeaderContainer>
  );
}
