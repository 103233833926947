import { useState } from 'react';

import { Knob, Rail, SwitchContainer, SwitchController } from './style';

interface SwitchProps {
  onChange: ((e: boolean) => boolean) | ((e: boolean) => void);
  disabled?: boolean;
  defaultChecked: boolean | undefined;
  key?: string;
}

export function Switch(props: SwitchProps) {
  const { onChange, defaultChecked, disabled, key } = props;
  const [isEnable, setIsEnable] = useState(defaultChecked);

  const handleSwitcher = () => {
    setIsEnable((prev) => !prev);

    onChange(!isEnable);
  };

  return (
    <SwitchContainer
      key={key || undefined}
      onClick={disabled ? () => null : handleSwitcher}
    >
      <SwitchController data-enable={isEnable}>
        <Rail>
          <Knob />
        </Rail>
      </SwitchController>
    </SwitchContainer>
  );
}

Switch.defaultProps = {
  disabled: false,
  key: '',
};
