/* eslint-disable no-console */
import toast from 'react-hot-toast';

import { useMutation } from '@apollo/client';
import { ActionIcon } from 'components/atoms/Icons/ActionIcon';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { Table } from 'components/organisms/Table';
import { useDrawer } from 'contexts/DrawerContext';
import { useModal } from 'contexts/ModalContext';
import { IconButton, StatusLabel } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';
import { toCurrencyBrl } from 'utils/masks';

import { DELETE_PAYMENT } from '../../query';
import { CostControlForm } from '../CostControlForm';
import { POModal } from '../POModal';

interface BudgetGroupProps {
  data: any;
  refetch?: any;
  projectData: any;
  groupName?: string;
}

export function BudgetGroupContent(props: BudgetGroupProps) {
  const { data, refetch, projectData, groupName } = props;
  const { openDrawer } = useDrawer();
  const { openModal } = useModal();
  const [deletePayment] = useMutation(DELETE_PAYMENT);

  const translateStatus = {
    pendente: 'active',
    'NF Recebida': 'active',
    vencido: 'error',
    'PO Emitida': 'warning',
    Pago: 'success',
  };

  const deleteRow = (id) => {
    deletePayment({ variables: { id } })
      .then(() => {
        toast.success('Pagamento deletado com sucesso!');
        if (refetch) {
          refetch();
        }
      })
      .catch(() => toast.error('Ocorreu um erro ao deletar o pagamento.'));
  };

  const handleDelete = (id) => {
    openModal({
      content: <DeleteModal onAccept={() => deleteRow(id)} />,
    });
  };

  const handleConfig = (index) => {
    openDrawer({
      title: 'Configurar Custo',
      width: 600,
      content: (
        <CostControlForm presetData={data[index]} isEditing refetch={refetch} />
      ),
      formId: 'cost-control-form',
    });
  };

  const handleAddRow = () => {
    openDrawer({
      title: 'Configurar Custo',
      width: 600,
      content: (
        <CostControlForm
          projectIds={projectData}
          groupName={groupName}
          refetch={refetch}
        />
      ),
      formId: 'cost-control-form',
    });
  };

  const handlePoVerify = (rowData) => {
    if (!(rowData.status === 'PO Emitida')) {
      openModal({
        content: <POModal paymentId={rowData._id} refetch={refetch} />,
        width: 410,
      });
    }
  };

  return (
    <Table
      pages={-1}
      fetchData={() => null}
      withAdding={handleAddRow}
      manualSort
      columns={[
        {
          Header: 'Status',
          accessor: (row) => (
            <StatusLabel variant={translateStatus[row.status] || 'active'}>
              {capitalize(row.status)}
            </StatusLabel>
          ),
        },
        { Header: 'PO', accessor: 'poCode' },
        {
          Header: 'Orçado',
          accessor: (row) =>
            toCurrencyBrl(`${Number(row?.budgetedValue).toFixed(2)}`),
        },
        {
          Header: 'Realizado',
          accessor: (row) =>
            toCurrencyBrl(`${Number(row?.realizedValue).toFixed(2)}`),
        },
        { Header: 'Fornecedor', accessor: 'providerName' },
        { Header: 'Data de pagamento', accessor: 'paymentDate' },
      ]}
      loading={false}
      emptyText="Não há registros para consulta"
      data={data}
      actions={(rowData, index) => [
        {
          component: (
            <IconButton role="button" onClick={() => handlePoVerify(rowData)}>
              <ActionIcon />
            </IconButton>
          ),
        },
        {
          component: (
            <IconButton role="button" onClick={() => handleDelete(rowData._id)}>
              <TrashIcon variant="light" width={20} height={20} />
            </IconButton>
          ),
        },
        {
          component: (
            <IconButton role="button" onClick={() => handleConfig(index)}>
              <GearIcon variant="light" width={20} height={20} />
            </IconButton>
          ),
        },
      ]}
    />
  );
}
