import ArrowRight from 'assets/img/ArrowRight.svg';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const BreadcrumbContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
`;

export const BreadcrumbTitle = styled.div`
  display: flex;
`;

export const Title = styled.h4`
  display: flex;
  align-items: center;

  color: ${colors.textOnBackground.medium};

  &:after {
    content: url(${ArrowRight});
    margin: 0 1rem;
    line-height: 1rem;
    font-size: 1.5rem;
  }

  &:last-child {
    color: ${colors.textOnBackground.active};

    &:after {
      content: '';
    }
  }
`;
