import { gql } from '@apollo/client';

export const GET_REVENUES = gql`
  query ($id: String) {
    ProjectItems(id: $id) {
      items {
        _id
        revenue
        revenues {
          name
          metadata
        }
      }
    }
  }
`;
