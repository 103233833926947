import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { SelectField } from 'components/atoms/SelectField';
import { Spinner } from 'components/atoms/Spinner';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import {
  CREATE_INTERNAL_PRODUCTION,
  GET_COMMISSION_BY_ID,
  UPDATE_INTERNAL_PRODUCTION,
} from 'pages/Commissions/query';
import { DefaultForm } from 'styles/miscellany';
import { validator } from 'utils/validators/form-validations';

interface CommissionFormProps {
  id?: string | boolean;
  refetch: any;
}

export function CommissionForm(props: CommissionFormProps) {
  const { currentCompanyId } = useWorkspace();
  const { id, refetch } = props;
  const isEditing = !!id;
  const methods = useForm();
  const [getCommissionById, getCommissionByIdRes] =
    useLazyQuery(GET_COMMISSION_BY_ID);
  const commissionItem =
    getCommissionByIdRes?.data?.ConfigurationItems?.items[0];
  const [updateCommission, updateCommissionRes] = useMutation(
    UPDATE_INTERNAL_PRODUCTION,
  );
  const [createCommission, createCommissionRes] = useMutation(
    CREATE_INTERNAL_PRODUCTION,
  );
  const { closeDrawer } = useDrawer();

  useEffect(() => {
    if (!commissionItem && isEditing) getCommissionById({ variables: { id } });

    if (commissionItem && isEditing) {
      const decodedValues = JSON.parse(commissionItem.metadata);
      Object.entries(decodedValues).forEach(([key, value]) => {
        if (key !== '__typename') {
          methods.setValue(key, value);
        }
      });
    }
  }, [commissionItem, methods, isEditing, getCommissionById, id]);

  const onSubmit = (dataForm) => {
    if (isEditing) {
      updateCommission({
        variables: {
          unit: currentCompanyId,
          id,
          label: dataForm.name,
          type: 'commission',
          metadata: JSON.stringify(dataForm),
        },
      });
    } else {
      createCommission({
        variables: {
          unit: currentCompanyId,
          label: dataForm.name,
          type: 'commission',
          metadata: JSON.stringify(dataForm),
        },
      });
    }
  };

  useEffect(() => {
    if (createCommissionRes.data) {
      toast.success('Cadastro efetuado!');

      refetch();

      closeDrawer();
    }

    if (createCommissionRes.error) {
      toast.error('Ops, falha ao realizar o cadastro.');
    }
  }, [createCommissionRes.data, createCommissionRes.error, refetch]);
  // FIXME: Resolve re-render caused by closeDrawer in dependencies

  useEffect(() => {
    if (updateCommissionRes.data) {
      toast.success('Cadastro atualizado!');

      refetch();

      closeDrawer();
    }

    if (updateCommissionRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
  }, [updateCommissionRes.data, updateCommissionRes.error, refetch]);
  // FIXME: Resolve re-render caused by closeDrawer in dependencies

  if (
    getCommissionByIdRes.loading ||
    createCommissionRes.loading ||
    updateCommissionRes.loading
  )
    return <Spinner />;

  return (
    <FormProvider {...methods}>
      <DefaultForm
        onSubmit={methods.handleSubmit(onSubmit)}
        id="commission-form"
      >
        <TextField
          name="name"
          label="Nome"
          validation={validator('required')}
        />
        <SelectField
          name="calculationBasis"
          label="Base de Cálculo"
          placeholder="Base de Cálculo"
          options={[
            {
              value: 'Sobre custos de terceiros',
              label: 'Sobre custos de terceiros',
            },
            {
              value: 'Sobre o valor total do projeto',
              label: 'Sobre o valor total do projeto',
            },
            {
              value: 'Sobre a receita do projeto',
              label: 'Sobre a receita do projeto',
            },
          ]}
          validation={validator('required')}
        />
        <TextField
          name="percentage"
          label="Percentual"
          validation={validator('required')}
          pattern="money"
          suffix="%"
        />
      </DefaultForm>
    </FormProvider>
  );
}

CommissionForm.defaultProps = {
  id: false,
};
