import { gql } from '@apollo/client';

export const GET_RATE_CARDS = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $sorting: [SorterInput]
  ) {
    RateCardItems(
      limit: $limit
      page: $page
      search_term: $search_term
      sorting: $sorting
    ) {
      pages
      items {
        _id
        category
        serviceName
        cost
        saleValue
        clientValue
        profitability
      }
    }
  }
`;

export const GET_RATE_CARD_BY_ID = gql`
  query ($id: String) {
    RateCardItems(id: $id) {
      items {
        category
        serviceName
        cost
        saleValue
        clientValue
        profitability
      }
    }
  }
`;

export const CREATE_RATE_CARD = gql`
  mutation (
    $category: String!
    $serviceName: String!
    $cost: Float
    $saleValue: Float
    $clientValue: Float
    $profitability: Float
  ) {
    createRateCard(
      category: $category
      serviceName: $serviceName
      cost: $cost
      saleValue: $saleValue
      clientValue: $clientValue
      profitability: $profitability
    ) {
      _id
    }
  }
`;

export const UPDATE_RATE_CARD = gql`
  mutation (
    $id: String!
    $category: String
    $serviceName: String
    $cost: Float
    $saleValue: Float
    $clientValue: Float
    $profitability: Float
  ) {
    rateCardSetup(
      id: $id
      category: $category
      serviceName: $serviceName
      cost: $cost
      saleValue: $saleValue
      clientValue: $clientValue
      profitability: $profitability
    )
  }
`;

export const DELETE_RATE_CARD = gql`
  mutation ($id: String!) {
    deleteRateCard(id: $id)
  }
`;
