import { gql } from '@apollo/client';

export const CLIENT_NAME = gql`
  query ($id: String!) {
    ClientItems(id: $id) {
      items {
        _id
        name
      }
    }
  }
`;

export const CLIENT_LIST_ALL_DATA = gql`
  query ($id: String!) {
    ClientItems(id: $id) {
      items {
        _id
        name
        legalName
        cityTaxId
        stateTaxId
        cnpj
        address
        city
        state
        country
        cep
        isPoRequired
        unit
        created_at
        updated_at
        deleted_at
      }
      pages
    }
  }
`;

export const CLIENT_LIST = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $unit: String
    $sorting: [SorterInput]
    $sort_by: [String]
  ) {
    ClientItems(
      limit: $limit
      page: $page
      search_term: $search_term
      unit: $unit
      sort_by: $sort_by
      sorting: $sorting
    ) {
      items {
        _id
        name
        unit
        is_disabled
      }
      pages
    }
  }
`;
export const TOGGLE_CLIENT = gql`
  mutation deleteNotification($id: String!, $isDisabled: Boolean) {
    clientSetup(id: $id, isDisabled: $isDisabled)
  }
`;

export const CREATE_CLIENT = gql`
  mutation createClient(
    $name: String!
    $legalName: String
    $cityTaxId: String
    $stateTaxId: String
    $unit: String!
    $cnpj: String
    $address: String
    $city: String
    $state: String
    $country: String
    $cep: String
    $isPoRequired: Boolean
  ) {
    createClient(
      name: $name
      legalName: $legalName
      cityTaxId: $cityTaxId
      stateTaxId: $stateTaxId
      unit: $unit
      cnpj: $cnpj
      address: $address
      city: $city
      state: $state
      country: $country
      cep: $cep
      isPoRequired: $isPoRequired
    ) {
      _id
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient(
    $id: String!
    $name: String!
    $legalName: String
    $cityTaxId: String
    $stateTaxId: String
    $unit: String!
    $cnpj: String
    $address: String
    $city: String
    $state: String
    $country: String
    $cep: String
    $isPoRequired: Boolean
  ) {
    clientSetup(
      id: $id
      name: $name
      legalName: $legalName
      cityTaxId: $cityTaxId
      stateTaxId: $stateTaxId
      unit: $unit
      cnpj: $cnpj
      address: $address
      city: $city
      state: $state
      country: $country
      cep: $cep
      isPoRequired: $isPoRequired
    )
  }
`;
