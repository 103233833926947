import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

interface InputStyledProps {
  isLight?: boolean;
}

export const InputStyled = styled.input<InputStyledProps>`
  width: 100%;
  height: 2.5rem;
  padding: 0 1rem;

  border: 1px solid ${colors.outline};
  border-radius: 4px;

  background: transparent;
  ${({ isLight }) => css`
    color: ${isLight ? colors.backgroundDark : colors.textOnBackground.medium};
  `}
  font-size: 0.875rem;

  outline: none;

  &:focus {
    box-shadow: 0 0 0 1px ${colors.primary};
    ${({ isLight }) => css`
      border-color: ${isLight ? colors.outline : colors.primary};
    `}
  }

  &:disabled {
    ${({ isLight }) => css`
      color: ${isLight
        ? colors.backgroundDark
        : colors.textOnBackground.disable};
      border: 1px solid ${isLight ? '#c4c4c4' : colors.outline};
    `}

    & + span {
      color: ${colors.textOnBackground.disable};
    }

    & ~ label {
      color: ${colors.backgroundDark};
    }
  }

  &.error {
    border: 1px solid ${colors.textOnBackground.error};

    &:focus {
      box-shadow: 0 0 0 1px ${colors.textOnBackground.error};
      border-color: ${colors.textOnBackground.error};
    }
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #202020 inset !important;
    ${({ isLight }) => css`
      -webkit-text-fill-color: ${isLight
        ? colors.backgroundDark
        : colors.textOnBackground.medium} !important;
    `}
  }
`;

export const ErrorMessageStyled = styled.span`
  font-size: 0.75rem;
  color: ${colors.textOnBackground.error};
  margin-top: 0.25rem;
  margin-left: 1rem;
`;
