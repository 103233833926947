import { useFormContext } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';

import {
  TextFieldStyled,
  LabelStyled,
  TextAreaStyled,
  ErrorMessageStyled,
} from './style';

type TextAreaType = React.InputHTMLAttributes<HTMLTextAreaElement>;

type TextAreaProps = {
  name: string;
  label: string;
  validation?: any;
  height?: string | undefined;
  readonly?: boolean;
} & TextAreaType;

export function TextArea(props: TextAreaProps) {
  const { name, label, disabled, validation, height, readOnly, ...rest } =
    props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message;
  const registration = !disabled && { ...register(name, validation) };

  return (
    <TextFieldStyled height={height}>
      <TextAreaStyled
        {...rest}
        {...registration}
        name={name}
        className={errorMessage && 'error'}
        disabled={disabled}
        placeholder=" "
        readOnly={readOnly}
      />
      <ErrorMessageStyled>
        <ErrorMessage errors={errors} name={name} />
      </ErrorMessageStyled>
      <LabelStyled htmlFor={name} readOnly={readOnly}>
        {label}
      </LabelStyled>
    </TextFieldStyled>
  );
}

TextArea.defaultProps = {
  validation: {},
  height: undefined,
};
