/* eslint-disable import/no-duplicates */
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DatePickerCalendar } from 'react-nice-dates';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { AnimatePresence, motion } from 'framer-motion';

import { Field } from '../Field';
import { CalendarIcon } from '../Icons/CalendarIcon';
import {
  CalendarFieldHolder,
  DatePickerContainer,
  CalendarIconStyled,
} from './style';

import 'react-nice-dates/build/style.css';

interface DatePickerProps {
  disabled?: boolean;
  onClick?: () => void;
}

export function DatePicker(props: DatePickerProps) {
  const { disabled, onClick } = props;
  const { setValue } = useFormContext();
  const [date, setDate] = useState<any>(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  useEffect(() => {
    if (!date) return;

    setValue('datePicker', date);
    setValue('datePickerLabel', format(date, 'dd/MM/yyyy', { locale: ptBR }));
    setIsCalendarOpen(false);
  }, [date, setValue]);

  return (
    <DatePickerContainer onClick={() => onClick && onClick()}>
      <Field name="datePicker" hidden />

      <CalendarFieldHolder onClick={() => !disabled && setIsCalendarOpen(true)}>
        <CalendarIconStyled>
          <CalendarIcon />
        </CalendarIconStyled>
        <Field
          id="datePicker"
          name="datePickerLabel"
          onClick={() => !disabled && setIsCalendarOpen(true)}
          readOnly
        />
      </CalendarFieldHolder>

      <AnimatePresence>
        {isCalendarOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 240 }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: 'linear' }}
            style={{ overflow: 'hidden' }}
            key="datePicker"
          >
            <DatePickerCalendar
              date={date}
              onDateChange={setDate}
              locale={ptBR}
              weekdayFormat="EEEEE"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </DatePickerContainer>
  );
}

DatePicker.defaultProps = {
  disabled: false,
  onClick: false,
};
