import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { Field, FieldProps } from 'components/atoms/Field';
import fetchJsonp from 'fetch-jsonp';
import { Services } from 'services/service';
import { validator } from 'utils/validators/form-validations';

import { TextFieldStyled, LabelStyled } from '../TextField/style';

interface CnpjDataModel {
  cnpj: string;
  name: string;
  legalName: string;
}
type TextFieldProps = {
  label?: string;
  onCnpjFound: (data: CnpjDataModel) => void;
} & FieldProps;

export function CnpjSearchField(props: TextFieldProps) {
  const { name, label, onCnpjFound, ...rest } = props;
  const cnpj = useWatch({ name: 'cnpj' });

  useEffect(() => {
    const unmasked = (cnpj || '').replace(/\D/g, '');
    if (unmasked.length === 14) {
      // external webservice requires JSONP requests
      fetchJsonp(`${Services.CnpjSearch}/${unmasked}`)
        .then((resp) => resp.json())
        .then((resp) => {
          const data = {
            cnpj: resp.cnpj,
            name: resp.fantasia || resp.nome,
            legalName: resp.nome,
            address: resp.logradouro,
            city: resp.municipio,
            state: resp.uf,
            country: 'BR',
          };
          onCnpjFound(data);
        });
    }
  }, [cnpj]);

  return (
    <TextFieldStyled>
      <Field
        pattern="cnpjMask"
        validation={validator('cnpjNotRequired')}
        id={name}
        name={name}
        placeholder=" "
        {...rest}
      />
      <LabelStyled htmlFor={name}>{label}</LabelStyled>
    </TextFieldStyled>
  );
}

CnpjSearchField.defaultProps = {
  label: 'CNPJ',
};
