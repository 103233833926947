interface PlusProps {
  color?: string;
}

export function Plus({ color }: PlusProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8332 10.8333H10.8332V15.8333H9.1665V10.8333H4.1665V9.16667H9.1665V4.16667H10.8332V9.16667H15.8332V10.8333Z"
        fill={color}
        fillOpacity="0.87"
      />
    </svg>
  );
}

Plus.defaultProps = {
  color: 'white',
};
