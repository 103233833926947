import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const RevenueFieldContainer = styled.div``;

export const RevenueFields = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RevenueField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  width: 16rem;
  box-shadow: 0 5px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const variants = {
  danger: colors.textOnBackground.error,
  warning: colors.secondary,
  primary: colors.primary,
};

interface ValueContainerProps {
  variant: string;
}

export const ValueContainer = styled.p<ValueContainerProps>`
  ${({ variant }) => css`
    color: ${variants[variant]};
  `}
`;
