import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useUser } from 'contexts/UserContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { AnimatePresence } from 'framer-motion';
import { GET_USER_BY_ID } from 'pages/Users/query';
import { capitalize } from 'utils/formatters/capitalize';

import { HomeIcon } from '../Icons/HomeIcon';
import { NeuronOsIcon } from '../Icons/NeuronOsIcon';
import {
  ArrowStyleContainer,
  InvisibleDivContainer,
  LabelHolder,
  ListItemStyleContainer,
  ListStyleContainer,
  ListTextContainer,
  NeuronIconStyledDiv,
  NeuronSelectorBorder,
  NeuronSelectorDiv,
  NeuronTextContainer,
  SelectedTextContainer,
  WorkspaceListDiv,
  WorkspaceSelectorStyled,
} from './style';

interface WorkspaceSelectorProps {
  isExpanded: boolean;
}
interface Company {
  text: string;
  key: string;
}

export function WorkspaceSelector({ isExpanded }: WorkspaceSelectorProps) {
  const [toogleMenu, setToggleMenu] = useState(false);
  const handleClick = (e: any) => {
    if (
      e.type === 'click' ||
      (e.type === 'keydown' && (e.keyCode === 32 || e.keyCode === 13))
    )
      setToggleMenu((prev) => !prev);
  };
  const {
    neuronMode,
    setCurrentCompany,
    setNeuronMode,
    currentCompanyId,
    companyList,
  } = useWorkspace();

  const user = useUser();
  const { permission } = user.userData;

  const [getUserById, getUserByIdRes] = useLazyQuery(GET_USER_BY_ID);
  const authorizedCompanies =
    getUserByIdRes?.data?.UserItems?.items[0]?.authorizedCompanies;

  const [newAuthorizedCompanies, setNewAuthorizedCompanies] = useState([
    { text: '', key: '' },
  ]);

  useEffect(() => {
    if (user.userData._id) {
      getUserById({ variables: { id: user.userData._id } });
    }
  }, [user.userData._id]);

  useEffect(() => {
    if (authorizedCompanies) {
      const tmpAuthorizedCompanies: [{ text: ''; key: '' }] =
        authorizedCompanies?.map((i) => {
          return {
            text: capitalize(i.name),
            key: i._id,
          };
        });
      setNewAuthorizedCompanies(tmpAuthorizedCompanies);
    }
  }, [authorizedCompanies]);

  function handleWorkspaceChange(company: Company) {
    setNeuronMode(false);
    setCurrentCompany(company.key, company.text);
  }

  function enterNeuronOsMode() {
    setNeuronMode(true);
  }

  return (
    <>
      <WorkspaceSelectorStyled
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <LabelHolder>
          <HomeIcon variant="light" width={24} height={24} />
          <SelectedTextContainer
            initial={false}
            animate={{
              opacity: isExpanded ? 1 : 0,
              width: isExpanded ? 140 : 0,
            }}
            transition={{
              type: 'linear',
              duration: 0.3,
              delay: isExpanded ? 0.3 : 0,
            }}
          >
            {permission === 'Team' || permission === 'Lead'
              ? newAuthorizedCompanies.find(
                  (company) => company.key === currentCompanyId,
                )?.text
              : neuronMode
              ? 'Neuron OS'
              : companyList.find((company) => company.key === currentCompanyId)
                  ?.text}
          </SelectedTextContainer>
        </LabelHolder>

        <ArrowStyleContainer direction={toogleMenu ? 'down' : 'up'} />
      </WorkspaceSelectorStyled>
      <AnimatePresence>
        {toogleMenu && (
          <>
            <InvisibleDivContainer
              onClick={() => {
                setToggleMenu(false);
              }}
            />
            <WorkspaceListDiv
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ListStyleContainer>
                {permission === 'Team' || permission === 'Lead' ? (
                  <></>
                ) : (
                  <>
                    <NeuronSelectorDiv
                      role="button"
                      onClick={() => {
                        enterNeuronOsMode();
                        setToggleMenu(false);
                      }}
                      onKeyDown={(key) => {
                        if (key.code === 'Enter' || key.code === 'Space ') {
                          enterNeuronOsMode();
                          setToggleMenu(false);
                        }
                      }}
                    >
                      <NeuronIconStyledDiv>
                        <NeuronOsIcon width={15} height={15} variant="light" />
                      </NeuronIconStyledDiv>
                      <NeuronTextContainer>Neuron OS</NeuronTextContainer>
                    </NeuronSelectorDiv>
                    <NeuronSelectorBorder />
                  </>
                )}
                {newAuthorizedCompanies.map((option) => {
                  return (
                    <ListItemStyleContainer
                      key={`workspace-${option.text}`}
                      role="button"
                      onClick={() => {
                        handleWorkspaceChange(option);
                        setToggleMenu(false);
                      }}
                      onKeyDown={(key) => {
                        if (key.code === 'Enter' || key.code === 'Space ') {
                          handleWorkspaceChange(option);
                          setToggleMenu(false);
                        }
                      }}
                    >
                      <ListTextContainer>{option.text}</ListTextContainer>
                    </ListItemStyleContainer>
                  );
                })}
              </ListStyleContainer>
            </WorkspaceListDiv>
          </>
        )}
      </AnimatePresence>
    </>
  );
}
