import styled from 'styled-components';

export const PermissionTextStyled = styled.p`
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;

  /* Dark Theme/Background */

  background: #181818;
  border-radius: 15px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.25px;

  /* Dark Theme/Text on Background/Active */

  color: #e1e1e1;
`;

export const PermissionColumnDivStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TrashStyled = styled.div`
  cursor: pointer;
`;
