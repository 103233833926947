import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

interface TextFieldStyledProps {
  height?: string | undefined;
}
export const TextFieldStyled = styled.div<TextFieldStyledProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: 0.625rem 0;

  position: relative;

  textarea {
    ${({ height }) => css`
      ${height ? `height: ${height}rem;` : 'height: 6.25rem;'}
    `}

    resize: none;
    padding: 1rem;
  }

  textarea:focus,
  textarea:not(:placeholder-shown) {
    & ~ label {
      top: -0.5rem;
      left: 0.625rem;

      font-size: 0.75rem;
      padding: 0 0.25rem;
    }
  }

  textarea:focus {
    & ~ label {
      color: ${colors.primary};
    }
  }

  textarea.error {
    & ~ label,
    & ~ span {
      color: ${colors.textOnBackground.error};
    }
  }

  textarea:disabled {
    & ~ label,
    & ~ span {
      color: ${colors.textOnBackground.disable};
      cursor: not-allowed;
    }
  }
`;

interface LabelStyledProps {
  readOnly?: boolean;
}

export const LabelStyled = styled.label<LabelStyledProps>`
  position: absolute;
  top: 1.5rem;
  left: 1rem;

  transition: 0.1s all;
  line-height: 1rem;
  font-size: 0.875rem;

  cursor: text;

  background: ${colors.backgroundLight};
  color: ${colors.textOnBackground.medium};

  ${({ readOnly }) =>
    readOnly &&
    css`
      color: ${colors.textOnBackground.disable};
    `}
`;

interface TextAreaStyledProps {
  readOnly?: boolean;
}

export const TextAreaStyled = styled.textarea<TextAreaStyledProps>`
  width: 100%;
  height: 2.5rem;
  padding: 0 1rem;

  border: 1px solid ${colors.outline};
  border-radius: 4px;

  background: ${colors.backgroundLight};
  color: ${colors.textOnBackground.medium};
  ${({ readOnly }) =>
    readOnly &&
    css`
      color: ${colors.textOnBackground.disable};
    `}
  font-size: 0.875rem;

  outline: none;

  &:focus {
    box-shadow: 0 0 0 1px ${colors.primary};
    border-color: ${colors.primary};
  }

  &:disabled {
    border: 1px solid ${colors.outline};
    color: ${colors.textOnBackground.disable};
    cursor: not-allowed;

    & + span {
      color: ${colors.textOnBackground.disable};
    }
  }

  &.error {
    border: 1px solid ${colors.textOnBackground.error};

    &:focus {
      box-shadow: 0 0 0 1px ${colors.textOnBackground.error};
      border-color: ${colors.textOnBackground.error};
    }
  }
`;

export const ErrorMessageStyled = styled.span`
  font-size: 0.75rem;
  color: ${colors.textOnBackground.error};
  margin-top: 0.25rem;
  margin-left: 1rem;
`;
