import { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';

import { ContactForm } from './components/ContactForm';
import { DELETE_CONTACT, GET_CONTACTS } from './query';
import { ClientContactsContainer, TrashStyled } from './style';

export function ClientContacts() {
  const { openDrawer } = useDrawer();
  const [searchTerm, setSearchTerm] = useState('');
  const [getContacts, contactsResponse] = useLazyQuery(GET_CONTACTS, {
    notifyOnNetworkStatusChange: true,
  });
  const [deleteContact] = useMutation(DELETE_CONTACT);
  const contactsData = contactsResponse.data?.ContactItems?.items || [];
  const pages = contactsResponse.data?.ContactItems?.pages || 1;

  const handleAction = (id?: string) => {
    openDrawer({
      title: id ? 'Editar Contato' : 'Adicionar Contato',
      content: <ContactForm id={id} refetch={contactsResponse.refetch} />,
      formId: 'contact-form',
      width: 600,
    });
  };

  const handleDelete = (id: string) => {
    deleteContact({ variables: { id } });

    if (contactsResponse.refetch) contactsResponse.refetch();
  };

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  return (
    <ClientContactsContainer>
      <Toolbar title="" onSearch={setSearchTerm} onAction={handleAction} />
      <Table
        pages={pages}
        fetchData={getContacts}
        searchTerm={searchTerm}
        columns={[
          { Header: 'Setor', accessor: 'area' },
          { Header: 'Nome', accessor: 'name' },
          { Header: 'Email', accessor: 'email' },
          { Header: 'Produto', accessor: 'product' },
        ]}
        loading={contactsResponse.loading}
        emptyText="Não há registros para consulta"
        data={contactsData}
        actions={(rowData) => [
          {
            component: (
              <TrashStyled onClick={() => handleDelete(rowData._id)}>
                <TrashIcon variant="light" width={24} height={24} />
              </TrashStyled>
            ),
          },
          {
            component: (
              <TrashStyled onClick={() => handleAction(rowData._id)}>
                <GearIcon variant="light" width={20} height={20} />
              </TrashStyled>
            ),
          },
        ]}
      />
    </ClientContactsContainer>
  );
}
