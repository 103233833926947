import styled from 'styled-components';
import { colors } from 'styles/colors';

export const AttachContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  > p {
    color: ${colors.white};
    font-weight: bold;
  }

  > button > span {
    margin: 0 0 0 0.75rem;
  }

  > button {
    display: flex;
    align-items: center;
    color: ${colors.primary};
    background-color: transparent;
    border: none;
  }
`;
