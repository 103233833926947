import { gql } from '@apollo/client';

export const QUERY_COMPANY_REPASS = gql`
  query queryCompany($companyId: String!) {
    CompanyItems(limit: 1, page: 1, id: $companyId) {
      items {
        transferAllowed
      }
    }
  }
`;
