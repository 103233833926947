import { useFormContext } from 'react-hook-form';

import { VisibilityIcon } from 'components/atoms/Icons/VisibilityIcon';
import { VisibilityOffIcon } from 'components/atoms/Icons/VisibilityOffIcon';
import { TextField } from 'components/molecules/TextField';
import { FormGroupWithAdding } from 'components/organisms/FormGroupWithAdding';
import { UnstyledButton } from 'pages/Companies/components/CompanyBankAccounts/style';
import {
  NoPrint,
  PresetsColumn,
} from 'pages/PrintedBudget/components/PrintedBudgetTable/style';
import {
  PrintedBudgetColumnTitle,
  PrintedBudgetTitleContainer,
} from 'pages/PrintedBudget/style';
import { clearCurrencyStrBrl, toCurrencyBrl } from 'utils/masks';

interface Props {
  groupName: string;
  isVisible?: boolean;
  onVisibilityClick?: () => void;
}
export function Presets(props: Props) {
  const { groupName, isVisible, onVisibilityClick } = props;

  const { setValue, getValues } = useFormContext();

  const updateTotal = (hours, index) => {
    const totalValue = toCurrencyBrl(
      clearCurrencyStrBrl(getValues(`${groupName}.presets.${index}.value`)) *
        hours,
    );
    setValue(`${groupName}.presets.${index}.total`, totalValue);
  };

  return (
    <div>
      <PrintedBudgetTitleContainer>
        <PrintedBudgetColumnTitle>Presets</PrintedBudgetColumnTitle>
        <UnstyledButton
          title="Visível"
          type="button"
          onClick={onVisibilityClick}
          style={{ marginBottom: 16 }}
        >
          <NoPrint data-html2canvas-ignore>
            {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </NoPrint>
        </UnstyledButton>
      </PrintedBudgetTitleContainer>
      {isVisible && (
        <FormGroupWithAdding
          title="Presets"
          groupName={`${groupName}.presets`}
          withoutAdding
          renderRow={({ index }) => (
            <PresetsColumn>
              <TextField
                name={`${groupName}.presets.${index}.item`}
                label="Item"
                placeholder="Item"
                isLight
              />
              <TextField
                name={`${groupName}.presets.${index}.quantity`}
                label="Quantidade"
                defaultValue={0}
                onInput={(hours) => {
                  updateTotal(hours, index);
                }}
                isLight
              />
              <TextField
                name={`${groupName}.presets.${index}.value`}
                label="Valor Preset"
                defaultValue={0}
                readOnly
                isLight
              />
              <TextField
                name={`${groupName}.presets.${index}.total`}
                label="Total com Impostos"
                defaultValue={0}
                readOnly
                isLight
              />
            </PresetsColumn>
          )}
        />
      )}
    </div>
  );
}
