import styled, { css } from 'styled-components';

interface PrintedBudgetTableContainerProps {
  isVisible?: boolean;
}

export const PrintedBudgetTableContainer = styled.div<PrintedBudgetTableContainerProps>`
  position: relative;

  ${({ isVisible }) =>
    isVisible &&
    css`
      margin-bottom: 9.75rem;
    `}

  > div {
    align-items: center;
  }
`;

export const PrintedBudgetLine = styled.div`
  position: absolute;
  top: 0;
  left: -26px;
  height: 100%;
  border-left: 3px solid black;

  @media print {
    border-left: none;
  }
`;

interface PrintedBudgetTableHeaderProps {
  index?: number;
}

export const PrintedBudgetTableHeader = styled.div<PrintedBudgetTableHeaderProps>`
  ${({ index }) =>
    (index && index % 2 === 0) || index === 0
      ? css`
          background: rgba(0, 0, 0, 0.02);
        `
      : css`
          background: transparent;
        `}

  margin: 0 -2rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;

  @media print {
    margin: 0;

    ${({ index }) =>
      (index && index % 2 === 0) || index === 0
        ? css`
            background: rgba(0, 0, 0, 0.02);
          `
        : css`
            background: transparent;
          `}
  }
`;

export const PrintedBudgetTableColumn = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  gap: 3rem;
`;

export const InternalProductColumn = styled.div`
  display: grid;
  grid-template-columns: 2.75fr 2.75fr 1.5fr 1.5fr 1.5fr;
  gap: 0 1rem;
`;

export const PresetsColumn = styled.div`
  display: grid;
  grid-template-columns: 3.75fr 1fr 1fr 1fr;
  gap: 0 1rem;
`;

export const ThirdyColumn = styled.div`
  display: grid;
  grid-template-columns: 5fr 6fr 3fr 2fr 3fr 4fr;
  gap: 0 1rem;
`;

export const InvoiceColumn = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 2fr 2fr 1fr 2fr 2fr;
  gap: 0 1rem;
`;

export const RightAlign = styled.span`
  display: flex;
  justify-content: right;
`;

export const NoPrint = styled.span`
  @media print {
    display: none;
  }
`;
