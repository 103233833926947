import styled, { css } from 'styled-components';

import { colors } from './colors';

export const DefaultForm = styled.form`
  > div {
    padding-bottom: 1rem;
  }
  display: grid;
`;

interface IconButtonProps {
  color?: string;
  size?: number;
}

export const IconButton = styled.button<IconButtonProps>`
  ${({ color, size }) => css`
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    font-size: 0;

    ${size &&
    css`
      font-size: ${size}rem;
      color: ${color || 'white'};
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 0.5rem;
    `}

    &:disabled {
      opacity: 0.25;
    }
  `}
`;

interface GridColumnProps {
  length?: number;
  gap: number;
  fragment?: boolean;
}

export const GridColumn = styled.div<GridColumnProps>`
  ${({ length, gap, fragment }) => css`
    display: grid;
    grid-template-columns: ${fragment
      ? `repeat(${length}, 1fr)`
      : `repeat(${length}, fit-content(100%))`};
    grid-column-gap: ${gap}rem;
    align-items: center;
    width: ${fragment ? '100%' : 'auto'};
  `}
`;

export const Card = styled.div`
  background-color: ${colors.backgroundLight};
  border-radius: 4px;
  padding: 1.5rem 1rem;
`;

interface StatusLabelProps {
  variant: 'active' | 'inactive' | 'warning' | 'error' | 'success';
}

const variants = {
  active: {
    border: colors.primary,
    color: colors.primary,
  },
  inactive: {
    border: colors.outline,
    color: colors.textOnBackground.disable,
  },
  warning: {
    border: colors.secondary,
    color: colors.secondary,
  },
  error: {
    border: colors.textOnBackground.error,
    color: colors.textOnBackground.error,
  },
  success: {
    border: colors.textOnBackground.success,
    color: colors.textOnBackground.success,
  },
};

export const StatusLabel = styled.div<StatusLabelProps>`
  ${({ variant }) => css`
    background-color: ${colors.background};
    padding: 6px 12px;
    border-radius: 20px;
    width: fit-content;
    border: 1px solid ${variants[variant].border};
    color: ${variants[variant].color};
  `}
`;
interface LineProps {
  color?: string;
}

export const Line = styled.hr<LineProps>`
  border: 0;

  ${({ color }) => css`
    border-top: 1px solid ${color};
  `}
`;

Line.defaultProps = {
  color: colors.outline,
};

interface FlexContainerProps {
  value?: number;
}

export const FlexContainer = styled.div<FlexContainerProps>`
  ${({ value }) => css`
    flex: ${value};
  `}
`;

FlexContainer.defaultProps = {
  value: 1,
};
