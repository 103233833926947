import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useMutation } from '@apollo/client';
import { Spinner } from 'components/atoms/Spinner';
import { TextArea } from 'components/atoms/TextArea';
import { useDrawer } from 'contexts/DrawerContext';
import { useUser } from 'contexts/UserContext';
import { DefaultForm } from 'styles/miscellany';
import { removeAttribute } from 'utils/formatters/remove-typename';

import { UPDATE_LEGALS } from './query';

interface LegalFormProps {
  refetch: any;
  legalsData: any;
  clientId: string;
  id?: string;
}

export function LegalForm(props: LegalFormProps) {
  const { id, legalsData, clientId, refetch } = props;
  const methods = useForm();
  const { closeDrawer } = useDrawer();
  const [updateLegals, updateLegalsResponse] = useMutation(UPDATE_LEGALS);
  const { userData } = useUser();

  useEffect(() => {
    if (id) {
      legalsData.forEach((item) => {
        if (item._id === id) {
          Object.entries(item).forEach(([key, value]) => {
            if (key !== '__typename') {
              methods.setValue(key, value);
            }
          });
        }
      });
    }
  }, [legalsData, methods, id]);

  const onSubmit = (dataForm) => {
    let newLegals = [...removeAttribute(legalsData, '__typename')];

    const rowIndex = newLegals.findIndex((item) => item._id === id);

    if (id) {
      newLegals[rowIndex || 0] = {
        ...dataForm,
        metadata: JSON.stringify({ ...userData, date: new Date() }),
      };
    } else {
      newLegals.push({
        ...dataForm,
        metadata: JSON.stringify({ ...userData, date: new Date() }),
      });
    }

    newLegals = [...removeAttribute(newLegals, '_id')];

    updateLegals({
      variables: { id: clientId, legals: newLegals },
    });
  };

  useEffect(() => {
    if (updateLegalsResponse.data) {
      toast.success(`Cadastro ${id ? 'atualizado' : 'efetuado'}!`);

      refetch();

      closeDrawer();
    }

    if (updateLegalsResponse.error) {
      toast.error(`Ops, falha ao ${id ? 'atualizar' : 'efetuar'} o cadastro.`);
    }
  }, [updateLegalsResponse.data, updateLegalsResponse.error, refetch]);

  if (updateLegalsResponse.loading) return <Spinner />;

  return (
    <FormProvider {...methods}>
      <DefaultForm onSubmit={methods.handleSubmit(onSubmit)} id="legal-form">
        <TextArea name="message" label="Anotação" height="15" />
      </DefaultForm>
    </FormProvider>
  );
}
