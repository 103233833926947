import { useFormContext } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { mask } from 'remask';
import { capitalize } from 'utils/formatters/capitalize';
import {
  clearCurrencyStr,
  clearCurrencyStrBrl,
  cnpjMask,
  cpfMask,
  dateMask,
  phoneMask,
  quotaMask,
  toCurrency,
  toCurrencyBrl,
} from 'utils/masks';

import { ErrorMessageStyled, InputStyled } from './style';

type InputType = React.InputHTMLAttributes<HTMLInputElement>;

export type FieldProps = {
  pattern?: 'money' | 'moneyPrefix' | string;
  validation?: any | undefined;
  name: string;
  isLight?: boolean;
} & InputType;

export function Field(props: FieldProps) {
  const {
    name,
    pattern,
    validation,
    disabled,
    onInput,
    isLight,
    onBlur,
    ...rest
  } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  function applyMask(e) {
    if (pattern === 'money') {
      const val = clearCurrencyStr(e.target.value);
      e.target.value = toCurrency(val);
    } else if (pattern === 'moneyPrefix') {
      const val = clearCurrencyStrBrl(e.target.value);
      e.target.value = toCurrencyBrl(mask(val, '999999999999999999999'));
    } else if (pattern === 'quotaMask') {
      const val = clearCurrencyStr(e.target.value);
      e.target.value = quotaMask(val);
    } else if (pattern === 'phoneMask') {
      e.target.value = phoneMask(e.target.value);
    } else if (pattern === 'dateMask') {
      e.target.value = dateMask(e.target.value);
    } else if (pattern === 'cnpjMask') {
      e.target.value = cnpjMask(e.target.value);
    } else if (pattern === 'cpfMask') {
      e.target.value = cpfMask(e.target.value);
    } else if (pattern === 'capitalize') {
      e.target.value = capitalize(e.target.value);
    }

    if (onInput) onInput(e.target.value);
  }

  const errorMessage = errors[name]?.message;
  const registration = !disabled && { ...register(name, validation) };

  return (
    <>
      <InputStyled
        {...rest}
        {...registration}
        name={name}
        onBlur={onBlur}
        onInput={(e) => applyMask(e)}
        className={errorMessage && 'error'}
        disabled={disabled}
        isLight={isLight}
      />
      {errorMessage && (
        <ErrorMessageStyled>
          <ErrorMessage errors={errors} name={name} />
        </ErrorMessageStyled>
      )}
    </>
  );
}

Field.defaultProps = {
  pattern: null,
  validation: {},
};
