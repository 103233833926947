export function PenIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6696 3.98773C14.9459 4.26398 14.9459 4.71023 14.6696 4.98648L13.3734 6.28273L10.7171 3.62648L12.0134 2.33023C12.2896 2.05398 12.7359 2.05398 13.0121 2.33023L14.6696 3.98773ZM2.125 14.8748V12.2186L9.95917 4.3844L12.6154 7.04065L4.78125 14.8748H2.125Z"
        fill="#5A52FF"
      />
    </svg>
  );
}
