import { gql } from '@apollo/client';

export const GET_INTERNAL_PRODUCTIONS_LIST = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $sorting: [SorterInput]
    $type: String!
    $unit: String!
  ) {
    ConfigurationItems(
      limit: $limit
      page: $page
      search_term: $search_term
      sorting: $sorting
      type: $type
      unit: $unit
    ) {
      items {
        _id
        label
        type
        metadata
      }
      pages
      total
    }
  }
`;

export const GET_THIRDPARTY_BY_ID = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $sorting: [SorterInput]
    $id: String!
  ) {
    ConfigurationItems(
      id: $id
      limit: $limit
      page: $page
      search_term: $search_term
      sorting: $sorting
    ) {
      items {
        _id
        label
        type
        metadata
      }
      pages
    }
  }
`;

export const UPDATE_INTERNAL_PRODUCTION = gql`
  mutation update(
    $id: String!
    $label: String!
    $type: String!
    $metadata: String!
    $unit: String!
  ) {
    configurationSetup(
      id: $id
      label: $label
      type: $type
      metadata: $metadata
      unit: $unit
    )
  }
`;

export const CREATE_INTERNAL_PRODUCTION = gql`
  mutation createConfiguration(
    $label: String!
    $type: String!
    $metadata: String!
    $unit: String!
  ) {
    createConfiguration(
      label: $label
      type: $type
      metadata: $metadata
      unit: $unit
    ) {
      _id
    }
  }
`;

export const DELETE_INTERNAL_PRODUCTION = gql`
  mutation deleteConfiguration($id: String!) {
    deleteConfiguration(id: $id)
  }
`;
